import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AttachIcon from '../../assets/AttachIcon'
import CheckIcon from '../../assets/CheckIcon'
import CloseIcon from '../../assets/CloseIcon'
import {
    DOCUMENTS_TYPE,
    PAGE_CONST,
    PERMISSION_FEATURE,
    PERMISSION_SCREEN,
    PERMISSION_TYPE
} from '../../common/constant/page-constant'
import { redirect } from '../../common/utils/redirectionUtils'
import { documentUploadHelper } from '../../helper/create-account-helper'
import './DocumentUpload.scss'
import { getDocumentsHelper } from '../../helper/personal-details-helper'
import { convertToTitleCase } from '../../common/utils/appUtils'

const DocumentUploadForm = props => {
    const [isFieldErrorVisible, setIsFieldErrorVisible] = useState(false)

    const [tradeLicenceFile, setTradeLicenceFile] = useState(undefined)
    const [dgdaLicenceFile, setDgdaLicenceFile] = useState(undefined)
    const [binFile, setBinFile] = useState(undefined)
    const [tinFile, setTinFile] = useState(undefined)
    const [vatFile, setVatFile] = useState(undefined)
    const [documents, setDocuments] = useState([])
    const [permissions, setPermissions] = useState({})
    // const []
    const [err, setErr] = useState('')

    const navigate = useNavigate()

    useEffect(() => {
        getDocumentsData()
    }, [])

    useEffect(() => {
        if (props.loginData.permissions) {
            let permission = props.loginData.permissions.filter(
                permission => permission.screen === PERMISSION_SCREEN.OTHER
            )[0]

            if (permission) setPermissions(permission)
        }
    }, [props.loginData])

    const checkValidation = () => {
        let tradeDocumentFromAPI = documents.find(
            doc => doc.type === DOCUMENTS_TYPE.TRADE_LICENSE_NUMBER
        )

        let dgdaDocumentFromAPI = documents.find(
            doc => doc.type === DOCUMENTS_TYPE.DGDA_LICENSE_NUMBER
        )

        return (
            (tradeLicenceFile?.number || tradeDocumentFromAPI) &&
            (dgdaLicenceFile?.number || dgdaDocumentFromAPI)
        )
    }

    const getDocumentsData = () => {
        getDocumentsHelper()
            .then(res => {
                setDocuments(res)
                console.log(res)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            setIsFieldErrorVisible(true)
            return
        }

        setIsFieldErrorVisible(false)
        let data = {
            tradeLicenceFile,
            dgdaLicenceFile,
            binFile,
            tinFile,
            vatFile
        }

        console.log(data)
        // return
        documentUploadHelper(data)
            .then(res => {
                if (!res.user?.is_verified) {
                    redirect(navigate, PAGE_CONST.OTP_VERIFICATION)
                    return
                } else if (!res.pharmacy.is_verified) {
                    redirect(navigate, PAGE_CONST.DOCUMENT_UPLOAD)
                    return
                } else if (res.user?.is_verified && res.pharmacy?.is_verified) {
                    redirect(navigate, PAGE_CONST.HOME)
                } else {
                    // some Error msg
                }
            })
            .catch(err => {
                console.log(err)
                setErr(err)
            })
        // redirect(navigate, PAGE_CONST.HOME);
    }

    const getFileState = type => {
        switch (type) {
            case DOCUMENTS_TYPE.TRADE_LICENSE_NUMBER:
                return tradeLicenceFile
            case DOCUMENTS_TYPE.DGDA_LICENSE_NUMBER:
                return dgdaLicenceFile
            case DOCUMENTS_TYPE.BIN_NUMBER:
                return binFile
            case DOCUMENTS_TYPE.TIN_NUMBER:
                return tinFile
            case DOCUMENTS_TYPE.VAT_NUMBER:
                return vatFile
            default:
                return null
        }
    }

    const setFileState = (type, file) => {
        switch (type) {
            case DOCUMENTS_TYPE.TRADE_LICENSE_NUMBER:
                setTradeLicenceFile(file)
                break
            case DOCUMENTS_TYPE.DGDA_LICENSE_NUMBER:
                setDgdaLicenceFile(file)
                break
            case DOCUMENTS_TYPE.BIN_NUMBER:
                setBinFile(file)
                break
            case DOCUMENTS_TYPE.TIN_NUMBER:
                setTinFile(file)
                break
            case DOCUMENTS_TYPE.VAT_NUMBER:
                setVatFile(file)
                break
            default:
                break
        }
    }

    const removeFile = type => {
        if (!type) return

        documents.splice(
            documents.findIndex(a => a.type === type),
            1
        )
        setFileState(type, undefined)

        // switch (type) {
        //     case DOCUMENTS_TYPE.TRADE_LICENSE_NUMBER:
        //         documents.splice(documents.findIndex(a => a.type === type) , 1)
        //         setTradeLicenceFile(undefined);
        //         break;
        //     case DOCUMENTS_TYPE.DGDA_LICENSE_NUMBER:
        //         documents.splice(documents.findIndex(a => a.type === type) , 1)
        //         setDgdaLicenceFile(undefined);
        //         break;
        //     case DOCUMENTS_TYPE.BIN_NUMBER:
        //         setBinFile(undefined);
        //         break;
        //     case DOCUMENTS_TYPE.TIN_NUMBER:
        //         setTinFile(undefined);
        //         break;
        //     case DOCUMENTS_TYPE.VAT_NUMBER:
        //         setVatFile(undefined);
        //         break;
        //     default:
        //         return null
        // }
    }

    const getDocumentForHtml = type => {
        // Check if the document exists in the state
        const existingDocument = documents.find(doc => doc.type === type)
        const fileFromState = getFileState(type)

        if (existingDocument || fileFromState) {
            // Display existing document
            return (
                <div className="formGroup typeFile">
                    <div className="uploadFile dFlex jcSpaceBetween">
                        <div className="">
                            <div className="inputHeading">
                                {convertToTitleCase(type)?.toUpperCase()}
                                {[
                                    DOCUMENTS_TYPE.TRADE_LICENSE_NUMBER,
                                    DOCUMENTS_TYPE.DGDA_LICENSE_NUMBER
                                ].includes(existingDocument?.type) && (
                                    <span>*</span>
                                )}
                            </div>
                            <div className="uploadFileName">
                                {/* <img
                                    width={30}
                                    src={existingDocument?.url}
                                    onclick="window.open(this.url)"></img> */}
                                <a
                                    id="myLink"
                                    href={existingDocument?.url}
                                    target="_blank">
                                    {existingDocument?.name ||
                                        fileFromState?.name}
                                </a>
                                {existingDocument?.number && (
                                    <div className="">
                                        {`Document Number: ${existingDocument?.number}`}
                                    </div>
                                )}
                                {/* <a onClick>
                                    {existingDocument?.name ||
                                        fileFromState?.name}
                                </a> */}
                            </div>
                            <div className="uploadFileProgress">
                                <div className="progress progress-moved">
                                    <div
                                        className={
                                            fileFromState
                                                ? 'animation progress-bar'
                                                : 'progress-bar'
                                        }></div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="cancelUpload"
                            onClick={() => {
                                setErr('')
                                // Clear the document
                                if (existingDocument) {
                                    setDocuments(
                                        documents.filter(
                                            doc => doc.type !== type
                                        )
                                    )
                                } else {
                                }
                            }}>
                            <div className="cancelUpload">
                                <CheckIcon />{' '}
                            </div>
                            <div onClick={() => removeFile(type, undefined)}>
                                <CloseIcon />
                            </div>
                        </div>
                        {fileFromState?.name && (
                            <div className="formGroup formFloating mt-2 fgrow1">
                                <input
                                    type="text"
                                    className="formControl"
                                    id="phone"
                                    placeholder={`Enter ${convertToTitleCase(
                                        type
                                    )}`}
                                    // value={
                                    //     customerDetails?.[
                                    //         CUSTOMER_FIELDS
                                    //             .PHONE_NUMBER
                                    //     ] || ''
                                    // }
                                    onChange={e => {
                                        fileFromState.number = e.target.value
                                    }}
                                    required
                                />
                                <label for="phone">{`Enter ${convertToTitleCase(
                                    type
                                )}`}</label>
                                {isFieldErrorVisible &&
                                    fileFromState.name &&
                                    !fileFromState?.number && (
                                        <div className="invalidFeedback">
                                            Please enter a valid Document
                                            Number.
                                        </div>
                                    )}
                            </div>
                        )}
                    </div>
                </div>
            )
        } else {
            // Display file input for uploading new document
            return (
                <div className="formGroup typeFile">
                    <div className="beforeUpload">
                        <div className="inputHeading">
                            {convertToTitleCase(type)?.toUpperCase()}
                            {[
                                DOCUMENTS_TYPE.TRADE_LICENSE_NUMBER,
                                DOCUMENTS_TYPE.DGDA_LICENSE_NUMBER
                            ].includes(type) && <span>*</span>}
                        </div>
                        <div className="customFile">
                            <input
                                type="file"
                                className="fileInput"
                                id={type}
                                aria-describedby={type}
                                // value={getFileFromState(type)}
                                onChange={e => {
                                    setErr('')
                                    setFileState(type, e.target.files[0])
                                }}
                            />
                            <label className="fileLabel" htmlFor={type}>
                                <AttachIcon />
                            </label>
                        </div>
                    </div>

                    {/* <input
                        type="text"
                        className="fileInput"
                        id={type}
                        aria-describedby={type}
                        // value={getFileFromState(type)}
                        onChange={e => {
                            setErr('')
                            setFileState(type, e.target.files[0])
                        }}
                    /> */}
                </div>
            )
        }
    }

    return (
        <form onSubmit={e => e.preventDefault()}>
            {getDocumentForHtml(DOCUMENTS_TYPE.TRADE_LICENSE_NUMBER)}
            {getDocumentForHtml(DOCUMENTS_TYPE.DGDA_LICENSE_NUMBER)}
            {getDocumentForHtml(DOCUMENTS_TYPE.BIN_NUMBER)}
            {getDocumentForHtml(DOCUMENTS_TYPE.TIN_NUMBER)}
            {getDocumentForHtml(DOCUMENTS_TYPE.VAT_NUMBER)}
            {err && <div class="invalidFeedback">{err}</div>}
            {(props.btnView ||
                (permissions?.features?.[PERMISSION_FEATURE.DOCUMENTS] ===
                    PERMISSION_TYPE.MANAGE &&
                    (tradeLicenceFile ||
                        dgdaLicenceFile ||
                        binFile ||
                        tinFile ||
                        vatFile))) && (
                <button
                    className="btn btnPrimary block space"
                    onClick={handleSubmit}>
                    {props.btnView ? 'COMPLETE REGISTRATION' : 'UPLOAD'}
                </button>
            )}
            {/* {props.flow && (
                <button
                    className="btn btnPrimary block space"
                    onClick={handleSubmit}>
                        COMPLETE REGISTRATION
                </button>
            )} */}
        </form>
    )
}

const mapStateToProps = state => {
    return {
        loginData: state.loginDataReducer?.loginData
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

DocumentUploadForm.propTypes = {
    btnView: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentUploadForm)
