import React from 'react'

const EditIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Edit">
            <path id="Vector" d="M20.9519 3.04794C20.2809 2.37696 19.3708 2 18.4219 2C17.4729 2 16.5629 2.37696 15.8919 3.04794L3.93987 14.9999C3.53366 15.4062 3.24808 15.9171 3.11487 16.4759L2.01987 21.0779C1.99022 21.2026 1.993 21.3328 2.02794 21.456C2.06288 21.5793 2.12883 21.6916 2.21948 21.7821C2.31013 21.8727 2.42246 21.9385 2.54577 21.9733C2.66908 22.0081 2.79925 22.0107 2.92387 21.9809L7.52487 20.8849C8.08405 20.7519 8.59536 20.4663 9.00187 20.0599L20.9519 8.10994C21.6229 7.43894 21.9998 6.52887 21.9998 5.57994C21.9998 4.63101 21.6229 3.72095 20.9519 3.04994V3.04794ZM16.9519 4.10794C17.1449 3.9149 17.3741 3.76177 17.6263 3.6573C17.8785 3.55282 18.1489 3.49905 18.4219 3.49905C18.6949 3.49905 18.9652 3.55282 19.2174 3.6573C19.4696 3.76177 19.6988 3.9149 19.8919 4.10794C20.0849 4.30099 20.238 4.53016 20.3425 4.78239C20.447 5.03461 20.5008 5.30494 20.5008 5.57794C20.5008 5.85095 20.447 6.12128 20.3425 6.3735C20.238 6.62572 20.0849 6.8549 19.8919 7.04794L18.9999 7.93894L16.0599 4.99994L16.9519 4.10894V4.10794ZM14.9999 6.06194L17.9399 8.99994L7.93987 18.9999C7.72987 19.2099 7.46587 19.3569 7.17687 19.4259L3.76087 20.2399L4.57387 16.8239C4.64287 16.5339 4.79087 16.2699 5.00087 16.0599L14.9999 6.05994V6.06194Z" fill="#EF8658" />
        </g>
    </svg>
)

export default EditIcon
