import { combineReducers } from 'redux'
import { LOGIN_CONST } from '../../common/constant/page-constant'
import { deleteCookieCustom } from '../../common/utils/storageUtils'
import { Types } from '../actions/types'
import { alertReducer } from './alert-reducer'
import { appDataReducer } from './app-data-reducer'
import { configReducer } from './config-reducer'
import { loginDataReducer } from './login-data-reducer'

const appReducer = combineReducers({
    configReducer: configReducer,
    alertReducer: alertReducer,
    loginDataReducer: loginDataReducer,
    appDataReducer: appDataReducer
})

export const rootReducer = (state, action) => {
    if (action.type === Types.LOGOUT) {
        deleteCookieCustom(LOGIN_CONST.ACCESS_TOKEN)
        deleteCookieCustom(LOGIN_CONST.REFRESH_TOKEN)
        return appReducer(undefined, action)
    }

    return appReducer(state, action)
}
