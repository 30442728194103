import React from 'react'

const AttachIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Attach" clip-path="url(#clip0_489_1213)">
            <path id="Vector" d="M18.0114 17.6964C18.0072 19.2631 17.3905 20.7659 16.293 21.8839C15.1955 23.0018 13.7043 23.6462 12.138 23.6792C10.5717 23.7123 9.05464 23.1314 7.91095 22.0608C6.76725 20.9901 6.08767 19.5146 6.01743 17.9496L6.01177 17.6766L6.01319 5.23298L6.01743 5.18631C6.0657 4.26565 6.45385 3.39564 7.10673 2.74472C7.75961 2.0938 8.63078 1.70828 9.55159 1.66278C10.4724 1.61728 11.3773 1.91504 12.0912 2.49843C12.8051 3.08181 13.2771 3.90931 13.4159 4.82073C13.4317 4.87019 13.4423 4.92113 13.4477 4.97276L13.452 5.05337L13.4527 5.17711L13.4576 5.38147H13.4534L13.4661 15.9103C13.4663 16.0899 13.402 16.2637 13.285 16.3999C13.168 16.5362 13.0059 16.6259 12.8283 16.6527L12.7173 16.6605C12.5376 16.6607 12.3639 16.5965 12.2276 16.4794C12.0914 16.3624 12.0017 16.2003 11.9748 16.0227L11.9663 15.9124L11.9536 5.15873C11.8972 4.6052 11.6359 4.09276 11.221 3.72201C10.8061 3.35127 10.2677 3.14901 9.7113 3.15494C9.15494 3.16088 8.62089 3.37458 8.21402 3.75409C7.80715 4.1336 7.55684 4.6515 7.51225 5.20611L7.51013 17.6957C7.52003 18.8631 7.98302 19.981 8.80141 20.8135C9.61981 21.6461 10.7296 22.1282 11.8966 22.1581C13.0637 22.188 14.1967 21.7634 15.0567 20.9738C15.9166 20.1843 16.4363 19.0916 16.5059 17.9262L16.5116 17.6943L16.5102 17.6759L16.5109 4.18929C16.5106 3.99977 16.5821 3.81718 16.711 3.67823C16.8399 3.53928 17.0166 3.45427 17.2056 3.4403C17.3946 3.42632 17.5819 3.48442 17.7298 3.60291C17.8777 3.72139 17.9753 3.89148 18.0029 4.07898L18.0107 4.18999L18.0114 17.695L18.0114 17.6964Z" fill="black" />
        </g>
        <defs>
            <clipPath id="clip0_489_1213">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export default AttachIcon
