import React from 'react'

const ProductIcon = () => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="fluent:box-24-regular">
            <path id="Vector" d="M12.3563 2.93189C13.4105 2.50447 14.5898 2.50447 15.644 2.93189L24.3917 6.47856C24.7685 6.63118 25.0911 6.89282 25.3183 7.22995C25.5455 7.56708 25.6668 7.96436 25.6668 8.37089V19.6327C25.6666 20.0391 25.5451 20.4361 25.318 20.773C25.0908 21.1099 24.7683 21.3713 24.3917 21.5239L15.644 25.0706C14.5898 25.498 13.4105 25.498 12.3563 25.0706L3.60866 21.5239C3.23187 21.3713 2.90921 21.1096 2.68204 20.7725C2.45486 20.4354 2.33349 20.0381 2.3335 19.6316V8.37206C2.33349 7.96553 2.45486 7.56825 2.68204 7.23112C2.90921 6.89399 3.23187 6.63235 3.60866 6.47973L12.3563 2.93189ZM14.986 4.55356C14.3537 4.29736 13.6466 4.29736 13.0143 4.55356L10.7802 5.46006L19.5617 8.87489L22.6755 7.67323L14.9848 4.55473L14.986 4.55356ZM17.1397 9.80939L8.41766 6.41673L5.35516 7.65806L14.0013 11.0204L17.1397 9.80939ZM4.0835 19.6327C4.08365 19.6906 4.10104 19.7472 4.13345 19.7952C4.16586 19.8432 4.21183 19.8805 4.2655 19.9022L13.0143 23.4489C13.0505 23.4641 13.0878 23.4781 13.1252 23.4909V12.5581L4.0835 9.04173V19.6327ZM14.986 23.4489L23.7348 19.9022C23.7887 19.8804 23.8348 19.8429 23.8672 19.7947C23.8996 19.7465 23.9169 19.6897 23.9168 19.6316V9.06973L14.8752 12.5604V23.4921C14.9124 23.4789 14.9494 23.4638 14.986 23.4489Z" fill="#EF8658" />
        </g>
    </svg>

)

export default ProductIcon
