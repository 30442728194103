import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

import { PRODUCT_DETAIL_FIELDS } from '../../common/constant/page-constant'
import SideDrawer from '../Common/SideDrawer/SideDrawer'
import BarcodeIcon from '../../assets/BarcodeIcon'
import ReactSelect from 'react-select'
import {
    getProductDetailByIdHelper,
    getProductSearchResultsHelper,
    getProductSearchSuggestionsHelper
} from '../../helper/personal-details-helper'
import { formatDate } from '../../common/utils/appUtils'
import TrashIcon from '../../assets/TrashIcon'
import BarcodeScanner from '../../components/ZXingScanner/BarcodeScanner'

function Product(props) {
    const navigate = useNavigate()
    const svgRef = useRef()

    const [viewDrawer, setViewDrawer] = useState(false)
    const [open, setOpen] = useState(false)
    const [barcodeValue, setBarcodeValue] = useState(undefined)

    const [searchTerm, setSearchTerm] = useState('')
    const [selectedSearchTerm, setSelectedSearchTerm] = useState(undefined)
    const [searchSuggestions, setSearchSuggestions] = useState([])
    const [prevSearchTermLength, setPrevSearchTermLength] = useState(0)
    const [timer, setTimer] = useState()
    const [isScannerVisible, setIsScannerVisible] = useState(false)

    useEffect(() => {
        handleSearchSuggestions(searchTerm)
        return () => clearTimeout(timer)
    }, [searchTerm])

    useEffect(() => {
        if (selectedSearchTerm) {
            handleSearch(selectedSearchTerm)
        }
    }, [selectedSearchTerm])

    const handleSearchSuggestions = searchTerm => {
        console.log('searchTerm : ', searchTerm, ' !searchTerm', !searchTerm)
        if (!searchTerm || searchTerm?.length === 0) {
            clearSearchData()
            return
        }

        const fetchSearchResults = async () => {
            console.log('in fetchSearchResults')
            try {
                const results = await getProductSearchSuggestionsHelper(
                    searchTerm
                )
                // dispatchRecentSearchListAction(searchTerm)

                setSearchSuggestions(results)
            } catch (error) {
                console.error('Error fetching search results:', error)
            }
            // setIsLoading(false)
        }

        // Check if search term length changes by more than 2 characters
        if (
            searchTerm?.length > 0 &&
            Math.abs(searchTerm.length - prevSearchTermLength) > 2
        ) {
            clearTimeout(timer)
            setTimer(null)
            // isTimerSet = false
            console.log('hitting api after 2 character change')
            setPrevSearchTermLength(() => searchTerm.length)
            fetchSearchResults()
            // setApiCalled(() => apiCalled)
        } else {
            console.log('in else timer: ', timer)
            if (!timer) {
                let tr = setTimeout(() => {
                    if (searchTerm.length > 0) {
                        console.log('hitting api after 1 sec')
                        setPrevSearchTermLength(searchTerm.length)
                        fetchSearchResults()
                        // isTimerSet = false
                    }
                }, 1000)
                // setApiCalled(true);
                setTimer(tr)
            } else {
                // Clear the existing timer and set a new one
                console.log('clearing timeout and setting a new one')
                clearTimeout(timer)
                let tr = setTimeout(() => {
                    if (searchTerm.length > 0) {
                        console.log('hitting api after 1 sec')
                        setPrevSearchTermLength(searchTerm.length)
                        fetchSearchResults()
                        // isTimerSet = false
                    }
                }, 1000)
                setTimer(tr)
            }
        }
    }

    const handleSearch = async () => {
        try {
            let data = await getProductDetailByIdHelper(selectedSearchTerm)

            // setSearchResults(data)
            props.updateProductDetails(data)
        } catch (err) {}
    }

    const clearSearchData = () => {
        setSearchSuggestions([])
        setSelectedSearchTerm(undefined)
        setPrevSearchTermLength(0)
        clearTimeout(timer)
        setTimer(() => null)
    }

    const handleScan = result => {
        if (result) {
            console.log('result in handleScan: ', result)
            // setScannedResult(result)
            props.handleProductUpdate(
                props.product?.product_id,
                PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE,
                result
            )
            setIsScannerVisible(false) // Hide the scanner after a successful scan

            props?.getProductDetailsByBarcode(result)
        }
    }

    return (
        <form onSubmit={e => e.preventDefault()} className="mt-5">
            {props?.clearProduct && (
                <div className="addedProductAction dFlex jcSpaceBetween aiCenter">
                    <div></div>
                    <div
                        className="trashIcon"
                        onClick={() => props?.clearProduct()}>
                        <TrashIcon />
                    </div>
                </div>
            )}

            <div className="formSection">
                <div className="formGroup formFloating fgrow1">
                    <input
                        type="text"
                        className="formControl"
                        id="text"
                        placeholder="Product Barcode/QR Code"
                        value={
                            props.product?.[
                                PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE
                            ] || ''
                        }
                        onChange={e => {
                            props.handleProductUpdate(
                                props.product?.product_id,
                                PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE,
                                e.target.value
                            )
                        }}
                        onBlur={e => props?.getProductDetailsByBarcode()}
                        disabled={props?.isBarcodeDisabled}
                        required
                    />
                    <label for="barcode">Product Barcode/QR Code</label>
                    {props.isFieldErrorVisible &&
                        !props.product?.[
                            PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE
                        ] && (
                            // || !props?.product?.id
                            <div className="invalidFeedback">
                                Please enter a valid Product Barcode/QR Code.
                            </div>
                        )}
                    <div
                        className="positionedIcon"
                        onClick={() => setIsScannerVisible(!isScannerVisible)}>
                        <BarcodeIcon />
                    </div>
                    {isScannerVisible && <BarcodeScanner onScan={handleScan} />}
                </div>
            </div>
            {/* <div className="formGroup formFloating"> */}
            {/* <select
                    className="formSelect"
                    id="floatingSelect"
                    disabled={true}>
                    <option value="none" selected disabled hidden>
                        {props?.product?.[PRODUCT_DETAIL_FIELDS.PRODUCT_NAME] ||
                            ''}
                    </option>
                </select> */}

            <div className="formGroup formFloating">
                <ReactSelect
                    className="formSelect reactSelect"
                    id="floatingSelect"
                    value={{
                        label:
                            props?.product?.[
                                PRODUCT_DETAIL_FIELDS.PRODUCT_NAME
                            ] || '',
                        dose: props.product?.[PRODUCT_DETAIL_FIELDS.DOSE],
                        category:
                            props?.product?.[
                                PRODUCT_DETAIL_FIELDS.MEDICAL_CATEGORY
                            ]
                    }}
                    readonly={props?.isBarcodeDisabled}
                    onInputChange={
                        option => {
                            if (option) setSearchTerm(option)
                        }
                        // handleBillDetailUpdate(
                        //     BILL_DETAILS_FIELDS.PHARMA_COMPANY,
                        //     option
                        // )
                    }
                    onChange={option => {
                        setSelectedSearchTerm(option?.id)
                    }}
                    options={searchSuggestions}
                    getOptionLabel={option => {
                        // console.log(option)
                        return `${option.label} ${
                            option.dose ? option.dose : ''
                        }  ${option.category ? option.category : ''}`
                    }}
                    placeholder="Product Name"
                    styles={{
                        control: base => ({
                            border: 0,
                            display: 'flex',
                            paddingTop: '20px'
                        }),
                        option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused
                                ? 'rgb(222, 235, 255)'
                                : state.isSelected
                                ? '#FFF'
                                : 'inherit',
                            color: '#212529',
                            '&:hover': {
                                backgroundColor: state.isSelected
                                    ? '#DEEBFF'
                                    : 'rgb(222, 235, 255)'
                            }
                        })
                    }}
                    // isClearable
                />
                <label for="floatingSelect">Product Name</label>
                {props.isFieldErrorVisible &&
                    !props?.product?.[PRODUCT_DETAIL_FIELDS.PRODUCT_NAME] && (
                        <div className="invalidFeedback">Product Name.</div>
                    )}
            </div>
            <div className="formGroup formFloating">
                <ReactSelect
                    className="formSelect reactSelect"
                    id="floatingSelect"
                    value={
                        props?.product?.[
                            PRODUCT_DETAIL_FIELDS.SELECTED_BATCH_DETAILS
                        ] || ''
                    }
                    onChange={option => {
                        props.handleProductUpdate(
                            props.product?.product_id,
                            'product_batch_inventory_id',
                            option?.product_batch_inventory_id
                        )
                        props.handleProductUpdate(
                            props.product?.product_id,
                            PRODUCT_DETAIL_FIELDS.SELECTED_BATCH_DETAILS,
                            option
                        )
                    }}
                    options={props?.product?.inventory || []}
                    getOptionLabel={option =>
                        `Batch: ${
                            option?.[PRODUCT_DETAIL_FIELDS.BATCH_NUMBER]
                        }, 
                            Expiry: ${formatDate(
                                option?.[PRODUCT_DETAIL_FIELDS.EXPIRY_DATE]
                            )}
                            `
                    }
                    placeholder="Select Product Batch"
                    styles={{
                        control: base => ({
                            border: 0,
                            display: 'flex',
                            paddingTop: '20px'
                        }),
                        option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused
                                ? 'rgb(222, 235, 255)'
                                : state.isSelected
                                ? '#FFF'
                                : 'inherit',
                            color: '#212529',
                            '&:hover': {
                                backgroundColor: state.isSelected
                                    ? '#DEEBFF'
                                    : 'rgb(222, 235, 255)'
                            }
                        })
                    }}
                    // isClearable
                />
                <label for="floatingSelect">Select Product Batch</label>
                {props.isFieldErrorVisible &&
                    props?.product?.[
                        PRODUCT_DETAIL_FIELDS.SELECTED_BATCH_DETAILS
                    ]?.id && (
                        <div className="invalidFeedback">
                            Please Select Product Batch.
                        </div>
                    )}
                {props?.product?.[
                    PRODUCT_DETAIL_FIELDS.SELECTED_BATCH_DETAILS
                ] && (
                    <div className="inputSuggestionsWrap">
                        <ul className="inputSuggestion dFlex">
                            <li className="suggestion">
                                Selected Batch Stock:{' '}
                                {
                                    props?.product?.[
                                        PRODUCT_DETAIL_FIELDS
                                            .SELECTED_BATCH_DETAILS
                                    ]?.[PRODUCT_DETAIL_FIELDS.STOCK]
                                }
                            </li>
                        </ul>
                    </div>
                )}
            </div>
            {/* </div> */}
            <div className="formInline dFlex flexNowrap">
                <div className="formGroup formFloating fgrow1">
                    <input
                        type="text"
                        className="formControl"
                        id="quantity"
                        placeholder="Quantity"
                        value={props.product?.[PRODUCT_DETAIL_FIELDS.QUANTITY]}
                        onChange={e =>
                            props.handleProductUpdate(
                                props.product?.product_id,
                                PRODUCT_DETAIL_FIELDS.QUANTITY,
                                e.target.value
                            )
                        }
                        required
                    />
                    <label for="Quantity">Quantity</label>
                    {props.isFieldErrorVisible &&
                        (!props?.product?.[PRODUCT_DETAIL_FIELDS.QUANTITY] ||
                            parseInt(
                                props?.product?.[PRODUCT_DETAIL_FIELDS.QUANTITY]
                            ) >
                                parseInt(
                                    props?.product?.[
                                        PRODUCT_DETAIL_FIELDS
                                            .SELECTED_BATCH_DETAILS
                                    ]?.[PRODUCT_DETAIL_FIELDS.STOCK]
                                )) && (
                            <div class="invalidFeedback">
                                Please select a valid Quantity.
                            </div>
                        )}
                </div>
                <div className="formGroup formFloating ml-4 fgrow1">
                    <input
                        type="text"
                        className="formControl"
                        id="dose"
                        placeholder="Dose"
                        value={
                            props.product?.[PRODUCT_DETAIL_FIELDS.DOSE] || ''
                        }
                        disabled={true}
                        required
                    />
                    <label for="Dose">Dose</label>
                    {false && (
                        <div class="invalidFeedback">
                            Please select a valid Dose.
                        </div>
                    )}
                </div>
            </div>
            <div className="formInline dFlex flexNowrap">
                <div className="formGroup formFloating formInputText fgrow1">
                    <div className="preInputGroup backColor">
                        <div className="preInputText">BDT</div>
                    </div>
                    <input
                        type="text"
                        className="formControl"
                        id="email"
                        placeholder="Cost/Unit"
                        value={
                            props.product?.[
                                PRODUCT_DETAIL_FIELDS.SELECTED_BATCH_DETAILS
                            ]?.[PRODUCT_DETAIL_FIELDS.PRODUCT_COST] || ''
                        }
                        disabled={true}
                        required
                    />
                    <label for="address">Cost/Unit</label>
                    {false && (
                        <div className="invalidFeedback">
                            Please enter Cost/Unit.
                        </div>
                    )}
                </div>
                <div className="formGroup formFloating formInputText ml-4 fgrow1">
                    <div className="preInputGroup backColor">
                        <div className="preInputText">BDT</div>
                    </div>
                    <input
                        type="text"
                        className="formControl"
                        id="email"
                        placeholder="Rate/Unit"
                        value={
                            props.product?.[
                                PRODUCT_DETAIL_FIELDS.SELECTED_BATCH_DETAILS
                            ]?.[PRODUCT_DETAIL_FIELDS.RATE_PER_UNIT] || ''
                        }
                        disabled={true}
                        required
                    />
                    <label for="address">Rate/Unit</label>
                    {false && (
                        <div className="invalidFeedback">
                            Please enter Rate/Unit.
                        </div>
                    )}
                </div>
            </div>
        </form>
    )
}

Product.propTypes = {
    isFieldErrorVisible: PropTypes.bool,
    handleProductUpdate: PropTypes.func,
    product: PropTypes.object
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        // testAction: (payload) => dispatch(testAction(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Product)
