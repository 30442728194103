import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './Alert.scss'
import CheckIcon from '../../assets/CheckIcon'

function Alert(props) {
    const [showAlert, setShowAlert] = useState(true)

    // useEffect(() => {
    //     {
    //         console.log('props.alertMessage: ', props.alertMessage)
    //     }
    // }, [props.alertMessage])

    return (
        showAlert && (
            <div
                className={`alert dFlex aiCenter ${props.alertType}`}
                role="alert">
                {props.success && <CheckIcon />}
                {props.alertMessage}
            </div>
        )
    )
}

Alert.propTypes = {
    alertType: PropTypes.string,
    alertMessage: PropTypes.string,
    success: PropTypes.bool
}

export default Alert
