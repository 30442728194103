import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AddIcon from '../../assets/AddIcon'
import CloseIcon from '../../assets/CloseIcon'
import FilterIcon from '../../assets/FilterIcon'
import FilterIconBright from '../../assets/FilterIconBright'
import SortIcon from '../../assets/SortIcon'
import {
    HEADER_CONFIG,
    HEADER_CONST
} from '../../common/constant/common-constant'
import {
    BILL_DETAILS_FIELDS,
    EXPIRED_PRODUCT_STATUS,
    PAGE_CONST,
    PRODUCT_CATEGORY_UI,
    PRODUCT_DETAIL_FIELDS
} from '../../common/constant/page-constant'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import {
    convertToTitleCase,
    formatDate,
    formatTime
} from '../../common/utils/appUtils'
import { redirect } from '../../common/utils/redirectionUtils'
import {
    getExpiredProductDetailHelper,
    getExpiredProductDetailsHelper,
    getExpiredProductsHelper,
    getInvoiceDetailsHelper,
    returnExpiredProductsHelper
} from '../../helper/home-helper'
import Card from '../Common/Card/Card'
import MobileHeader from '../Common/Header/MobileHeader'
import SideDrawer from '../Common/SideDrawer/SideDrawer'
import ViewInventory from '../ManageInventory/ViewInventory'

import './ExpiredProducts.scss'
import ViewExpiredProducts from './ViewExpiredProducts'

const ExpiredProducts = props => {
    const navigate = useNavigate()
    const isMobile = useCheckMobileScreen()

    const [viewDrawer, setViewDrawer] = useState(false)
    const [open, setOpen] = useState(false)
    const [isViewExpiredProductsActive, setViewExpiredProductsActive] =
        useState(false)
    const [isFilterToggle, setIsFilterToggle] = useState(false)
    const [headerOptions, setHeaderOptions] = useState({
        [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
        [HEADER_CONFIG.TITLE]: HEADER_CONST.EXPIRED_PRODUCTS,
        [HEADER_CONFIG.HANDLE_BACK_FUNC]: () =>
            redirect(navigate, PAGE_CONST.HOME)
    })
    const [expiredProductList, setExpiredProductList] = useState([])
    const [activeExpiredTab, setActiveExpiredTab] = useState(
        EXPIRED_PRODUCT_STATUS.RETURN
    )
    const [expiredProductDetails, setExpiredProductDetails] =
        useState(undefined)

    useEffect(() => {
        if (!open && !isViewExpiredProductsActive) {
            handleHeaderConfigUpdate(HEADER_CONST.EXPIRED_PRODUCTS, () =>
                redirect(navigate, PAGE_CONST.HOME)
            )
            return
        }

        if (open) {
            handleHeaderConfigUpdate(HEADER_CONST.ADD_EXPIRED_PRODUCTS, () =>
                handleDrawerToggle()
            )
        } else if (isViewExpiredProductsActive) {
            handleHeaderConfigUpdate(HEADER_CONST.ADD_EXPIRED_PRODUCTS, () =>
                setViewExpiredProductsActive(!isViewExpiredProductsActive)
            )
        }
    }, [open, isViewExpiredProductsActive])

    useEffect(() => {
        getExpiredProducts()
    }, [])

    const getExpiredProducts = () => {
        getExpiredProductsHelper()
            .then(res => {
                console.log('res: ', res)
                setExpiredProductList(res)
            })
            .catch(err => {
                console.log('err: ', err)
            })
    }
    const handleDrawerToggle = () => {
        setViewDrawer(!viewDrawer)
        setOpen(!open)
    }

    const handleHeaderConfigUpdate = (title, handleBackFunc) => {
        setHeaderOptions({
            [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
            [HEADER_CONFIG.TITLE]: title,
            [HEADER_CONFIG.HANDLE_BACK_FUNC]: () => handleBackFunc()
        })
    }

    const handleExpiredCardClick = (productReceivingId, expiredProductId) => {
        let data = {
            product_receiving_detail_id: productReceivingId,
            expired_product_id: expiredProductId
        }

        console.log('expired details: ', data)
        getExpiredProductDetailsHelper(data)
            .then(res => {
                console.log('mc res: ', res)
                setExpiredProductDetails(res)
                setViewExpiredProductsActive(true)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleExpiredReturnClick = data => {
        returnExpiredProductsHelper(data)
            .then(res => {
                console.log('res of handleExpiredReturnClick', res)
                setViewExpiredProductsActive(!isViewExpiredProductsActive)
                getExpiredProducts()
            })
            .catch(err => {
                console.log('err of handleExpiredReturnClick', err)
            })
    }

    return (
        <>
            {isMobile && <MobileHeader headerOptions={headerOptions} />}
            <div className="pageBodyRightPanel">
                {isMobile && (
                    <div className="addProductBtnMobile dFlex flexColumnReverse aiCenter mwebVisible fixed">
                        {/* <button className="animated zoomIn btn btnPrimary">
                            <AddIcon />
                        </button> */}
                        {/* <button
                            className="animated zoomIn btn btnSecondary pointer mb-2"
                            onClick={handleDrawerToggle}>
                            <SortIcon />
                        </button>
                        <button
                            className="animated zoomIn btn btnSecondary pointer mb-2"
                            onClick={() => setIsFilterToggle(true)}>
                            <FilterIconBright />
                        </button> */}
                    </div>
                )}
                {!isMobile && (
                    <div className="pageBodyRightPanelHeader dFlex jcSpaceBetween aiCenter">
                        <div className="pageBodyRightPanelHeading">
                            Expired Products
                        </div>
                        <div className="addProductBtn dFlex aiCenter">
                            {/* <button className="btn btnPrimary">ADD</button> */}
                            {/* <div
                                className="pointer ml-4"
                                onClick={handleDrawerToggle}>
                                <SortIcon />
                            </div>
                            <div
                                className="pointer ml-4"
                                onClick={() => setIsFilterToggle(true)}>
                                <FilterIcon />
                            </div> */}
                            <div
                                className="pointer ml-4"
                                onClick={() => props.onClose()}>
                                <CloseIcon />
                            </div>
                        </div>
                    </div>
                )}
                <div className="expiredWrapper mt-5">
                    <ul className="tabs dFlex">
                        <li
                            className={`tabItem ${
                                activeExpiredTab ===
                                EXPIRED_PRODUCT_STATUS.RETURN
                                    ? 'active'
                                    : ''
                            }`}
                            onClick={() =>
                                setActiveExpiredTab(
                                    EXPIRED_PRODUCT_STATUS.RETURN
                                )
                            }>
                            Return
                        </li>
                        <li
                            className={`tabItem ${
                                activeExpiredTab ===
                                EXPIRED_PRODUCT_STATUS.RETURNED
                                    ? 'active'
                                    : ''
                            }`}
                            onClick={() =>
                                setActiveExpiredTab(
                                    EXPIRED_PRODUCT_STATUS.RETURNED
                                )
                            }>
                            Returned
                        </li>
                    </ul>
                    <div className="productLlistWrap dFlex">
                        {expiredProductList &&
                            expiredProductList
                                .filter(
                                    product =>
                                        product.status === activeExpiredTab
                                )
                                .map(expiredProduct => {
                                    return (
                                        <div className="cardWrap mt-5">
                                            <Card
                                                strong={false}
                                                headerSup={
                                                    expiredProduct?.updated_at
                                                        ? formatDate(
                                                              expiredProduct?.updated_at
                                                          ) +
                                                          ' ' +
                                                          formatTime(
                                                              expiredProduct?.updated_at
                                                          )
                                                        : ''
                                                }
                                                headerMain={`${
                                                    expiredProduct[
                                                        BILL_DETAILS_FIELDS
                                                            .PHARMA_COMPANY
                                                    ]
                                                }`}
                                                location={
                                                    expiredProduct.pharma_address
                                                }
                                                cardFooter={{
                                                    returnLossAmount:
                                                        expiredProduct.return_loss ||
                                                        '---',
                                                    recievableAmounut:
                                                        expiredProduct?.receivable_amount?.toFixed(
                                                            2
                                                        ) || '---'
                                                }}
                                                handleClick={() =>
                                                    handleExpiredCardClick(
                                                        expiredProduct.product_receiving_detail_id,
                                                        expiredProduct.id
                                                    )
                                                }
                                            />
                                        </div>
                                    )
                                })}
                    </div>
                </div>
                <ViewExpiredProducts
                    open={isViewExpiredProductsActive}
                    handleDrawerToggle={() =>
                        setViewExpiredProductsActive(
                            !isViewExpiredProductsActive
                        )
                    }
                    data={expiredProductDetails}
                    onConfirmClick={data => handleExpiredReturnClick(data)}
                />
                <SideDrawer
                    handleDrawerToggle={handleDrawerToggle}
                    open={open}
                    drawerButton={false}
                    drawerHeading="Sort">
                    <form className="mt-5">
                        <div className="formGroup formFloating">
                            <select className="formSelect" id="floatingSelect">
                                <option value="none" selected disabled hidden>
                                    Quantity
                                </option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                            </select>
                            <label for="floatingSelect">Quantity</label>
                            {false && (
                                <div class="invalidFeedback">
                                    Please choose Quantity.
                                </div>
                            )}
                        </div>
                        <div className="formGroup formFloating">
                            <select className="formSelect" id="floatingSelect">
                                <option value="none" selected disabled hidden>
                                    Amount
                                </option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                            </select>
                            <label for="floatingSelect">Amount</label>
                            {false && (
                                <div class="invalidFeedback">
                                    Please choose Amount.
                                </div>
                            )}
                        </div>
                    </form>
                </SideDrawer>
                <SideDrawer
                    handleDrawerToggle={() =>
                        setIsFilterToggle(!isFilterToggle)
                    }
                    open={isFilterToggle}
                    drawerButton={false}
                    drawerHeading="Filter">
                    <form className="mt-5">
                        <div className="formGroup formFloating">
                            <select className="formSelect" id="floatingSelect">
                                <option value="none" selected disabled hidden>
                                    Status
                                </option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                            </select>
                            <label for="floatingSelect">Status</label>
                            {false && (
                                <div class="invalidFeedback">
                                    Please choose Status.
                                </div>
                            )}
                        </div>
                    </form>
                </SideDrawer>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpiredProducts)
