import React from 'react'

const ArrowDown = (props) => (
    <svg onClick={props.onClick} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Dropdown">
            <path id="Vector" d="M4.21983 8.47C4.36045 8.32955 4.55108 8.25066 4.74983 8.25066C4.94858 8.25066 5.1392 8.32955 5.27983 8.47L11.9998 15.19L18.7198 8.47C18.7885 8.39631 18.8713 8.33721 18.9633 8.29622C19.0553 8.25523 19.1546 8.23319 19.2553 8.23141C19.356 8.22963 19.456 8.24816 19.5494 8.28588C19.6428 8.3236 19.7276 8.37974 19.7989 8.45096C19.8701 8.52218 19.9262 8.60702 19.964 8.7004C20.0017 8.79379 20.0202 8.89382 20.0184 8.99452C20.0166 9.09523 19.9946 9.19454 19.9536 9.28654C19.9126 9.37854 19.8535 9.46134 19.7798 9.53L12.5298 16.78C12.3892 16.9205 12.1986 16.9993 11.9998 16.9993C11.8011 16.9993 11.6105 16.9205 11.4698 16.78L4.21983 9.53C4.07938 9.38938 4.00049 9.19875 4.00049 9C4.00049 8.80125 4.07938 8.61063 4.21983 8.47Z" fill="#EF8658" />
        </g>
    </svg>

)

export default ArrowDown
