import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HEADER_CONFIG, HEADER_CONST } from '../../common/constant/common-constant';
import { PAGE_CONST } from '../../common/constant/page-constant';
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook';
import { redirect } from '../../common/utils/redirectionUtils';
import Card from '../Common/Card/Card';
import MobileHeader from '../Common/Header/MobileHeader';

import "./OrderBook.scss";

const AddNewOrder = (props) => {
    const navigate = useNavigate();
    const isMobile = useCheckMobileScreen();

    const [headerOptions, setHeaderOptions] = useState({
        [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
        [HEADER_CONFIG.TITLE]: HEADER_CONST.TERMS_AND_CONDITIONS,
        [HEADER_CONFIG.HANDLE_BACK_FUNC]: () => redirect(navigate, PAGE_CONST.HOME)
    })


    return (
        <>
            {isMobile && <MobileHeader headerOptions={headerOptions} />}
            <div className='pageBodyRightPanel'>
                {/* <div className="pageBodyRightPanelHeader dFlex jcSpaceBetween aiCenter mb-5">
                    <div className="pageBodyRightPanelHeading">Order Book</div>
                    <div className="addProductBtn dFlex aiCenter">
                        <button
                            className="btn btnPrimary">
                            ADD
                        </button>
                    </div>
                </div> */}
                <div className="productLlistWrap dFlex">
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => { return {} }
const mapDispatchToProps = (dispatch) => { return {} }

export default connect(mapStateToProps, mapDispatchToProps)(AddNewOrder);
