import React from 'react'

const Logo = () => (
    <svg width="70" height="62" viewBox="0 0 70 62" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.85069 20.317C3.06716 16.6633 2.96192 10.6342 6.61563 6.85068C10.2693 3.06715 16.2984 2.96191 20.0819 6.61562L30.5 16.6762L17.2687 30.3775L6.85069 20.317Z" fill="url(#paint0_linear_447_12542)" />
        <path d="M31.5051 16.5067C33.0438 17.9926 31.0697 22.5329 27.096 26.6478C23.1223 30.7627 18.6537 32.894 17.115 31.4081C15.5764 29.9223 17.5504 25.382 21.5242 21.2671C25.4979 17.1522 29.9665 15.0209 31.5051 16.5067Z" fill="#3A3A3A" />
        <path d="M31.5051 16.5067C33.0438 17.9926 31.0697 22.5329 27.096 26.6478C23.1223 30.7627 18.6537 32.894 17.115 31.4081C15.5764 29.9223 17.5504 25.382 21.5242 21.2671C25.4979 17.1522 29.9665 15.0209 31.5051 16.5067Z" stroke="black" />
        <path d="M30.863 17.167C32.0841 18.3462 30.139 22.3413 26.5186 26.0903C22.8982 29.8393 18.9735 31.9226 17.7524 30.7435C16.5314 29.5643 18.4764 25.5692 22.0968 21.8202C25.7172 18.0712 29.642 15.9879 30.863 17.167Z" fill="#7F9CA9" />
        <path d="M30.863 17.167C32.0841 18.3462 30.139 22.3413 26.5186 26.0903C22.8982 29.8393 18.9735 31.9226 17.7524 30.7435C16.5314 29.5643 18.4764 25.5692 22.0968 21.8202C25.7172 18.0712 29.642 15.9879 30.863 17.167Z" stroke="black" />
        <path d="M62.3407 20.317C66.1242 16.6633 66.2295 10.6342 62.5758 6.85068C58.9221 3.06715 52.893 2.96191 49.1095 6.61562L38.6915 16.6762L51.9227 30.3775L62.3407 20.317Z" fill="url(#paint1_linear_447_12542)" />
        <path d="M37.6863 16.5067C36.1476 17.9926 38.1217 22.5329 42.0954 26.6478C46.0691 30.7627 50.5377 32.894 52.0764 31.4081C53.615 29.9223 51.641 25.382 47.6673 21.2671C43.6935 17.1522 39.2249 15.0209 37.6863 16.5067Z" fill="#3A3A3A" />
        <path d="M37.6863 16.5067C36.1476 17.9926 38.1217 22.5329 42.0954 26.6478C46.0691 30.7627 50.5377 32.894 52.0764 31.4081C53.615 29.9223 51.641 25.382 47.6673 21.2671C43.6935 17.1522 39.2249 15.0209 37.6863 16.5067Z" stroke="black" />
        <path d="M38.3279 17.167C37.1068 18.3462 39.0519 22.3413 42.6723 26.0903C46.2927 29.8393 50.2174 31.9226 51.4385 30.7435C52.6596 29.5643 50.7145 25.5692 47.0941 21.8202C43.4737 18.0712 39.5489 15.9879 38.3279 17.167Z" fill="#BA5325" />
        <path d="M38.3279 17.167C37.1068 18.3462 39.0519 22.3413 42.6723 26.0903C46.2927 29.8393 50.2174 31.9226 51.4385 30.7435C52.6596 29.5643 50.7145 25.5692 47.0941 21.8202C43.4737 18.0712 39.5489 15.9879 38.3279 17.167Z" stroke="black" />
        <path d="M34.9155 32.8036V59.2132H41.3738C44.1279 59.2132 47.0816 59.2132 49.227 57.4862C50.685 56.3126 51.9439 54.4711 52.0329 51.7142C52.1959 46.6605 47.903 44.6499 45.512 44.3782C46.7075 43.563 49.0006 41.0851 48.6094 37.6942C48.2181 34.3034 44.3165 33.0209 42.4145 32.8036H34.9155Z" fill="url(#paint2_linear_447_12542)" stroke="#EC713C" strokeWidth="2" />
        <path d="M33.6162 32.8036V59.2132H27.1579C24.4039 59.2132 21.4501 59.2132 19.3047 57.4862C17.8467 56.3126 16.5878 54.4711 16.4989 51.7142C16.3359 46.6605 20.6288 44.6499 23.0198 44.3782C21.8243 43.563 19.5311 41.0851 19.9223 37.6942C20.3136 34.3034 24.2153 33.0209 26.1172 32.8036H33.6162Z" fill="#CBDDE4" stroke="#ABC8D3" strokeWidth="2" />
        <path d="M32.5459 39.9767C32.182 39.9767 31.833 40.1212 31.5757 40.3785C31.3184 40.6358 31.1739 40.9848 31.1739 41.3487V43.8967H28.6259C28.262 43.8967 27.9131 44.0412 27.6558 44.2985C27.3985 44.5558 27.2539 44.9048 27.2539 45.2686V48.4046C27.2539 49.162 27.8686 49.7766 28.6259 49.7766H31.1739V52.3246C31.1739 53.082 31.7885 53.6966 32.5459 53.6966H35.6819C36.0458 53.6966 36.3947 53.5521 36.652 53.2948C36.9093 53.0375 37.0539 52.6885 37.0539 52.3246V49.7766H39.6019C39.9657 49.7766 40.3147 49.6321 40.572 49.3748C40.8293 49.1175 40.9739 48.7685 40.9739 48.4046V45.2686C40.9739 44.9048 40.8293 44.5558 40.572 44.2985C40.3147 44.0412 39.9657 43.8967 39.6019 43.8967H37.0539V41.3487C37.0539 40.9848 36.9093 40.6358 36.652 40.3785C36.3947 40.1212 36.0458 39.9767 35.6819 39.9767H32.5459Z" fill="white" />
        <circle cx="43.7946" cy="26.6574" r="2.85616" fill="white" />
        <circle cx="9.8377" cy="58.3926" r="1.90411" fill="#EF8658" />
        <circle cx="50.9349" cy="41.097" r="1.11073" fill="#7F9CA9" />
        <circle cx="15.2327" cy="42.2077" r="2.22146" fill="#EC713C" />
        <circle cx="44.7463" cy="59.1439" r="2.85616" fill="white" />
        <circle cx="26.8162" cy="31.6929" r="1.74543" fill="white" />
        <circle cx="27.6093" cy="52.7969" r="0.952053" fill="white" />
        <circle cx="39.6688" cy="30.265" r="0.952053" fill="white" />
        <defs>
            <linearGradient id="paint0_linear_447_12542" x1="12.694" y1="28.8368" x2="17.7717" y2="5.35278" gradientUnits="userSpaceOnUse">
                <stop stopColor="#7D9AA6" />
                <stop offset="1" stopColor="#CBDDE4" />
            </linearGradient>
            <linearGradient id="paint1_linear_447_12542" x1="74.1013" y1="13.4452" x2="40.2952" y2="15.032" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F35712" />
                <stop offset="1" stopColor="#EF8253" />
            </linearGradient>
            <linearGradient id="paint2_linear_447_12542" x1="43.4764" y1="32.8036" x2="43.4764" y2="59.2132" gradientUnits="userSpaceOnUse">
                <stop stopColor="#EF8658" />
                <stop offset="1" stopColor="#EC713C" />
            </linearGradient>
        </defs>
    </svg>
)

export default Logo
