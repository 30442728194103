export const SIDEBAR_CONST = {
    HOME: 'HOME',
    USER_PROFILE: 'USER_PROFILE',
    SETTINGS: 'SETTINGS',
    HELP: 'HELP',
    STOCKS: 'STOCKS',
    NOTIFICATION: 'NOTIFICATION',
    PROFILE_MENU: 'PROFILE_MENU'
}

export const WIZARD_STEPS = {
    STEP_1: 'STEP_1',
    STEP_2: 'STEP_2',
    STEP_3: 'STEP_3'
}

export const HEADER_CONST = {
    HOME: 'Home',
    STOCKS: 'Stocks',
    PRODUCT_RECEIVING: 'Product Receiving',
    ADD_PRODUCT_RECEIVING: 'Add Product Receiving',
    VIEW_PRODUCT_RECEIVING: 'View Product Receiving',
    VIEW_BARCODE: 'View Barcode',
    SALES: 'Sales',
    ADD_SALES: 'Add New Sales',
    VIEW_SALES: 'View Sales',
    EXCHANGE_RETURN: 'Exchange/Return',
    ADD_EXCHANGE_RETURN: 'Add Exchange/Return',
    VIEW_EXCHANGE_RETURN: 'View Exchange/Return',
    MANAGE_INVENTORY: 'Manage Inventory',
    VIEW_INVENTORY: 'View Inventory',
    CUSTOMERS: 'Customers',
    ADD_CUSTOMERS: 'Add Customers',
    USERS: 'User',
    ADD_USERS: 'Add User',
    DAMAGE_LOSS_ADJUSTMENT: 'Damage Loss Adjustment',
    ADD_DAMAGE_LOSS_ADJUSTMENT: 'Add Damage/Loss',
    EXPIRED_PRODUCTS: 'Expired Products',
    ADD_EXPIRED_PRODUCTS: 'Add Expired Products',
    RETURNED: 'Returned',
    RETURN: 'Return',
    REPORTS: 'Reports',
    PERSONAL_DETAILS: 'Personal Details',
    DOCUMENTS: 'Documents',
    PLATFORM_CHARGES: 'Platform Charges',
    BRANCH_MANAGEMENT: 'Branch Management',
    ADD_BRANCH_MANAGEMENT: 'Add Branch Management',
    ACCOUNTING: 'Accounting',
    ORDER_BOOK: 'Order Book',
    ADD_ORDER_BOOK: 'Add Order Book',
    DISCOUNTS: 'Discount Settings',
    TERMS_AND_CONDITIONS: 'Terms And Conditions',
    PRIVACY_POLICY: 'Privacy Policy',
    ABOUT: 'About',
    NOTIFICATION: 'Notification',
    SETTINGS: 'Settings',
    HELP: 'Help',
    PROFILE_MENU: 'Profile Menu',
    DELIVERY_PARTNER: 'Delivery Partner',
    ADD_DELIVERY_PARTNER: 'Add Delivery Partner'
}

export const HEADER_CONFIG = {
    IS_BACK_VISIBLE: 'isBackVisible',
    HANDLE_BACK_FUNC: 'handleBackFunc',
    TITLE: 'title'
}
