import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import CloseIcon from '../../assets/CloseIcon'
import Card from '../Common/Card/Card'
import SideDrawer from '../Common/SideDrawer/SideDrawer'
import {
    CUSTOMER_FIELDS,
    PAGE_CONST,
    PERMISSION_FEATURE,
    PERMISSION_SCREEN,
    PERMISSION_TYPE
} from '../../common/constant/page-constant'

import './ManageCustomer.scss'
import { addCustomerAlertAction } from '../../modules/actions/action'
import {
    addCustomerHelper,
    getCustomerHelper,
    getAllCustomersHelper
} from '../../helper/home-helper'
import Alert from '../../components/Alert/Alert'
import {
    HEADER_CONFIG,
    HEADER_CONST
} from '../../common/constant/common-constant'
import MobileHeader from '../Common/Header/MobileHeader'
import { useNavigate } from 'react-router-dom'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import { redirect } from '../../common/utils/redirectionUtils'
import AddIcon from '../../assets/AddIcon'
import { convertToTitleCase } from '../../common/utils/appUtils'

const ManageCustomer = props => {
    const navigate = useNavigate()
    const isMobile = useCheckMobileScreen()

    const [viewDrawer, setViewDrawer] = useState(false)
    const [open, setOpen] = useState(false)
    const [customerData, setCustomerData] = useState(undefined)
    const [manageCustomerData, setManageCustomerData] = useState(undefined)
    const [name, setName] = useState(undefined)
    const [mobileNumber, setMobileNumber] = useState(undefined)
    const [billingAddress, setBillingAddress] = useState(undefined)
    const [shippingAddress, setShippingAddress] = useState(undefined)
    const [isFieldErrorVisible, setIsFieldErrorVisible] = useState(false)
    const [addCustomerAlert, setAddCustomerAlert] = useState(false)
    const [headerOptions, setHeaderOptions] = useState({
        [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
        [HEADER_CONFIG.TITLE]: HEADER_CONST.CUSTOMERS,
        [HEADER_CONFIG.HANDLE_BACK_FUNC]: () =>
            redirect(navigate, PAGE_CONST.HOME)
    })
    const [loginData, setLoginData] = useState({})
    const [permissions, setPermissions] = useState({})

    useEffect(() => {
        let addCustomerAlert = props.alert?.addCustomerAlert
        setAddCustomerAlert(addCustomerAlert)
    }, [props.alert?.addCustomerAlert])

    useEffect(() => {
        getCustomerData()
    }, [])

    useEffect(() => {
        if (!open) {
            handleHeaderConfigUpdate(HEADER_CONST.CUSTOMERS, () =>
                redirect(navigate, PAGE_CONST.HOME)
            )
            return
        }

        handleHeaderConfigUpdate(HEADER_CONST.ADD_CUSTOMERS, () =>
            handleDrawerToggle()
        )
    }, [open])

    useEffect(() => {
        setLoginData(props.loginData)

        if (props.loginData.permissions) {
            console.log(
                'props.loginData.permissions: ',
                props.loginData.permissions
            )

            let permission = props.loginData.permissions.filter(
                permission => permission.screen === PERMISSION_SCREEN.OTHER
            )[0]

            console.log('home screen permission: ', permission)
            if (permission) setPermissions(permission)
        }
    }, [props.loginData])

    const handleHeaderConfigUpdate = (title, handleBackFunc) => {
        setHeaderOptions({
            [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
            [HEADER_CONFIG.TITLE]: title,
            [HEADER_CONFIG.HANDLE_BACK_FUNC]: () => handleBackFunc()
        })
    }

    const getCustomerData = () => {
        getAllCustomersHelper()
            .then(res => {
                console.log('mc res: ', res)
                setManageCustomerData(res)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleCustomerDetailUpdate = (key, value) => {
        setCustomerData({
            ...customerData,
            [key]: value
        })
    }

    const handleDrawerToggle = () => {
        setViewDrawer(!viewDrawer)
        setOpen(!open)
    }

    const checkValidation = () => {
        if (
            customerData &&
            customerData[CUSTOMER_FIELDS.NAME] &&
            customerData[CUSTOMER_FIELDS.PHONE_NUMBER] &&
            customerData[CUSTOMER_FIELDS.BILLING_ADDRESS]
        ) {
            return true
        }

        return false
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            setIsFieldErrorVisible(true)
            return
        }

        setIsFieldErrorVisible(false)

        let data = { ...customerData }

        data[CUSTOMER_FIELDS.SHIPPING_ADDRESS] =
            data[CUSTOMER_FIELDS.BILLING_ADDRESS]

        console.log('data: ', data)

        addCustomerHelper(data)
            .then(res => {
                console.log(res)

                props.addCustomerAlertAction(true)

                setTimeout(() => {
                    props.addCustomerAlertAction(false)
                }, 5000)
                getCustomerData()
                resetDrawerData()
                handleDrawerToggle()
            })
            .catch(() => {})
    }

    const resetDrawerData = () => {
        setCustomerData({})
    }

    return (
        <>
            {isMobile && <MobileHeader headerOptions={headerOptions} />}
            {addCustomerAlert && (
                <Alert
                    success
                    alertType="success"
                    alertMessage="New Customer added Successfully!"
                />
            )}
            <div className="pageBodyRightPanel">
                {isMobile && (
                    <div className="addProductBtnMobile dFlex aiCenter mwebVisible fixed">
                        <button
                            className="animated zoomIn btn btnPrimary"
                            onClick={handleDrawerToggle}>
                            <AddIcon />
                        </button>
                    </div>
                )}
                {!isMobile && (
                    <div className="pageBodyRightPanelHeader dFlex jcSpaceBetween aiCenter">
                        <div className="pageBodyRightPanelHeading">
                            Manage Customer
                        </div>
                        <div className="addProductBtn dFlex aiCenter">
                            {permissions?.features?.[
                                PERMISSION_FEATURE.CUSTOMERS
                            ] === PERMISSION_TYPE.MANAGE && (
                                <button
                                    className="btn btnPrimary"
                                    onClick={handleDrawerToggle}>
                                    ADD
                                </button>
                            )}
                            <div
                                className="pointer ml-4"
                                onClick={() => props.onClose()}>
                                <CloseIcon />
                            </div>
                        </div>
                    </div>
                )}
                <div className="productLlistWrap dFlex">
                    {manageCustomerData &&
                        manageCustomerData.map(customer => {
                            return (
                                <div className="cardWrap">
                                    <Card
                                        // users
                                        userInitials={convertToTitleCase(
                                            customer?.name?.[0]
                                        )}
                                        headerSup={customer.mobile_no}
                                        headerMain={customer.name}
                                        address={customer.billing_address}
                                    />
                                </div>
                            )
                        })}
                </div>
                <SideDrawer
                    handleDrawerToggle={handleDrawerToggle}
                    open={open}
                    downloadButton={true}
                    downloadText={'Save'}
                    drawerHeading="Add Customer"
                    handleClick={() => handleSubmit()}
                    sideDrawerFooter={
                        <div className="download btn btnPrimary block">
                            Save
                        </div>
                    }>
                    <div className="wizardFieldset">
                        <form className="mt-5">
                            <div className="formGroup formFloating fgrow1">
                                <input
                                    type="text"
                                    className="formControl"
                                    id="custName"
                                    placeholder="Customer Name"
                                    value={customerData?.[CUSTOMER_FIELDS.NAME]}
                                    onChange={e =>
                                        handleCustomerDetailUpdate(
                                            CUSTOMER_FIELDS.NAME,
                                            e.target.value
                                        )
                                    }
                                    required
                                />
                                <label for="invoice">Customer Name</label>
                                {isFieldErrorVisible &&
                                    !customerData?.[CUSTOMER_FIELDS.NAME] && (
                                        <div class="invalidFeedback">
                                            Please enter a Customer name.
                                        </div>
                                    )}
                            </div>
                            <div className="formInline dFlex flexNowrap">
                                <div className="formGroup formFloating fgrow1 maxWidth">
                                    <select
                                        className="formSelect"
                                        id="floatingSelect">
                                        {/* <option value="1">+91</option> */}
                                        <option value="2">+88</option>
                                    </select>
                                    <label for="floatingSelect">Country</label>
                                    {false && (
                                        <div class="invalidFeedback">
                                            Please choose country code.
                                        </div>
                                    )}
                                </div>
                                <div className="formGroup formFloating ml-4 fgrow1">
                                    <input
                                        type="text"
                                        className="formControl"
                                        id="phone"
                                        placeholder="Phone"
                                        value={
                                            customerData?.[
                                                CUSTOMER_FIELDS.PHONE_NUMBER
                                            ]
                                        }
                                        onChange={e =>
                                            handleCustomerDetailUpdate(
                                                CUSTOMER_FIELDS.PHONE_NUMBER,
                                                e.target.value
                                            )
                                        }
                                        required
                                    />
                                    <label for="phone">Phone</label>
                                    {isFieldErrorVisible &&
                                        !customerData?.[
                                            CUSTOMER_FIELDS.PHONE_NUMBER
                                        ] && (
                                            <div class="invalidFeedback">
                                                Please select a valid Phone.
                                            </div>
                                        )}
                                </div>
                            </div>
                            <div className="formGroup formFloating">
                                <input
                                    type="text"
                                    className="formControl"
                                    id="billing"
                                    placeholder="Address"
                                    value={
                                        customerData?.[
                                            CUSTOMER_FIELDS.BILLING_ADDRESS
                                        ]
                                    }
                                    onChange={e =>
                                        handleCustomerDetailUpdate(
                                            CUSTOMER_FIELDS.BILLING_ADDRESS,
                                            e.target.value
                                        )
                                    }
                                    required
                                />
                                <label for="address">Address</label>
                                {isFieldErrorVisible &&
                                    !customerData?.[
                                        CUSTOMER_FIELDS.BILLING_ADDRESS
                                    ] && (
                                        <div class="invalidFeedback">
                                            Please enter a valid Billing
                                            Address.
                                        </div>
                                    )}
                            </div>
                            {/* <div className="formGroup formFloating">
                                <input
                                    type="text"
                                    className="formControl"
                                    id="shipping"
                                    placeholder="Shipping Address"
                                    value={
                                        customerData?.[
                                            CUSTOMER_FIELDS.SHIPPING_ADDRESS
                                        ]
                                    }
                                    onChange={e =>
                                        handleCustomerDetailUpdate(
                                            CUSTOMER_FIELDS.SHIPPING_ADDRESS,
                                            e.target.value
                                        )
                                    }
                                    required
                                />
                                <label for="address">Shipping Address</label>
                                {isFieldErrorVisible &&
                                    !customerData?.[
                                        CUSTOMER_FIELDS.SHIPPING_ADDRESS
                                    ] && (
                                        <div class="invalidFeedback">
                                            Please enter a valid Shipping
                                            Address.
                                        </div>
                                    )}
                            </div> */}
                        </form>
                    </div>
                </SideDrawer>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        alert: state.alertReducer.alert,
        loginData: state.loginDataReducer?.loginData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addCustomerAlertAction: payload =>
            dispatch(addCustomerAlertAction(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageCustomer)
