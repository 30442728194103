import { Types } from '../actions/types'
import initialState from '../store/initial.state'

export const loginDataReducer = (
    state = initialState.loginDataReducer,
    action
) => {
    switch (action.type) {
        case Types.LOGIN_DATA: {
            let data = action.payload
            return {
                ...state,
                loginData: {
                    ...state.loginData,
                    ...data
                }
            }
        }
        case Types.UPDATE_BRANCH: {
            let data = action.payload

            let updatedBranchData = {
                branch_name: data.name,
                email: data.email,
                location: data.location
            }

            return {
                ...state,
                loginData: {
                    ...state.loginData,
                    ...updatedBranchData
                }
            }
        }
        default:
            return state
    }
}
