import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import "./ProductReceiving.scss";
import SideDrawer from '../Common/SideDrawer/SideDrawer';
import LocationIcon from '../../assets/LocationIcon';

function ViewProduct({ open, handleDrawerToggle }) {
    const navigate = useNavigate();

    return (
        <SideDrawer handleDrawerToggle={handleDrawerToggle} open={open} drawerHeading='Product Receiving'>
            <div className='viewProductWrap'>
                <div className='viewProductHeader'>
                    <div className='headerRow dFlex jcSpaceBetween aiCenter'>
                        <div className='headerRowLeft'>Received by : Name of Person</div>
                        <div className='headerRowRight'>25 Jan 2022</div>
                    </div>
                    <div className='ProductUniqueTag'>#765432456789</div>
                    <ul className='moreDetailsRow dFlex'>
                        <li className='moreDetails'>Batch No. : 34567</li>
                        <li className='moreDetails'>Expiry Date: 25 Jan 2024</li>
                    </ul>
                </div>
                <div className='viewProductHeader'>
                    <div className='productSupplierName'>Supplier Name</div>
                    <div className='productSupplierAddress dFlex aiCenter'>
                        <LocationIcon className="icon" /> Lorem ipsum dose amet consectetur. Bibendum </div>
                </div>
                <div className='productDescriptionWrap'>
                    <div className='productDescription'>
                        <div className='productTitle'>Mesacol Tablet</div>
                        <ul className='productQuantityList dFlex'>
                            <li className='productQuantity'>300 mg</li>
                            <li className='productQuantity'>Qty: 250</li>
                            <li className='productQuantity'>Bonus Qty: 250</li>
                        </ul>
                        <table className='table productPrice' cellPadding={2} cellSpacing={2}>
                            <thead>
                                <tr>
                                    <td colSpan='2'>Product Pricing</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cost/Unit (BDT)</td>
                                    <td align='right'>25000</td>
                                </tr>
                                <tr>
                                    <td>Rate/Unit (BDT)</td>
                                    <td align='right'>25000</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='productDescription'>
                        <div className='productTitle'>Mesacol Tablet</div>
                        <ul className='productQuantityList dFlex'>
                            <li className='productQuantity'>300 mg</li>
                            <li className='productQuantity'>Qty: 250</li>
                            <li className='productQuantity'>Bonus Qty: 250</li>
                        </ul>
                        <table className='table productPrice' cellPadding={2} cellSpacing={2}>
                            <thead>
                                <tr>
                                    <td colSpan='2'>Product Pricing</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cost/Unit (BDT)</td>
                                    <td align='right'>25000</td>
                                </tr>
                                <tr>
                                    <td>Rate/Unit (BDT)</td>
                                    <td align='right'>25000</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </SideDrawer >
    );
}

const mapStateToProps = (state) => {

    return {};
}

const mapDispatchToProps = (dispatch) => {

    return {
        // testAction: (payload) => dispatch(testAction(payload))
    }
}

ViewProduct.propTypes = {
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewProduct);