import React from 'react'

const LogoLarge = () => (
    <svg width="219" height="196" viewBox="0 0 219 196" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.5871 64.0202C9.66486 52.5071 9.33325 33.509 20.8464 21.5867C32.3596 9.66453 51.3577 9.33293 63.2799 20.8461L96.1079 52.5477L54.4151 95.7219L21.5871 64.0202Z" fill="url(#paint0_linear_240_11609)" />
        <path d="M99.2755 52.0132C104.124 56.6952 97.9035 71.002 85.382 83.9684C72.8604 96.9348 58.7794 103.651 53.931 98.9687C49.0827 94.2867 55.303 79.9799 67.8246 67.0134C80.3461 54.047 94.4271 47.3312 99.2755 52.0132Z" fill="#3A3A3A" />
        <path d="M99.2755 52.0132C104.124 56.6952 97.9035 71.002 85.382 83.9684C72.8604 96.9348 58.7794 103.651 53.931 98.9687C49.0827 94.2867 55.303 79.9799 67.8246 67.0134C80.3461 54.047 94.4271 47.3312 99.2755 52.0132Z" stroke="black" />
        <path d="M97.253 54.0947C101.101 57.8103 94.9717 70.3992 83.5635 82.2127C72.1553 94.0262 59.788 100.591 55.9404 96.8752C52.0928 93.1596 58.2218 80.5707 69.63 68.7572C81.0381 56.9437 93.4054 50.3791 97.253 54.0947Z" fill="#7F9CA9" />
        <path d="M97.253 54.0947C101.101 57.8103 94.9717 70.3992 83.5635 82.2127C72.1553 94.0262 59.788 100.591 55.9404 96.8752C52.0928 93.1596 58.2218 80.5707 69.63 68.7572C81.0381 56.9437 93.4054 50.3791 97.253 54.0947Z" stroke="black" />
        <path d="M196.441 64.0202C208.363 52.5071 208.695 33.509 197.182 21.5867C185.669 9.66453 166.671 9.33293 154.748 20.8461L121.92 52.5477L163.613 95.7219L196.441 64.0202Z" fill="url(#paint1_linear_240_11609)" />
        <path d="M118.753 52.0132C113.905 56.6952 120.125 71.002 132.646 83.9684C145.168 96.9348 159.249 103.651 164.097 98.9687C168.946 94.2867 162.725 79.9799 150.204 67.0134C137.682 54.047 123.601 47.3312 118.753 52.0132Z" fill="#3A3A3A" />
        <path d="M118.753 52.0132C113.905 56.6952 120.125 71.002 132.646 83.9684C145.168 96.9348 159.249 103.651 164.097 98.9687C168.946 94.2867 162.725 79.9799 150.204 67.0134C137.682 54.047 123.601 47.3312 118.753 52.0132Z" stroke="black" />
        <path d="M120.775 54.0947C116.928 57.8103 123.057 70.3992 134.465 82.2127C145.873 94.0262 158.24 100.591 162.088 96.8752C165.936 93.1596 159.807 80.5707 148.398 68.7572C136.99 56.9437 124.623 50.3791 120.775 54.0947Z" fill="#BA5325" />
        <path d="M120.775 54.0947C116.928 57.8103 123.057 70.3992 134.465 82.2127C145.873 94.0262 158.24 100.591 162.088 96.8752C165.936 93.1596 159.807 80.5707 148.398 68.7572C136.99 56.9437 124.623 50.3791 120.775 54.0947Z" stroke="black" />
        <path d="M110.022 103.367V186.586H139.274C142.028 186.586 144.811 186.448 147.396 185.497C154.159 183.009 163.524 176.475 163.96 162.956C164.474 147.031 150.946 140.696 143.412 139.84C147.179 137.271 154.405 129.463 153.172 118.778C151.94 108.093 139.645 104.052 133.652 103.367H110.022Z" fill="url(#paint2_linear_240_11609)" stroke="#EC713C" strokeWidth="2" />
        <path d="M105.927 103.367V186.586H76.6746C73.9206 186.586 71.1377 186.448 68.553 185.497C61.7895 183.009 52.4247 176.475 51.9886 162.956C51.4749 147.031 65.0023 140.696 72.5365 139.84C68.7694 137.271 61.5434 129.463 62.7762 118.778C64.0091 108.093 76.3036 104.052 82.2967 103.367H105.927Z" fill="#CBDDE4" stroke="#ABC8D3" strokeWidth="2" />
        <path d="M102.553 125.97C101.407 125.97 100.307 126.425 99.4964 127.236C98.6856 128.047 98.2301 129.146 98.2301 130.293V138.322H90.2012C89.0546 138.322 87.955 138.777 87.1442 139.588C86.3334 140.399 85.8779 141.499 85.8779 142.645V152.527C85.8779 154.913 87.8148 156.85 90.2012 156.85H98.2301V164.879C98.2301 167.266 100.167 169.202 102.553 169.202H112.435C113.582 169.202 114.681 168.747 115.492 167.936C116.303 167.125 116.758 166.026 116.758 164.879V156.85H124.787C125.934 156.85 127.034 156.395 127.844 155.584C128.655 154.773 129.111 153.674 129.111 152.527V142.645C129.111 141.499 128.655 140.399 127.844 139.588C127.034 138.777 125.934 138.322 124.787 138.322H116.758V130.293C116.758 129.146 116.303 128.047 115.492 127.236C114.681 126.425 113.582 125.97 112.435 125.97H102.553Z" fill="white" />
        <circle cx="138" cy="84" r="9" fill="white" />
        <circle cx="31" cy="184" r="6" fill="#EF8658" />
        <circle cx="160.5" cy="129.5" r="3.5" fill="#7F9CA9" />
        <circle cx="48" cy="133" r="7" fill="#EC713C" />
        <circle cx="141" cy="186.367" r="9" fill="white" />
        <circle cx="84.5" cy="99.8672" r="5.5" fill="white" />
        <circle cx="87" cy="166.367" r="3" fill="white" />
        <circle cx="125" cy="95.3672" r="3" fill="white" />
        <defs>
            <linearGradient id="paint0_linear_240_11609" x1="40" y1="90.8668" x2="56" y2="16.8668" gradientUnits="userSpaceOnUse">
                <stop stopColor="#7D9AA6" />
                <stop offset="1" stopColor="#CBDDE4" />
            </linearGradient>
            <linearGradient id="paint1_linear_240_11609" x1="233.5" y1="42.3668" x2="126.974" y2="47.3668" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F35712" />
                <stop offset="1" stopColor="#EF8253" />
            </linearGradient>
            <linearGradient id="paint2_linear_240_11609" x1="136.998" y1="103.367" x2="136.998" y2="186.586" gradientUnits="userSpaceOnUse">
                <stop stopColor="#EF8658" />
                <stop offset="1" stopColor="#EC713C" />
            </linearGradient>
        </defs>
    </svg>
)

export default LogoLarge
