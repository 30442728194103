import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

import './ExchangeReturn.scss'
import SideDrawer from '../Common/SideDrawer/SideDrawer'
import LocationIcon from '../../assets/LocationIcon'
import {
    ADD_EXCHANGE_RETURN_FLOW,
    EXCHANGE_RETURN_UI_NAME,
    PAYMENT_MODE_OPTIONS,
    PRODUCT_DETAIL_FIELDS
} from '../../common/constant/page-constant'
import { formatDate, formatTime } from '../../common/utils/appUtils'
import html2canvas from 'html2canvas'
import DownloadIcon from '../../assets/DownloadIcon'

function ViewExchangeReturn({ open, handleDrawerToggle, data }) {
    const navigate = useNavigate()
    const ref = useRef(null)

    const getDiscountedAmount = () => {
        return (
            (data.discount / 100) *
            (data.new_amount ? data.new_amount : data.old_amount)
        )
    }

    const getTradeDiscountedAmount = () => {
        return (
            (data.trade_discount / 100) *
            (data.new_amount ? data.new_amount : data.old_amount)
        )
    }

    const getVatAmount = () => {
        let discount = getDiscountedAmount() || 0
        let tradeDiscount = getTradeDiscountedAmount() || 0

        let remainingAmount =
            (data.new_amount ? data.new_amount : data.old_amount) -
            discount -
            tradeDiscount

        let vatPercentage = data.vat || 0

        return (vatPercentage / 100) * remainingAmount
    }

    const captureScreenshot = () => {
        try {
            var canvasPromise = html2canvas(ref.current, {
                useCORS: true,
                allowTaint: true,
                letterRendering: true,
                scale: window.devicePixelRatio * 3
            })

            canvasPromise.then(canvas => {
                var paddedCanvas = document.createElement('canvas')
                var context = paddedCanvas.getContext('2d')
                var padding = 30
                paddedCanvas.width = canvas.width + 2 * padding
                paddedCanvas.height = canvas.height + 2 * padding
                context.fillStyle = '#ffffff' // background color
                context.fillRect(0, 0, paddedCanvas.width, paddedCanvas.height)
                context.drawImage(canvas, padding, padding)

                // Convert the modified canvas to a data URL
                var dataURL = paddedCanvas.toDataURL('image/png')

                var a = document.createElement('a')
                a.innerHTML = 'DOWNLOAD'
                a.target = '_blank'
                a.href = dataURL
                // Set the download attribute of the link
                a.download = 'exchange.png' // filename
                document.body.appendChild(a)

                a.click()
                document.body.removeChild(a)
            })
        } catch (errMsg) {
            console.log('errMsg: ', errMsg)
        }
    }

    return (
        <SideDrawer
            handleDrawerToggle={handleDrawerToggle}
            open={open}
            drawerHeading={EXCHANGE_RETURN_UI_NAME[data.reason_type]}>
            <div className="viewProductWrap exchangeReturn">
                <div ref={ref}>
                    <div className="viewProductHeader">
                        <div className="headerRow dFlex jcSpaceBetween aiCenter">
                            <div className="headerRowLeft">
                                {EXCHANGE_RETURN_UI_NAME[data.reason_type]}
                            </div>
                            <div className="headerRowRight dFlex flexColumn">
                                {formatDate(data.created_at)}
                                <small>{formatTime(data.created_at)}</small>
                            </div>
                        </div>
                        <div className="ProductUniqueTag">
                            {data.invoice_number}
                        </div>
                        <div className="ProductUniqueTag exchangeId">
                            {data.old_invoice_number}
                        </div>
                    </div>
                    <div className="viewProductHeader">
                        <ul className="moreDetailsRow dFlex">
                            <li className="moreDetails strong">
                                {data?.customer?.name}
                            </li>
                            <li className="moreDetails strong">
                                {data?.customer?.mobile_no}
                            </li>
                        </ul>
                        <div className="productSupplierAddress dFlex aiCenter">
                            <LocationIcon className="icon" />{' '}
                            {data?.customer?.shipping_address}
                        </div>
                    </div>
                    {data.delivery && (
                        <div className="viewProductHeader">
                            <ul className="moreDetailsRow dFlex">
                                <li className="moreDetails">
                                    Delivery By: Ashaf Hussain
                                </li>
                                <li className="moreDetails">
                                    +880 95435678976
                                </li>
                            </ul>
                        </div>
                    )}
                    <div className="productDescriptionWrap">
                        <div className="headerRowLeft mt-2 mb-2">{`${
                            EXCHANGE_RETURN_UI_NAME[data.reason_type]
                        } Products`}</div>
                        <table className="table exchange viewExchange">
                            <thead>
                                <tr>
                                    <td width="60%">Medicine</td>
                                    <td width="15%" align="right">
                                        Qty
                                    </td>
                                    <td width="25%">Amount</td>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.product_details?.map(product => {
                                    if (product.type === 'NEW') return

                                    return (
                                        <tr>
                                            <td>
                                                {`
                                                    ${
                                                        product?.[
                                                            PRODUCT_DETAIL_FIELDS
                                                                .PRODUCT_NAME
                                                        ]
                                                    } ${
                                                    product?.[
                                                        PRODUCT_DETAIL_FIELDS
                                                            .DOSE
                                                    ]
                                                }
                                                `}
                                            </td>
                                            <td>
                                                <div className="dFlex flexColumn">
                                                    {product.old_quantity}{' '}
                                                    <br />{' '}
                                                    <span className="exchangeQty">
                                                        {product.new_quantity}
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="dFlex flexColumn strong">
                                                    BDT{' '}
                                                    {product.rate_per_unit *
                                                        product.old_quantity}
                                                    <br />
                                                    <span className="exchangeVal">
                                                        BDT{' '}
                                                        {product.rate_per_unit *
                                                            product.new_quantity}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <div class="exchangeValueRow dFlex jcSpaceBetween">
                            <div class="exchangeValue strong">
                                {EXCHANGE_RETURN_UI_NAME[data.reason_type]}{' '}
                                Product value
                            </div>
                            <div class="exchangeValue strong">
                                {data.old_amount}
                            </div>
                        </div>
                        {data.reason_type ===
                            ADD_EXCHANGE_RETURN_FLOW.EXCHANGE_FLOW && (
                            <>
                                <div className="headerRowLeft mt-2 mb-2">
                                    New Products
                                </div>
                                <table className="table exchange viewExchange">
                                    <thead>
                                        <tr>
                                            <td width="50%">Medicine</td>
                                            <td width="30%" align="right">
                                                Qty
                                            </td>
                                            <td width="30%">Amount</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.product_details?.map(product => {
                                            if (product.type != 'NEW') return

                                            return (
                                                <tr>
                                                    <td>
                                                        {`
                                                    ${
                                                        product?.[
                                                            PRODUCT_DETAIL_FIELDS
                                                                .PRODUCT_NAME
                                                        ]
                                                    } ${
                                                            product?.[
                                                                PRODUCT_DETAIL_FIELDS
                                                                    .DOSE
                                                            ]
                                                        }
                                                `}
                                                    </td>
                                                    <td>
                                                        <div className="dFlex flexColumn">
                                                            {
                                                                product.new_quantity
                                                            }{' '}
                                                            <br />
                                                            {/* <span className='exchangeQty'>{product.new_quantity}</span> */}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="dFlex flexColumn strong">
                                                            {/* BDT {product.rate_per_unit*product.old_quantity}<br />  */}
                                                            <span className="exchangeVal">
                                                                BDT{' '}
                                                                {product.rate_per_unit *
                                                                    product.new_quantity}
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <div class="exchangeValueRow dFlex jcSpaceBetween">
                                    <div class="exchangeValue strong">
                                        New Product value
                                    </div>
                                    <div class="exchangeValue strong">
                                        {data.new_amount_total}
                                    </div>
                                </div>
                            </>
                        )}
                        <table
                            className="table productPrice pt-4"
                            cellPadding={2}
                            cellSpacing={2}>
                            <tbody>
                                {/* <tr>
                                <td>Exchange Amount (BDT)</td>
                                <td align='right'>20,000</td>
                            </tr> */}
                                <tr>
                                    <td>Amount (BDT)</td>
                                    <td align="right">
                                        {data.new_amount
                                            ? data.new_amount
                                            : data.old_amount}
                                    </td>
                                </tr>
                                <tr>
                                    <td>{`Special Discount (${
                                        data.discount || 0
                                    }%)`}</td>
                                    <td align="right">
                                        {getDiscountedAmount()}
                                    </td>
                                </tr>
                                {/* <tr>
                                <td>{`Trade Discount (${data.trade_discount}%)`}</td>
                                <td align="right">
                                    {getTradeDiscountedAmount()}
                                </td>
                            </tr> */}
                                <tr>
                                    <td>{`Vat (${data.vat || 0}%)`}</td>
                                    <td align="right">{getVatAmount()}</td>
                                </tr>
                                <tr>
                                    <td className="strong">
                                        Total Payable (BDT)
                                    </td>
                                    <td
                                        className={`strong ${
                                            data.total_amount !== 0 &&
                                            (data.total_amount > 0
                                                ? 'textGreen'
                                                : 'textRed')
                                        }`}
                                        align="right">
                                        {data.total_amount}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {data.payment_mode && (
                            <div className="disclaimerNotes">
                                <div className="disclaimerNotesHeading">
                                    Payment Mode
                                </div>
                                <div className="disclaimerNotesDesc">
                                    {PAYMENT_MODE_OPTIONS.find(
                                        item => item.name === data.payment_mode
                                    )?.label || data?.payment_mode}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="actionButton mt-5 dFlex fdColumn ai-flex-end">
                    <button
                        className="btn btnPrimary dFlex "
                        onClick={() => captureScreenshot()}>
                        <DownloadIcon />
                    </button>
                </div>
            </div>
        </SideDrawer>
    )
}

const mapStateToProps = state => {
    return {}
}
const mapDispatchToProps = dispatch => {
    return {}
}

ViewExchangeReturn.propTypes = {
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewExchangeReturn)
