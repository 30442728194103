import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import EditIcon from '../../assets/EditIcon'
import EditIconActive from '../../assets/EditIconActive'
import LocationIcon from '../../assets/LocationIcon'
import {
    HEADER_CONFIG,
    HEADER_CONST
} from '../../common/constant/common-constant'
import { PAGE_CONST } from '../../common/constant/page-constant'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import { convertToTitleCase } from '../../common/utils/appUtils'
import { redirect } from '../../common/utils/redirectionUtils'
import {
    getPersonalDetailsHelper,
    updatePasswordHelper,
    updatePersonalDetailsHelper
} from '../../helper/personal-details-helper'
import MobileHeader from '../Common/Header/MobileHeader'

import './PersonalDetails.scss'
import AttachIcon from '../../assets/AttachIcon'
import CheckIcon from '../../assets/CheckIcon'
import CloseIcon from '../../assets/CloseIcon'

const PersonalDetails = props => {
    const navigate = useNavigate()
    const isMobile = useCheckMobileScreen()

    const [viewDrawer, setViewDrawer] = useState(false)
    const [activeItem, setActiveItem] = useState(undefined)
    const [open, setOpen] = useState(false)
    const [data, setData] = useState(undefined)
    const [headerOptions, setHeaderOptions] = useState({
        [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
        [HEADER_CONFIG.TITLE]: HEADER_CONST.PERSONAL_DETAILS,
        [HEADER_CONFIG.HANDLE_BACK_FUNC]: () => props?.onBackClick()
    })
    const [isEditActive, setIsEditActive] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [err, setErr] = useState('')
    const [profileImageFile, setProfileImageFile] = useState(undefined)
    const [pharmacyLogo, setPharmacyLogo] = useState(undefined)
    const [errMsg, setErrMsg] = useState(undefined)
    const [startTime, setStartTime] = useState({
        hours: 0,
        minutes: 0
    })
    const [closeTime, setCloseTime] = useState({
        hours: 0,
        minutes: 0
    })
    const [timePopup, setTimePopup] = useState('')
    const [editingPassword, setEditingPassword] = useState(false)

    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    useEffect(() => {
        getPersonalDetails()
    }, [])

    const getPersonalDetails = () => {
        getPersonalDetailsHelper()
            .then(res => {
                setData(res)
                setStartTime({
                    hours: res?.start_time?.split(':')[0],
                    minutes: res?.start_time?.split(':')[1]
                })

                setCloseTime({
                    hours: res?.end_time?.split(':')[0],
                    minutes: res?.end_time?.split(':')[1]
                })

                setPharmacyLogo(res?.logo)

                console.log('res::', res)
            })
            .catch(err => {
                setErrMsg(err)
            })
    }

    const updatePersonalDetails = () => {
        if (!profileImageFile && !pharmacyLogo && !startTime && !closeTime) {
            return
        }
        let reqData = new FormData()
        if (profileImageFile) {
            reqData.append('profile_image', profileImageFile)
        }
        if (pharmacyLogo) {
            reqData.append('logo', pharmacyLogo)
        }
        if (startTime) {
            reqData.append(
                'start_time',
                `${startTime?.hours}:${startTime?.minutes}`
            )
        }
        if (closeTime) {
            reqData.append(
                'end_time',
                `${closeTime.hours}:${closeTime.minutes}`
            )
        }
        updatePersonalDetailsHelper(reqData)
            .then(res => {
                console.log('res::', res)

                getPersonalDetails()
                setErrMsg(undefined)
                setProfileImageFile(undefined)
                setIsEditActive(false)
                setIsEditing(false)
            })
            .catch(err => {
                setErrMsg(err)
            })
    }

    const handleRemoveLogo = () => {
        setPharmacyLogo('')
        setIsEditing(true)
    }

    const handlePasswordUpdate = () => {
        if (newPassword !== confirmPassword) {
            setErrMsg('New Password and Confirm Password do not match')
            return
        }

        if (!currentPassword || !newPassword || !confirmPassword) {
            setErrMsg('Please enter all fields')
            return
        }

        if (currentPassword && newPassword && confirmPassword) {
            updatePasswordHelper(currentPassword, newPassword)
                .then(res => {
                    console.log('res::', res)
                    setCurrentPassword('')
                    setNewPassword('')
                    setConfirmPassword('')
                    setEditingPassword(false)
                    setErrMsg(undefined)
                })
                .catch(err => {
                    setErrMsg(err)
                })
        }
    }

    return (
        <>
            {isMobile && <MobileHeader headerOptions={headerOptions} />}
            <div className="pageBodyRightPanel">
                <div className="pageBodyRightPanelHeader dFlex jcSpaceBetween aiCenter">
                    <div className="pageBodyRightPanelHeading">
                        Personal Details
                    </div>
                </div>
                <div className="personalDetailsRow dFlex flexNowrap aiCenter">
                    <div className="personalIcon dFlex aiCenter jcContentCenter">
                        {/* {convertToTitleCase(data?.branch_name?.[0])} */}
                        <img
                            className="iconImage"
                            src={data?.profile_image_url}
                        />
                    </div>
                    <div className="personalNameWrap">
                        <div className="personalName">{data?.branch_name}</div>
                        <div className="personalDesc">{data?.location}</div>
                    </div>
                </div>

                <div className="otherDetailsRow">
                    <div className="otherDetailsWrap">
                        <div className="otherDetailsHeading dFlex jcSpaceBetween aiCenter">
                            Pharmacy Store Details
                            <div
                                className="otherDetailsEdit"
                                onClick={() => {
                                    setErrMsg(undefined)
                                    setProfileImageFile(undefined)
                                    setIsEditActive(!isEditActive)
                                }}>
                                {isEditing && (
                                    <button
                                        className="btn btnPrimary block space"
                                        onClick={updatePersonalDetails}>
                                        {'SUBMIT'}
                                    </button>
                                )}

                                {isEditActive ? (
                                    <EditIconActive />
                                ) : (
                                    <EditIcon />
                                )}
                            </div>
                        </div>
                        {isEditActive && (
                            <div className="formGroup typeFile">
                                {!profileImageFile && (
                                    <div className="beforeUpload">
                                        <div className="inputHeading">
                                            {'Profile Image'}
                                        </div>
                                        <div className="customFile">
                                            <input
                                                type="file"
                                                className="fileInput"
                                                id={'Profile Image'}
                                                aria-describedby={
                                                    'Profile Image'
                                                }
                                                // value={profileImageFile}
                                                onChange={e => {
                                                    console.log(e.target.files)
                                                    setIsEditing(true)
                                                    // setErr('')
                                                    setProfileImageFile(
                                                        e.target.files[0]
                                                    )
                                                }}
                                            />
                                            <label
                                                className="fileLabel"
                                                htmlFor={'Profile Image'}>
                                                <AttachIcon />
                                            </label>
                                        </div>
                                    </div>
                                )}
                                {profileImageFile && (
                                    <div className="uploadFile dFlex flexNowrap jcSpaceBetween">
                                        <div className="">
                                            <div className="inputHeading">
                                                {'Profile Image'}
                                            </div>
                                            <div className="uploadFileName">
                                                <a
                                                    id="myLink"
                                                    href={profileImageFile?.url}
                                                    target="_blank">
                                                    {profileImageFile?.name}
                                                </a>
                                            </div>
                                            <div className="uploadFileProgress">
                                                <div className="progress progress-moved">
                                                    <div
                                                        className={
                                                            profileImageFile
                                                                ? 'animation progress-bar'
                                                                : 'progress-bar'
                                                        }></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="cancelUpload"
                                            onClick={() => {
                                                // setErr('')
                                            }}>
                                            <div className="cancelUpload">
                                                <CheckIcon />{' '}
                                            </div>
                                            <div
                                                onClick={() => {
                                                    setIsEditing(false)
                                                    setProfileImageFile(
                                                        undefined
                                                    )
                                                }}>
                                                <CloseIcon />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        <div className="otherDetail">
                            {
                                <>
                                    <div className="otherDetailLabel">
                                        Location
                                    </div>
                                    <div className="otherDetailData">
                                        {data?.location}
                                    </div>
                                </>
                            }
                            {/* {isEditActive && (
                                <div className="formGroup formFloating fgrow1">
                                    <input
                                        type="text"
                                        className="formControl"
                                        id="batch"
                                        placeholder="Location"
                                        value={data?.location}
                                        onChange={e =>{}
                                        }
                                        required
                                    />
                                    <label htmlFor="batch">Location</label>
                                    {false && (
                                        <div class="invalidFeedback">
                                            Please select a valid Location.
                                        </div>
                                    )}
                                </div>
                            )} */}
                        </div>
                        <div className="otherDetail">
                            <div className="otherDetailLabel">Category</div>
                            <div className="otherDetailData">
                                {data?.pharmacy_category}
                            </div>
                        </div>
                        <div className="otherDetail storeTime">
                            <div
                                className="start"
                                onClick={() => setTimePopup('start')}>
                                <div className="otherDetailLabel">
                                    Start Time
                                </div>
                                <div className="otherDetailData">
                                    {startTime.hours || startTime.minutes
                                        ? `${startTime.hours}:${startTime.minutes}`
                                        : data?.start_time || '00:00'}
                                </div>
                            </div>
                            <div
                                className="close"
                                onClick={() => setTimePopup('close')}>
                                <div className="otherDetailLabel">
                                    Close Time
                                </div>
                                <div className="otherDetailData">
                                    {closeTime.hours || closeTime.minutes
                                        ? `${closeTime.hours}:${closeTime.minutes}`
                                        : data?.end_time || '00:00'}
                                </div>
                            </div>
                            {timePopup && (
                                <div className="timePopup">
                                    <div className="timePopupInner">
                                        <div className="timePopupHeader">
                                            {timePopup === 'start'
                                                ? 'Start Time'
                                                : 'Close Time'}
                                        </div>
                                        <div className="timePopupBody">
                                            <div className="timePopupTime">
                                                <input
                                                    type="text"
                                                    value={
                                                        timePopup === 'start'
                                                            ? startTime.hours
                                                            : closeTime.hours
                                                    }
                                                    max={23}
                                                    onChange={e => {
                                                        setIsEditing(true)
                                                        timePopup === 'start'
                                                            ? setStartTime({
                                                                ...startTime,
                                                                hours: e
                                                                    .target
                                                                    .value
                                                            })
                                                            : setCloseTime({
                                                                ...closeTime,
                                                                hours: e
                                                                    .target
                                                                    .value
                                                            })
                                                    }}
                                                />
                                                <p className="timePopupTimeSubtitle">
                                                    Hours
                                                </p>
                                            </div>
                                            <div className="timePopupColon">
                                                <p>:</p>
                                            </div>
                                            <div className="timePopupTime">
                                                <input
                                                    type="text"
                                                    value={
                                                        timePopup === 'start'
                                                            ? startTime.minutes
                                                            : closeTime.minutes
                                                    }
                                                    max={59}
                                                    onChange={e => {
                                                        setIsEditing(true)
                                                        timePopup === 'start'
                                                            ? setStartTime({
                                                                ...startTime,
                                                                minutes:
                                                                    e.target
                                                                        .value
                                                            })
                                                            : setCloseTime({
                                                                ...closeTime,
                                                                minutes:
                                                                    e.target
                                                                        .value
                                                            })
                                                    }}
                                                />
                                                <p className="timePopupTimeSubtitle">
                                                    Minutes
                                                </p>
                                            </div>
                                        </div>
                                        <div className="timePopupFooter">
                                            <button
                                                className="btn btnPrimaryOutlined"
                                                onClick={() => {
                                                    setIsEditing(false)
                                                    setTimePopup('')
                                                }}>
                                                Cancel
                                            </button>
                                            <button
                                                className="btn btnPrimary"
                                                onClick={() => {
                                                    updatePersonalDetails()
                                                    setTimePopup('')
                                                }}>
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="otherDetail">
                            <div className="otherDetailLabel">
                                Pharmacy Logo
                            </div>
                            <div className="otherDetailData profileLogo">
                                <div className="profileLogoInner">
                                    {!pharmacyLogo ? (
                                        <>
                                            <input
                                                type="file"
                                                hidden
                                                id="pharmacyLogo"
                                                name="pharmacyLogo"
                                                onChange={e => {
                                                    // max 2MB

                                                    setErrMsg(undefined)
                                                    if (
                                                        e.target.files[0].size >
                                                        2097152
                                                    ) {
                                                        setErrMsg(
                                                            'File size should not exceed 2MB'
                                                        )
                                                        return
                                                    }
                                                    setIsEditing(true)
                                                    setPharmacyLogo(
                                                        e.target.files[0]
                                                    )
                                                }}
                                            />{' '}
                                            <label htmlFor="pharmacyLogo">
                                                Upload Pharmacy Logo
                                            </label>
                                        </>
                                    ) : (
                                        <>
                                            <div className="profileLogoInnerName">
                                                {pharmacyLogo
                                                    ? pharmacyLogo.name ||
                                                      'Logo Uploaded'
                                                    : 'Logo Uploaded'}
                                                {pharmacyLogo && (
                                                    <span
                                                        onClick={() => {
                                                            setErrMsg(undefined)
                                                            if (
                                                                pharmacyLogo.name
                                                            ) {
                                                                setIsEditing(
                                                                    false
                                                                )
                                                            } else {
                                                                setIsEditing(
                                                                    true
                                                                )
                                                            }
                                                            setPharmacyLogo(
                                                                undefined
                                                            )
                                                        }}>
                                                        <CloseIcon />
                                                    </span>
                                                )}
                                            </div>
                                            {isEditing && pharmacyLogo && (
                                                <div className="uploadFileProgress">
                                                    <div className="progress progress-moved">
                                                        <div
                                                            className={
                                                                pharmacyLogo
                                                                    ? 'animation progress-bar'
                                                                    : 'progress-bar'
                                                            }></div>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                                <p className="profileLogoIcon">
                                    <AttachIcon />
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="otherDetailsWrap">
                        <div className="otherDetailsHeading dFlex jcSpaceBetween aiCenter">
                            Owner Details
                            {/* <EditIcon /> */}
                        </div>
                        <div className="otherDetail">
                            <div className="otherDetailLabel">Name</div>
                            <div className="otherDetailData">{data?.name}</div>
                        </div>
                        <div className="otherDetail">
                            <div className="otherDetailLabel">Phone Number</div>
                            <div className="otherDetailData">
                                {data?.mobile_no}
                            </div>
                        </div>
                        <div className="otherDetail">
                            <div className="otherDetailLabel">Email ID</div>
                            <div className="otherDetailData">{data?.email}</div>
                        </div>
                    </div>
                    <div className="otherDetailsWrap">
                        <div className="otherDetailsHeading dFlex jcSpaceBetween aiCenter">
                            Password
                            <span
                                onClick={() =>
                                    setEditingPassword(!editingPassword)
                                }>
                                {editingPassword ? <CloseIcon /> : <EditIcon />}
                            </span>
                        </div>
                        {!editingPassword && (
                            <div className="otherDetail">
                                <div className="otherDetailLabel">
                                    Current Password
                                </div>
                                <div className="otherDetailData">°°°°°°°°°</div>
                            </div>
                        )}

                        {editingPassword && (
                            <>
                                <div className="formGroup">
                                    <div className="formGroup formFloating fgrow1">
                                        <input
                                            type="password"
                                            className="formControl"
                                            value={currentPassword}
                                            onChange={e => {
                                                setCurrentPassword(
                                                    e.target.value
                                                )
                                            }}
                                            id="current_password"
                                            placeholder="Current Password"
                                        />
                                        <label htmlFor="current_password">
                                            Current Password
                                        </label>
                                    </div>

                                    <div className="formSection dFlex">
                                        <div className="formGroup formFloating fgrow1">
                                            <input
                                                type="password"
                                                className="formControl"
                                                id="password"
                                                value={newPassword}
                                                placeholder="New Password"
                                                onChange={e => {
                                                    setNewPassword(
                                                        e.target.value
                                                    )
                                                }}
                                            />
                                            <label htmlFor="password">
                                                New Password
                                            </label>
                                        </div>

                                        <div className="formGroup formFloating fgrow1 ml-5">
                                            <input
                                                type="password"
                                                className="formControl"
                                                id="confirm_password"
                                                placeholder="Confirm New Password"
                                                onChange={e => {
                                                    setConfirmPassword(
                                                        e.target.value
                                                    )
                                                }}
                                            />
                                            {newPassword.length > 0 &&
                                                confirmPassword.length > 0 &&
                                                confirmPassword ===
                                                newPassword && (
                                                    <span className="passwordMatch">
                                                        <CheckIcon />
                                                    </span>
                                                )}
                                            <label htmlFor="confirm_password">
                                                Confirm New Password
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {editingPassword && (
                                    <button
                                        className="btn btnPrimary block "
                                        disabled={
                                            !currentPassword ||
                                            !newPassword ||
                                            !confirmPassword ||
                                            confirmPassword !== newPassword
                                        }
                                        onClick={handlePasswordUpdate}>
                                        Update Password
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                </div>
                {errMsg && <div className="invalidFeedback">{errMsg}</div>}
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetails)
