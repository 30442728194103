import React, { useState } from 'react'
import { connect } from 'react-redux'

import './Login.scss'
import LogoLarge from '../../assets/LogoLarge'

function LoginLeft(props) {
    return (
        <div className="loginLeft">
            <div className="loginLeftControl">
                <div className="appLogo">
                    <LogoLarge />
                </div>
                <div className="appName">DG ePharma</div>
                {/* <div className="appDesc">
                    Lorem ipsum dolor sit amet consectetur. Arcu mattis donec eu
                    etiam amet.
                </div> */}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginLeft)
