import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { useScreenshot, createFileName } from 'use-react-screenshot'

import './OrderBook.scss'
import SideDrawer from '../Common/SideDrawer/SideDrawer'
import LocationIcon from '../../assets/LocationIcon'
import DownloadIcon from '../../assets/DownloadIcon'
import html2canvas from 'html2canvas'
import {
    convertToTitleCase,
    formatDate,
    formatTime
} from '../../common/utils/appUtils'

function ViewOrderBook({
    open,
    handleDrawerToggle,
    data,
    onConfirmClick,
    drawerButton,
    isDrawerButtonVisible
}) {
    const navigate = useNavigate()
    const ref = useRef(null)
    const [image, takeScreenshot] = useScreenshot()

    // useEffect(() => {
    //     if (image) {
    //         download(image, { name: 'order-book', extension: 'png' })
    //     }
    // }, [image])

    // const getImage = () => {
    //     console.log('get image clicked')
    //     takeScreenshot(ref.current, { padding: 20 })
    // }

    // const download = (iImage, { name = 'img', extension = 'png' } = {}) => {
    //     const a = document.createElement('a')
    //     a.href = iImage
    //     a.download = createFileName(extension, name)
    //     a.click()
    // }

    // const captureScreenshot = () => {
    //     var canvasPromise = html2canvas(ref.current, {
    //         useCORS: true
    //     })

    //     canvasPromise.then(canvas => {
    //         var dataURL = canvas.toDataURL('order-book/png')
    //         // Create an image element from the data URL
    //         var img = new Image()
    //         img.src = dataURL
    //         // img.alt = 'order'
    //         img.download = 'order-book.png'
    //         // Create a link element
    //         var a = document.createElement('a')
    //         a.innerHTML = 'DOWNLOAD'
    //         a.target = '_blank'
    //         // Set the href of the link to the data URL of the image
    //         a.href = img.src
    //         // Set the download attribute of the link
    //         a.download = img.download
    //         // Append the link to the page
    //         document.body.appendChild(a)
    //         // Click the link to trigger the download
    //         a.click()
    //     })
    // }

    const captureScreenshot = () => {
        try {
            var canvasPromise = html2canvas(ref.current, {
                useCORS: true,
                allowTaint: true,
                letterRendering: true,
                scale: window.devicePixelRatio * 3,
                logging: true
            })

            canvasPromise.then(canvas => {
                var paddedCanvas = document.createElement('canvas')
                var context = paddedCanvas.getContext('2d')
                var padding = 30
                paddedCanvas.width = canvas.width + 2 * padding
                paddedCanvas.height = canvas.height + 2 * padding
                context.fillStyle = '#ffffff' // background color
                context.fillRect(0, 0, paddedCanvas.width, paddedCanvas.height)
                context.drawImage(canvas, padding, padding)

                // Convert the modified canvas to a data URL
                var dataURL = paddedCanvas.toDataURL('image/png')

                var a = document.createElement('a')
                a.innerHTML = 'DOWNLOAD'
                a.target = '_blank'
                a.href = dataURL
                // Set the download attribute of the link
                a.download = 'order-book.png' // filename
                document.body.appendChild(a)

                a.click()
                document.body.removeChild(a)
            })
        } catch (errMsg) {
            console.log('errMsg: ', errMsg)
        }
    }

    return (
        <div>
            <SideDrawer
                handleDrawerToggle={handleDrawerToggle}
                open={open}
                drawerButton={drawerButton && data?.status !== 'COMPLETED'}
                buttonText={'Confirm Order'}
                handleClick={onConfirmClick}
                // viewScreenButton={true}
            >
                <div className="viewProductWrap viewOrderBook">
                    <div ref={ref}>
                        <div className="viewProductHeader">
                            <div className="headerRowLeft">
                                {`${formatDate(
                                    data?.created_at
                                )} | ${formatTime(data?.created_at)}`}
                                {/* 28 Dec 2023 | 12:30 pm */}
                            </div>
                            <div className="ProductUniqueTag">
                                {data?.order_no}
                            </div>
                        </div>
                        <div className="viewProductHeader">
                            <div className="contactDetailsRow dFlex  aiCenter">
                                {data?.branch?.logo && (
                                    <div className="contactDetailsRowLogo">
                                        <img
                                            src={data?.branch?.logo}
                                            alt="logo"
                                        />
                                    </div>
                                )}
                                <div className="moreDetailsRow">
                                    <ul className="moreDetailsRow dFlex">
                                        <li className="moreDetails strong">
                                            {data?.branch?.name}
                                        </li>
                                        <li>{data?.branch?.mobile_no}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="productSupplierAddress dFlex">
                                <LocationIcon className="icon" />
                                {data?.branch?.location}
                            </div>
                        </div>
                        <div className="viewProductHeader">
                            <div className="headerRowLeft">Pharma Company</div>
                            <div className="ProductUniqueTag">
                                {data?.pharma?.name}
                            </div>
                        </div>
                        <div className="mb-5">
                            <table className="orderBook">
                                <thead>
                                    <tr>
                                        <td>Medicines</td>
                                        <td width="10%" className="alignright">
                                            Qty
                                        </td>
                                        <td width="20%" className="alignright">
                                            Amount
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.product_details?.map(product => {
                                        return (
                                            <tr>
                                                <td>{`${product.product?.name} ${product.product?.dose}`}</td>
                                                <td className="alignright">
                                                    {product?.quantity}
                                                </td>
                                                <td className="nowrap alignright">
                                                    {`BDT ${
                                                        product?.amount || '-'
                                                    }`}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    {/* <tr>
                                    <td>Mesacol 300 mg</td>
                                    <td className="alignright">200</td>
                                    <td className="nowrap alignright">
                                        BDT 20,000
                                    </td>
                                </tr>
                                <tr>
                                    <td>Mesacol 300 mg</td>
                                    <td className="alignright">200</td>
                                    <td className="nowrap alignright">
                                        BDT 20,000
                                    </td>
                                </tr> */}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>Total</td>
                                        <td
                                            colSpan={2}
                                            className="alignright strong">
                                            {`BDT ${data?.total_amount || '-'}`}
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div className="actionButton mt-5 dFlex fdColumn ai-flex-end">
                        <button
                            className="btn btnPrimary dFlex "
                            onClick={() => captureScreenshot()}>
                            <DownloadIcon />
                        </button>
                    </div>
                </div>
            </SideDrawer>
        </div>
    )
}

const mapStateToProps = state => {
    return {}
}
const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewOrderBook)
