import { DataF } from '@react-google-maps/api'
import {
    addDeliveryPartnerAPI,
    addOrderBookAPI,
    createCampaignDiscountAPI,
    createSingleProductDiscountAPI,
    createVoucherAPI,
    enableAccountingAPI,
    getAccoutingAPI,
    getBalanceSheetStatementAPI,
    getCampaignDiscountAPI,
    getCampaignDiscountDetailAPI,
    getDeliveryPartnersAPI,
    getDocumentsAPI,
    getNotificationsAPI,
    getOrderBookAPI,
    getOrderBookDetailsAPI,
    getOrderBookSearchResultsAPI,
    getOrderBookSearchSuggestionsAPI,
    getPersonalDetailsAPI,
    getProductDetailByIdAPI,
    getProductSearchResultsAPI,
    getProductSearchSuggestionsAPI,
    getProfitLossStatementAPI,
    getReceiptPaymentAPI,
    getSingleProductDiscountAPI,
    getVoucherDetailsAPI,
    getVouchersAPI,
    updateCampaignDiscountAPI,
    updateDeliveryPartnerStatusAPI,
    updateNotificationsAPI,
    updatePasswordAPI,
    updatePersonalDetailsAPI,
    updateSingleProductDiscountAPI,
    updateVoucherAPI
} from '../adapter/adapter'

export function getPersonalDetailsHelper() {
    let promise = new Promise(function (resolve, reject) {
        getPersonalDetailsAPI()
            .then(res => {
                if (res?.data?.data && res?.data?.data) {
                    resolve(res.data.data)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('Get pDetails Failure')
                }
            })
            .catch(err => {
                reject('Get pDetails Failure')
            })
    })

    return promise
}

export function updatePersonalDetailsHelper(data) {
    let promise = new Promise(function (resolve, reject) {
        updatePersonalDetailsAPI(data)
            .then(res => {
                if (res?.data?.data && res?.data?.data) {
                    resolve(res.data.data)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('update Personal Details Failure')
                }
            })
            .catch(err => {
                reject('update Personal Details Failure')
            })
    })

    return promise
}

export function getNotificationsHelper() {
    let promise = new Promise(function (resolve, reject) {
        getNotificationsAPI()
            .then(res => {
                if (res?.data?.data && res?.data?.data?.notifications) {
                    resolve(res.data.data.notifications)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('Get pDetails Failure')
                }
            })
            .catch(err => {
                reject('Get pDetails Failure')
            })
    })

    return promise
}

export function updateNotificationsHelper(data) {
    let promise = new Promise(function (resolve, reject) {
        updateNotificationsAPI(data)
            .then(res => {
                if (res?.data?.data && res?.data?.data?.notifications) {
                    resolve(res.data.data.notifications)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('Get pDetails Failure')
                }
            })
            .catch(err => {
                reject('Get pDetails Failure')
            })
    })

    return promise
}

export function getDocumentsHelper() {
    let promise = new Promise(function (resolve, reject) {
        getDocumentsAPI()
            .then(res => {
                if (res?.data?.data && res?.data?.data) {
                    resolve(res.data.data)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('Get Documents Failure')
                }
            })
            .catch(err => {
                reject('Get Documents Failure')
            })
    })

    return promise
}

export function getDeliveryPartnersHelper() {
    let promise = new Promise(function (resolve, reject) {
        getDeliveryPartnersAPI()
            .then(res => {
                if (res?.data?.data && res?.data?.data) {
                    resolve(res.data.data)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('Get Documents Failure')
                }
            })
            .catch(err => {
                reject('Get Documents Failure')
            })
    })

    return promise
}

export function addDeliveryPartnerHelper(data) {
    let promise = new Promise(function (resolve, reject) {
        addDeliveryPartnerAPI(data)
            .then(res => {
                if (
                    res?.data?.data &&
                    res?.data?.data?.is_delivery_partner_added
                ) {
                    resolve(res.data.data.is_delivery_partner_added)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('Get Documents Failure')
                }
            })
            .catch(err => {
                reject('Get Documents Failure')
            })
    })

    return promise
}

export function getOrderBookHelper() {
    let promise = new Promise(function (resolve, reject) {
        getOrderBookAPI()
            .then(res => {
                if (res?.data?.data && res?.data?.data?.order_books) {
                    resolve(res.data.data.order_books)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('Get Documents Failure')
                }
            })
            .catch(err => {
                reject('Get Documents Failure')
            })
    })

    return promise
}

export function addOrderBookHelper(data) {
    let promise = new Promise(function (resolve, reject) {
        addOrderBookAPI(data)
            .then(res => {
                if (res?.data?.data && res?.data?.data) {
                    resolve(res.data.data)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('Get Documents Failure')
                }
            })
            .catch(err => {
                reject('Get Documents Failure')
            })
    })

    return promise
}

export function getOrderBookDetailsHelper(id) {
    let reqData = {
        order_book_id: id
    }

    let promise = new Promise(function (resolve, reject) {
        getOrderBookDetailsAPI(reqData)
            .then(res => {
                if (res?.data?.data && res?.data?.data?.order_books) {
                    resolve(res.data.data.order_books)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('Get Documents Failure')
                }
            })
            .catch(err => {
                reject('Get Documents Failure')
            })
    })

    return promise
}

export function getOrderBookSearchSuggestionsHelper(searchType, searchTerm) {
    let reqData = {
        search_string: searchTerm,
        search_type: searchType
    }
    let promise = new Promise(function (resolve, reject) {
        getOrderBookSearchSuggestionsAPI(reqData)
            .then(res => {
                if (res?.data?.data && res?.data?.data?.search_suggestions) {
                    resolve(res.data.data.search_suggestions)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('Get Documents Failure')
                }
            })
            .catch(err => {
                reject('Get Documents Failure')
            })
    })

    return promise
}

export function getOrderBookSearchResultsHelper(
    searchType,
    selectedSearchTerm
) {
    let reqData = {
        search_type: searchType,
        search_string: selectedSearchTerm
    }

    let promise = new Promise(function (resolve, reject) {
        getOrderBookSearchResultsAPI(reqData)
            .then(res => {
                if (res?.data?.data && res?.data?.data?.search_results) {
                    resolve(res.data.data?.search_results)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('Get Documents Failure')
                }
            })
            .catch(err => {
                reject('Get Documents Failure')
            })
    })

    return promise
}

export function getProductSearchSuggestionsHelper(searchTerm) {
    let reqData = {
        search_string: searchTerm
    }
    let promise = new Promise(function (resolve, reject) {
        getProductSearchSuggestionsAPI(reqData)
            .then(res => {
                if (res?.data?.data && res?.data?.data?.search_suggestions) {
                    resolve(res.data.data.search_suggestions)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('Get Documents Failure')
                }
            })
            .catch(err => {
                reject('Get Documents Failure')
            })
    })

    return promise
}

export function getProductDetailByIdHelper(productId) {
    let reqData = {
        product_id: productId
    }

    let promise = new Promise(function (resolve, reject) {
        getProductDetailByIdAPI(reqData)
            .then(res => {
                if (res?.data?.data && res?.data?.data?.product_detail) {
                    resolve(res.data.data?.product_detail)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('Get Product Details By Id Failure !!')
                }
            })
            .catch(err => {
                reject('Get Product Details By Id Failure !!')
            })
    })

    return promise
}

export function getProductSearchResultsHelper(productId) {
    let reqData = {
        product_id: productId
    }

    let promise = new Promise(function (resolve, reject) {
        getProductSearchResultsAPI(reqData)
            .then(res => {
                if (res?.data?.data && res?.data?.data?.search_results) {
                    resolve(res.data.data?.search_results)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('Get Product Search Results Failure')
                }
            })
            .catch(err => {
                reject('Get Product Search Results Failure')
            })
    })

    return promise
}

export function createSingleProductDiscountHelper(
    productId,
    discountRate,
    discountPercentage
) {
    let reqData = {
        product_id: productId,
        discount_rate: discountRate,
        discount_percentage: discountPercentage
    }
    let promise = new Promise(function (resolve, reject) {
        createSingleProductDiscountAPI(reqData)
            .then(res => {
                if (
                    res?.data?.data &&
                    res?.data?.data?.is_single_product_discount_created
                ) {
                    resolve(res.data.data.is_single_product_discount_created)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('Get Documents Failure')
                }
            })
            .catch(err => {
                reject('Get Documents Failure')
            })
    })

    return promise
}

export function getSingleProductDiscountHelper(productId) {
    let promise = new Promise(function (resolve, reject) {
        getSingleProductDiscountAPI()
            .then(res => {
                if (
                    res?.data?.data &&
                    res?.data?.data?.single_product_discounts
                ) {
                    resolve(res.data.data.single_product_discounts)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('Get Documents Failure')
                }
            })
            .catch(err => {
                reject('Get Documents Failure')
            })
    })

    return promise
}

export function updateSingleProductDiscountHelper(
    discountId,
    action,
    discountRate,
    discountPercentage
) {
    let reqData = {
        single_product_discount_id: discountId,
        discount_rate: discountRate,
        action: action,
        discount_percentage: discountPercentage
    }
    let promise = new Promise(function (resolve, reject) {
        updateSingleProductDiscountAPI(reqData)
            .then(res => {
                if (res?.data?.data && res?.data?.data?.is_updated) {
                    resolve(res.data.data.is_updated)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('Get Documents Failure')
                }
            })
            .catch(err => {
                reject('Get Documents Failure')
            })
    })

    return promise
}
export function createCampaignDiscountHelper(
    discountName,
    discountPercentage,
    startDate,
    endDate,
    productIdList
) {
    let reqData = {
        name: discountName,
        start_date: startDate,
        end_date: endDate,
        discount_percentage: discountPercentage,
        product_ids: productIdList
    }

    let promise = new Promise(function (resolve, reject) {
        createCampaignDiscountAPI(reqData)
            .then(res => {
                if (
                    res?.data?.data &&
                    res?.data?.data?.is_discount_campaign_created
                ) {
                    resolve(res.data.data.is_discount_campaign_created)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('Get Documents Failure')
                }
            })
            .catch(err => {
                reject('Get Documents Failure')
            })
    })

    return promise
}

export function getCampaignDiscountHelper() {
    let promise = new Promise(function (resolve, reject) {
        getCampaignDiscountAPI()
            .then(res => {
                if (res?.data?.data && res?.data?.data?.discount_campaigns) {
                    resolve(res.data.data.discount_campaigns)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('Get Documents Failure')
                }
            })
            .catch(err => {
                reject('Get Documents Failure')
            })
    })

    return promise
}

export function getCampaignDiscountDetailHelper(id) {
    let reqData = {
        discount_campaign_id: id
    }

    let promise = new Promise(function (resolve, reject) {
        getCampaignDiscountDetailAPI(reqData)
            .then(res => {
                if (
                    res?.data?.data &&
                    res?.data?.data?.discount_campaign_details
                ) {
                    resolve(res.data.data.discount_campaign_details)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('Get Documents Failure')
                }
            })
            .catch(err => {
                reject('Get Documents Failure')
            })
    })

    return promise
}

export function updateCampaignDiscountHelper(
    type,
    campaignId,
    discountName,
    discountPercentage,
    startDate,
    endDate,
    productIdList
) {
    let reqData = {
        update_type: type,
        discount_campaign_id: campaignId,
        name: discountName,
        start_date: startDate,
        end_date: endDate,
        discount_percentage: discountPercentage,
        product_ids: productIdList
    }

    let promise = new Promise(function (resolve, reject) {
        updateCampaignDiscountAPI(reqData)
            .then(res => {
                if (
                    res?.data?.data &&
                    res?.data?.data?.is_discount_campaign_updated
                ) {
                    resolve(res.data.data.is_discount_campaign_updated)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('Get Documents Failure')
                }
            })
            .catch(err => {
                reject('Get Documents Failure')
            })
    })

    return promise
}

export function getAccountingHelper(productId) {
    let promise = new Promise(function (resolve, reject) {
        getAccoutingAPI()
            .then(res => {
                if (res?.data?.data && res?.data?.data?.accounting) {
                    resolve(res.data.data.accounting)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('Get Documents Failure')
                }
            })
            .catch(err => {
                reject('Get Documents Failure')
            })
    })

    return promise
}

export function enableAccountingHelper(data) {
    let promise = new Promise(function (resolve, reject) {
        enableAccountingAPI(data)
            .then(res => {
                if (res?.data?.data && res?.data?.data?.accounting_id) {
                    resolve(res.data.data?.accounting_id)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('Enable Accounting Helper Failure')
                }
            })
            .catch(err => {
                reject('enable Accounting Helper Failure')
            })
    })

    return promise
}

export function getVouchersHelper(accountingId, startDate, endDate) {
    let reqData = {
        accounting_id: accountingId,
        start_date: startDate,
        end_date: endDate
    }

    let promise = new Promise(function (resolve, reject) {
        getVouchersAPI(reqData)
            .then(res => {
                if (res?.data?.data && res?.data?.data?.vouchers) {
                    resolve(res.data.data.vouchers)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('enableAccountingHelper Failure')
                }
            })
            .catch(err => {
                reject('enableAccountingHelper Failure')
            })
    })

    return promise
}

export function getVoucherDetailsHelper(id) {
    let reqData = {
        voucher_id: id
    }

    let promise = new Promise(function (resolve, reject) {
        getVoucherDetailsAPI(reqData)
            .then(res => {
                if (res?.data?.data && res?.data?.data?.voucher) {
                    resolve(res.data.data.voucher)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('enableAccountingHelper Failure')
                }
            })
            .catch(err => {
                reject('enableAccountingHelper Failure')
            })
    })

    return promise
}

export function createVoucherHelper(data) {
    let promise = new Promise(function (resolve, reject) {
        createVoucherAPI(data)
            .then(res => {
                if (res?.data?.data && res?.data?.data?.voucher_id) {
                    resolve(res.data.data.voucher_id)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('Create Voucher Failure !!')
                }
            })
            .catch(err => {
                reject('Create Voucher Failure !!')
            })
    })

    return promise
}

export function updateVoucherHelper(data) {
    let promise = new Promise(function (resolve, reject) {
        updateVoucherAPI(data)
            .then(res => {
                if (res?.data?.data && res?.data?.data?.voucher_id) {
                    resolve(res.data.data.voucher_id)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('Update Voucher Failure !!')
                }
            })
            .catch(err => {
                reject('Update Voucher Failure !!')
            })
    })

    return promise
}

export function getProfitLossStatementHelper(accountingId, startDate, endDate) {
    let reqData = {
        accounting_id: accountingId,
        start_date: startDate,
        end_date: endDate
    }

    let promise = new Promise(function (resolve, reject) {
        getProfitLossStatementAPI(reqData)
            .then(res => {
                if (
                    res?.data?.data &&
                    res?.data?.data?.profit_and_loss_statement
                ) {
                    resolve(res.data.data.profit_and_loss_statement)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('enableAccountingHelper Failure')
                }
            })
            .catch(err => {
                reject('enableAccountingHelper Failure')
            })
    })

    return promise
}

export function getBalanceSheetStatementHelper(
    accountingId,
    startDate,
    endDate
) {
    let reqData = {
        accounting_id: accountingId,
        start_date: startDate,
        end_date: endDate
    }

    let promise = new Promise(function (resolve, reject) {
        getBalanceSheetStatementAPI(reqData)
            .then(res => {
                if (
                    res?.data?.data &&
                    res?.data?.data?.balance_sheet_statement
                ) {
                    resolve(res.data.data.balance_sheet_statement)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('enableAccountingHelper Failure')
                }
            })
            .catch(err => {
                reject('enableAccountingHelper Failure')
            })
    })

    return promise
}

export function getReceiptPaymentHelper(accountingId, startDate, endDate) {
    let reqData = {
        accounting_id: accountingId,
        start_date: startDate,
        end_date: endDate
    }

    let promise = new Promise(function (resolve, reject) {
        getReceiptPaymentAPI(reqData)
            .then(res => {
                if (res?.data?.data && res?.data?.data?.receipts_and_payments) {
                    resolve(res.data.data.receipts_and_payments)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('enableAccountingHelper Failure')
                }
            })
            .catch(err => {
                reject('enableAccountingHelper Failure')
            })
    })

    return promise
}

export function updateDeliveryPartnerStatusHelper(id, action) {
    let reqData = {
        delivery_partner_id: id,
        action: action
    }

    let promise = new Promise(function (resolve, reject) {
        updateDeliveryPartnerStatusAPI(reqData)
            .then(res => {
                if (res?.data?.data && res?.data?.data) {
                    resolve(res.data.data)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('update Delivery Partner Failure')
                }
            })
            .catch(err => {
                reject('update Delivery Partner Failure')
            })
    })

    return promise
}

export function updatePasswordHelper(currentPassword, newPassword) {
    let reqData = {
        old_password: currentPassword,
        new_password: newPassword
    }
    let promise = new Promise(function (resolve, reject) {
        updatePasswordAPI(reqData)
            .then(res => {
                if (res?.data?.data && res?.data?.data) {
                    resolve(res.data.data)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('Update Password Failure')
                }
            })
            .catch(err => {
                reject('Update Password Failure')
            })
    })

    return promise
}
