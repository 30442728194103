import React from 'react'

const CheckIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Check">
            <circle id="Ellipse 11" cx="12" cy="12" r="7" fill="white" />
            <path id="Vector" d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM15.22 8.97L10.75 13.44L8.78 11.47C8.63783 11.3375 8.44978 11.2654 8.25548 11.2688C8.06118 11.2723 7.87579 11.351 7.73838 11.4884C7.60097 11.6258 7.52225 11.8112 7.51883 12.0055C7.5154 12.1998 7.58752 12.3878 7.72 12.53L10.22 15.03C10.3606 15.1705 10.5512 15.2493 10.75 15.2493C10.9488 15.2493 11.1394 15.1705 11.28 15.03L16.28 10.03C16.3537 9.96134 16.4128 9.87854 16.4538 9.78654C16.4948 9.69454 16.5168 9.59522 16.5186 9.49452C16.5204 9.39382 16.5018 9.29379 16.4641 9.2004C16.4264 9.10701 16.3703 9.02218 16.299 8.95096C16.2278 8.87974 16.143 8.8236 16.0496 8.78588C15.9562 8.74816 15.8562 8.72963 15.7555 8.73141C15.6548 8.73318 15.5555 8.75523 15.4635 8.79622C15.3715 8.83721 15.2887 8.89631 15.22 8.97Z" fill="#33C481" />
        </g>
    </svg>

)

export default CheckIcon
