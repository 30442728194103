import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import BackIcon from '../../../assets/BackIcon'
import Logo from '../../../assets/Logo'
import NotificationIcon from '../../../assets/NotificationActiveIcon'
import SearchIcon from '../../../assets/SearchIcon'

import './Header.scss'
import useCheckMobileScreen from '../../../common/hooks/use-check-mobile-screen-hook'
import { redirect } from '../../../common/utils/redirectionUtils'
import { PAGE_CONST } from '../../../common/constant/page-constant'
import ArrowDown from '../../../assets/ArrowDown'
import { logoutAction } from '../../../modules/actions/action'
import NotificationActiveIcon from '../../../assets/NotificationActiveIcon'
import NotificationInActiveIcon from '../../../assets/NotificationInActiveIcon'
import { updateBranchVisibilityHelper } from '../../../helper/branch-management-helper'
import { getStoreStatusHelper } from '../../../helper/home-helper'

const Header = props => {
    const navigate = useNavigate()
    const inputRef = useRef()

    const [viewSearch, setViewSearch] = useState(false)
    const [headerTitle, setHeaderTitle] = useState()
    const [isViewOption, setIsViewOption] = useState(false)
    const [loginData, setLoginData] = useState({})
    const [isNotificationActive, setIsNotificationActive] = useState(false)
    const [isStoreActive, setIsStoreActive] = useState(false)

    useEffect(() => {
        setLoginData(props.loginData)
    }, [props.loginData])

    useEffect(() => {
        const handleGetStatus = async () => {
            try {
                const status = await getStoreStatusHelper()
                setIsStoreActive(status.is_taking_online_orders)
            } catch (error) {
                console.log('Unable to fetch store status: ', error)
            }
        }

        if (loginData?.branch_name) {
            handleGetStatus()
        }
    }, [loginData])

    useEffect(() => {
        console.log(
            ' props?.config?.isNotificationActive: ',
            props?.config?.isNotificationActive
        )
        setIsNotificationActive(props?.config?.isNotificationActive)
    }, [props?.config?.isNotificationActive])

    const expandSearch = () => {
        inputRef.current.focus(setViewSearch(true))
    }

    const handleClick = () => {
        // navigate(PAGE_CONST.NOTIFICATION)
        redirect(navigate, PAGE_CONST.NOTIFICATION)
    }

    const handleLogout = () => {
        props.logoutAction()
        // navigate(PAGE_CONST.LOGIN)
        redirect(navigate, PAGE_CONST.LOGIN)
    }

    const handleStoreActiveToggle = isActive => {
        updateBranchVisibilityHelper(!isActive)
        setIsStoreActive(!isActive)
    }

    return (
        <header className="header fixed">
            <div className="webHeader dFlex jcSpaceBetween aiCenter">
                <div className="headerLeft">
                    <div className="logoWrapper dFlex aiCenter">
                        <div
                            className="logo"
                            onClick={() => redirect(navigate, PAGE_CONST.HOME)}>
                            <Logo />
                        </div>
                        <div className="logoName dFlex flexColumn">
                            <span>DG ePharma</span>
                            <small>{loginData?.branch_name}</small>
                        </div>
                    </div>
                </div>
                <div className="headerRight dFlex aiCenter">
                    <div className="dFlex aiCenter">
                        <p>Store {isStoreActive ? 'Active' : 'Offline'}</p>
                        <div
                            onClick={() =>
                                handleStoreActiveToggle(isStoreActive)
                            }
                            className={`activeToggle ml-2 ${
                                isStoreActive ? 'active' : 'inactive'
                            }`}>
                            <div className="activeToggleIcon"></div>
                        </div>
                    </div>
                    {/* <div
                        className={`formGroup mb-0 searchBar ${
                            viewSearch ? 'expand' : ''
                        }`}
                        onClick={expandSearch}
                        onBlur={() => setViewSearch(false)}>
                        <input
                            ref={inputRef}
                            type="text"
                            className="formControl"
                            placeholder="Search"
                        />
                        <div className={`searchIcon`}>
                            <SearchIcon />
                        </div>
                    </div> */}
                    <div
                        className="notification dFlex ml-5"
                        onClick={handleClick}>
                        {isNotificationActive ? (
                            <NotificationActiveIcon />
                        ) : (
                            <NotificationInActiveIcon />
                        )}
                    </div>
                    <div className="account ml-5">
                        <div className="loggedInUser">
                            <div
                                className="loggedInUserName dFlex"
                                onClick={() => setIsViewOption(!isViewOption)}>
                                {loginData?.name} <ArrowDown />
                            </div>
                            {isViewOption && (
                                <ul className="loggedInUserOptions">
                                    <li
                                        className="option"
                                        onClick={handleLogout}>
                                        Logout
                                    </li>
                                </ul>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

const mapStateToProps = state => {
    return {
        loginData: state.loginDataReducer?.loginData,
        config: state.configReducer?.config
    }
}
const mapDispatchToProps = dispatch => {
    return {
        logoutAction: () => dispatch(logoutAction())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
