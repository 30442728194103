import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import './ProfileMenu.scss'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import {
    PAGE_CONST,
    PROFILE_CONST,
    PROFILE_NAVIGATION_ITEMS,
    REACT_NATIVE_MESSAGE_TYPE
} from '../../common/constant/page-constant'
import MobileHeader from '../Common/Header/MobileHeader'
import PrimaryUserIcon from '../../assets/PrimaryUserIcon'
import PrimaryDocumentIcon from '../../assets/PrimaryDocumentIcon'
import PrimaryBranchIcon from '../../assets/PrimaryBranchIcon'
import PrimarySalesIcon from '../../assets/PrimarySalesIcon'
import PrimaryPrivacyPolicyIcon from '../../assets/PrimaryPrivacyPolicyIcon'
import PrimaryInfoIcon from '../../assets/PrimaryInfoIcon'
import SideMenu from '../Common/SideMenu/SideMenu'
import Header from '../Common/Header/Header'
import Accounting from '../Accounting/Accounting'
import PersonalDetails from '../PersonalDetails/PersonalDetails'
import Documents from '../Documents/Documents'
import PlatformCharges from '../PlatformCharges/PlatformCharges'
import BranchManagement from '../BranchManagement/BranchManagement'
import Help from '../Help/Help'
import { useNavigate } from 'react-router-dom'
import { redirect } from '../../common/utils/redirectionUtils'

import AcknowledgeScreen from '../Common/AcknowledgeScreen/AcknowledgeScreen'
import PopupCard from '../Common/PopupCard/PopupCard'
import OrderBook from '../OrderBook/OrderBook'
import Discount from '../Discounts/Discount'
import { logoutAction } from '../../modules/actions/action'
import DeliveryPartners from '../DeliveryPartners/DeliveryPartners'
import TermAndCondition from '../TermAndCondition/TermAndCondition'
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy'
import About from '../About/About'
import { isReactNativeWebView } from '../../common/utils/appUtils'

const ProfileMenu = props => {
    const navigate = useNavigate()
    const isMobile = useCheckMobileScreen()
    const [activeItem, setActiveItem] = useState(undefined)
    const [isConfirmLogout, setIsConfirmLogout] = useState(false)

    useEffect(() => {
        setActiveItem(undefined)
    }, [])

    const headerOptions = {
        isBackVisible: false,
        title: 'Profile Menu'
    }

    useEffect(() => {
        if (props.config?.activeProfileDetailNavigation) {
            setActiveItem(props.config.activeProfileDetailNavigation)
        }
    }, [props.config?.activeProfileDetailNavigation])

    const handleItemClick = itemName => {
        // props.activeProfileDetailNavigation(itemName);
        if (itemName === PROFILE_CONST.HELP) {
            redirect(navigate, PAGE_CONST.HELP)
            return
        }

        setActiveItem(itemName)
    }

    const handleLogout = () => {
        if (isReactNativeWebView()) {
            let message = JSON.stringify({
                type: REACT_NATIVE_MESSAGE_TYPE.LOGOUT,
                data: undefined
            })

            window.ReactNativeWebView.postMessage(message)
        } else {
            props.logoutAction()
            // navigate(PAGE_CONST.LOGIN)
            redirect(navigate, PAGE_CONST.LOGIN)
        }
    }

    return (
        <>
            {isMobile && <MobileHeader headerOptions={headerOptions} />}
            {!isMobile && <Header />}
            <SideMenu
                activeItem={PAGE_CONST.PROFILE_MENU}
                component={!activeItem && PAGE_CONST.PROFILE_MENU}
            />
            <div className="pageBody">
                {!activeItem && (
                    <>
                        <div className="pageBodyRightPanel">
                            <div className="personalDetailsRow dFlex flexNowrap aiCenter m-0">
                                <div className="personalIcon dFlex aiCenter jcContentCenter">
                                    {props?.loginData?.branch_name?.[0]}
                                </div>
                                <div className="personalNameWrap">
                                    <div className="personalName">
                                        {props?.loginData?.branch_name}
                                    </div>
                                    <div className="personalDesc">
                                        Lo ipsum dolor sit amet consectetur.
                                        Molestie vulputate felis nisl aenean
                                        tempor.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profileMenuWrapper">
                            <ul className="profileMenuList">
                                {PROFILE_NAVIGATION_ITEMS.map(item => {
                                    return (
                                        <li
                                            className="menuItem"
                                            onClick={() =>
                                                handleItemClick(item.name)
                                            }>
                                            {item.profileDetailIcon}{' '}
                                            <span>{item.name}</span>
                                        </li>
                                    )
                                })}
                            </ul>
                            <div
                                className="logOut"
                                onClick={() => setIsConfirmLogout(true)}>
                                Logout
                            </div>
                        </div>
                    </>
                )}
                {activeItem === PROFILE_CONST.PERSONAL_DETAILS && (
                    <PersonalDetails
                        onBackClick={() => setActiveItem(undefined)}
                    />
                )}
                {activeItem === PROFILE_CONST.DOCUMENTS && (
                    <Documents onBackClick={() => setActiveItem(undefined)} />
                )}
                {activeItem === PROFILE_CONST.PLATFORM_CHARGES && (
                    <PlatformCharges
                        onBackClick={() => setActiveItem(undefined)}
                    />
                )}
                {activeItem === PROFILE_CONST.BRANCH_MANAGEMENT && (
                    <BranchManagement
                        onBackClick={() => setActiveItem(undefined)}
                    />
                )}
                {activeItem === PROFILE_CONST.DELIVERY_PARTNER && (
                    <DeliveryPartners
                        onBackClick={() => setActiveItem(undefined)}
                    />
                )}
                {activeItem === PROFILE_CONST.ACCOUNTING && (
                    <Accounting onBackClick={() => setActiveItem(undefined)} />
                )}
                {activeItem === PROFILE_CONST.ORDER_BOOK && (
                    <OrderBook onBackClick={() => setActiveItem(undefined)} />
                )}
                {activeItem === PROFILE_CONST.DISCOUNTS && (
                    <Discount onBackClick={() => setActiveItem(undefined)} />
                )}
                {activeItem === PROFILE_CONST.TERMS_AND_CONDITIONS && (
                    <TermAndCondition
                        onBackClick={() => setActiveItem(undefined)}
                    />
                )}
                {activeItem === PROFILE_CONST.PRIVACY_POLICY && (
                    <PrivacyPolicy
                        onBackClick={() => setActiveItem(undefined)}
                    />
                )}
                {activeItem === PROFILE_CONST.ABOUT && (
                    <About onBackClick={() => setActiveItem(undefined)} />
                )}
                {activeItem === PROFILE_CONST.HELP && (
                    <Help onBackClick={() => setActiveItem(undefined)} />
                )}
            </div>
            {isConfirmLogout && (
                <PopupCard
                    header="Are you sure you want to logout?"
                    body="Lorem ipsum dolor sit t consectetur. Nulla bibendum ac ornare sodales.?">
                    <button
                        className="btn btnPrimaryOutlined dFlex"
                        onClick={() => setIsConfirmLogout(false)}>
                        Cancel
                    </button>
                    <button
                        className="btn btnPrimary dFlex"
                        onClick={handleLogout}>
                        Logout
                    </button>
                </PopupCard>
            )}
        </>
    )
}

const mapStateToProps = state => {
    return {
        loginData: state.loginDataReducer?.loginData
    }
}
const mapDispatchToProps = dispatch => {
    return {
        logoutAction: () => dispatch(logoutAction())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu)
