import React from 'react'

const BackIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Scroll">
            <path id="Vector" d="M15.5303 4.22007C15.6708 4.3607 15.7497 4.55132 15.7497 4.75007C15.7497 4.94882 15.6708 5.13945 15.5303 5.28007L8.81032 12.0001L15.5303 18.7201C15.604 18.7887 15.6631 18.8715 15.7041 18.9635C15.7451 19.0555 15.7671 19.1548 15.7689 19.2556C15.7707 19.3563 15.7522 19.4563 15.7144 19.5497C15.6767 19.6431 15.6206 19.7279 15.5494 19.7991C15.4781 19.8703 15.3933 19.9265 15.2999 19.9642C15.2065 20.0019 15.1065 20.0204 15.0058 20.0187C14.9051 20.0169 14.8058 19.9948 14.7138 19.9539C14.6218 19.9129 14.539 19.8538 14.4703 19.7801L7.22032 12.5301C7.07987 12.3894 7.00098 12.1988 7.00098 12.0001C7.00098 11.8013 7.07987 11.6107 7.22032 11.4701L14.4703 4.22007C14.6109 4.07962 14.8016 4.00073 15.0003 4.00073C15.1991 4.00073 15.3897 4.07962 15.5303 4.22007Z" fill="#3A3A3A" />
        </g>
    </svg>
)

export default BackIcon
