import React from 'react'

const ChevronDown = () => (
    <svg
        width="16"
        height="9"
        viewBox="0 0 16 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.8982 0.0096283C14.9722 0.0096283 15.5462 1.27363 14.8382 2.08263L9.31716 8.39263C9.1529 8.58034 8.95042 8.73078 8.72328 8.83385C8.49614 8.93691 8.24959 8.99023 8.00016 8.99023C7.75072 8.99023 7.50417 8.93691 7.27703 8.83385C7.04989 8.73078 6.84741 8.58034 6.68316 8.39263L1.16116 2.08263C0.454156 1.27363 1.02816 0.0096283 2.10116 0.0096283L13.8992 0.0096283H13.8982Z"
            fill="#828282"
        />
    </svg>
)

export default ChevronDown
