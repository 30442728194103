import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import CloseIcon from '../../../assets/CloseIcon'

import './SideDrawer.scss'
import useCheckMobileScreen from '../../../common/hooks/use-check-mobile-screen-hook'

const SideDrawer = ({
    open,
    handleDrawerToggle,
    children,
    drawerHeading,
    drawerButton,
    holdButton,
    downloadButton,
    handleClick,
    handleHoldClick,
    buttonText,
    holdButtonText,
    downloadText,
    sideDrawerFooter,
    mwebSideDrawerFooter,
    ref
}) => {
    const isMobile = useCheckMobileScreen()

    return (
        <div className={`sideDrawer ${open ? '' : 'hide'}`} ref={ref}>
            <div className="sideDrawerHeader dFlex jcSpaceBetween fixed">
                {!isMobile && (
                    <div className="sideDrawerHeading">{drawerHeading}</div>
                )}
                {!isMobile && (
                    <div className="btnGroup dFlex aiCenter flexNowrap">
                        {holdButton && (
                            <button
                                className="btn btnPrimaryOutlined"
                                onClick={handleHoldClick}>
                                {holdButtonText || 'Hold'}
                            </button>
                        )}
                        {drawerButton && (
                            <button
                                className="btn btnPrimary ml-4"
                                onClick={handleClick}>
                                {buttonText || 'Next'}
                            </button>
                        )}
                        {/* {downloadButton && <div className='download btn btnPrimary block' onClick={() => handleClick()}>{downloadText || 'Save & Print'}</div>} */}
                        <div
                            className="pointer ml-4"
                            onClick={handleDrawerToggle}>
                            <CloseIcon />{' '}
                        </div>
                    </div>
                )}
            </div>
            <div className="sideDrawerBody">{children}</div>
            {(sideDrawerFooter || !isMobile) && downloadButton && (
                <div className="sideDrawerFooter">
                    {
                        <div
                            className="download btn btnPrimary block"
                            onClick={() => handleClick()}>
                            {downloadText || 'Save & Print'}
                        </div>
                    }
                </div>
            )}
            {(mwebSideDrawerFooter || isMobile) &&
                (holdButton || drawerButton || downloadButton) && (
                    <div className="sideDrawerFooter dFlex flexNowrap">
                        {holdButton && (
                            <button
                                className="btn btnPrimaryOutlined block"
                                onClick={handleHoldClick}>
                                {holdButtonText || 'Hold'}
                            </button>
                        )}
                        {drawerButton && (
                            <button
                                className="btn btnPrimary block"
                                onClick={handleClick}>
                                {buttonText || 'Next'}
                            </button>
                        )}
                        {downloadButton && (
                            <div
                                className="download btn btnPrimary block"
                                onClick={() => handleClick()}>
                                {downloadText || 'Save & Print'}
                            </div>
                        )}
                    </div>
                )}
        </div>
    )
}

SideDrawer.propTypes = {
    open: PropTypes.bool,
    drawerButton: PropTypes.bool,
    holdButton: PropTypes.bool,
    drawerHeading: PropTypes.string,
    handleDrawerToggle: PropTypes.func,
    children: PropTypes.node,
    viewScreenButton: PropTypes.bool
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(SideDrawer)
