import React from 'react'

const PhoneIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Call">
            <path id="Vector" d="M5.88105 2.01509L6.85355 1.72259C7.38629 1.56194 7.95929 1.60061 8.46561 1.83139C8.97194 2.06216 9.377 2.46927 9.60522 2.97675L10.3569 4.64842C10.5531 5.08492 10.6077 5.5719 10.5131 6.04103C10.4185 6.51017 10.1794 6.93791 9.82939 7.26425L8.58355 8.42509C8.54716 8.459 8.5241 8.50482 8.51855 8.55425C8.48189 8.88509 8.70605 9.52925 9.22355 10.4251C9.59855 11.0759 9.93939 11.5334 10.2286 11.7893C10.4311 11.9684 10.5419 12.0068 10.5894 11.9934L12.2644 11.4809C12.7218 11.341 13.2116 11.3477 13.665 11.5002C14.1184 11.6526 14.5127 11.9431 14.7927 12.3309L15.8594 13.8109C16.1841 14.2609 16.3345 14.8134 16.2827 15.3659C16.2309 15.9184 15.9804 16.4333 15.5777 16.8151L14.8386 17.5151C14.4467 17.8862 13.9654 18.1495 13.4416 18.2793C12.9177 18.4091 12.3692 18.4011 11.8494 18.2559C9.55439 17.6151 7.49689 15.6784 5.65355 12.4859C3.80855 9.28925 3.16022 6.53592 3.75855 4.22509C3.89287 3.70537 4.15879 3.229 4.5307 2.84192C4.9026 2.45483 5.36712 2.17007 5.88105 2.01509ZM6.24189 3.21259C5.93346 3.30534 5.65413 3.47598 5.43081 3.70806C5.20749 3.94013 5.04771 4.22582 4.96689 4.53759C4.46522 6.48092 5.03939 8.92175 6.73605 11.8609C8.43105 14.7959 10.2552 16.5126 12.1861 17.0526C12.498 17.1396 12.8271 17.1443 13.1413 17.0663C13.4556 16.9883 13.7443 16.8303 13.9794 16.6076L14.7177 15.9076C14.9009 15.7341 15.0149 15.5 15.0385 15.2488C15.0621 14.9976 14.9937 14.7463 14.8461 14.5418L13.7794 13.0626C13.6521 12.8861 13.4727 12.7539 13.2665 12.6845C13.0602 12.6152 12.8374 12.6122 12.6294 12.6759L10.9502 13.1901C9.97522 13.4801 9.09105 12.6959 8.14105 11.0501C7.50022 9.94175 7.20189 9.08342 7.27689 8.41592C7.31522 8.06925 7.47689 7.74925 7.73105 7.51092L8.97689 6.35009C9.13593 6.20167 9.24452 6.00718 9.28741 5.79391C9.33031 5.58063 9.30535 5.35929 9.21605 5.16092L8.46522 3.48925C8.36147 3.25858 8.17733 3.07353 7.94717 2.96865C7.717 2.86376 7.45654 2.84621 7.21439 2.91925L6.24189 3.21259Z" fill="#212121" />
        </g>
    </svg>
)

export default PhoneIcon
