import React from 'react'

const AddIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Add">
            <path id="Vector" d="M11.7502 3C11.9314 3.00001 12.1066 3.06564 12.2431 3.18477C12.3797 3.30389 12.4686 3.46845 12.4932 3.648L12.5002 3.75L12.5012 11H19.7542C19.9442 11.0001 20.1272 11.0722 20.266 11.202C20.4049 11.3317 20.4893 11.5093 20.5022 11.6989C20.5152 11.8885 20.4557 12.0759 20.3358 12.2233C20.2159 12.3707 20.0445 12.4671 19.8562 12.493L19.7542 12.5H12.5012L12.5032 19.75C12.5029 19.9399 12.4305 20.1227 12.3007 20.2613C12.1709 20.4 11.9933 20.4842 11.8038 20.4971C11.6143 20.5099 11.427 20.4503 11.2797 20.3304C11.1324 20.2105 11.0361 20.0392 11.0102 19.851L11.0032 19.749L11.0012 12.5H3.75221C3.56219 12.4999 3.37927 12.4278 3.24042 12.298C3.10157 12.1683 3.01714 11.9907 3.00419 11.8011C2.99123 11.6115 3.05073 11.4241 3.17065 11.2767C3.29056 11.1293 3.46196 11.0329 3.65021 11.007L3.75221 11H11.0022L11.0002 3.75C11.0002 3.55109 11.0792 3.36032 11.2199 3.21967C11.3605 3.07902 11.5513 3 11.7502 3Z" fill="white" />
        </g>
    </svg>
)

export default AddIcon
