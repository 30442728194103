import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { redirect } from '../../common/utils/redirectionUtils'
import { connect } from 'react-redux'
import CloseIcon from '../../assets/CloseIcon'
import EditIcon from '../../assets/EditIcon'
import {
    HEADER_CONFIG,
    HEADER_CONST
} from '../../common/constant/common-constant'
import {
    PAGE_CONST,
    PERMISSION_FEATURE,
    PERMISSION_SCREEN,
    PERMISSION_TYPE,
    PROFILE_NAVIGATION_ITEMS
} from '../../common/constant/page-constant'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import Card from '../Common/Card/Card'
import MobileHeader from '../Common/Header/MobileHeader'

import './PlatformCharges.scss'

const PlatformCharges = props => {
    const navigate = useNavigate()
    const isMobile = useCheckMobileScreen()

    const [headerOptions, setHeaderOptions] = useState({
        [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
        [HEADER_CONFIG.TITLE]: HEADER_CONST.PLATFORM_CHARGES,
        [HEADER_CONFIG.HANDLE_BACK_FUNC]: () => props?.onBackClick()
    })
    const [permissions, setPermissions] = useState({})

    // const handleDrawerToggle = () => {
    //     setViewDrawer(!viewDrawer)
    //     setOpen(!open)
    // }

    useEffect(() => {
        if (props.loginData.permissions) {
            let permission = props.loginData.permissions.filter(
                permission => permission.screen === PERMISSION_SCREEN.OTHER
            )[0]

            if (permission) setPermissions(permission)
        }
    }, [props.loginData])

    return (
        <>
            {isMobile && <MobileHeader headerOptions={headerOptions} />}
            <div className="pageBodyRightPanel">
                {isMobile &&
                    permissions?.features?.[
                        PERMISSION_FEATURE.PLATFORM_CHARGES
                    ] === PERMISSION_TYPE.MANAGE && (
                        <div className="addProductBtnMobile dFlex aiCenter mwebVisible fixed">
                            <button className="btn btnPrimary lhauto">
                                Pay Now
                            </button>
                        </div>
                    )}
                {!isMobile && (
                    <div className="pageBodyRightPanelHeader dFlex jcSpaceBetween aiCenter mb-5">
                        <div className="pageBodyRightPanelHeading">
                            Platform Charges
                        </div>
                        <div className="addProductBtn dFlex aiCenter">
                            {permissions?.features?.[
                                PERMISSION_FEATURE.PLATFORM_CHARGES
                            ] === PERMISSION_TYPE.MANAGE && (
                                <button className="btn btnPrimary">
                                    Pay Now
                                </button>
                            )}
                            <div className="pointer ml-4">
                                <CloseIcon />
                            </div>
                        </div>
                    </div>
                )}
                <div className="consolidateStrip dFlex aiCenter rowReverse">
                    <div className="consolidateData">38,500</div>
                    <div className="consolidateDataText">
                        <small className="weightNormal">
                            Due Date: 04 Dec 2023{' '}
                        </small>{' '}
                        Total Due for Dec 2023
                    </div>
                </div>
                <div className="productLlistWrap dFlex">
                    <div className="cardWrap">
                        <Card
                            cardRight="BDT 250"
                            headerSup="+880 865432567"
                            headerMain="FFDR3456789"
                            documents
                        />
                    </div>
                    <div className="cardWrap">
                        <Card
                            cardRight="BDT 250"
                            headerSup="+880 865432567"
                            headerMain="FFDR3456789"
                            documents
                        />
                    </div>
                    <div className="cardWrap">
                        <Card
                            cardRight="BDT 250"
                            headerSup="+880 865432567"
                            headerMain="FFDR3456789"
                            documents
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        loginData: state.loginDataReducer?.loginData
    }
}
const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(PlatformCharges)
