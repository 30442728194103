import {
    addQueryAPI,
    addReminderAPI,
    getQueriesAPI,
    getReminderAPI
} from '../adapter/adapter'

export function getQueryHelper() {
    let promise = new Promise(function (resolve, reject) {
        getQueriesAPI()
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data) {
                    resolve(res.data.data)
                } else {
                    reject('get query Failure')
                }
            })
            .catch(err => {
                reject('get query Failure')
            })
    })

    return promise
}

export function addQueryHelper(reqData) {
    let promise = new Promise(function (resolve, reject) {
        addQueryAPI(reqData)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data) {
                    resolve(res.data.data)
                } else {
                    reject('add query Failure')
                }
            })
            .catch(err => {
                reject('add query Failure')
            })
    })

    return promise
}

export function getReminderHelper() {
    let promise = new Promise(function (resolve, reject) {
        getReminderAPI()
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data) {
                    resolve(res.data.data)
                } else {
                    reject('get reminder Failure')
                }
            })
            .catch(err => {
                reject('get reminder Failure')
            })
    })

    return promise
}

export function addReminderHelper(reqData) {
    let promise = new Promise(function (resolve, reject) {
        addReminderAPI(reqData)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data) {
                    resolve(res.data.data)
                } else {
                    reject('add reminder Failure')
                }
            })
            .catch(err => {
                reject('add reminder Failure')
            })
    })

    return promise
}
