import React from 'react'

const SalesBlack = () => (
    <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g id="fluent:currency-dollar-rupee-24-regular">
            <path
                d="M48.2399 28V24H25.5732V12C25.5732 10.9391 25.1518 9.92172 24.4017 9.17157C23.6515 8.42143 22.6341 8 21.5732 8C20.5124 8 19.495 8.42143 18.7448 9.17157C17.9947 9.92172 17.5732 10.9391 17.5732 12C17.5732 13.0609 17.9947 14.0783 18.7448 14.8284C19.495 15.5786 20.5124 16 21.5732 16V24H13.5732V28H21.5732V44.5333C21.5732 50.8267 26.6666 55.92 32.9066 56C39.1466 55.8933 44.1066 50.7733 43.9999 44.5333C43.9999 40.2933 41.9999 36.2933 38.6666 33.6533C38.0902 33.171 37.4645 32.7508 36.7999 32.4C36.2132 32.1333 35.5732 32 34.9332 32C33.0399 32 31.3066 33.04 30.3732 34.6667C29.8666 35.4667 29.5999 36.4 29.5999 37.3333C29.6266 40.2667 31.9999 42.6667 34.9599 42.6667C36.6132 42.6667 38.1599 41.84 39.1732 40.5333C39.7332 41.7867 39.9999 43.1467 39.9999 44.5333C40.1066 48.5333 36.9599 51.8667 32.9066 52C28.8266 52 25.5466 48.6133 25.5732 44.5333V28H48.2399Z"
                fill="#212121"
            />
        </g>
    </svg>
)

export default SalesBlack
