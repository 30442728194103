import { PAGE_CONST, PROFILE_CONST } from "../constant/page-constant";

export const redirect = (navigate, page, data = {}) => {

    switch (page) {
        case PROFILE_CONST.ADD_ORDER_BOOK:
            navigate('/addorderbook');
            break;
        case PAGE_CONST.LOGIN:
            navigate('/login');
            break;
        case PAGE_CONST.CREATE_ACCOUNT:
            navigate('/create-account');
            break;
        case PAGE_CONST.RESET_PASSWORD:
            navigate('/reset-password');
            break;
        case PAGE_CONST.OTP_VERIFICATION:
            navigate('/otp-verification');
            break;
        case PAGE_CONST.DOCUMENT_UPLOAD:
            navigate('/document-upload');
            break;
        case PAGE_CONST.HOME:
            navigate('/home');
            break;
        case PAGE_CONST.STOCKS:
            navigate('/stocks');
            break;
        case PAGE_CONST.HOME_DETAILS:
            navigate('/home/details');
            break;
        case PAGE_CONST.USER_PROFILE:
            navigate('/profile');
            break;
        case PAGE_CONST.SETTINGS:
            navigate('/settings');
            break;
        case PAGE_CONST.HELP:
            navigate('/help');
            break;
        case PAGE_CONST.NOTIFICATION:
            navigate('/notification');
            break;
        case PAGE_CONST.PROFILE_MENU:
            navigate('/profilemenu');
            break;
        default:
            navigate('/login')
    }
}