const CallIcon = () => {
    return (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.77194 2.93905L8.84894 2.59505C9.85694 2.27305 10.9349 2.79405 11.3669 3.81205L12.2269 5.84005C12.6019 6.72305 12.3939 7.76205 11.7129 8.40805L9.81994 10.2061C9.93694 11.2821 10.2979 12.3411 10.9039 13.3831C11.4798 14.3912 12.252 15.2736 13.1749 15.9781L15.4509 15.2181C16.3129 14.9311 17.2519 15.2621 17.7809 16.0391L19.0129 17.8491C19.6289 18.7531 19.5179 19.9991 18.7549 20.7651L17.9369 21.5861C17.1229 22.4031 15.9609 22.7001 14.8849 22.3641C12.3459 21.5721 10.0119 19.2211 7.88194 15.3111C5.74894 11.3951 4.99694 8.07105 5.62394 5.34305C5.88794 4.19505 6.70594 3.28005 7.77394 2.93905H7.77194Z"
                fill="#212121"
            />
        </svg>
    )
}

export default CallIcon
