import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import HelpIcon from '../../../assets/HelpIcon'
import SettingsIcon from '../../../assets/SettingsIcon'
import UserIcon from '../../../assets/UserIcon'

import './SideMenu.scss'
import {
    HEADER_CONFIG,
    SIDEBAR_CONST
} from '../../../common/constant/common-constant'
import { redirect } from '../../../common/utils/redirectionUtils'
import {
    MOBILE_SIDEMENU_VISIBLE_SCREENS,
    PAGE_CONST,
    PERMISSION_FEATURE,
    PERMISSION_SCREEN,
    PERMISSION_TYPE
} from '../../../common/constant/page-constant'
import StockIcon from '../../../assets/StockIcon'
import HomeIcon from '../../../assets/HomeIcon'
import HomeFillIcon from '../../../assets/HomeFillIcon'
import UserFillIcon from '../../../assets/UserFillIcon'
import SettingsFillIcon from '../../../assets/SettingsFillIcon'
import HelpFillIcon from '../../../assets/HelpFillIcon'
import useCheckMobileScreen from '../../../common/hooks/use-check-mobile-screen-hook'

const SideMenu = props => {
    const navigate = useNavigate()
    const isMobile = useCheckMobileScreen()
    const [activeItem, setActiveItem] = useState('')
    const [permissions, setPermissions] = useState({})

    useEffect(() => {
        if (props.activeItem) setActiveItem(props.activeItem)
    }, [props.activeItem])

    const handleHeaderConfig = title => {
        // headerAction
        // HEADER_CONFIG.IS_BACK_VISIBLE: false,
    }

    useEffect(() => {
        if (props.loginData.permissions) {
            console.log(
                'props.loginData.permissions: ',
                props.loginData.permissions
            )

            let permission = props.loginData.permissions.reduce(
                (acc, permission) => {
                    acc.features = {
                        ...acc.features,
                        ...permission.features
                    }

                    return acc
                },
                {}
            )

            if (permission) setPermissions(permission)
        }
    }, [props.loginData])

    const handleClick = item => {
        setActiveItem(item)
        switch (item) {
            case SIDEBAR_CONST.HOME:
                redirect(navigate, PAGE_CONST.HOME)
                break
            case SIDEBAR_CONST.STOCKS:
                redirect(navigate, PAGE_CONST.STOCKS)
                break
            case SIDEBAR_CONST.USER_PROFILE:
                redirect(navigate, PAGE_CONST.USER_PROFILE)
                break
            case SIDEBAR_CONST.SETTINGS:
                redirect(navigate, PAGE_CONST.SETTINGS)
                break
            case SIDEBAR_CONST.HELP:
                redirect(navigate, PAGE_CONST.HELP)
                break
            case SIDEBAR_CONST.PROFILE_MENU:
                redirect(navigate, PAGE_CONST.PROFILE_MENU)
                break
        }
    }

    return (
        (!isMobile ||
            MOBILE_SIDEMENU_VISIBLE_SCREENS.includes(props?.component)) && (
            <nav className="sideMenu fixed">
                <ul className="navbarMenu">
                    <li
                        className={`navbarIcon ${
                            activeItem === PAGE_CONST.HOME ? 'active' : ''
                        }`}
                        onClick={() => handleClick(SIDEBAR_CONST.HOME)}>
                        {activeItem !== 'HOME' ? (
                            <HomeIcon />
                        ) : (
                            <HomeFillIcon />
                        )}
                    </li>
                    {permissions?.features?.[PERMISSION_FEATURE.BAR_GRAPH] !==
                        PERMISSION_TYPE.RESTRICTED && (
                        <li
                            className={`navbarIcon mwebVisible ${
                                activeItem === PAGE_CONST.STOCKS ? 'active' : ''
                            }`}
                            onClick={() => handleClick(SIDEBAR_CONST.STOCKS)}>
                            {activeItem !== 'STOCK' ? (
                                <StockIcon />
                            ) : (
                                <StockIcon />
                            )}
                        </li>
                    )}
                    <li
                        className={`navbarIcon mwebVisible ${
                            activeItem === PAGE_CONST.PROFILE_MENU
                                ? 'active'
                                : ''
                        }`}
                        onClick={() => handleClick(SIDEBAR_CONST.PROFILE_MENU)}>
                        {activeItem !== 'PROFILE_MENU' ? (
                            <UserIcon />
                        ) : (
                            <UserFillIcon />
                        )}
                    </li>
                    <li
                        className={`navbarIcon webVisible ${
                            activeItem === PAGE_CONST.USER_PROFILE
                                ? 'active'
                                : ''
                        }`}
                        onClick={() => handleClick(SIDEBAR_CONST.USER_PROFILE)}>
                        {activeItem !== 'USER_PROFILE' ? (
                            <UserIcon />
                        ) : (
                            <UserFillIcon />
                        )}
                    </li>
                    {permissions?.features?.[
                        PERMISSION_FEATURE.REMINDER_SETTINGS
                    ] !== PERMISSION_TYPE.RESTRICTED && (
                        <li
                            className={`navbarIcon webVisible ${
                                activeItem === PAGE_CONST.SETTINGS
                                    ? 'active'
                                    : ''
                            }`}
                            onClick={() => handleClick(SIDEBAR_CONST.SETTINGS)}>
                            {activeItem !== 'SETTINGS' ? (
                                <SettingsIcon />
                            ) : (
                                <SettingsFillIcon />
                            )}
                        </li>
                    )}
                    <li
                        className={`navbarIcon webVisible ${
                            activeItem === PAGE_CONST.HELP ? 'active' : ''
                        }`}
                        onClick={() => handleClick(SIDEBAR_CONST.HELP)}>
                        {activeItem !== 'HELP' ? (
                            <HelpIcon />
                        ) : (
                            <HelpFillIcon />
                        )}
                    </li>
                </ul>
            </nav>
        )
    )
}

const mapStateToProps = state => {
    return {
        loginData: state.loginDataReducer?.loginData
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu)
