import React from 'react'

const PendingImage = () => (
    <svg
        width="126"
        height="112"
        viewBox="0 0 126 112"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.7">
            <path
                d="M46.4225 46.4225C51.809 46.4225 56.9749 44.2827 60.7837 40.4738C64.5926 36.665 66.7323 31.4991 66.7323 26.1126C66.7323 20.7261 64.5926 15.5602 60.7837 11.7514C56.9749 7.94252 51.809 5.80273 46.4225 5.80273C41.036 5.80273 35.8701 7.94252 32.0613 11.7514C28.2524 15.5602 26.1126 20.7261 26.1126 26.1126C26.1126 31.4991 28.2524 36.665 32.0613 40.4738C35.8701 44.2827 41.036 46.4225 46.4225 46.4225ZM21.7605 52.2253C19.0673 52.2253 16.4843 53.2952 14.5799 55.1996C12.6755 57.104 11.6056 59.6869 11.6056 62.3802V63.8309C11.6056 70.774 16.0244 76.6464 22.2973 80.6388C28.6049 84.6543 37.1438 87.0422 46.4225 87.0422C55.7012 87.0422 64.2371 84.6543 70.5477 80.6388C76.8205 76.6464 81.2394 70.774 81.2394 63.8309V62.3802C81.2394 59.6869 80.1695 57.104 78.2651 55.1996C76.3607 53.2952 73.7777 52.2253 71.0845 52.2253H21.7605Z"
                fill="url(#paint0_linear_1351_16845)"
            />
        </g>
        <g filter="url(#filter0_b_1351_16845)">
            <circle
                cx="83.5"
                cy="69.5"
                r="42.5"
                fill="#FCEFE9"
                fill-opacity="0.4"
            />
            <circle
                cx="83.5"
                cy="69.5"
                r="40"
                stroke="white"
                stroke-width="5"
            />
        </g>
        <path
            d="M87.4976 73.1128H79.7605C79.3758 73.1128 79.0068 72.9599 78.7347 72.6879C78.4627 72.4158 78.3098 72.0468 78.3098 71.6621V60.0564C78.3098 59.6717 78.4627 59.3027 78.7347 59.0306C79.0068 58.7586 79.3758 58.6057 79.7605 58.6057C80.1453 58.6057 80.5143 58.7586 80.7864 59.0306C81.0584 59.3027 81.2113 59.6717 81.2113 60.0564V70.2114H87.4976C87.8824 70.2114 88.2514 70.3642 88.5234 70.6363C88.7955 70.9083 88.9483 71.2773 88.9483 71.6621C88.9483 72.0468 88.7955 72.4158 88.5234 72.6879C88.2514 72.9599 87.8824 73.1128 87.4976 73.1128ZM81.2113 50.8687C70.5302 50.8687 61.8685 59.5303 61.8685 70.2114C61.8685 80.8924 70.5302 89.5541 81.2113 89.5541C91.8923 89.5541 100.554 80.8924 100.554 70.2114C100.554 59.5303 91.8923 50.8687 81.2113 50.8687Z"
            fill="#EC713C"
        />
        <defs>
            <filter
                id="filter0_b_1351_16845"
                x="33"
                y="19"
                width="101"
                height="101"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
                <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_1351_16845"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_backgroundBlur_1351_16845"
                    result="shape"
                />
            </filter>
            <linearGradient
                id="paint0_linear_1351_16845"
                x1="46.4225"
                y1="-7.97896"
                x2="46.4225"
                y2="87.0422"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#EF8658" />
                <stop offset="1" stopColor="#EC713C" />
            </linearGradient>
        </defs>
    </svg>
)

export default PendingImage
