import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    HEADER_CONFIG,
    HEADER_CONST
} from '../../common/constant/common-constant'
import { HOME_CONST, PAGE_CONST } from '../../common/constant/page-constant'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import { redirect } from '../../common/utils/redirectionUtils'
import Header from '../Common/Header/Header'
import MobileHeader from '../Common/Header/MobileHeader'
import SideMenu from '../Common/SideMenu/SideMenu'

import './Notification.scss'
import {
    getNotificationsHelper,
    updateNotificationsHelper
} from '../../helper/personal-details-helper'
import {
    activeHomeDetailNavigation,
    notificationActiveAction
} from '../../modules/actions/action'
import { formatDate, formatTime } from '../../common/utils/appUtils'

const Notification = ({
    dispatchNotificationActiveAction,
    dispatchActiveHomeDetailNavigation
}) => {
    const navigate = useNavigate()
    const isMobile = useCheckMobileScreen()
    const [notifications, setNotifications] = useState(undefined)

    const [headerOptions] = useState({
        [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
        [HEADER_CONFIG.TITLE]: HEADER_CONST.NOTIFICATION,
        [HEADER_CONFIG.HANDLE_BACK_FUNC]: () =>
            redirect(navigate, PAGE_CONST.HOME)
    })

    useEffect(() => {
        getNotifications()
    }, [])

    const getNotifications = async () => {
        getNotificationsHelper()
            .then(res => {
                setNotifications(res)
                console.log(res)

                dispatchNotificationActiveAction(false)
                updateNotifications(res)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const updateNotifications = async notifications => {
        if (notifications?.length <= 0) return

        let data = notifications?.reduce((prev, current) =>
            prev && prev.id > current.id ? prev : current
        )

        updateNotificationsHelper({ id: data?.id })
            .then(res => {
                setNotifications(res)
                console.log(res)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleNotificationClick = notification => {
        let homeDetailNavigation
        let navigationPage
        switch (notification?.screen) {
            case 'SALES':
                homeDetailNavigation = HOME_CONST.SALES
                navigationPage = PAGE_CONST.HOME_DETAILS
                break
            default:
                homeDetailNavigation = HOME_CONST.SALES
                navigationPage = PAGE_CONST.HOME_DETAILS
        }
        dispatchActiveHomeDetailNavigation(homeDetailNavigation)

        redirect(navigate, navigationPage)
        // navigate(navigationPage)
    }

    return (
        <div className="container notification">
            {!isMobile && <Header />}
            {isMobile && <MobileHeader headerOptions={headerOptions} />}
            <SideMenu component={PAGE_CONST.NOTIFICATION} />
            <div className="pageBody dFlex">
                <div className="notificationWrapper">
                    {notifications?.map(notification => {
                        return (
                            <div
                                className={`notificationRow ${
                                    !notification.is_seen ? 'unread' : ''
                                }`}
                                onClick={notification =>
                                    handleNotificationClick(notification)
                                }>
                                <div className="notificationRowHead">
                                    {notification.heading}
                                </div>
                                <div className="notificationRowDesc">
                                    {notification.sub_heading}
                                </div>
                                <div className="notificationRowFoot">
                                    {`${formatDate(
                                        notification.created_at
                                    )} ${formatTime(notification.created_at)}`}
                                </div>
                            </div>
                        )
                    })}

                    {/* <div className="notificationRow unread">
                        <div className="notificationRowHead">
                            Lorem ipsum dolor sit amet consectetur.
                        </div>
                        <div className="notificationRowDesc">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Nunc orci faucibus diam gravida eu eget ipsum
                        </div>
                        <div className="notificationRowFoot">26 Oct 2023</div>
                    </div>
                    <div className="notificationRow unread">
                        <div className="notificationRowHead">
                            Lorem ipsum dolor sit amet consectetur.
                        </div>
                        <div className="notificationRowDesc">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Nunc orci faucibus diam gravida eu eget ipsum
                        </div>
                        <div className="notificationRowFoot">26 Oct 2023</div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {}
}
const mapDispatchToProps = dispatch => {
    return {
        dispatchNotificationActiveAction: payload =>
            dispatch(notificationActiveAction(payload)),
        dispatchActiveHomeDetailNavigation: payload =>
            dispatch(activeHomeDetailNavigation(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification)
