import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import "./PopupCard.scss";

const PopupCard = (props) => {

    return (
        <>
            <div className='popupCardWrap'>
                <div className='popupCard'>
                    <div className='popupCardHeader'>
                        {props.header}
                    </div>
                    <div className='popupCardBody'>
                        {props.body}
                    </div>
                    <div className='popupCardFooter dFlex flexNowrap'>
                        {props.children}
                    </div>
                </div>
            </div>
            <div className='bgmask'></div>
        </>
    );
}

const mapStateToProps = (state) => { return {}; }
const mapDispatchToProps = (dispatch) => { return {} }

PopupCard.propTypes = {
    header: PropTypes.string,
    body: PropTypes.string,
    children: PropTypes.node,
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupCard);
