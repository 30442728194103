import React, { useEffect, useRef } from 'react'
import { Html5Qrcode } from 'html5-qrcode'

const BarcodeScanner = ({ onScan }) => {
    const videoRef = useRef(null)
    let html5QrCode = null

    useEffect(() => {
        const startScanner = async () => {
            try {
                const cameras = await Html5Qrcode.getCameras()
                if (cameras && cameras.length > 0) {
                    const cameraId = cameras[0].id // Select the first camera by default
                    const config = {
                        // facingMode: 'environment',
                        deviceId: cameraId
                    }

                    html5QrCode = new Html5Qrcode('qr-code-reader')
                    await html5QrCode.start(
                        config,
                        {
                            fps: 10,
                            qrbox: 250
                        },
                        decodedText => {
                            onScan(decodedText)

                            if (html5QrCode && html5QrCode?.stop) {
                                html5QrCode
                                    .stop()
                                    .then(() => {
                                        if (videoRef.current) {
                                            const videoElement =
                                                videoRef.current
                                            videoElement.srcObject
                                                .getTracks()
                                                .forEach(track => track.stop())
                                            videoElement.srcObject = null
                                        }
                                        console.log(
                                            'Scanner stopped successfully.'
                                        )
                                    })
                                    .catch(err => {
                                        console.error(
                                            'Failed to stop scanner:',
                                            err
                                        )
                                    })
                            }
                        }
                    )
                } else {
                    throw new Error('No cameras found')
                }
            } catch (error) {
                console.error('Failed to start scanner:', error)
            }
        }

        startScanner()

        return () => {
            if (html5QrCode && html5QrCode?.stop && html5QrCode?.isScanning) {
                html5QrCode
                    .stop()
                    .then(() => {
                        if (videoRef.current) {
                            const videoElement = videoRef.current
                            videoElement.srcObject
                                .getTracks()
                                .forEach(track => track.stop())
                            videoElement.srcObject = null
                        }

                        html5QrCode.clear()
                    })
                    .catch(err => {
                        console.error('Failed to stop scanner:', err)
                    })
            }
        }
    }, [onScan])

    return (
        <div className="scannerContainer">
            <div id="qr-code-reader" ref={videoRef} style={{ width: '100%' }} />
        </div>
    )
}

export default BarcodeScanner
