import './App.css'
import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { connect, Provider } from 'react-redux'
import { store, persistor } from './modules/store/store'
import { PersistGate } from 'redux-persist/integration/react'
import { routes } from './common/constant/routes-constant'
// import { socketInstance } from './common/context/socket'
import //     socketInstance,
//     SocketContext,
SocketProvider from './common/context/socket'

import './common/scss/common.scss'
import AcknowledgeScreen from './pages/Common/AcknowledgeScreen/AcknowledgeScreen'
import CheckPrimaryIcon from './assets/CheckPrimaryIcon'

function App({ newSalesAlert, updateSalesByCustomerAlert }) {
    return (
        <>
            {/* <Provider store={store}> */}
            <PersistGate persistor={persistor}>
                <SocketProvider>
                    {newSalesAlert && (
                        <AcknowledgeScreen
                            onlyAlert
                            icon={<CheckPrimaryIcon />}
                            main={'New Online Sales Received'}
                            sub="Lorem ipsum dolor sit amet consectetur. Ligula ut volutpat morbi ullamcorper"
                            success
                            alertType="success"
                            alertMessage={'New Online Sales Received'}
                        />
                    )}
                    {updateSalesByCustomerAlert && (
                        <AcknowledgeScreen
                            onlyAlert
                            icon={<CheckPrimaryIcon />}
                            main={'Sales Order Updated By Customer'}
                            sub="Lorem ipsum dolor sit amet consectetur. Ligula ut volutpat morbi ullamcorper"
                            success
                            alertType="success"
                            alertMessage={'Sales Order Updated By Customer'}
                        />
                    )}
                    <div className="App" id="App">
                        <BrowserRouter>
                            <Routes>
                                {routes.map(route => {
                                    return (
                                        <Route
                                            key={route.key}
                                            path={route.path}
                                            authNeeded={route.authNeeded}
                                            element={route.element}
                                        />
                                    )
                                })}
                                <Route
                                    path="*"
                                    element={<Navigate to="/login" />}
                                />
                            </Routes>
                        </BrowserRouter>
                    </div>
                </SocketProvider>
            </PersistGate>
            {/* </Provider> */}
        </>
    )

    // return (
    //     <SocketContext.Provider store={store} value={socketInstance}>
    //         <PersistGate persistor={persistor}>
    //             <div className="App" id="App">
    //                 <BrowserRouter>
    //                     <Routes>
    //                         {routes.map(route => {
    //                             return (
    //                                 <Route
    //                                     key={route.key}
    //                                     path={route.path}
    //                                     authNeeded={route.authNeeded}
    //                                     element={route.element}
    //                                 />
    //                             )
    //                         })}
    //                         <Route
    //                             path="*"
    //                             element={<Navigate to="/login" />}
    //                         />
    //                     </Routes>
    //                 </BrowserRouter>
    //             </div>
    //         </PersistGate>
    //     </SocketContext.Provider>
    // )
}

// export default App
const mapStateToProps = state => ({
    newSalesAlert: state.alertReducer.alert?.newSalesAlert,
    updateSalesByCustomerAlert:
        state.alertReducer.alert?.updateSalesByCustomerAlert
})

export default connect(mapStateToProps)(App)
