import React from 'react'

const PrimaryDocumentIcon = () => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Document">
            <path id="Vector" d="M6.99984 2.3335C6.381 2.3335 5.78751 2.57933 5.34992 3.01691C4.91234 3.4545 4.6665 4.04799 4.6665 4.66683V23.3335C4.6665 23.9523 4.91234 24.5458 5.34992 24.9834C5.78751 25.421 6.381 25.6668 6.99984 25.6668H20.9998C21.6187 25.6668 22.2122 25.421 22.6498 24.9834C23.0873 24.5458 23.3332 23.9523 23.3332 23.3335V11.4662C23.333 10.8474 23.0871 10.254 22.6495 9.8165L15.8502 3.01716C15.4127 2.57955 14.8193 2.33363 14.2005 2.3335H6.99984ZM6.4165 4.66683C6.4165 4.51212 6.47796 4.36375 6.58736 4.25435C6.69675 4.14495 6.84513 4.0835 6.99984 4.0835H13.9998V9.3335C13.9998 9.95233 14.2457 10.5458 14.6833 10.9834C15.1208 11.421 15.7143 11.6668 16.3332 11.6668H21.5832V23.3335C21.5832 23.4882 21.5217 23.6366 21.4123 23.746C21.3029 23.8554 21.1545 23.9168 20.9998 23.9168H6.99984C6.84513 23.9168 6.69675 23.8554 6.58736 23.746C6.47796 23.6366 6.4165 23.4882 6.4165 23.3335V4.66683ZM20.2765 9.91683H16.3332C16.1785 9.91683 16.0301 9.85537 15.9207 9.74598C15.8113 9.63658 15.7498 9.48821 15.7498 9.3335V5.39016L20.2765 9.91683Z" fill="#EF8658" />
        </g>
    </svg>
)

export default PrimaryDocumentIcon
