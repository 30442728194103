import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AddIcon from '../../assets/AddIcon'
import CloseIcon from '../../assets/CloseIcon'
import {
    HEADER_CONFIG,
    HEADER_CONST,
    WIZARD_STEPS
} from '../../common/constant/common-constant'
import {
    ADD_USER_STAGES,
    HOME_NAVIGATION_ITEMS,
    GET_PERMISSION_INITIAL_DATA,
    PERMISSION_TYPE,
    USER_FIELDS,
    PERMISSION_NAME_MAPPING,
    PAGE_CONST,
    CARD_ACTION,
    ADD_USER_FLOW,
    PERMISSION_FEATURE,
    PERMISSION_SCREEN
} from '../../common/constant/page-constant'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import { convertToTitleCase } from '../../common/utils/appUtils'
import { redirect } from '../../common/utils/redirectionUtils'
import Alert from '../../components/Alert/Alert'
import { getBranchHelper } from '../../helper/branch-management-helper'
import {
    addUserHelper,
    getUserHelper,
    updateUserHelper
} from '../../helper/home-helper'
import {
    addUserAlertAction,
    deleteUserAlertAction
} from '../../modules/actions/action'
import Card from '../Common/Card/Card'
import Header from '../Common/Header/Header'
import MobileHeader from '../Common/Header/MobileHeader'
import SideDrawer from '../Common/SideDrawer/SideDrawer'

import './ManageUsers.scss'

const ManageUsers = props => {
    const navigate = useNavigate()
    const isMobile = useCheckMobileScreen()

    const [viewDrawer, setViewDrawer] = useState(false)
    const [open, setOpen] = useState(false)
    const [wizardState, setWizardState] = useState(undefined)
    const [addUserState, setAddUserState] = useState(undefined)
    const [userData, setUserData] = useState(undefined)
    const [permissionData, setPermissionData] = useState(undefined)
    const [isFieldErrorVisible, setIsFieldErrorVisible] = useState(false)
    const [serverError, setServerError] = useState(undefined)
    const [addUserAlert, setAddUserAlert] = useState(false)
    const [deleteUserAlert, setDeleteUserAlert] = useState(false)
    const [branchData, setBranchData] = useState(undefined)
    const [manageUserData, setManageUserData] = useState(undefined)
    const [headerOptions, setHeaderOptions] = useState({
        [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
        [HEADER_CONFIG.TITLE]: HEADER_CONST.USERS,
        [HEADER_CONFIG.HANDLE_BACK_FUNC]: () =>
            redirect(navigate, PAGE_CONST.HOME)
    })
    const [addUserFlow, setAddUserFlow] = useState(undefined)
    const [permissions, setPermissions] = useState({})

    useEffect(() => {
        if (!open) {
            handleHeaderConfigUpdate(HEADER_CONST.USERS, () =>
                redirect(navigate, PAGE_CONST.HOME)
            )
            return
        }

        handleHeaderConfigUpdate(HEADER_CONST.ADD_USERS, () =>
            handleDrawerToggle()
        )
        handleWizardState(WIZARD_STEPS.STEP_1)
        setAddUserState(ADD_USER_STAGES.USER_DETAILS)
        if (!permissionData)
            setPermissionData([...GET_PERMISSION_INITIAL_DATA()])
    }, [open])

    useEffect(() => {
        let addUserAlert = props.alert?.addUserAlert
        let deleteUserAlert = props.alert?.deleteUserAlert

        setAddUserAlert(addUserAlert)
        setDeleteUserAlert(deleteUserAlert)
    }, [props.alert?.addUserAlert, props.alert?.deleteUserAlert])

    useEffect(() => {
        getBranchData()
        getUserData()
    }, [])

    useEffect(() => {
        if (props.loginData.permissions) {
            console.log(
                'props.loginData.permissions: ',
                props.loginData.permissions
            )

            let permission = props.loginData.permissions.filter(
                permission =>
                    permission.screen === PERMISSION_SCREEN.PERMISSIONS
            )[0]

            console.log('home screen permission: ', permission)
            if (permission) setPermissions(permission)
        }
    }, [props.loginData])

    const handleHeaderConfigUpdate = (title, handleBackFunc) => {
        setHeaderOptions({
            [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
            [HEADER_CONFIG.TITLE]: title,
            [HEADER_CONFIG.HANDLE_BACK_FUNC]: () => handleBackFunc()
        })
    }

    const getBranchData = () => {
        getBranchHelper().then(res => {
            console.log('br res: ', res)
            setBranchData(res)
        })
    }

    const getUserData = () => {
        getUserHelper()
            .then(res => {
                console.log('mu res: ', res)
                setManageUserData(res)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleDrawerToggle = () => {
        setViewDrawer(!viewDrawer)
        setOpen(!open)
        setAddUserState(ADD_USER_STAGES.USER_DETAILS)
    }

    const resetDrawerData = () => {
        setUserData({})
        setPermissionData(undefined)
        setAddUserState(undefined)
        setAddUserFlow(undefined)
    }

    const handleWizardState = state => {
        switch (state) {
            case WIZARD_STEPS.STEP_2:
                setWizardState({
                    [WIZARD_STEPS.STEP_1]: true,
                    [WIZARD_STEPS.STEP_2]: false
                })
                break
            case WIZARD_STEPS.STEP_1:
                setWizardState({
                    [WIZARD_STEPS.STEP_1]: false,
                    [WIZARD_STEPS.STEP_2]: false
                })
                break
        }
    }

    const getWizardStateClass = step => {
        switch (step) {
            case WIZARD_STEPS.STEP_1: {
                if (wizardState?.[WIZARD_STEPS.STEP_1]) return 'completed'
                else return 'inprogress'
            }
            case WIZARD_STEPS.STEP_2: {
                if (wizardState?.[WIZARD_STEPS.STEP_2]) return 'completed'
                else if (wizardState?.[WIZARD_STEPS.STEP_1]) return 'inprogress'
                else return ''
            }
            default:
                return ''
        }
    }

    const handleUserDetailUpdate = (key, value) => {
        setUserData({
            ...userData,
            [key]: value
        })
    }

    const handleNextClick = () => {
        const isValidState = checkValidation()
        if (!isValidState) {
            setIsFieldErrorVisible(true)
            return
        }

        setServerError(undefined)
        setIsFieldErrorVisible(false)
        switch (addUserState) {
            case ADD_USER_STAGES.USER_DETAILS:
                setAddUserState(ADD_USER_STAGES.PERMISSIONS)
                handleWizardState(WIZARD_STEPS.STEP_2)
                break
            case ADD_USER_STAGES.PERMISSIONS:
                handleSubmit()
                break
        }
    }

    const handleSubmit = () => {
        setServerError('')
        let data = { userData, permissionData }

        console.log('P initial:', GET_PERMISSION_INITIAL_DATA())
        console.log('data:', data)

        // return

        if (addUserFlow === ADD_USER_FLOW.EDIT) {
            updateUserHelper(data, addUserFlow)
                .then(res => {
                    console.log(res)

                    props.addUserAlertAction(true)

                    setTimeout(() => {
                        props.addUserAlertAction(false)
                        console.log('setting add user flow undefined')
                        setAddUserFlow(undefined)
                        resetDrawerData()
                    }, 5000)

                    getUserData()
                    handleDrawerToggle()
                })
                .catch(err => {
                    setServerError(err)
                })
        } else {
            addUserHelper(data)
                .then(res => {
                    console.log(res)

                    props.addUserAlertAction(true)

                    setTimeout(() => {
                        props.addUserAlertAction(false)
                    }, 5000)
                    getUserData()
                    resetDrawerData()
                    handleDrawerToggle()
                })
                .catch(err => {
                    setServerError(err)
                })
        }
    }

    const handleDelete = id => {
        let data = {
            userData: {
                id: id
            }
        }
        updateUserHelper(data, ADD_USER_FLOW.DELETE)
            .then(res => {
                console.log(res)

                props.deleteUserAlertAction(true)

                setTimeout(() => {
                    props.deleteUserAlertAction(false)
                    // console.log('setting add user flow undefined')
                    setAddUserFlow(undefined)
                    resetDrawerData()
                }, 5000)
                getUserData()
                // handleDrawerToggle()
            })
            .catch(err => {
                setServerError(err)
            })
    }

    const checkValidation = () => {
        // return true;
        if (addUserState === ADD_USER_STAGES.USER_DETAILS) {
            if (userData) {
                if (
                    userData[USER_FIELDS.NAME] &&
                    userData[USER_FIELDS.PHONE_NUMBER] &&
                    // && userData[USER_FIELDS.EMAIL]
                    userData[USER_FIELDS.BRANCH_ID] &&
                    (addUserFlow === ADD_USER_FLOW.EDIT ||
                        (userData[USER_FIELDS.PASSWORD] &&
                            userData[USER_FIELDS.CONFIRM_PASSWORD] ===
                                userData[USER_FIELDS.PASSWORD]))
                ) {
                    return true
                }
            }

            return false
        }
        if (addUserState === ADD_USER_STAGES.PERMISSIONS) {
            return true
            // if (permissionData) {
            //     if (userData[USER_FIELDS.NAME]
            //         && userData[USER_FIELDS.PHONE_NUMBER]
            //         && userData[USER_FIELDS.EMAIL]
            //         && userData[USER_FIELDS.BRANCH_ID]
            //         ) {
            //         return true;
            //     }
            // }

            // return false;
        }

        return false
    }

    const handlePermissionData = (
        permissionObjName,
        permissionName,
        permissionType,
        checked
    ) => {
        console.log(permissionObjName, permissionName, permissionType, checked)

        if (!permissionObjName || !permissionName || !permissionType) return
        let pData = [...permissionData]

        let pObj = pData.filter(ele => ele.name === permissionObjName)
        let permission = pObj[0].permissionList.filter(
            ele1 => ele1.name === permissionName
        )

        if (checked) permission[0].permissionType = permissionType
        else permission[0].permissionType = PERMISSION_TYPE.RESTRICTED

        setPermissionData(pData)
    }

    const handleSelectAllPermissionData = (permissionType, checked) => {
        let pData = [...permissionData]

        pData.map(pObj => {
            pObj.permissionList.map(permission => {
                if (checked) permission.permissionType = permissionType
                else permission.permissionType = PERMISSION_TYPE.RESTRICTED
            })
        })

        setPermissionData(pData)
    }

    const isSelectAllPermissionData = permissionType => {
        let pData = [...permissionData]

        return pData.every(pObj =>
            pObj.permissionList.every(
                permission => permission.permissionType === permissionType
            )
        )
    }

    const handleEditUser = (user, action) => {
        if (action === ADD_USER_FLOW.DELETE) {
            handleDelete(user?.id)

            return
        }
        console.log(user, action)

        resetDrawerData()
        // setDrawer Data

        let branch = branchData?.filter(
            branch => branch.id === user?.branch_id
        )[0]
        let userData = {
            id: user.id,
            [USER_FIELDS.NAME]: user?.name,
            [USER_FIELDS.EMAIL]: user?.email,
            [USER_FIELDS.PHONE_NUMBER]: user?.mobile_no,
            [USER_FIELDS.BRANCH_ID]: branch.id
        }

        let permissionData = GET_PERMISSION_INITIAL_DATA()

        user.permissions.map(existingPermission => {
            let permissionObj = permissionData.filter(
                initialPermission =>
                    initialPermission.name === existingPermission.screen
            )[0]

            permissionObj.permissionList.map(permission => {
                let permissionType =
                    existingPermission?.features?.[permission?.name]

                if (permissionType) permission.permissionType = permissionType
            })
        })

        console.log('permissionData: ', permissionData)

        setUserData(userData)
        setPermissionData(permissionData)
        setAddUserFlow(ADD_USER_FLOW.EDIT)
        setAddUserState(ADD_USER_STAGES.USER_DETAILS)
        handleDrawerToggle()
    }

    const onWizardStepClick = step => {
        setServerError(undefined)
        switch (step) {
            case WIZARD_STEPS.STEP_1:
                setAddUserState(ADD_USER_STAGES.USER_DETAILS)
                break
            case WIZARD_STEPS.STEP_2:
                setAddUserState(ADD_USER_STAGES.PERMISSIONS)
                break
        }
    }

    return (
        <>
            {isMobile && <MobileHeader headerOptions={headerOptions} />}
            {addUserAlert && (
                <Alert
                    success
                    alertType="success"
                    alertMessage={
                        addUserFlow === ADD_USER_FLOW.EDIT
                            ? 'User updated Successfully!'
                            : 'New User added Successfully!'
                    }
                />
            )}
            {deleteUserAlert && (
                <Alert
                    success
                    alertType="success"
                    alertMessage={'User deleted Successfully!'}
                />
            )}
            <div className="pageBodyRightPanel">
                {isMobile && (
                    <div className="addProductBtnMobile dFlex flexColumnReverse aiCenter mwebVisible fixed">
                        <button
                            className="animated zoomIn btn btnPrimary"
                            onClick={handleDrawerToggle}>
                            <AddIcon />
                        </button>
                    </div>
                )}
                {!isMobile && (
                    <div className="pageBodyRightPanelHeader dFlex jcSpaceBetween aiCenter">
                        <div className="pageBodyRightPanelHeading">
                            Manage Users
                        </div>
                        <div className="addProductBtn dFlex aiCenter">
                            {permissions?.features?.[
                                PERMISSION_FEATURE.MANAGE_USERS
                            ] === PERMISSION_TYPE.MANAGE && (
                                <button
                                    className="btn btnPrimary"
                                    onClick={() => {
                                        resetDrawerData()
                                        handleDrawerToggle()
                                    }}>
                                    ADD
                                </button>
                            )}
                            <div
                                className="pointer ml-4"
                                onClick={() => props.onClose()}>
                                <CloseIcon />
                            </div>
                        </div>
                    </div>
                )}
                <div className="productLlistWrap dFlex">
                    {manageUserData &&
                        manageUserData.map(user => {
                            return (
                                <div className="cardWrap">
                                    <Card
                                        users
                                        cardAction={
                                            permissions?.features?.[
                                                PERMISSION_FEATURE.MANAGE_USERS
                                            ] === PERMISSION_TYPE.MANAGE &&
                                            CARD_ACTION.USER
                                        }
                                        onCardAction={action =>
                                            handleEditUser(user, action)
                                        }
                                        userInitials={convertToTitleCase(
                                            user?.name?.[0]
                                        )}
                                        // userInitials="A"
                                        headerMain={user.name}
                                        phone={user.mobile_no}
                                        email={user.email}
                                        // cardRight={true}
                                    />
                                </div>
                            )
                        })}
                </div>
            </div>
            {open && (
                <SideDrawer
                    handleDrawerToggle={handleDrawerToggle}
                    open={open}
                    drawerButton={true}
                    drawerHeading={
                        addUserFlow === ADD_USER_FLOW.EDIT
                            ? 'Edit User'
                            : 'Add User'
                    }
                    handleClick={() => handleNextClick()}>
                    <ul class="WizardStepsWrap">
                        <li
                            className={`wizardStep ${getWizardStateClass(
                                WIZARD_STEPS.STEP_1
                            )}`}
                            onClick={() => {
                                if (
                                    getWizardStateClass(WIZARD_STEPS.STEP_1) ===
                                    'completed'
                                )
                                    onWizardStepClick(WIZARD_STEPS.STEP_1)
                            }}>
                            <div class="circle">1</div>
                        </li>
                        <li
                            className={`wizardStep ${getWizardStateClass(
                                WIZARD_STEPS.STEP_2
                            )}`}
                            onClick={() => {
                                if (
                                    getWizardStateClass(WIZARD_STEPS.STEP_2) ===
                                    'completed'
                                )
                                    onWizardStepClick(WIZARD_STEPS.STEP_2)
                            }}>
                            <div class="circle">2</div>
                        </li>
                    </ul>
                    <div className="wizardFieldset">
                        {serverError && (
                            <div class="invalidFeedback mb-4">
                                {serverError}
                            </div>
                        )}
                        {addUserState === ADD_USER_STAGES.USER_DETAILS && (
                            <form onSubmit={e => e.preventDefault()}>
                                <div className="formGroup formFloating fgrow1">
                                    <input
                                        type="text"
                                        className="formControl"
                                        id="invoice"
                                        placeholder="User Name"
                                        value={userData?.[USER_FIELDS.NAME]}
                                        onChange={e =>
                                            handleUserDetailUpdate(
                                                USER_FIELDS.NAME,
                                                e.target.value
                                            )
                                        }
                                        required
                                    />
                                    <label for="invoice">User Name</label>
                                    {isFieldErrorVisible &&
                                        !userData?.[USER_FIELDS.NAME] && (
                                            <div class="invalidFeedback">
                                                Please enter a user name.
                                            </div>
                                        )}
                                </div>
                                <div className="formInline dFlex flexNowrap">
                                    <div className="formGroup formFloating fgrow1 maxWidth">
                                        <select
                                            className="formSelect"
                                            id="floatingSelect"
                                            value={
                                                userData?.[
                                                    USER_FIELDS.AREA_CODE
                                                ]
                                            }
                                            onChange={e =>
                                                handleUserDetailUpdate(
                                                    USER_FIELDS.AREA_CODE,
                                                    e.target.value
                                                )
                                            }>
                                            {/* <option value="none" selected disabled hidden>Select an Option</option> */}
                                            {/* <option value="1">+91</option> */}
                                            <option value="2">+88</option>
                                        </select>
                                        <label for="floatingSelect">
                                            Country
                                        </label>
                                        {false && (
                                            <div class="invalidFeedback">
                                                Please choose country code.
                                            </div>
                                        )}
                                    </div>
                                    <div className="formGroup formFloating ml-4 fgrow1">
                                        <input
                                            type="text"
                                            className="formControl"
                                            id="phone"
                                            placeholder="Phone"
                                            value={
                                                userData?.[
                                                    USER_FIELDS.PHONE_NUMBER
                                                ]
                                            }
                                            onChange={e =>
                                                handleUserDetailUpdate(
                                                    USER_FIELDS.PHONE_NUMBER,
                                                    e.target.value
                                                )
                                            }
                                            required
                                        />
                                        <label for="phone">Phone</label>
                                        {isFieldErrorVisible &&
                                            !userData?.[
                                                USER_FIELDS.PHONE_NUMBER
                                            ] && (
                                                <div class="invalidFeedback">
                                                    Please select a valid Phone.
                                                </div>
                                            )}
                                    </div>
                                </div>
                                <div className="formGroup formFloating">
                                    <input
                                        type="text"
                                        className="formControl"
                                        id="email"
                                        placeholder="Email Address"
                                        value={userData?.[USER_FIELDS.EMAIL]}
                                        onChange={e =>
                                            handleUserDetailUpdate(
                                                USER_FIELDS.EMAIL,
                                                e.target.value
                                            )
                                        }
                                        required
                                    />
                                    <label for="address">Email Address</label>
                                    {isFieldErrorVisible &&
                                        !userData?.[USER_FIELDS.EMAIL] && (
                                            <div class="invalidFeedback">
                                                Please enter a valid Email
                                                Address.
                                            </div>
                                        )}
                                </div>
                                <div className="formGroup formFloating">
                                    <select
                                        className="formSelect"
                                        id="floatingSelect"
                                        value={
                                            userData?.[USER_FIELDS.BRANCH_ID]
                                        }
                                        onChange={e =>
                                            handleUserDetailUpdate(
                                                USER_FIELDS.BRANCH_ID,
                                                e.target.value
                                            )
                                        }>
                                        <option
                                            value="none"
                                            selected
                                            disabled
                                            hidden>
                                            Select an Option
                                        </option>

                                        {branchData &&
                                            branchData.map(branch => {
                                                return (
                                                    <option value={branch.id}>
                                                        {branch.name}
                                                    </option>
                                                )
                                            })}
                                        {/* <option value="1">Delhi Branch</option>
                                <option value="2">Pune Branch</option> */}
                                    </select>
                                    <label for="floatingSelect">
                                        Branch Name
                                    </label>
                                    {isFieldErrorVisible &&
                                        !userData?.[USER_FIELDS.BRANCH_ID] && (
                                            <div class="invalidFeedback">
                                                Please choose Branch Name.
                                            </div>
                                        )}
                                </div>
                                {ADD_USER_FLOW.EDIT !== addUserFlow && (
                                    <>
                                        <div className="formGroup formFloating">
                                            <input
                                                type="password"
                                                className="formControl"
                                                id="password"
                                                placeholder="Password"
                                                value={
                                                    userData?.[
                                                        USER_FIELDS.PASSWORD
                                                    ]
                                                }
                                                onChange={e =>
                                                    handleUserDetailUpdate(
                                                        USER_FIELDS.PASSWORD,
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <label for="password">
                                                Password
                                            </label>
                                            {isFieldErrorVisible && false && (
                                                <div class="invalidFeedback">
                                                    Please enter a valid
                                                    Password.
                                                </div>
                                            )}
                                        </div>
                                        <div className="formGroup formFloating">
                                            <input
                                                type="password"
                                                className="formControl"
                                                id="confirmPassword"
                                                placeholder="Confirm Password"
                                                value={
                                                    userData?.[
                                                        USER_FIELDS
                                                            .CONFIRM_PASSWORD
                                                    ]
                                                }
                                                onChange={e =>
                                                    handleUserDetailUpdate(
                                                        USER_FIELDS.CONFIRM_PASSWORD,
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <label for="confirmPassword">
                                                Confirm Password
                                            </label>
                                            {userData?.[USER_FIELDS.PASSWORD] !=
                                                userData?.[
                                                    USER_FIELDS.CONFIRM_PASSWORD
                                                ] && (
                                                <div class="invalidFeedback">
                                                    Password doesn't match.
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </form>
                        )}
                        {addUserState === ADD_USER_STAGES.PERMISSIONS && (
                            <form>
                                <table className="table setUserPermissions">
                                    <thead>
                                        <tr>
                                            <td>Lorem ipsum dolor sit amet</td>
                                            <td>View</td>
                                            <td>Manage</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Select All</td>
                                            <td width="10%">
                                                <div class="formCheck">
                                                    <input
                                                        class="formCheckInput"
                                                        type="checkbox"
                                                        name="flexRadioDefault"
                                                        id=""
                                                        checked={isSelectAllPermissionData(
                                                            PERMISSION_TYPE.VIEW
                                                        )}
                                                        onChange={e =>
                                                            handleSelectAllPermissionData(
                                                                PERMISSION_TYPE.VIEW,
                                                                e.target.checked
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </td>
                                            <td width="10%">
                                                <div class="formCheck">
                                                    <input
                                                        class="formCheckInput"
                                                        type="checkbox"
                                                        name="flexRadioDefault"
                                                        id=""
                                                        checked={isSelectAllPermissionData(
                                                            PERMISSION_TYPE.MANAGE
                                                        )}
                                                        onChange={e =>
                                                            handleSelectAllPermissionData(
                                                                PERMISSION_TYPE.MANAGE,
                                                                e.target.checked
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">
                                                <div className="border"></div>
                                            </td>
                                        </tr>

                                        {permissionData.map(
                                            (permissionObj, count) => {
                                                let heading = (
                                                    <thead>
                                                        <tr>
                                                            <td colSpan="3">
                                                                <td>
                                                                    {
                                                                        PERMISSION_NAME_MAPPING[
                                                                            permissionObj
                                                                                .name
                                                                        ]
                                                                    }
                                                                </td>
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                )
                                                let data =
                                                    permissionObj.permissionList.map(
                                                        permission => {
                                                            return (
                                                                <>
                                                                    <tr>
                                                                        <td>
                                                                            {
                                                                                PERMISSION_NAME_MAPPING[
                                                                                    permission
                                                                                        .name
                                                                                ]
                                                                            }
                                                                        </td>
                                                                        <td width="10%">
                                                                            <div class="formCheck">
                                                                                <input
                                                                                    class="formCheckInput"
                                                                                    type="checkbox"
                                                                                    name="flexRadioDefault"
                                                                                    id=""
                                                                                    checked={
                                                                                        permission?.permissionType ===
                                                                                        PERMISSION_TYPE.VIEW
                                                                                    }
                                                                                    onChange={e =>
                                                                                        handlePermissionData(
                                                                                            permissionObj.name,
                                                                                            permission.name,
                                                                                            PERMISSION_TYPE.VIEW,
                                                                                            e
                                                                                                .target
                                                                                                .checked
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                        <td width="10%">
                                                                            <div class="formCheck">
                                                                                <input
                                                                                    class="formCheckInput"
                                                                                    type="checkbox"
                                                                                    name="flexRadioDefault"
                                                                                    id=""
                                                                                    checked={
                                                                                        permission?.permissionType ===
                                                                                        PERMISSION_TYPE.MANAGE
                                                                                    }
                                                                                    onChange={e =>
                                                                                        handlePermissionData(
                                                                                            permissionObj.name,
                                                                                            permission.name,
                                                                                            PERMISSION_TYPE.MANAGE,
                                                                                            e
                                                                                                .target
                                                                                                .checked
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        }
                                                    )

                                                let gap = (
                                                    <tr>
                                                        <td colSpan="3">
                                                            <div className="border"></div>
                                                        </td>
                                                    </tr>
                                                )

                                                return (
                                                    <>
                                                        {heading}
                                                        {data}
                                                        {count + 1 !=
                                                            permissionData?.length &&
                                                            gap}
                                                    </>
                                                )
                                            }
                                        )}
                                        {/* <thead>
                                    <tr>
                                        <td colSpan='3'>
                                            <td>Permissions</td>
                                        </td>
                                    </tr>
                                </thead>
                                <tr>
                                    <td>Manage Users</td>
                                    <td width='10%'>
                                        <div class="formCheck">
                                            <input class="formCheckInput" type="checkbox" name="flexRadioDefault" id="" />
                                        </div>
                                    </td>
                                    <td width='10%'>
                                        <div class="formCheck">
                                            <input class="formCheckInput" type="checkbox" name="flexRadioDefault" id="" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan='3'>
                                        <div className='border'></div>
                                    </td>
                                </tr>
                                <thead>
                                    <tr>
                                        <td colSpan='3'>
                                            <td>Home Screen</td>
                                        </td>
                                    </tr>
                                </thead>
                                <tr>
                                    <td>Profit</td>
                                    <td width='10%'>
                                        <div class="formCheck">
                                            <input class="formCheckInput" type="checkbox" name="flexRadioDefault" id="" />
                                        </div>
                                    </td>
                                    <td width='10%'>
                                        <div class="formCheck">
                                            <input class="formCheckInput" type="checkbox" name="flexRadioDefault" id="" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan='3'>
                                        <div className='border'></div>
                                    </td>
                                </tr>
                                <thead>
                                    <tr>
                                        <td colSpan='3'>
                                            <td>Manage Store</td>
                                        </td>
                                    </tr>
                                </thead>
                                <tr>
                                    <td>Product Receiving</td>
                                    <td width='10%'>
                                        <div class="formCheck">
                                            <input class="formCheckInput" type="checkbox" name="flexRadioDefault" id="" />
                                        </div>
                                    </td>
                                    <td width='10%'>
                                        <div class="formCheck">
                                            <input class="formCheckInput" type="checkbox" name="flexRadioDefault" id="" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Sales</td>
                                    <td width='10%'>
                                        <div class="formCheck">
                                            <input class="formCheckInput" type="checkbox" name="flexRadioDefault" id="" />
                                        </div>
                                    </td>
                                    <td width='10%'>
                                        <div class="formCheck">
                                            <input class="formCheckInput" type="checkbox" name="flexRadioDefault" id="" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Exchange/Return</td>
                                    <td width='10%'>
                                        <div class="formCheck">
                                            <input class="formCheckInput" type="checkbox" name="flexRadioDefault" id="" />
                                        </div>
                                    </td>
                                    <td width='10%'>
                                        <div class="formCheck">
                                            <input class="formCheckInput" type="checkbox" name="flexRadioDefault" id="" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Inventory</td>
                                    <td width='10%'>
                                        <div class="formCheck">
                                            <input class="formCheckInput" type="checkbox" name="flexRadioDefault" id="" />
                                        </div>
                                    </td>
                                    <td width='10%'>
                                        <div class="formCheck">
                                            <input class="formCheckInput" type="checkbox" name="flexRadioDefault" id="" />
                                        </div>
                                    </td>
                                </tr> */}
                                    </tbody>
                                </table>
                            </form>
                        )}
                    </div>
                </SideDrawer>
            )}
        </>
        // </div >
    )
}

const mapStateToProps = state => {
    return {
        alert: state.alertReducer.alert,
        loginData: state.loginDataReducer?.loginData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addUserAlertAction: payload => dispatch(addUserAlertAction(payload)),
        deleteUserAlertAction: payload =>
            dispatch(deleteUserAlertAction(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers)
