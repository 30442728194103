import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { WIZARD_STEPS } from '../../common/constant/common-constant'

function WizardSteps(props) {
    useEffect(props => {
        // inprogress
        //
    })

    const getWizardStateClass = step => {
        switch (step) {
            case WIZARD_STEPS.STEP_1: {
                if (props.wizardState?.[WIZARD_STEPS.STEP_1]) return 'completed'
                else return 'inprogress'
            }
            case WIZARD_STEPS.STEP_2: {
                if (props.wizardState?.[WIZARD_STEPS.STEP_2]) return 'completed'
                else if (props.wizardState?.[WIZARD_STEPS.STEP_1])
                    return 'inprogress'
                else return ''
            }
            case WIZARD_STEPS.STEP_3: {
                if (props.wizardState?.[WIZARD_STEPS.STEP_3]) return 'completed'
                else if (props.wizardState?.[WIZARD_STEPS.STEP_2])
                    return 'inprogress'
                else return ''
            }
            default:
                return ''
        }
    }

    return (
        <ul className="WizardStepsWrap">
            <li
                className={`wizardStep ${getWizardStateClass(
                    WIZARD_STEPS.STEP_1
                )}`}
                onClick={() => {
                    if (
                        props.onStepClick &&
                        getWizardStateClass(WIZARD_STEPS.STEP_1) === 'completed'
                    )
                        props.onStepClick(WIZARD_STEPS.STEP_1)
                }}>
                <div className="circle">1</div>
            </li>
            <li
                className={`wizardStep ${getWizardStateClass(
                    WIZARD_STEPS.STEP_2
                )}`}
                onClick={() => {
                    if (
                        props.onStepClick &&
                        getWizardStateClass(WIZARD_STEPS.STEP_2) === 'completed'
                    )
                        props.onStepClick(WIZARD_STEPS.STEP_2)
                }}>
                <div className="circle">2</div>
            </li>
            <li
                className={`wizardStep ${getWizardStateClass(
                    WIZARD_STEPS.STEP_3
                )}`}
                onClick={() => {
                    if (
                        props.onStepClick &&
                        getWizardStateClass(WIZARD_STEPS.STEP_3) === 'completed'
                    )
                        props.onStepClick(WIZARD_STEPS.STEP_3)
                }}>
                <div className="circle">3</div>
            </li>
        </ul>
    )
}

WizardSteps.propTypes = {
    wizardState: PropTypes.any.isRequired
}

export default WizardSteps
