import React from 'react'

const EmailIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Mail">
            <path id="Vector" d="M4.37484 3.33301H15.6248C16.3166 3.33296 16.9822 3.59764 17.485 4.07273C17.9879 4.54783 18.2898 5.19734 18.329 5.88801L18.3332 6.04134V13.958C18.3332 14.6498 18.0685 15.3154 17.5934 15.8182C17.1183 16.321 16.4688 16.623 15.7782 16.6622L15.6248 16.6663H4.37484C3.68306 16.6664 3.01748 16.4017 2.51464 15.9266C2.01181 15.4515 1.70984 14.802 1.67067 14.1113L1.6665 13.958V6.04134C1.66646 5.34956 1.93113 4.68398 2.40623 4.18115C2.88133 3.67831 3.53083 3.37634 4.2215 3.33717L4.37484 3.33301ZM17.0832 7.81051L10.2915 11.3855C10.2148 11.426 10.1305 11.4501 10.044 11.4563C9.95751 11.4625 9.87066 11.4506 9.789 11.4213L9.709 11.3863L2.9165 7.81134V13.958C2.91652 14.324 3.05415 14.6766 3.30207 14.9458C3.54999 15.2151 3.89009 15.3812 4.25484 15.4113L4.37484 15.4163H15.6248C15.991 15.4163 16.3437 15.2786 16.6129 15.0305C16.8822 14.7824 17.0483 14.4421 17.0782 14.0772L17.0832 13.958V7.81051ZM15.6248 4.58301H4.37484C4.00885 4.58302 3.65624 4.72065 3.38701 4.96857C3.11778 5.2165 2.95162 5.55659 2.9215 5.92134L2.9165 6.04134V6.39884L9.99984 10.1263L17.0832 6.39801V6.04134C17.0831 5.67522 16.9454 5.3225 16.6973 5.05325C16.4492 4.784 16.1089 4.61793 15.744 4.58801L15.6248 4.58301Z" fill="#212121" />
        </g>
    </svg>
)

export default EmailIcon
