import { Types } from '../actions/types'
import initialState from '../store/initial.state'

export const configReducer = (state = initialState.configReducer, action) => {
    switch (action.type) {
        case Types.ACTIVE_HOME_DETAIL_NAVIGATION: {
            let activeHomeDetailNavigation = action.payload
            return {
                ...state,
                config: {
                    ...state.config,
                    activeHomeDetailNavigation: activeHomeDetailNavigation
                }
            }
        }
        case Types.ACTIVE_PROFILE_DETAIL_NAVIGATION: {
            let activeProfileDetailNavigation = action.payload
            return {
                ...state,
                config: {
                    ...state.config,
                    activeProfileDetailNavigation: activeProfileDetailNavigation
                }
            }
        }
        case Types.IS_NOTIFICATION_ACTIVE: {
            let isNotificationActive = action.payload
            return {
                ...state,
                config: {
                    ...state.config,
                    isNotificationActive: isNotificationActive
                }
            }
        }
        default:
            return state
    }
}
