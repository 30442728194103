import React from 'react'

const UserFillIcon = () => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="User">
            <path id="Vector" d="M20.7142 16.333C21.0588 16.3332 21.4 16.4012 21.7182 16.5332C22.0365 16.6652 22.3257 16.8586 22.5692 17.1024C22.8127 17.3461 23.0059 17.6355 23.1376 17.9538C23.2693 18.2722 23.337 18.6135 23.3369 18.958V20.029C23.3369 20.6975 23.128 21.3493 22.7396 21.8934C20.9359 24.418 17.9912 25.6675 14.0012 25.6675C10.0089 25.6675 7.06539 24.4169 5.26639 21.8922C4.8792 21.3485 4.67121 20.6976 4.67139 20.0302V18.9569C4.67139 18.261 4.94783 17.5936 5.43989 17.1015C5.93195 16.6095 6.59934 16.333 7.29522 16.333H20.7142ZM14.0001 2.33887C14.7661 2.33887 15.5246 2.48975 16.2324 2.7829C16.9401 3.07606 17.5832 3.50574 18.1248 4.04741C18.6665 4.58909 19.0962 5.23215 19.3894 5.93988C19.6825 6.64761 19.8334 7.40616 19.8334 8.1722C19.8334 8.93824 19.6825 9.69679 19.3894 10.4045C19.0962 11.1123 18.6665 11.7553 18.1248 12.297C17.5832 12.8387 16.9401 13.2683 16.2324 13.5615C15.5246 13.8546 14.7661 14.0055 14.0001 14.0055C12.453 14.0055 10.9692 13.391 9.87526 12.297C8.7813 11.203 8.16672 9.7193 8.16672 8.1722C8.16672 6.6251 8.7813 5.14137 9.87526 4.04741C10.9692 2.95345 12.453 2.33887 14.0001 2.33887Z" fill="#212121" />
        </g>
    </svg>
)

export default UserFillIcon
