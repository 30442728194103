import React from 'react'

const DamageLossBlack = () => (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="fluent:box-24-regular">
            <path id="Vector" d="M28.2428 6.70147C30.6525 5.72451 33.3479 5.72451 35.7575 6.70147L55.7522 14.8081C56.6134 15.157 57.3509 15.755 57.8702 16.5256C58.3894 17.2962 58.6668 18.2043 58.6668 19.1335V44.8748C58.6663 45.8036 58.3887 46.711 57.8694 47.4811C57.3502 48.2512 56.613 48.8488 55.7522 49.1975L35.7575 57.3041C33.3479 58.2811 30.6525 58.2811 28.2428 57.3041L8.24816 49.1975C7.38692 48.8486 6.64942 48.2506 6.13016 47.48C5.61089 46.7094 5.33349 45.8014 5.3335 44.8721V19.1361C5.33349 18.2069 5.61089 17.2989 6.13016 16.5283C6.64942 15.7577 7.38692 15.1597 8.24816 14.8108L28.2428 6.70147ZM34.2535 10.4081C32.8083 9.82254 31.192 9.82254 29.7468 10.4081L24.6402 12.4801L44.7122 20.2855L51.8295 17.5388L34.2508 10.4108L34.2535 10.4081ZM39.1762 22.4215L19.2402 14.6668L12.2402 17.5041L32.0028 25.1895L39.1762 22.4215ZM9.3335 44.8748C9.33384 45.0072 9.37359 45.1365 9.44768 45.2462C9.52176 45.3559 9.62683 45.441 9.7495 45.4908L29.7468 53.5975C29.8295 53.6321 29.9148 53.6641 30.0002 53.6935V28.7041L9.3335 20.6668V44.8748ZM34.2535 53.5975L54.2508 45.4908C54.3739 45.4409 54.4793 45.3553 54.5534 45.2451C54.6275 45.1348 54.667 45.005 54.6668 44.8721V20.7308L34.0002 28.7095V53.6961C34.0853 53.6661 34.1698 53.6314 34.2535 53.5975Z" fill="#212121" />
            <path id="Vector 3" d="M22.3116 43.9779L20.168 46.691C19.9627 46.9508 19.5498 46.7355 19.6453 46.4185L21.7654 39.3824C21.84 39.1351 22.1714 39.0913 22.3076 39.3108L23.7216 41.5905C23.8686 41.8274 24.2311 41.7532 24.2731 41.4776L24.5824 39.4498C24.6074 39.286 24.7604 39.1735 24.9242 39.1984L26.9315 39.5046C27.1202 39.5334 27.2875 39.3809 27.2762 39.1904L26.9527 33.7023C26.9382 33.4548 27.213 33.2974 27.419 33.4353L32.9039 37.1045C33.0262 37.1863 33.1898 37.1674 33.2901 37.0598L39.3823 30.5264C39.5171 30.3818 39.7525 30.4048 39.8567 30.5728L43.8924 37.0792C43.974 37.2108 44.1423 37.2587 44.2809 37.1898L49.8624 34.415C50.1644 34.2649 50.4461 34.6502 50.2114 34.8924L43.5882 41.7281C43.4523 41.8683 43.2207 41.8434 43.1178 41.6774L40.3398 37.1987C40.2108 36.9908 39.9006 37.0164 39.8074 37.2426L35.7962 46.9856C35.7364 47.1309 35.5746 47.2054 35.4253 47.1564L26.8065 44.3271C26.6749 44.2839 26.5309 44.3364 26.4579 44.4541L25.0247 46.7671C24.9075 46.9564 24.6321 46.9565 24.5148 46.7673L22.802 44.0058C22.6923 43.8291 22.4405 43.8148 22.3116 43.9779Z" fill="#212121" />
        </g>
    </svg>
)

export default DamageLossBlack
