import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import Select from 'react-select'

import './ProductReceiving.scss'
import { redirect } from '../../common/utils/redirectionUtils'
import {
    ADD_PRODUCT_RECEIVING_STAGES,
    BILL_DETAILS_FIELDS,
    PAGE_CONST,
    PAYMENT_DETAILS_FIELDS,
    PERMISSION_FEATURE,
    PERMISSION_SCREEN,
    PERMISSION_TYPE,
    PRODUCT_DETAIL_FIELDS
} from '../../common/constant/page-constant'
import Card from '../Common/Card/Card'
import SideDrawer from '../Common/SideDrawer/SideDrawer'
import TrashIcon from '../../assets/TrashIcon'
import ArrowDown from '../../assets/ArrowDown'
import AddProduct from './AddProduct'
import ViewProduct from './ViewProduct'
import WizardSteps from '../../components/WizardSteps/WizardSteps'
import {
    HEADER_CONFIG,
    HEADER_CONST,
    WIZARD_STEPS
} from '../../common/constant/common-constant'
import AddIcon from '../../assets/AddIcon'
import CloseIcon from '../../assets/CloseIcon'
import Alert from '../../components/Alert/Alert'
import {
    addProductReceivingHelper,
    getInvoiceDetailsHelper,
    getMedicalCategoryHelper,
    getProductDataHelper,
    getProductReceivingHelper,
    getProductsHelper,
    getSuppliersHelper,
    updateProductDataHelper,
    updateSuppliersHelper
} from '../../helper/home-helper'
import { addProductReceivingAlertAction } from '../../modules/actions/action'
import ProductReceivingBlack from '../../assets/ProductReceivingBlack'
import ViewInventory from '../ManageInventory/ViewInventory'
import Header from '../Common/Header/Header'
import MobileHeader from '../Common/Header/MobileHeader'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import CheckPrimaryIcon from '../../assets/CheckPrimaryIcon'
import AcknowledgeScreen from '../Common/AcknowledgeScreen/AcknowledgeScreen'
import PopupCard from '../Common/PopupCard/PopupCard'
import AddProductReceiving from './AddProductReceiving'

function ProductReceiving(props) {
    const navigate = useNavigate()
    const isMobile = useCheckMobileScreen()

    const [viewDrawer, setViewDrawer] = useState(false)
    const [open, setOpen] = useState(false)
    const [isViewInventoryActive, setViewInventoryActive] = useState(false)
    const [addProductReceivingAlert, setAddProductReceivingAlert] =
        useState(false)
    const [productReceivingData, setProductReceivingData] = useState(undefined)
    const [stats, setStats] = useState({})
    const [invoiceData, setInvoiceData] = useState(undefined)
    const [headerOptions, setHeaderOptions] = useState({
        [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
        [HEADER_CONFIG.TITLE]: HEADER_CONST.PRODUCT_RECEIVING,
        [HEADER_CONFIG.HANDLE_BACK_FUNC]: () =>
            redirect(navigate, PAGE_CONST.HOME)
    })
    const [loginData, setLoginData] = useState({})
    const [permissions, setPermissions] = useState({})
    const [serverError, setServerError] = useState(undefined)

    useEffect(() => {
        let addProductReceivingAlert = props.alert?.addProductReceivingAlert
        setAddProductReceivingAlert(addProductReceivingAlert)
    }, [props.alert?.addProductReceivingAlert])

    useEffect(() => {
        setLoginData(props.loginData)

        if (props.loginData.permissions) {
            console.log(
                'props.loginData.permissions: ',
                props.loginData.permissions
            )

            let permission = props.loginData.permissions.filter(
                permission =>
                    permission.screen === PERMISSION_SCREEN.MANAGE_STORE
            )[0]

            console.log('home screen permission: ', permission)
            if (permission) setPermissions(permission)
        }
    }, [props.loginData])

    useEffect(() => {
        console.log('open use effect !!', open, isViewInventoryActive)
        if (!open && !isViewInventoryActive) {
            handleHeaderConfigUpdate(HEADER_CONST.PRODUCT_RECEIVING, () =>
                redirect(navigate, PAGE_CONST.HOME)
            )
            return
        }

        if (open) {
            handleHeaderConfigUpdate(HEADER_CONST.ADD_PRODUCT_RECEIVING, () =>
                handleDrawerToggle()
            )
        } else if (isViewInventoryActive) {
            handleHeaderConfigUpdate(HEADER_CONST.VIEW_PRODUCT_RECEIVING, () =>
                setViewInventoryActive(!isViewInventoryActive)
            )
        }
    }, [open, isViewInventoryActive])

    // useEffect(() => {
    //     console.log('isViewInventoryActive useEffect !!', isViewInventoryActive)
    //     if (!isViewInventoryActive) {
    //         handleHeaderConfigUpdate(HEADER_CONST.PRODUCT_RECEIVING, () =>
    //             redirect(navigate, PAGE_CONST.HOME)
    //         )
    //         return
    //     }

    // }, [isViewInventoryActive])

    useEffect(() => {
        getProductReceiving()
    }, [])

    const handleHeaderConfigUpdate = (title, handleBackFunc) => {
        setHeaderOptions({
            [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
            [HEADER_CONFIG.TITLE]: title,
            [HEADER_CONFIG.HANDLE_BACK_FUNC]: () => handleBackFunc()
        })
    }

    const getProductReceiving = () => {
        getProductReceivingHelper()
            .then(res => {
                let pReceivings = res.product_receivings
                console.log('res prd: ', res)
                setStats(res.stats)
                setProductReceivingData(pReceivings)
            })
            .catch(err => {
                console.log('err prd: ', err)
            })
    }

    const handleSubmit = async data => {
        setServerError(undefined)

        try {
            let res = await addProductReceivingHelper(data)
            console.log(res)

            props.addProductReceivingAlertAction(true)
            setTimeout(() => {
                props.addProductReceivingAlertAction(false)
            }, 5000)
            getProductReceiving()
            handleDrawerToggle()
        } catch (errMsg) {
            setServerError(errMsg)
        }
    }

    const getInvoiceDetails = id => {
        console.log('invoice details: ', id)
        getInvoiceDetailsHelper(id)
            .then(res => {
                console.log('id res: ', res)
                setInvoiceData(res)
                setViewInventoryActive(true)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleDrawerToggle = () => {
        console.log('in drawer toggle !!, ', viewDrawer, open)
        setViewDrawer(!viewDrawer)
        setOpen(!open)
    }

    return (
        <>
            {isMobile && <MobileHeader headerOptions={headerOptions} />}
            {/* {addProductReceivingAlert && <Alert success alertType='success' alertMessage='New Product Receiving added Successfully!' />} */}
            {addProductReceivingAlert && (
                <AcknowledgeScreen
                    icon={<CheckPrimaryIcon />}
                    alertType="success"
                    alertMessage="New Product Receiving added Successfully!"
                    main="New Product Receiving added Successfully!"
                    sub="Lorem ipsum dolor sit amet consectetur. Ligula ut volutpat morbi ullamcorper"
                />
            )}
            <div className="pageBodyRightPanel">
                <div className="addProductBtnMobile dFlex aiCenter mwebVisible fixed">
                    <button
                        className="animated zoomIn btn btnPrimary"
                        onClick={handleDrawerToggle}>
                        <AddIcon />
                    </button>
                </div>
                <div className="pageBodyRightPanelHeader dFlex jcSpaceBetween aiCenter webVisible mb-5">
                    <div className="pageBodyRightPanelHeading">
                        Product Receiving
                    </div>
                    <div className="addProductBtn dFlex aiCenter">
                        {permissions?.features?.[
                            PERMISSION_FEATURE.PRODUCT_RECEIVING
                        ] === PERMISSION_TYPE.MANAGE && (
                            <button
                                className="btn btnPrimary"
                                onClick={handleDrawerToggle}>
                                ADD
                            </button>
                        )}
                        <div
                            className="pointer ml-4"
                            onClick={() => props.onClose()}>
                            <CloseIcon />
                        </div>
                    </div>
                </div>
                <div className="consolidateStrip dFlex jcSpaceBetween aiCenter">
                    <div className="consolidateDataIcon dFlex">
                        <ProductReceivingBlack />
                    </div>
                    <div className="consolidateDataText">
                        Purchase({stats?.month_name}) <small>BDT</small>
                    </div>
                    <div className="consolidateData">
                        {stats?.total_amount?.total_amount}
                    </div>
                </div>
                <div className="productLlistWrap dFlex">
                    {productReceivingData &&
                        productReceivingData.map(productReceiving => {
                            return (
                                <div className="cardWrap">
                                    <Card
                                        // product
                                        // productDesc
                                        headerSup={
                                            productReceiving.date_of_bill
                                        }
                                        headerMain={
                                            productReceiving.invoice_number
                                        }
                                        sales={`Pharma Company : ${productReceiving.supplier_name}`}
                                        cardRight={`BDT ${Number(
                                            parseFloat(
                                                productReceiving.total_amount
                                            ).toFixed(2)
                                        )}`}
                                        handleClick={() =>
                                            getInvoiceDetails(
                                                productReceiving.id
                                            )
                                        }
                                    />
                                </div>
                            )
                        })}
                </div>
            </div>
            {/* <ViewProduct open={isViewProductActive} handleDrawerToggle={() => setViewProductActive(!isViewProductActive)} /> */}

            {open && (
                <AddProductReceiving
                    open={open}
                    handleDrawerToggle={handleDrawerToggle}
                    handleSubmit={handleSubmit}
                    serverError={serverError}
                />
            )}

            {/* <ViewProduct handleDrawerToggle={handleDrawerToggle} open={open} /> */}
            <ViewInventory
                open={isViewInventoryActive}
                handleDrawerToggle={() =>
                    setViewInventoryActive(!isViewInventoryActive)
                }
                invoiceData={invoiceData}
            />
        </>
    )
}

const mapStateToProps = state => {
    return {
        alert: state.alertReducer.alert,
        loginData: state.loginDataReducer?.loginData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addProductReceivingAlertAction: payload =>
            dispatch(addProductReceivingAlertAction(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductReceiving)
