import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { OTP_CONST, PAGE_CONST } from '../../common/constant/page-constant'
import { redirect } from '../../common/utils/redirectionUtils'
import {
    sendOTPHelper,
    validateOTPHelper
} from '../../helper/create-account-helper'
import LoginLeft from '../Login/LoginLeft'

import './OtpVerification.scss'

const OtpVerification = props => {
    const navigate = useNavigate()

    const [flow, setFlow] = useState(OTP_CONST.SEND_OTP)
    const [otp, setOtp] = useState(['', '', '', ''])
    const [phoneNumber, setPhoneNumber] = useState(undefined)
    const [isFieldErrorVisible, setIsFieldErrorVisible] = useState(false)
    const [serverError, setServerError] = useState(undefined)
    const otpInputs = useRef([])

    useEffect(() => {
        if (props?.loginData?.phoneNumber)
            setPhoneNumber(props.loginData.phoneNumber)
    }, [props?.loginData])

    const handleSendOTP = () => {
        setServerError(undefined)
        sendOTPHelper()
            .then(res => {
                setFlow(OTP_CONST.VERIFY_OTP)
                console.log(res)
            })
            .catch(err => {
                setServerError(err)
                console.log(err)
            })
    }

    const handleVerifyOTP = () => {
        const otpValue = otp.join('')
        if (otpValue.length !== 4) {
            setIsFieldErrorVisible(true)
            return
        }

        validateOTPHelper(otpValue)
            .then(res => {
                if (!res.user?.is_verified) {
                    redirect(navigate, PAGE_CONST.OTP_VERIFICATION)
                } else if (!res.pharmacy.is_verified) {
                    redirect(navigate, PAGE_CONST.DOCUMENT_UPLOAD)
                } else if (res.user?.is_verified && res.pharmacy?.is_verified) {
                    redirect(navigate, PAGE_CONST.HOME)
                } else {
                    // some Error msg
                }
            })
            .catch(err => {
                setServerError(err)
            })
    }

    const handleInputChange = (index, value) => {
        setServerError(undefined)

        const updatedOtp = [...otp]
        updatedOtp[index] = value

        setOtp(updatedOtp)

        if (value && index < 3) {
            otpInputs.current[index + 1].focus()
        }
    }

    const handleInputBackspace = (index, event) => {
        if (event.keyCode === 8 && !otp[index] && index > 0) {
            otpInputs.current[index - 1].focus()
        }
    }

    const handleSubmit = () => {
        if (flow === OTP_CONST.SEND_OTP) {
            handleSendOTP()
        } else if (flow === OTP_CONST.VERIFY_OTP) {
            handleVerifyOTP()
        }
    }

    const handleResendOTP = () => {
        handleSendOTP()
    }

    return (
        <main className="loginPage otpPage dFlex jcSpaceBetween flexNowrap">
            <LoginLeft />
            <div className="loginRight">
                <div className="loginRightControl">
                    <div className="login-content">
                        {flow === OTP_CONST.SEND_OTP && (
                            <>
                                <div className="welcomeHeading">Send OTP</div>
                                <div className="welcomeDesc">
                                    {`The OTP will be sent to your Mobile Number: ${phoneNumber}`}
                                    .{/* <span>CHANGE NUMBER</span> */}
                                </div>
                            </>
                        )}
                        {flow === OTP_CONST.VERIFY_OTP && (
                            <>
                                <div className="welcomeHeading">Verify OTP</div>
                                <div className="welcomeDesc">
                                    {`The OTP has been sent to your Mobile Number: ${phoneNumber}`}
                                    .{/* <span>CHANGE NUMBER</span> */}
                                </div>
                            </>
                        )}
                    </div>
                    <form onSubmit={e => e.preventDefault()}>
                        {flow === OTP_CONST.VERIFY_OTP && (
                            <>
                                <div className="formInline dFlex flexNowrap">
                                    {Array.from({ length: 4 }).map(
                                        (_, index) => (
                                            <div
                                                className="formGroup ml-4 otpInput"
                                                key={index}>
                                                <input
                                                    type="text"
                                                    className="formControl"
                                                    maxLength="1"
                                                    placeholder="0"
                                                    onChange={e =>
                                                        handleInputChange(
                                                            index,
                                                            e.target.value
                                                        )
                                                    }
                                                    onKeyDown={e =>
                                                        handleInputBackspace(
                                                            index,
                                                            e
                                                        )
                                                    }
                                                    ref={input =>
                                                        (otpInputs.current[
                                                            index
                                                        ] = input)
                                                    }
                                                    value={otp[index]}
                                                    required
                                                />
                                            </div>
                                        )
                                    )}
                                </div>

                                <p className="otpInfo">
                                    OTP is valid for 2 mins
                                </p>

                                {isFieldErrorVisible &&
                                    !otp.join('').trim() && (
                                        <div class="invalidFeedback">
                                            Please enter a valid OTP
                                        </div>
                                    )}
                                <div class="approvedFeedback strong">
                                    VERIFIED
                                </div>
                                <div
                                    className="createAccountWrap"
                                    onClick={handleResendOTP}>
                                    <div className="createAccount">
                                        RESEND OTP
                                    </div>
                                </div>
                            </>
                        )}
                        {serverError && (
                            <div class="invalidFeedback mb-4">
                                {serverError}
                            </div>
                        )}
                        <button
                            className="btn btnPrimary block space"
                            onClick={handleSubmit}>
                            Submit
                        </button>
                    </form>

                    <div className="loginFooter"></div>
                </div>
            </div>
        </main>
    )
}

const mapStateToProps = state => {
    return {
        loginData: state.loginDataReducer.loginData
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(OtpVerification)
