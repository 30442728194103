import {
    addProductReceivingAPI,
    addUserAPI,
    getProductReceivingAPI,
    getProductsAPI,
    getUserAPI,
    addCustomerAPI,
    getProductDetailsByBarcodeAPI,
    addDamageLossAdjustmentAPI,
    getDamageLossAdjustmentAPI,
    addInventoryDetailsAPI,
    getInventoryDetailsAPI,
    getProductDetailsAPI,
    getInvoiceDetailsAPI,
    getCustomersAPI,
    getCustomerAPI,
    getAllCustomerAPI,
    getAllCustomersAPI,
    getAllSalesAPI,
    getSalesAPI,
    createSalesAPI,
    getSalesOrderByInvoiceNumberAPI,
    createExchangeReturnAPI,
    getExchangeReturnAPI,
    getExchangeReturnOrdersAPI,
    getExchangeReturnOrdersByIdAPI,
    getAllExchangeReturnOrdersAPI,
    getSuppliersAPI,
    getProductDataAPI,
    updateProductDataAPI,
    getMedicalCategoryAPI,
    updateSuppliersAPI,
    updateSalesAPI,
    getExpiredProductsAPI,
    returnExpiredProductsAPI,
    getExpiredProductDetailAPI,
    getExpiredProductDetailsAPI,
    confirmOnlineSalesOrderAPI,
    updateUserAPI,
    getStatisticsAPI,
    getStocksAndBarchartDataAPI,
    updatePrescriptionOrderAPI,
    getCampaignInventoryDetailsAPI,
    addSuppliersAPI,
    getStoreStatusAPI,
    getReportAPI,
    downloadReportExcelAPI,
    downloadReportPdfAPI
} from '../adapter/adapter'
import {
    CUSTOMER_FIELDS,
    DAMAGE_LOSS_FIELDS
} from '../common/constant/page-constant'

export function getSuppliersHelper() {
    let promise = new Promise(function (resolve, reject) {
        getSuppliersAPI()
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data) {
                    resolve(res.data.data)
                } else {
                    reject('get products Failure')
                }
            })
            .catch(err => {
                reject('get products Failure')
            })
    })

    return promise
}

export function getExpiredProductsHelper() {
    let promise = new Promise(function (resolve, reject) {
        getExpiredProductsAPI()
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data) {
                    resolve(res.data.data)
                } else {
                    reject('get expired products Failure')
                }
            })
            .catch(err => {
                reject('get expired products Failure')
            })
    })

    return promise
}

export function getExpiredProductDetailsHelper(data) {
    // let reqData = {
    //     product_receiving_detail_id: id
    // }

    let promise = new Promise(function (resolve, reject) {
        getExpiredProductDetailsAPI(data)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data) {
                    resolve(res.data.data)
                } else {
                    reject('get expired products Failure')
                }
            })
            .catch(err => {
                reject('get expired products Failure')
            })
    })

    return promise
}

export function returnExpiredProductsHelper(data) {
    let reqData = {
        expired_product_id: data?.id,
        return_loss: data.returnLoss,
        amount_receivable: data.amountReceivable,
        type: data.type,
        notes: data.notes
    }

    let promise = new Promise(function (resolve, reject) {
        returnExpiredProductsAPI(reqData)
            .then(res => {
                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data) {
                    resolve(res.data.data)
                } else {
                    reject('get expired products Failure')
                }
            })
            .catch(err => {
                reject('get expired products Failure')
            })
    })

    return promise
}

export function updateSuppliersHelper(data) {
    let promise = new Promise(function (resolve, reject) {
        updateSuppliersAPI(data)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (
                    res?.data?.data &&
                    res?.data?.data?.is_supplier_details_updated
                ) {
                    resolve(res.data.data?.is_supplier_details_updated)
                } else {
                    reject('Update Supplier Failure')
                }
            })
            .catch(err => {
                reject('Update Supplier Failure')
            })
    })

    return promise
}

export function addSuppliersHelper(data) {
    let promise = new Promise(function (resolve, reject) {
        addSuppliersAPI(data)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (
                    res?.data?.data &&
                    res?.data?.data?.is_supplier_added
                ) {
                    resolve(res.data.data?.supplier_id)
                } else {
                    reject('Add Supplier Failure')
                }
            })
            .catch(err => {
                reject('Add Supplier Failure')
            })
    })

    return promise
}

export function getProductsHelper(data) {
    let reqData = data

    console.log(reqData)

    let promise = new Promise(function (resolve, reject) {
        getProductsAPI(reqData)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data?.products) {
                    resolve(res.data.data.products)
                } else {
                    reject('get products Failure')
                }
            })
            .catch(err => {
                reject('get products Failure')
            })
    })

    return promise
}

export function getProductDataHelper(data) {
    let reqData = data

    console.log(reqData)

    let promise = new Promise(function (resolve, reject) {
        getProductDataAPI(reqData)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data) {
                    resolve(res.data.data)
                } else {
                    reject('get products Failure')
                }
            })
            .catch(err => {
                reject('get products Failure')
            })
    })

    return promise
}

export function getMedicalCategoryHelper() {
    let promise = new Promise(function (resolve, reject) {
        getMedicalCategoryAPI()
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data?.categories) {
                    resolve(res.data.data?.categories)
                } else {
                    reject('get products Failure')
                }
            })
            .catch(err => {
                reject('get products Failure')
            })
    })

    return promise
}

export function updateProductDataHelper(data) {
    let reqData = data

    console.log(reqData)

    let promise = new Promise(function (resolve, reject) {
        updateProductDataAPI(reqData)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data) {
                    resolve(res.data.data)
                } else {
                    reject('Update products Failure !!')
                }
            })
            .catch(err => {
                reject('Update products Failure !!')
            })
    })

    return promise
}

export function addProductReceivingHelper(data) {
    let reqData = data

    console.log(reqData)

    let promise = new Promise(function (resolve, reject) {
        addProductReceivingAPI(reqData)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (
                    res?.data?.data &&
                    res?.data?.data?.is_product_receiving_added
                ) {
                    resolve()
                } else {
                    reject('Add Product Receiving Failure')
                }
            })
            .catch(err => {
                reject('Add Product Receiving Failure')
            })
    })

    return promise
}

export function getProductReceivingHelper() {
    let promise = new Promise(function (resolve, reject) {
        getProductReceivingAPI()
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data) {
                    resolve(res.data.data)
                } else {
                    reject('addProductReceiving Failure')
                }
            })
            .catch(err => {
                reject('addProductReceiving Failure')
            })
    })

    return promise
}

export function getUserHelper() {
    let promise = new Promise(function (resolve, reject) {
        getUserAPI()
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data?.users) {
                    resolve(res.data.data.users)
                } else {
                    reject('Add User Failure')
                }
            })
            .catch(err => {
                reject('Add User Failure')
            })
    })

    return promise
}

export function addUserHelper(data) {
    let reqData = {
        userData: {
            name: data.userData?.name,
            email: data.userData?.email,
            mobile_no: data.userData?.mobileNumber,
            password: data.userData?.password,
            branch_id: data.userData?.branchId
        },
        permissionData: data.permissionData
    }

    console.log(reqData)

    let promise = new Promise(function (resolve, reject) {
        addUserAPI(reqData)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data?.is_user_added) {
                    resolve()
                } else {
                    reject('Add User Failure !!')
                }
            })
            .catch(err => {
                reject('Add User Failure !!')
            })
    })

    return promise
}

export function updateUserHelper(data, action) {
    let reqData = {
        action: action,
        userData: {
            user_id: data.userData?.id,
            name: data.userData?.name,
            email: data.userData?.email,
            mobile_no: data.userData?.mobileNumber,
            password: data.userData?.password,
            branch_id: data.userData?.branchId
        },
        permissionData: data?.permissionData
    }

    console.log(reqData)

    let promise = new Promise(function (resolve, reject) {
        updateUserAPI(reqData)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data?.is_user_added) {
                    resolve()
                } else {
                    reject('Update User Failure !!')
                }
            })
            .catch(err => {
                reject('Update User Failure !!')
            })
    })

    return promise
}

export function getAllCustomersHelper() {
    let promise = new Promise(function (resolve, reject) {
        getAllCustomersAPI()
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data?.customers) {
                    resolve(res.data.data.customers)
                } else {
                    reject('Add User Failure')
                }
            })
            .catch(err => {
                reject('Add User Failure')
            })
    })

    return promise
}

export function getCustomerHelper(data) {
    let reqData = {
        mobile_no: data[CUSTOMER_FIELDS.PHONE_NUMBER]
    }

    console.log(reqData)

    let promise = new Promise(function (resolve, reject) {
        getCustomerAPI(reqData)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data?.customer) {
                    let customer = res.data.data.customer
                    let customerDetails = {
                        id: customer.id,
                        [CUSTOMER_FIELDS.NAME]: customer.name,
                        [CUSTOMER_FIELDS.PHONE_NUMBER]: customer.mobile_no,
                        [CUSTOMER_FIELDS.BILLING_ADDRESS]:
                            customer.billing_address,
                        [CUSTOMER_FIELDS.SHIPPING_ADDRESS]:
                            customer.shipping_address
                    }
                    resolve(customerDetails)
                } else {
                    reject('Get Customer Failure')
                }
            })
            .catch(err => {
                reject('Get Customer Failure')
            })
    })

    return promise
}

export function addCustomerHelper(data) {
    let reqData = {
        name: data?.name,
        mobile_no: data?.mobileNumber,
        shipping_address: data?.shippingAddress,
        billing_address: data?.billingAddress
    }

    console.log(reqData)

    let promise = new Promise(function (resolve, reject) {
        addCustomerAPI(reqData)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (
                    res?.data?.data &&
                    res?.data?.data?.is_customer_added
                ) {
                    resolve({
                        id: res.data.data.customer_id
                    })
                } else {
                    reject('Add Customer Failure !!')
                }
            })
            .catch(err => {
                reject('Add Customer Failure !!')
            })
    })

    return promise
}

export function getProductDetailsByBarcodeHelper(barcode) {
    let reqData = {
        barcode: barcode
    }

    let promise = new Promise(function (resolve, reject) {
        getProductDetailsByBarcodeAPI(reqData)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data?.product_detail) {
                    resolve(res.data.data.product_detail)
                } else {
                    reject('Get Product Details By Barcode Failure !!')
                }
            })
            .catch(err => {
                reject('Get Product Details By Barcode Failure !!')
            })
    })

    return promise
}

export function addDamageLossAdjustmentHelper(data) {
    let reqData = {
        challan_number:
            data?.damageLossData?.[DAMAGE_LOSS_FIELDS.CHALLAN_NUMBER],
        product_batch_inventory_id:
            data?.damageLossData?.product_batch_inventory_id,
        quantity: data?.damageLossData?.[DAMAGE_LOSS_FIELDS.QUANTITY],
        notes: data?.damageLossData?.[DAMAGE_LOSS_FIELDS.NOTES],
        product_id: data?.productData?.product_id
    }

    let promise = new Promise(function (resolve, reject) {
        addDamageLossAdjustmentAPI(reqData)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (
                    res?.data?.data &&
                    res?.data?.data?.is_loss_adjustment_added
                ) {
                    resolve()
                } else {
                    reject('Add DamageLossAdjustment Failure')
                }
            })
            .catch(err => {
                reject('Add DamageLossAdjustment Failure ', err)
            })
    })

    return promise
}

export function getDamageLossAdjustmentHelper() {
    let promise = new Promise(function (resolve, reject) {
        getDamageLossAdjustmentAPI()
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (
                    res?.data?.data &&
                    res?.data?.data?.damage_loss_adjustments
                ) {
                    resolve(res.data.data.damage_loss_adjustments)
                } else {
                    reject('get dla Failure')
                }
            })
            .catch(err => {
                reject('get dla Failure')
            })
    })

    return promise
}

export function getInventoryDetailsHelper() {
    let promise = new Promise(function (resolve, reject) {
        getInventoryDetailsAPI()
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data?.inventory) {
                    resolve(res.data.data.inventory)
                } else {
                    reject('Add Customer Failure')
                }
            })
            .catch(err => {
                reject('Add Customer Failure')
            })
    })

    return promise
}

export function getCampaignInventoryDetailsHelper() {
    let promise = new Promise(function (resolve, reject) {
        getCampaignInventoryDetailsAPI()
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data?.inventory) {
                    resolve(res.data.data.inventory)
                } else {
                    reject('Add Customer Failure')
                }
            })
            .catch(err => {
                reject('Add Customer Failure')
            })
    })

    return promise
}

export function getProductDetailsHelper(id) {
    let reqData = {
        product_id: id
    }

    console.log(reqData)

    let promise = new Promise(function (resolve, reject) {
        getProductDetailsAPI(reqData)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (
                    res?.data?.data &&
                    res?.data?.data?.product_details
                ) {
                    resolve(res.data.data.product_details)
                } else {
                    reject('Add Customer Failure')
                }
            })
            .catch(err => {
                reject('Add Customer Failure')
            })
    })

    return promise
}

export function getInvoiceDetailsHelper(id) {
    let reqData = {
        product_receiving_id: id
    }

    console.log(reqData)

    let promise = new Promise(function (resolve, reject) {
        getInvoiceDetailsAPI(reqData)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (
                    res?.data?.data &&
                    res?.data?.data?.product_receiving
                ) {
                    resolve(res.data.data.product_receiving)
                } else {
                    reject('Add Customer Failure')
                }
            })
            .catch(err => {
                reject('Add Customer Failure')
            })
    })

    return promise
}

export function getAllSalesOrderHelper() {
    let promise = new Promise(function (resolve, reject) {
        getAllSalesAPI()
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data) {
                    resolve(res.data.data)
                } else {
                    reject('Add User Failure')
                }
            })
            .catch(err => {
                reject('Add User Failure')
            })
    })

    return promise
}

export function getSalesOrderHelper(id) {
    let reqData = {
        sales_order_id: id
    }

    console.log(reqData)

    let promise = new Promise(function (resolve, reject) {
        getSalesAPI(reqData)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data.sales_order) {
                    resolve(res.data?.data.sales_order)
                } else {
                    reject('Get Customer Failure')
                }
            })
            .catch(err => {
                reject('Get Customer Failure')
            })
    })

    return promise
}

export function createSalesHelper(data) {
    let reqData = {
        status: data.status,
        customer_details: data.customerDetails,
        product_details: data.productDetails,
        payment_details: data.paymentDetails
    }

    console.log(reqData)

    let promise = new Promise(function (resolve, reject) {
        createSalesAPI(reqData)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data?.is_created) {
                    resolve()
                } else {
                    reject('Update Sales Failure !!')
                }
            })
            .catch(err => {
                reject('Update Sales Failure !!')
            })
    })

    return promise
}

export function updateSalesHelper(data) {
    let reqData = {
        sales_order_id: data.id,
        product_details: data.productDetails,
        payment_details: data.paymentDetails
    }

    console.log(reqData)

    let promise = new Promise(function (resolve, reject) {
        updateSalesAPI(reqData)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (
                    res?.data?.data &&
                    res?.data?.data?.is_sales_order_updated
                ) {
                    resolve()
                } else {
                    reject('Add Customer Failure')
                }
            })
            .catch(err => {
                reject('Add Customer Failure')
            })
    })

    return promise
}

export function getSalesOrderByInvoiceNumberHelper(id) {
    let reqData = {
        invoice_number: id
    }

    console.log(reqData)

    let promise = new Promise(function (resolve, reject) {
        getSalesOrderByInvoiceNumberAPI(reqData)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data.sales_order) {
                    resolve(res.data?.data.sales_order)
                } else {
                    reject('Get Sales By InvoiceNumber Failure !!')
                }
            })
            .catch(err => {
                reject('Get Sales By InvoiceNumber Failure !!')
            })
    })

    return promise
}

export function createExchangeReturnHelper(data) {
    let reqData = {
        sales_order_id: data.salesOrderId,
        reason_type: data.addExchangeReturnFlow,
        customer_details: data.customerDetails,
        old_product_details: data.oldProductDetailsArray,
        new_product_details: data.newProductDetailsArray,
        payment_details: data.paymentDetails
    }

    console.log(reqData)

    let promise = new Promise(function (resolve, reject) {
        createExchangeReturnAPI(reqData)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data?.is_created) {
                    resolve()
                } else {
                    reject('Add Exchange Return Failure')
                }
            })
            .catch(err => {
                reject('Add Exchange Return Failure')
            })
    })

    return promise
}

export function getAllExchangeReturnOrdersHelper(data) {
    let promise = new Promise(function (resolve, reject) {
        getAllExchangeReturnOrdersAPI(data)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (
                    res?.data?.data &&
                    res?.data?.data?.exchange_or_return_sales_orders
                ) {
                    resolve(res.data.data.exchange_or_return_sales_orders)
                } else {
                    reject('Add Exchange Return Orders Failure')
                }
            })
            .catch(err => {
                reject('Add Exchange Return Orders Failure')
            })
    })

    return promise
}

export function getExchangeReturnOrderByIdHelper(id) {
    let reqData = {
        exchange_or_return_sales_order_id: id
    }
    let promise = new Promise(function (resolve, reject) {
        getExchangeReturnOrdersByIdAPI(reqData)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (
                    res?.data?.data &&
                    res?.data?.data?.exchange_or_return_sales_order
                ) {
                    resolve(res.data.data.exchange_or_return_sales_order)
                } else {
                    reject('Add Exchange Return Orders Failure')
                }
            })
            .catch(err => {
                reject('Add Exchange Return Orders Failure')
            })
    })

    return promise
}

export function confirmOnlineSalesOrderHelper(data) {
    let reqData = {
        sales_order_id: data.id,
        delivery_partner_id: data.deliveryPartnerId,
        status: data.status
    }
    let promise = new Promise(function (resolve, reject) {
        confirmOnlineSalesOrderAPI(reqData)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data) {
                    resolve(res.data.data)
                } else {
                    reject('Confirm Sales Failure !!')
                }
            })
            .catch(err => {
                reject('Confirm Sales Failure !!')
            })
    })

    return promise
}

export function getStatisticsHelper(data) {
    let promise = new Promise(function (resolve, reject) {
        getStatisticsAPI()
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data) {
                    resolve(res.data.data)
                } else {
                    reject('get Stats Failure !!')
                }
            })
            .catch(err => {
                reject('get Stats Failure !!')
            })
    })

    return promise
}

export const getStoreStatusHelper = () => {
    let promise = new Promise(function (resolve, reject) {
        getStoreStatusAPI()
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data) {
                    resolve(res.data.data)
                } else {
                    reject('get Store Status Failure !!')
                }
            })
            .catch(err => {
                reject('get Store Status Failure !!')
            })
    })

    return promise
}

export function getStocksAndBarchartDataHelper(data) {
    let promise = new Promise(function (resolve, reject) {
        getStocksAndBarchartDataAPI()
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data) {
                    resolve(res.data.data)
                } else {
                    reject('get Stocks Failure !!')
                }
            })
            .catch(err => {
                reject('get Stocks Failure !!')
            })
    })

    return promise
}

export function updatePrescriptionOrderHelper(data) {
    let reqData = {
        order_id: data.orderId,
        product_details: data.productDetails,
        payment_details: data.paymentDetails
    }

    let promise = new Promise(function (resolve, reject) {
        updatePrescriptionOrderAPI(reqData)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data) {
                    resolve(res.data.data)
                } else {
                    reject('update Prescription Order Failure !!')
                }
            })
            .catch(err => {
                reject('update Prescription Order Failure !!')
            })
    })

    return promise
}

export function getReportHelper(data) {
    let reqData = {
        start_date: data.startDate,
        end_date: data.endDate,
        type: data.reportType,
        sub_type: data.subType
    }

    let promise = new Promise(function (resolve, reject) {
        getReportAPI(reqData)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data) {
                    resolve(res.data.data)
                } else {
                    reject('get Reports Failure !!')
                }
            })
            .catch(err => {
                reject('get Reports Failure !!')
            })
    })

    return promise
}

export function downloadReportExcelHelper(data) {
    let reqData = {
        start_date: data.startDate,
        end_date: data.endDate,
        type: data.reportType,
        sub_type: data.subType
    }

    let promise = new Promise(function (resolve, reject) {
        downloadReportExcelAPI(reqData)
            .then(res => {
                console.log(res.data)

                try {
                    const blob = new Blob([res.data], {
                        type: res.headers['content-type']
                    })
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.setAttribute(
                        'download',
                        `${data.reportType}_${data.subType}.csv`
                    ) // Set the file name
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)

                    resolve()
                } catch (err) {
                    reject('download Reports Failure !!')
                }
            })
            .catch(err => {
                reject('download Reports Failure !!')
            })
    })

    return promise
}

export function downloadReportPdfHelper(data) {
    let reqData = {
        start_date: data.startDate,
        end_date: data.endDate,
        type: data.reportType,
        sub_type: data.subType
    }

    let promise = new Promise(function (resolve, reject) {
        downloadReportPdfAPI(reqData)
            .then(res => {
                console.log(res.data)

                try {
                    const blob = new Blob([res.data], {
                        type: res.headers['content-type']
                    })
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.setAttribute(
                        'download',
                        `${data.reportType}_${data.subType}.pdf`
                    ) // Set the file name
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)

                    resolve()
                } catch (err) {
                    reject('download Reports Failure !!')
                }
            })
            .catch(err => {
                reject('download Reports Failure !!')
            })
    })

    return promise
}
