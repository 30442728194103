import React from 'react'

const HelpFillIcon = () => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="fluent:chat-help-24-filled">
            <path id="Vector" d="M14.0002 2.33301C20.4437 2.33301 25.6668 7.55617 25.6668 13.9997C25.6668 20.4432 20.4437 25.6663 14.0002 25.6663C12.1128 25.6688 10.2532 25.2115 8.58217 24.334L3.57717 25.6372C3.40994 25.6807 3.23422 25.6798 3.06745 25.6346C2.90067 25.5893 2.74861 25.5012 2.62636 25.3791C2.5041 25.2569 2.41588 25.105 2.37045 24.9382C2.32502 24.7715 2.32395 24.5958 2.36734 24.4285L3.66934 19.4247C2.78944 17.7519 2.33091 15.8897 2.33351 13.9997C2.33351 7.55617 7.55667 2.33301 14.0002 2.33301ZM14.0002 18.083C13.6908 18.083 13.394 18.2059 13.1752 18.4247C12.9564 18.6435 12.8335 18.9403 12.8335 19.2497C12.8335 19.5591 12.9564 19.8558 13.1752 20.0746C13.394 20.2934 13.6908 20.4163 14.0002 20.4163C14.3096 20.4163 14.6063 20.2934 14.8251 20.0746C15.0439 19.8558 15.1668 19.5591 15.1668 19.2497C15.1668 18.9403 15.0439 18.6435 14.8251 18.4247C14.6063 18.2059 14.3096 18.083 14.0002 18.083ZM14.0002 7.87467C13.1493 7.87467 12.3332 8.21269 11.7315 8.81437C11.1299 9.41605 10.7918 10.2321 10.7918 11.083C10.7919 11.3047 10.8761 11.5181 11.0275 11.6801C11.1788 11.8421 11.386 11.9406 11.6072 11.9557C11.8284 11.9708 12.047 11.9014 12.219 11.7615C12.391 11.6216 12.5035 11.4216 12.5337 11.202L12.55 10.9337C12.5894 10.5627 12.7694 10.221 13.0531 9.97877C13.3367 9.73653 13.7024 9.61218 14.075 9.63131C14.4475 9.65043 14.7985 9.81158 15.0559 10.0816C15.3132 10.3516 15.4573 10.71 15.4585 11.083C15.4585 11.7118 15.301 12.0222 14.706 12.637L14.3513 12.9987C13.4717 13.9133 13.1252 14.5702 13.1252 15.7497C13.1252 15.9817 13.2174 16.2043 13.3815 16.3684C13.5455 16.5325 13.7681 16.6247 14.0002 16.6247C14.2322 16.6247 14.4548 16.5325 14.6189 16.3684C14.783 16.2043 14.8752 15.9817 14.8752 15.7497C14.8752 15.1208 15.0327 14.8105 15.6277 14.1957L15.9823 13.834C16.862 12.9193 17.2085 12.2625 17.2085 11.083C17.2085 10.2321 16.8705 9.41605 16.2688 8.81437C15.6671 8.21269 14.8511 7.87467 14.0002 7.87467Z" fill="#212121" />
        </g>
    </svg>
)

export default HelpFillIcon
