import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ReactSelect from 'react-select'
import CheckPrimaryIcon from '../../assets/CheckPrimaryIcon'
import CloseIcon from '../../assets/CloseIcon'
import DiscountBag from '../../assets/DiscountBag'
import DotsIcon from '../../assets/DotsIcon'
import {
    HEADER_CONFIG,
    HEADER_CONST
} from '../../common/constant/common-constant'
import {
    DISCOUNT_TYPE,
    PAGE_CONST,
    PERMISSION_FEATURE,
    PERMISSION_SCREEN,
    PERMISSION_TYPE,
    PROFILE_CONST
} from '../../common/constant/page-constant'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import { convertToTitleCase, formatDate } from '../../common/utils/appUtils'
import { redirect } from '../../common/utils/redirectionUtils'
import {
    getCampaignInventoryDetailsHelper,
    getInventoryDetailsHelper
} from '../../helper/home-helper'
import {
    createCampaignDiscountHelper,
    createSingleProductDiscountHelper,
    getCampaignDiscountDetailHelper,
    getCampaignDiscountHelper,
    getProductSearchResultsHelper,
    getProductSearchSuggestionsHelper,
    getSingleProductDiscountHelper,
    updateCampaignDiscountHelper,
    updateSingleProductDiscountHelper
} from '../../helper/personal-details-helper'
import {
    addCampaignDiscountAction,
    addSingleProductDiscountAction,
    updateCampaignDiscountAction
} from '../../modules/actions/action'
import AcknowledgeScreen from '../Common/AcknowledgeScreen/AcknowledgeScreen'
import Card from '../Common/Card/Card'
import MobileHeader from '../Common/Header/MobileHeader'

import './Discount.scss'

const Discount = props => {
    const navigate = useNavigate()
    const isMobile = useCheckMobileScreen()

    const [viewDrawer, setViewDrawer] = useState(false)
    const [isCardActiveId, setIsCardActiveId] = useState(false)
    const [open, setOpen] = useState(false)
    const [headerOptions, setHeaderOptions] = useState({
        [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
        [HEADER_CONFIG.TITLE]: HEADER_CONST.DISCOUNTS,
        [HEADER_CONFIG.HANDLE_BACK_FUNC]: () => props?.onBackClick()
    })
    const [discountType, setDiscountType] = useState(
        DISCOUNT_TYPE.SINGLE_PRODUCT
    )
    const [singleProductDiscountList, setSingleProductDiscountList] = useState(
        []
    )

    const [searchTerm, setSearchTerm] = useState('')
    const [selectedSearchTerm, setSelectedSearchTerm] = useState(undefined)
    const [searchSuggestions, setSearchSuggestions] = useState([])
    const [prevSearchTermLength, setPrevSearchTermLength] = useState(0)
    const [timer, setTimer] = useState()
    const [apiCalled, setApiCalled] = useState(false)
    const [searchResults, setSearchResults] = useState({})
    const [discountRate, setDiscountRate] = useState(undefined)
    const [discountPercentage, setDiscountPercentage] = useState(undefined)
    const [addSingleProductDiscountAlert, setAddSingleProductDiscountAlert] =
        useState(false)
    const [selectedMedicalCategory, setSelectedMedicalCategory] =
        useState(undefined)
    const [addCampaignDiscountAlert, setAddCampaignDiscountAlert] =
        useState(false)
    const [updateCampaignDiscountAlert, setUpdateCampaignDiscountAlert] =
        useState(false)
    const [campaignDiscountList, setCampaignDiscountList] = useState([])
    const [addCampaignActive, setAddCampaignActive] = useState(false)
    const [viewCampaignActiveId, setViewCampaignActiveId] = useState('')
    const [viewCampaignCardActionActive, setViewCampaignCardActionActive] =
        useState(false)
    const [inventory, setInventory] = useState([])
    const [selectedProducts, setSelectedProducts] = useState([])
    const [campaignDiscountName, setCampaignDiscountName] = useState('')
    const [campaignDiscountPercentage, setCampaignDiscountPercentage] =
        useState('')
    const [campaignStartDate, setCampaignStartDate] = useState('')
    const [campaignEndDate, setCampaignEndDate] = useState('')
    const [isErrorFieldVisible, setIsErrorFieldVisible] = useState(false)
    const [activeCampaignData, setActiveCampaignData] = useState({})
    const [previousCampaignList, setPreviousCampaignList] = useState([])
    const [campaignDetailsData, setCampaignDetailsData] = useState({})
    const [addCampaignFlow, setAddCampaignFlow] = useState(undefined)
    const [permissions, setPermissions] = useState({})

    useEffect(() => {
        getSingleProductDiscountData()
    }, [])

    useEffect(() => {
        handleSearchSuggestions(searchTerm)
        return () => clearTimeout(timer)
    }, [searchTerm])

    useEffect(() => {
        setAddCampaignActive(false)
        setViewCampaignActiveId(undefined)
        setViewCampaignCardActionActive(false)
        setSelectedProducts([])

        if (discountType === DISCOUNT_TYPE.CAMPAIGN) getCampaignDiscountData()
        else if (discountType === DISCOUNT_TYPE.SINGLE_PRODUCT)
            getSingleProductDiscountData()
    }, [discountType])

    useEffect(() => {
        let addSingleProductDiscountAlert =
            props.alert?.addSingleProductDiscountAlert
        let addCampaignDiscountAlert = props.alert?.addCampaignDiscountAlert
        let updateCampaignDiscountAlert =
            props.alert?.updateCampaignDiscountAlert

        setAddSingleProductDiscountAlert(addSingleProductDiscountAlert)
        setAddCampaignDiscountAlert(addCampaignDiscountAlert)
        setUpdateCampaignDiscountAlert(updateCampaignDiscountAlert)
    }, [
        props.alert?.addSingleProductDiscountAlert,
        props.alert?.addCampaignDiscountAlert,
        props.alert?.updateCampaignDiscountAlert
    ])

    useEffect(() => {
        if (props.loginData.permissions) {
            let permission = props.loginData.permissions.filter(
                permission => permission.screen === PERMISSION_SCREEN.SETTINGS
            )[0]

            if (permission) setPermissions(permission)
        }
    }, [props.loginData])

    const handleHeaderConfigUpdate = (title, handleBackFunc) => {
        setHeaderOptions({
            [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
            [HEADER_CONFIG.TITLE]: title,
            [HEADER_CONFIG.HANDLE_BACK_FUNC]: () => handleBackFunc()
        })
    }

    useEffect(() => {
        if (!viewCampaignActiveId) {
            handleHeaderConfigUpdate(HEADER_CONST.DISCOUNTS, () =>
                props?.onBackClick()
            )
            return
        }

        handleHeaderConfigUpdate(HEADER_CONST.DISCOUNTS, () => {
            setViewCampaignActiveId(undefined)
        })
    }, [viewCampaignActiveId])

    const updateDiscountPercentage = () => {
        let dRate = discountRate

        let dPercentage = ((searchResults?.cost_per_unit || 0) / dRate) * 100
        setDiscountPercentage(dPercentage)
    }

    const updateDiscountRate = () => {
        let dPercentage = discountPercentage

        let discountRate =
            ((searchResults?.cost_per_unit || 0) * discountPercentage) / 100
        setDiscountRate(discountRate)
    }

    const handleDrawerToggle = () => {
        setViewDrawer(!viewDrawer)
        setOpen(!open)
    }

    const handleSearchSuggestions = searchTerm => {
        console.log('searchTerm : ', searchTerm, ' !searchTerm', !searchTerm)
        if (!searchTerm || searchTerm?.length === 0) {
            clearSearchData()
            return
        }

        const fetchSearchResults = async () => {
            console.log('in fetchSearchResults')
            try {
                const results = await getProductSearchSuggestionsHelper(
                    searchTerm
                )
                // dispatchRecentSearchListAction(searchTerm)
                setSearchSuggestions(results)
            } catch (error) {
                console.error('Error fetching search results:', error)
            }
            // setIsLoading(false)
        }

        // Check if search term length changes by more than 2 characters
        if (
            searchTerm?.length > 0 &&
            Math.abs(searchTerm.length - prevSearchTermLength) > 2
        ) {
            clearTimeout(timer)
            setTimer(null)
            // isTimerSet = false
            console.log('hitting api after 2 character change')
            setPrevSearchTermLength(() => searchTerm.length)
            fetchSearchResults()
            // setApiCalled(() => apiCalled)
        } else {
            console.log('in else timer: ', timer)
            if (!timer) {
                let tr = setTimeout(() => {
                    if (searchTerm.length > 0) {
                        console.log('hitting api after 1 sec')
                        setPrevSearchTermLength(searchTerm.length)
                        fetchSearchResults()
                        // isTimerSet = false
                    }
                }, 1000)
                // setApiCalled(true);
                setTimer(tr)
            } else {
                // Clear the existing timer and set a new one
                console.log('clearing timeout and setting a new one')
                clearTimeout(timer)
                let tr = setTimeout(() => {
                    if (searchTerm.length > 0) {
                        console.log('hitting api after 1 sec')
                        setPrevSearchTermLength(searchTerm.length)
                        fetchSearchResults()
                        // isTimerSet = false
                    }
                }, 1000)
                setTimer(tr)
            }
        }
    }

    const handleSearch = async () => {
        try {
            let data = await getProductSearchResultsHelper(selectedSearchTerm)

            setSearchResults(data)
        } catch (err) {}
    }

    const handleAddCampaignValidation = () => {
        if (
            campaignDiscountName &&
            campaignDiscountPercentage &&
            campaignStartDate &&
            campaignEndDate &&
            selectedProducts?.length > 0
        ) {
            return true
        }

        return false
    }

    const handleCreateSingleProduct = async () => {
        try {
            let data = await createSingleProductDiscountHelper(
                searchResults.id,
                discountRate,
                discountPercentage
            )

            props.dispatchSingleProductDiscountAction(true)
            setTimeout(() => {
                props.dispatchSingleProductDiscountAction(false)
            }, 5000)

            // props.dispatchCampaignDiscountAction(true)
            // setTimeout(() => {
            //     props.dispatchCampaignDiscountAction(false)
            // }, 5000)

            getSingleProductDiscountData()
            setSearchResults({})
            clearSearchData()
            setSearchTerm(undefined)
        } catch (err) {}
    }

    const handleCreateCampaignDiscount = async () => {
        if (!handleAddCampaignValidation()) {
            setIsErrorFieldVisible(true)
            return
        }

        if (addCampaignFlow === 'EDIT') {
            let data = await updateCampaignDiscountHelper(
                'EDIT',
                campaignDetailsData?.id,
                campaignDiscountName,
                campaignDiscountPercentage,
                campaignStartDate,
                campaignEndDate,
                selectedProducts
            )

            props.dispatchUpdateCampaignDiscountAlert(true)
            setTimeout(() => {
                props.dispatchUpdateCampaignDiscountAlert(false)
            }, 5000)
        } else {
            let data = await createCampaignDiscountHelper(
                campaignDiscountName,
                campaignDiscountPercentage,
                campaignStartDate,
                campaignEndDate,
                selectedProducts
            )

            props.dispatchCampaignDiscountAction(true)
            setTimeout(() => {
                props.dispatchCampaignDiscountAction(false)
            }, 5000)
        }

        setAddCampaignFlow(undefined)
        getCampaignDiscountData()
        clearCampaignData()
    }

    const handleSaveClick = async () => {
        if (discountType === DISCOUNT_TYPE.CAMPAIGN && !addCampaignActive) {
            await getCampaignInventory()
            clearCampaignData()
            setAddCampaignActive(true)
            return
        }

        if (discountType === DISCOUNT_TYPE.SINGLE_PRODUCT) {
            if (!searchResults || !discountRate || !discountPercentage) return
            handleCreateSingleProduct()
        } else if (discountType === DISCOUNT_TYPE.CAMPAIGN) {
            handleCreateCampaignDiscount()
        }
    }

    const getCampaignInventory = async () => {
        try {
            let data = await getCampaignInventoryDetailsHelper()
            setInventory(data)
        } catch (err) {
            console.log('errMsg: ', err)
        }
    }

    const clearSearchData = () => {
        setSearchSuggestions([])
        setSelectedSearchTerm(undefined)
        setPrevSearchTermLength(0)
        setApiCalled(() => false)
        clearTimeout(timer)
        setTimer(() => null)
        // setApiCalled(false)
    }

    const clearCampaignData = () => {
        setCampaignStartDate('')
        setCampaignEndDate('')
        setCampaignDiscountPercentage('')
        setCampaignDiscountName('')
        setSelectedProducts([])
        setAddCampaignActive(false)
        setViewCampaignActiveId(undefined)
        setViewCampaignCardActionActive(false)
        // setCampaignDetailsData({})
        setAddCampaignFlow(undefined)
    }

    const getSingleProductDiscountData = async () => {
        try {
            let data = await getSingleProductDiscountHelper()

            setSingleProductDiscountList(data)
        } catch (err) {}
    }

    const getCampaignDiscountData = async () => {
        try {
            let campaignList = await getCampaignDiscountHelper()

            setActiveCampaignData({})
            setPreviousCampaignList([])

            campaignList.map(campaign => {
                if (campaign.status === 'RUNNING') {
                    setActiveCampaignData(campaign)
                } else {
                    let pCList = previousCampaignList || []
                    pCList.push(campaign)

                    setPreviousCampaignList(pCList)
                }
            })

            setCampaignDiscountList(campaignList)
        } catch (err) {
            console.log('err: ', err)
        }
    }

    const handleCampaignClick = async id => {
        try {
            let data = await getCampaignDiscountDetailHelper(id)

            clearCampaignData()
            setCampaignDetailsData(data)
            setViewCampaignActiveId(id)
        } catch (err) {
            console.log('err: ', err)
        }
    }

    const handleProductCheckboxClick = (product, checked) => {
        let selectedProductList = selectedProducts || []

        let index = selectedProductList.findIndex(id => id === product.id)

        if (checked) {
            if (index < 0) selectedProductList.push(product.id)
        } else {
            if (index > -1) selectedProductList.splice(index, 1)
        }

        setSelectedProducts([...selectedProductList])

        console.log('selectedProductList: ', selectedProductList)
    }

    const handleCampaignEditClick = async (campaignData, type) => {
        clearCampaignData()
        if (type === 'DELETE') {
            let data = await updateCampaignDiscountHelper(
                'DELETE',
                campaignDetailsData?.id
            )

            props.dispatchUpdateCampaignDiscountAlert(true)
            setTimeout(() => {
                props.dispatchUpdateCampaignDiscountAlert(false)
            }, 5000)

            getCampaignDiscountData()
        } else if (type === 'EDIT') {
            setCampaignStartDate(
                moment(campaignData?.start_date).format('YYYY-MM-DD')
            )
            setCampaignEndDate(
                moment(campaignData?.end_date).format('YYYY-MM-DD')
            )
            setCampaignDiscountPercentage(campaignData?.discount_percentage)
            setCampaignDiscountName(campaignData?.name)

            let sProducts = campaignData?.product_details?.map(product => {
                return product.product.id
            })

            setAddCampaignFlow('EDIT')
            setSelectedProducts(sProducts)
            getCampaignInventory()
            setAddCampaignActive(true)
        }
    }

    const handleDiscountDelete = async singleProductDiscount => {
        let data = await updateSingleProductDiscountHelper(
            singleProductDiscount?.id,
            'DELETE'
        )

        props.dispatchUpdateCampaignDiscountAlert(true)
        setTimeout(() => {
            props.dispatchUpdateCampaignDiscountAlert(false)
        }, 5000)

        clearSearchData()
        getSingleProductDiscountData()
    }

    return (
        <>
            {isMobile && <MobileHeader headerOptions={headerOptions} />}
            {addSingleProductDiscountAlert && (
                <AcknowledgeScreen
                    icon={<CheckPrimaryIcon />}
                    main={'New Product Discount added Successfully!'}
                    sub="Lorem ipsum dolor sit amet consectetur. Ligula ut volutpat morbi ullamcorper"
                    success
                    alertType="success"
                    alertMessage={'New Product Discount added Successfully!'}
                />
            )}
            {addCampaignDiscountAlert && (
                <AcknowledgeScreen
                    icon={<CheckPrimaryIcon />}
                    main={'New Campaign added Successfully!'}
                    sub="Lorem ipsum dolor sit amet consectetur. Ligula ut volutpat morbi ullamcorper"
                    success
                    alertType="success"
                    alertMessage={'New Campaign added Successfully!!'}
                />
            )}
            {updateCampaignDiscountAlert && (
                <AcknowledgeScreen
                    icon={<CheckPrimaryIcon />}
                    main={'Discount updated Successfully!'}
                    sub="Lorem ipsum dolor sit amet consectetur. Ligula ut volutpat morbi ullamcorper"
                    success
                    alertType="success"
                    alertMessage={'Discount updated Successfully!'}
                />
            )}
            <div className="pageBodyRightPanel">
                <div className="pageBodyRightPanelHeader dFlex jcSpaceBetween aiCenter mb-5">
                    <div className="pageBodyRightPanelHeading">
                        Discount Settings
                    </div>
                    <div className="addProductBtn dFlex aiCenter">
                        {permissions?.features?.[
                            PERMISSION_FEATURE.DISCOUNT_SETTINGS
                        ] === PERMISSION_TYPE.MANAGE &&
                            (discountType !== DISCOUNT_TYPE.CAMPAIGN ||
                                !activeCampaignData?.id ||
                                addCampaignActive) && (
                                <button
                                    className="btn btnPrimary"
                                    onClick={() => handleSaveClick()}>
                                    {discountType === DISCOUNT_TYPE.CAMPAIGN &&
                                    !addCampaignActive
                                        ? 'ADD'
                                        : 'SAVE'}
                                </button>
                            )}
                    </div>
                </div>
                <div className="mb-5">
                    <ul className="tabs dFlex mb-5">
                        <li
                            className={
                                discountType === DISCOUNT_TYPE.SINGLE_PRODUCT
                                    ? 'tabItem active'
                                    : 'tabItem'
                            }
                            onClick={() => {
                                setAddCampaignActive(false)
                                setViewCampaignActiveId(undefined)
                                setDiscountType(DISCOUNT_TYPE.SINGLE_PRODUCT)
                            }}>
                            Single Product
                        </li>
                        <li
                            className={
                                discountType === DISCOUNT_TYPE.CAMPAIGN
                                    ? 'tabItem active'
                                    : 'tabItem'
                            }
                            onClick={() =>
                                setDiscountType(DISCOUNT_TYPE.CAMPAIGN)
                            }>
                            Campaign
                        </li>
                    </ul>
                    <div class="tabContent">
                        {discountType === DISCOUNT_TYPE.SINGLE_PRODUCT && (
                            <div className="tabPane">
                                <div className="dFlex aiCenter jcSpaceBetween mb-4">
                                    <div className="formGroup formFloating fgrow1 maxWidth">
                                        <ReactSelect
                                            className="formSelect reactSelect"
                                            id="floatingSelect"
                                            value={searchSuggestions.filter(
                                                suggestion =>
                                                    selectedSearchTerm ===
                                                    suggestion.id
                                            )}
                                            onInputChange={
                                                option => {
                                                    if (option)
                                                        setSearchTerm(option)
                                                }
                                                // handleBillDetailUpdate(
                                                //     BILL_DETAILS_FIELDS.PHARMA_COMPANY,
                                                //     option
                                                // )
                                            }
                                            onChange={option => {
                                                setSelectedSearchTerm(
                                                    option?.id
                                                )
                                            }}
                                            options={searchSuggestions}
                                            getOptionLabel={option =>
                                                option.label
                                            }
                                            placeholder="Select Product"
                                            styles={{
                                                control: base => ({
                                                    border: 0,
                                                    display: 'flex',
                                                    paddingTop: '20px'
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor:
                                                        state.isFocused
                                                            ? 'rgb(222, 235, 255)'
                                                            : state.isSelected
                                                            ? '#FFF'
                                                            : 'inherit',
                                                    color: '#212529',
                                                    '&:hover': {
                                                        backgroundColor:
                                                            state.isSelected
                                                                ? '#DEEBFF'
                                                                : 'rgb(222, 235, 255)'
                                                    }
                                                })
                                            }}
                                            // menuIsOpen={true}
                                            isClearable
                                        />
                                        <label for="floatingSelect">
                                            Select Product
                                        </label>
                                    </div>
                                    <button
                                        className="btn btnPrimary ml-4"
                                        onClick={() => handleSearch()}>
                                        Search
                                    </button>
                                </div>
                                {searchResults?.id && (
                                    <div className="mb-5">
                                        <table className="orderBook">
                                            <thead>
                                                <tr>
                                                    <td width="20%">
                                                        Medicine
                                                    </td>
                                                    <td width="15%">Type</td>
                                                    <td width="12%">
                                                        Cost/Unit
                                                    </td>
                                                    <td width="12%">
                                                        Rate/Unit
                                                    </td>
                                                    <td width="12%">Stock</td>
                                                    <td width="12%">
                                                        Discounted Rate
                                                    </td>
                                                    <td width="12%">
                                                        Discount %
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{`${searchResults?.name} ${searchResults?.dose}`}</td>
                                                    <td>
                                                        {convertToTitleCase(
                                                            searchResults.category
                                                        )}
                                                    </td>
                                                    <td className="nowrap">
                                                        {`BDT ${searchResults?.cost_per_unit}`}
                                                    </td>
                                                    <td className="nowrap">
                                                        {`BDT ${searchResults?.rate_per_unit}`}
                                                    </td>
                                                    <td>
                                                        {searchResults?.stock}
                                                    </td>
                                                    <td>
                                                        <div className="formGroup mb-0">
                                                            <input
                                                                className="formControl formsm"
                                                                placeholder="Discount Rate"
                                                                value={
                                                                    discountRate
                                                                }
                                                                onChange={event => {
                                                                    let data =
                                                                        event
                                                                            .target
                                                                            .value

                                                                    if (
                                                                        data < 0
                                                                    )
                                                                        data = 0
                                                                    else if (
                                                                        data >
                                                                        searchResults?.rate_per_unit
                                                                    )
                                                                        data =
                                                                            searchResults?.rate_per_unit
                                                                    setDiscountRate(
                                                                        data
                                                                    )

                                                                    let dPercentage =
                                                                        (((searchResults?.rate_per_unit ||
                                                                            0) -
                                                                            data) /
                                                                            searchResults?.rate_per_unit) *
                                                                        100

                                                                    setDiscountPercentage(
                                                                        dPercentage?.toFixed(
                                                                            2
                                                                        )
                                                                    )
                                                                }}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="formGroup mb-0">
                                                            <input
                                                                className="formControl formsm"
                                                                placeholder="Discount Rate"
                                                                value={
                                                                    discountPercentage
                                                                }
                                                                onChange={event => {
                                                                    let data =
                                                                        event
                                                                            .target
                                                                            .value
                                                                    if (
                                                                        data >
                                                                        100
                                                                    )
                                                                        data = 100
                                                                    else if (
                                                                        data < 0
                                                                    )
                                                                        data = 0

                                                                    setDiscountPercentage(
                                                                        data
                                                                    )

                                                                    let discountRate =
                                                                        searchResults?.rate_per_unit -
                                                                        ((searchResults?.rate_per_unit ||
                                                                            0) *
                                                                            data) /
                                                                            100
                                                                    setDiscountRate(
                                                                        discountRate
                                                                    )
                                                                }}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                {singleProductDiscountList?.length > 0 && (
                                    <div className="mb-4 dFlex">
                                        <div className="formCheck">
                                            <input
                                                className="formCheckInput"
                                                type="radio"
                                                name="flexRadioDefault"
                                                value="ALL"
                                                id="medical-category"
                                                onChange={event => {
                                                    setSelectedMedicalCategory(
                                                        event.target.value
                                                    )
                                                }}
                                            />
                                            <label className="ml-2">ALL</label>
                                        </div>
                                        <div className="formCheck">
                                            <input
                                                className="formCheckInput"
                                                type="radio"
                                                value="PHARMACEUTICAL"
                                                name="flexRadioDefault"
                                                id="medical-category"
                                                onChange={event => {
                                                    setSelectedMedicalCategory(
                                                        event.target.value
                                                    )
                                                }}
                                            />
                                            <label className="ml-2">
                                                Pharma
                                            </label>
                                        </div>
                                        <div className="formCheck">
                                            <input
                                                className="formCheckInput"
                                                type="radio"
                                                value="NON_PHARMACEUTICAL"
                                                name="flexRadioDefault"
                                                id="medical-category"
                                                onChange={event => {
                                                    setSelectedMedicalCategory(
                                                        event.target.value
                                                    )
                                                }}
                                            />
                                            <label className="ml-2">
                                                Non-Pharma
                                            </label>
                                        </div>
                                    </div>
                                )}
                                {singleProductDiscountList?.length > 0 && (
                                    <div className="mb-5">
                                        <table className="orderBook">
                                            <thead>
                                                <tr>
                                                    <td width="20%">
                                                        Medicine
                                                    </td>
                                                    <td width="15%">Type</td>
                                                    <td width="12%">
                                                        Cost/Unit
                                                    </td>
                                                    <td width="12%">
                                                        Rate/Unit
                                                    </td>
                                                    <td width="12%">Stock</td>
                                                    <td width="12%">
                                                        Discounted Rate
                                                    </td>
                                                    <td width="12%">
                                                        Discount %
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {singleProductDiscountList?.map(
                                                    singleProductDiscount => {
                                                        if (
                                                            (selectedMedicalCategory ===
                                                                'PHARMACEUTICAL' &&
                                                                singleProductDiscount
                                                                    ?.product
                                                                    ?.type ===
                                                                    'NON_PHARMACEUTICAL') ||
                                                            (selectedMedicalCategory ===
                                                                'NON_PHARMACEUTICAL' &&
                                                                singleProductDiscount
                                                                    ?.product
                                                                    ?.type ===
                                                                    'PHARMACEUTICAL')
                                                        )
                                                            return

                                                        return (
                                                            <tr>
                                                                <td>
                                                                    {`${singleProductDiscount?.product?.name} ${singleProductDiscount?.product?.dose}`}
                                                                </td>
                                                                <td>
                                                                    {convertToTitleCase(
                                                                        singleProductDiscount
                                                                            ?.product
                                                                            ?.category
                                                                    )}
                                                                </td>
                                                                <td className="nowrap">
                                                                    {`BDT ${singleProductDiscount?.product?.cost_per_unit}`}
                                                                </td>
                                                                <td className="nowrap">
                                                                    {`BDT ${singleProductDiscount?.product?.rate_per_unit}`}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        singleProductDiscount?.stock
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        singleProductDiscount?.discount_rate
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        singleProductDiscount?.discount_percentage
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {permissions
                                                                        ?.features?.[
                                                                        PERMISSION_FEATURE
                                                                            .DISCOUNT_SETTINGS
                                                                    ] ===
                                                                        PERMISSION_TYPE.MANAGE && (
                                                                        <div className="cardHeaderRight">
                                                                            {isCardActiveId ===
                                                                            singleProductDiscount.id ? (
                                                                                <span
                                                                                    onClick={() =>
                                                                                        setIsCardActiveId(
                                                                                            undefined
                                                                                        )
                                                                                    }>
                                                                                    <CloseIcon />
                                                                                </span>
                                                                            ) : (
                                                                                <span
                                                                                    onClick={() =>
                                                                                        setIsCardActiveId(
                                                                                            singleProductDiscount.id
                                                                                        )
                                                                                    }>
                                                                                    <DotsIcon />
                                                                                </span>
                                                                            )}
                                                                            {isCardActiveId ===
                                                                                singleProductDiscount.id && (
                                                                                <ul
                                                                                    className={`cardActionsWrapper ${
                                                                                        isCardActiveId ===
                                                                                        singleProductDiscount.id
                                                                                            ? 'show'
                                                                                            : 'hide'
                                                                                    }`}>
                                                                                    {/* <li className="actionItem">
                                                                                    Edit
                                                                                </li> */}
                                                                                    <li
                                                                                        className="actionItem"
                                                                                        onClick={() => {
                                                                                            handleDiscountDelete(
                                                                                                singleProductDiscount
                                                                                            )
                                                                                        }}>
                                                                                        Delete
                                                                                    </li>
                                                                                </ul>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                )}

                                                {/* <tr>
                                                <td>Mesacol 300 mg</td>
                                                <td>Medicine</td>
                                                <td className="nowrap">
                                                    BDT 12,000
                                                </td>
                                                <td className="nowrap">
                                                    BDT 25,000
                                                </td>
                                                <td>200</td>
                                                <td>200</td>
                                                <td>10</td>
                                                <td>
                                                    <DotsIcon />
                                                </td>
                                            </tr> */}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        )}
                        {discountType === DISCOUNT_TYPE.CAMPAIGN && (
                            <div className="tabPane">
                                {!addCampaignActive &&
                                    !viewCampaignActiveId && (
                                        <>
                                            {activeCampaignData?.id && (
                                                <div
                                                    className="discountCardWrapper mb-4"
                                                    onClick={() =>
                                                        handleCampaignClick(
                                                            activeCampaignData.id
                                                        )
                                                    }>
                                                    <div className="cardWrapperHeading">
                                                        Active Now
                                                    </div>
                                                    <div className="discountCard active dFlex aiCenter jcSpaceBetween">
                                                        <div className="discountDetails dFlex">
                                                            <DiscountBag />
                                                            <div className="ml-4">
                                                                <div className="discountDuration">
                                                                    {`${formatDate(
                                                                        activeCampaignData.start_date
                                                                    )} - ${formatDate(
                                                                        activeCampaignData.end_date
                                                                    )}`}
                                                                </div>
                                                                <div className="discountName">
                                                                    {
                                                                        activeCampaignData.name
                                                                    }
                                                                </div>
                                                                <div className="discountProducts">
                                                                    {`No. of Products: ${activeCampaignData.num_of_products}`}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="discount">
                                                            {`${activeCampaignData.discount_percentage}% Off`}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {previousCampaignList?.length >
                                                0 && (
                                                <div className="discountCardWrapper mb-5">
                                                    <div className="cardWrapperHeading">
                                                        Previous
                                                    </div>
                                                    <div className="dFlex">
                                                        {previousCampaignList?.map(
                                                            campaign => {
                                                                return (
                                                                    <div className="discountCard previous dFlex aiCenter jcSpaceBetween">
                                                                        <div className="discountDetails dFlex">
                                                                            <div className="">
                                                                                <div className="discountDuration">
                                                                                    {`${formatDate(
                                                                                        campaign.start_date
                                                                                    )} - ${formatDate(
                                                                                        campaign.end_date
                                                                                    )}`}
                                                                                </div>
                                                                                <div className="discountName">
                                                                                    {
                                                                                        campaign.name
                                                                                    }
                                                                                </div>
                                                                                <div className="discountProducts">
                                                                                    {`No. of Products: ${campaign.num_of_products}`}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="discount">
                                                                            {`${campaign.discount_percentage}% Off`}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                {addCampaignActive && (
                                    <div className="form mb-5">
                                        <div className="formInline dFlex flexNowrap">
                                            <div className="formGroup formFloating fgrow1 maxWidth relative">
                                                <input
                                                    className="formControl"
                                                    placeholder="Discount Name"
                                                    type="text"
                                                    value={campaignDiscountName}
                                                    onChange={event => {
                                                        setCampaignDiscountName(
                                                            event.target.value
                                                        )
                                                    }}
                                                />
                                                <label for="location">
                                                    Discount Name
                                                </label>
                                                {isErrorFieldVisible &&
                                                    !campaignDiscountName && (
                                                        <div className="invalidFeedback">
                                                            Please Enter
                                                            Discount Name.
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="formGroup formFloating fgrow1 maxWidth ml-5 formInputText">
                                                <div className="preInputGroup backColor">
                                                    <div className="preInputText">
                                                        %
                                                    </div>
                                                </div>
                                                <input
                                                    type="text"
                                                    className="formControl"
                                                    id="email"
                                                    placeholder="Percentage"
                                                    value={
                                                        campaignDiscountPercentage
                                                    }
                                                    onChange={event => {
                                                        setCampaignDiscountPercentage(
                                                            event.target.value
                                                        )
                                                    }}
                                                    required
                                                />
                                                <label for="address">
                                                    Percentage
                                                </label>
                                                {isErrorFieldVisible &&
                                                    !campaignDiscountPercentage && (
                                                        <div className="invalidFeedback">
                                                            Please Enter
                                                            Percentage.
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                        <div className="formInline dFlex flexNowrap">
                                            <div className="formGroup formFloating fgrow1 maxWidth">
                                                <input
                                                    type="date"
                                                    className="formControl"
                                                    id="invoice"
                                                    placeholder="Date"
                                                    value={campaignStartDate}
                                                    onChange={event => {
                                                        setCampaignStartDate(
                                                            event.target.value
                                                        )
                                                    }}
                                                    required
                                                />
                                                <label for="invoice">
                                                    Start Date
                                                </label>
                                                {isErrorFieldVisible &&
                                                    !campaignStartDate && (
                                                        <div class="invalidFeedback">
                                                            Please select a
                                                            valid Date.
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="formGroup formFloating fgrow1 maxWidth ml-5">
                                                <input
                                                    type="date"
                                                    className="formControl"
                                                    id="invoice"
                                                    placeholder="Date"
                                                    value={campaignEndDate}
                                                    onChange={event => {
                                                        setCampaignEndDate(
                                                            event.target.value
                                                        )
                                                    }}
                                                    required
                                                />
                                                <label for="invoice">
                                                    End Date
                                                </label>
                                                {isErrorFieldVisible &&
                                                    !campaignEndDate && (
                                                        <div class="invalidFeedback">
                                                            Please select a
                                                            valid Date.
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                        <div className="mb-5">
                                            <table className="orderBook">
                                                <thead>
                                                    <tr>
                                                        <td width="5%"></td>
                                                        <td width="20%">
                                                            Medicine
                                                        </td>
                                                        <td width="15%">
                                                            Type
                                                        </td>
                                                        <td width="12%">
                                                            Cost/Unit
                                                        </td>
                                                        <td width="12%">
                                                            Rate/Unit
                                                        </td>
                                                        <td width="12%">
                                                            Stock
                                                        </td>
                                                        <td width="12%">
                                                            Discounted Rate
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {inventory?.length > 0 &&
                                                        inventory.map(
                                                            product => {
                                                                return (
                                                                    <tr>
                                                                        <td>
                                                                            <div class="formCheck">
                                                                                <input
                                                                                    class="formCheckInput"
                                                                                    type="checkbox"
                                                                                    name="flexRadioDefault"
                                                                                    checked={
                                                                                        selectedProducts.findIndex(
                                                                                            id =>
                                                                                                id ===
                                                                                                product.id
                                                                                        ) >
                                                                                        -1
                                                                                    }
                                                                                    id=""
                                                                                    onChange={event => {
                                                                                        handleProductCheckboxClick(
                                                                                            product,
                                                                                            event
                                                                                                .target
                                                                                                .checked
                                                                                        )
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            {`${product.name} ${product.dose} `}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                product.category
                                                                            }
                                                                        </td>
                                                                        <td className="nowrap">
                                                                            {`BDT ${product.cost_per_unit}`}
                                                                        </td>
                                                                        <td className="nowrap">
                                                                            {`BDT ${product.rate_per_unit}`}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                product.quantity
                                                                            }
                                                                        </td>
                                                                        <td className="nowrap">
                                                                            BDT{' '}
                                                                            {campaignDiscountPercentage
                                                                                ? parseFloat(
                                                                                      (campaignDiscountPercentage /
                                                                                          100) *
                                                                                          product.rate_per_unit
                                                                                  ).toFixed(
                                                                                      2
                                                                                  )
                                                                                : ''}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}
                                {viewCampaignActiveId &&
                                    campaignDetailsData?.id && (
                                        <>
                                            <div className="discountCardWrapper mb-4">
                                                <div className="discountCard edit dFlex aiCenter jcSpaceBetween">
                                                    <div className="discountDetails dFlex">
                                                        <div className="">
                                                            <div className="discountName">
                                                                {
                                                                    campaignDetailsData?.name
                                                                }
                                                            </div>
                                                            <div className="discountDuration">
                                                                {`${formatDate(
                                                                    campaignDetailsData?.start_date
                                                                )} - ${formatDate(
                                                                    campaignDetailsData?.end_date
                                                                )}`}
                                                            </div>
                                                            <div className="discountProducts">
                                                                {`No. of Products: ${campaignDetailsData?.num_of_products}`}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="dFlex">
                                                        <div className="discount">
                                                            {`${campaignDetailsData?.discount_percentage}% Off`}
                                                        </div>
                                                        {permissions
                                                            ?.features?.[
                                                            PERMISSION_FEATURE
                                                                .DISCOUNT_SETTINGS
                                                        ] ===
                                                            PERMISSION_TYPE.MANAGE && (
                                                            <div className="cardHeaderRight ml-5">
                                                                {viewCampaignCardActionActive ? (
                                                                    <span
                                                                        onClick={() =>
                                                                            setViewCampaignCardActionActive(
                                                                                false
                                                                            )
                                                                        }>
                                                                        <CloseIcon />
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        onClick={() =>
                                                                            setViewCampaignCardActionActive(
                                                                                true
                                                                            )
                                                                        }>
                                                                        <DotsIcon />
                                                                    </span>
                                                                )}
                                                                {viewCampaignCardActionActive && (
                                                                    <ul
                                                                        className={`cardActionsWrapper ${
                                                                            viewCampaignCardActionActive
                                                                                ? 'show'
                                                                                : 'hide'
                                                                        }`}>
                                                                        <li
                                                                            className="actionItem"
                                                                            onClick={() =>
                                                                                handleCampaignEditClick(
                                                                                    campaignDetailsData,
                                                                                    'EDIT'
                                                                                )
                                                                            }>
                                                                            Edit
                                                                        </li>
                                                                        <li
                                                                            className="actionItem"
                                                                            onClick={() =>
                                                                                handleCampaignEditClick(
                                                                                    campaignDetailsData,
                                                                                    'DELETE'
                                                                                )
                                                                            }>
                                                                            Delete
                                                                        </li>
                                                                    </ul>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-5">
                                                <table className="orderBook">
                                                    <thead>
                                                        <tr>
                                                            <td width="20%">
                                                                Medicine
                                                            </td>
                                                            <td width="15%">
                                                                Type
                                                            </td>
                                                            <td width="12%">
                                                                Cost/Unit
                                                            </td>
                                                            <td width="12%">
                                                                Rate/Unit
                                                            </td>
                                                            <td width="12%">
                                                                Stock
                                                            </td>
                                                            <td width="12%">
                                                                Discounted Rate
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {campaignDetailsData?.product_details?.map(
                                                            product => {
                                                                return (
                                                                    <tr>
                                                                        <td>
                                                                            {`${product?.product.name} ${product?.product?.dose} `}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                product
                                                                                    ?.product
                                                                                    .category
                                                                            }
                                                                        </td>
                                                                        <td className="nowrap">
                                                                            {`BDT ${product?.product?.cost_per_unit}`}
                                                                        </td>
                                                                        <td className="nowrap">
                                                                            {`BDT ${product?.product?.rate_per_unit}`}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                product
                                                                                    ?.product
                                                                                    ?.quantity
                                                                            }
                                                                        </td>
                                                                        <td className="nowrap">
                                                                            BDT{' '}
                                                                            {campaignDetailsData?.discount_percentage
                                                                                ? parseFloat(
                                                                                      (campaignDetailsData?.discount_percentage /
                                                                                          100) *
                                                                                          product
                                                                                              ?.product
                                                                                              ?.rate_per_unit
                                                                                  ).toFixed(
                                                                                      2
                                                                                  )
                                                                                : ''}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                    )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        alert: state.alertReducer.alert,
        loginData: state.loginDataReducer?.loginData
    }
}
const mapDispatchToProps = dispatch => {
    return {
        dispatchSingleProductDiscountAction: payload =>
            dispatch(addSingleProductDiscountAction(payload)),
        dispatchCampaignDiscountAction: payload =>
            dispatch(addCampaignDiscountAction(payload)),
        dispatchUpdateCampaignDiscountAlert: payload =>
            dispatch(updateCampaignDiscountAction(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Discount)
