import React from 'react'

const FilterIconBright = (props) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Filter 1">
            <path id="Vector" d="M4.7002 12.3008H20.3002" stroke="white" stroke-width="1.4" stroke-linecap="round" />
            <path id="Vector_2" d="M4.7002 18.3008H20.3002" stroke="white" stroke-width="1.4" stroke-linecap="round" />
            <path id="Vector_3" d="M4.7002 6.30078H20.3002" stroke="white" stroke-width="1.4" stroke-linecap="round" />
            <path id="Vector_4" d="M9.0002 8.29922C10.2705 8.29922 11.3002 7.26947 11.3002 5.99922C11.3002 4.72896 10.2705 3.69922 9.0002 3.69922C7.72994 3.69922 6.7002 4.72896 6.7002 5.99922C6.7002 7.26947 7.72994 8.29922 9.0002 8.29922Z" fill="#6E6E6E" stroke="white" stroke-width="1.4" />
            <path id="Vector_5" d="M16.0002 14.2992C17.2705 14.2992 18.3002 13.2695 18.3002 11.9992C18.3002 10.729 17.2705 9.69922 16.0002 9.69922C14.7299 9.69922 13.7002 10.729 13.7002 11.9992C13.7002 13.2695 14.7299 14.2992 16.0002 14.2992Z" fill="#6E6E6E" stroke="white" stroke-width="1.4" />
            <path id="Vector_6" d="M9.0002 20.2992C10.2705 20.2992 11.3002 19.2695 11.3002 17.9992C11.3002 16.729 10.2705 15.6992 9.0002 15.6992C7.72994 15.6992 6.7002 16.729 6.7002 17.9992C6.7002 19.2695 7.72994 20.2992 9.0002 20.2992Z" fill="#6E6E6E" stroke="white" stroke-width="1.4" />
        </g>
    </svg>
)

export default FilterIconBright
