import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '../../assets/CloseIcon'
import {
    HEADER_CONFIG,
    HEADER_CONST
} from '../../common/constant/common-constant'
import {
    ADD_PRODUCT_RECEIVING_FLOW,
    PAGE_CONST,
    PERMISSION_FEATURE,
    PERMISSION_SCREEN,
    PERMISSION_TYPE,
    PRODUCT_DETAIL_FIELDS,
    PRODUCT_STRIP_MEDICAL_CATEGORY,
    PROFILE_CONST
} from '../../common/constant/page-constant'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import { redirect } from '../../common/utils/redirectionUtils'
import Card from '../Common/Card/Card'
import MobileHeader from '../Common/Header/MobileHeader'
import Select from 'react-select'

import './OrderBook.scss'
import ViewOrderBook from './ViewOrderBook'
import {
    addOrderBookHelper,
    getOrderBookDetailsHelper,
    getOrderBookHelper,
    getOrderBookSearchResultsHelper,
    getOrderBookSearchSuggestionsHelper
} from '../../helper/personal-details-helper'
import AcknowledgeScreen from '../Common/AcknowledgeScreen/AcknowledgeScreen'
import CheckPrimaryIcon from '../../assets/CheckPrimaryIcon'
import {
    addProductReceivingAlertAction,
    newOrderBookAction
} from '../../modules/actions/action'
import {
    convertToTitleCase,
    formatDate,
    formatTime
} from '../../common/utils/appUtils'
import AddProductReceiving from '../ProductReceiving/AddProductReceiving'
import { addProductReceivingHelper } from '../../helper/home-helper'

const OrderBook = props => {
    const navigate = useNavigate()
    const isMobile = useCheckMobileScreen()

    const [viewDrawer, setViewDrawer] = useState(false)
    const [open, setOpen] = useState(false)
    const [headerOptions, setHeaderOptions] = useState({
        [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
        [HEADER_CONFIG.TITLE]: HEADER_CONST.TERMS_AND_CONDITIONS,
        [HEADER_CONFIG.HANDLE_BACK_FUNC]: () =>
            redirect(navigate, PAGE_CONST.HOME)
    })
    const [isAddOrderActive, setIsAddOrderActive] = useState(false)
    const [orderBookData, setOrderBookData] = useState([])
    const [searchType, setSearchType] = useState('')
    const [searchTerm, setSearchTerm] = useState('')
    const [selectedSearchTerm, setSelectedSearchTerm] = useState(undefined)
    const [searchSuggestions, setSearchSuggestions] = useState([])
    const [searchResults, setSearchResults] = useState([])
    const [selectedProducts, setSelectedProducts] = useState([])
    const [viewAddedProduct, setViewAddedProduct] = useState(false)
    const [searchResultDataList, setSearchResultDataList] = useState([])
    const [recentSearchDataList, setRecentSearchDataList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [prevSearchTermLength, setPrevSearchTermLength] = useState(0)
    const [timer, setTimer] = useState()
    const [apiCalled, setApiCalled] = useState(false)
    const [orderBookAlert, setOrderBookAlert] = useState(false)
    const [addProductReceivingAlert, setAddProductReceivingAlert] =
        useState(false)
    const [orderDetailsData, setOrderDetailsData] = useState([])
    const [productReceivingData, setProductReceivingData] = useState({})
    const [pROpen, setPROpen] = useState(false)
    const [serverError, setServerError] = useState(undefined)
    const [permissions, setPermissions] = useState({})
    // const inputRef = useRef(null)

    useEffect(() => {
        console.log('in useEffect of order book')
        getOrderBook()
    }, [])

    useEffect(() => {
        let addOrderBookAlert = props.alert?.addOrderBookAlert
        setOrderBookAlert(addOrderBookAlert)
    }, [props.alert?.addOrderBookAlert])

    useEffect(() => {
        let addProductReceivingAlert = props.alert?.addProductReceivingAlert
        setAddProductReceivingAlert(addProductReceivingAlert)
    }, [props.alert?.addProductReceivingAlert])

    useEffect(() => {
        handleSearchSuggestions(searchTerm)
        return () => clearTimeout(timer)
    }, [searchTerm])

    const handleHeaderConfigUpdate = (title, handleBackFunc) => {
        setHeaderOptions({
            [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
            [HEADER_CONFIG.TITLE]: title,
            [HEADER_CONFIG.HANDLE_BACK_FUNC]: () => handleBackFunc()
        })
    }

    useEffect(() => {
        if (!isAddOrderActive && !viewDrawer && !pROpen) {
            handleHeaderConfigUpdate(HEADER_CONST.ORDER_BOOK, () =>
                props?.onBackClick()
            )
            return
        }

        handleHeaderConfigUpdate(HEADER_CONST.ADD_ORDER_BOOK, () => {
            setIsAddOrderActive(false)
            setViewDrawer(false)
            setPROpen(false)
        })
    }, [isAddOrderActive, viewDrawer, pROpen])

    useEffect(() => {
        if (props.loginData.permissions) {
            let permission = props.loginData.permissions.filter(
                permission =>
                    permission.screen === PERMISSION_SCREEN.MANAGE_STORE
            )[0]

            if (permission) setPermissions(permission)
        }
    }, [props.loginData])

    const handleDrawerToggle = () => {
        setViewDrawer(!viewDrawer)
        setOpen(!open)
    }

    const getOrderBook = async () => {
        try {
            let data = await getOrderBookHelper()

            setOrderBookData(data)
        } catch (err) {}
    }

    const handleSearchSuggestions = searchTerm => {
        console.log('searchTerm : ', searchTerm, ' !searchTerm', !searchTerm)
        if (!searchTerm || searchTerm?.length === 0) {
            clearSearchData()
            return
        }

        const fetchSearchResults = async () => {
            console.log('in fetchSearchResults')
            setIsLoading(true)
            try {
                const results = await getOrderBookSearchSuggestionsHelper(
                    searchType,
                    searchTerm
                )
                // dispatchRecentSearchListAction(searchTerm)
                setSearchSuggestions(results)
            } catch (error) {
                console.error('Error fetching search results:', error)
            }
            setIsLoading(false)
        }

        // Check if search term length changes by more than 2 characters
        if (
            searchTerm?.length > 0 &&
            Math.abs(searchTerm.length - prevSearchTermLength) > 2
        ) {
            clearTimeout(timer)
            setTimer(null)
            // isTimerSet = false
            console.log('hitting api after 2 character change')
            setPrevSearchTermLength(() => searchTerm.length)
            fetchSearchResults()
            // setApiCalled(() => apiCalled)
        } else {
            console.log('in else timer: ', timer)
            if (!timer) {
                let tr = setTimeout(() => {
                    if (searchTerm.length > 0) {
                        console.log('hitting api after 1 sec')
                        setPrevSearchTermLength(searchTerm.length)
                        fetchSearchResults()
                        // isTimerSet = false
                    }
                }, 1000)
                // setApiCalled(true);
                setTimer(tr)
            } else {
                // Clear the existing timer and set a new one
                console.log('clearing timeout and setting a new one')
                clearTimeout(timer)
                let tr = setTimeout(() => {
                    if (searchTerm.length > 0) {
                        console.log('hitting api after 1 sec')
                        setPrevSearchTermLength(searchTerm.length)
                        fetchSearchResults()
                        // isTimerSet = false
                    }
                }, 1000)
                setTimer(tr)
            }
        }
    }

    const handleSearch = async () => {
        setViewAddedProduct(false)
        try {
            let data = await getOrderBookSearchResultsHelper(
                searchType,
                selectedSearchTerm
            )

            setSearchResults(data)
        } catch (err) {}
    }

    const handleProductCheckboxClick = (product, checked) => {
        let selectedProductList = selectedProducts || []

        let index = selectedProductList.findIndex(p => p.id === product.id)

        if (checked) {
            if (index < 0) selectedProductList.push(product)
        } else {
            if (index > -1) selectedProductList.splice(index, 1)
        }

        setSelectedProducts([...selectedProductList])

        console.log('selectedProductList: ', selectedProductList)
    }

    const handleQuanitityChange = (product, quantity) => {
        let selectedProductList = selectedProducts || []
        let index = selectedProductList.findIndex(p => p.id === product.id)

        if (index > -1) {
            product = selectedProductList[index]
            product.quantity = quantity

            selectedProductList[index] = product
        }
    }

    const getSelectedProductTotalAmount = () => {
        let amount = 0

        selectedProducts.map(product => {
            amount += (product?.cost_per_unit || 0) * product?.quantity
        })

        return amount
    }

    const getSelectedProductTotalQuantity = () => {
        let quantity = 0

        selectedProducts.map(product => {
            quantity += parseInt(product?.quantity)
        })

        return quantity
    }

    const addOrderBook = async () => {
        if (!viewAddedProduct) {
            setViewAddedProduct(true)
            return
        }

        let supplier_id
        let productDetails = selectedProducts?.map(product => {
            supplier_id = product?.pharma_company_id

            return {
                id: product?.id,
                quantity: product?.quantity,
                amount: product?.cost_per_unit
            }
        })
        let reqData = {
            supplier_id: supplier_id,
            product_details: productDetails,
            total_quantity: getSelectedProductTotalQuantity(),
            total_amount: getSelectedProductTotalAmount()
        }
        try {
            let data = await addOrderBookHelper(reqData)

            props.dispatchNewOrderBookAction(true)
            setTimeout(() => {
                props.dispatchNewOrderBookAction(false)
            }, 5000)

            getOrderBook()
            setSearchResults([])
            setViewAddedProduct(false)
            setIsAddOrderActive(false)
        } catch (err) {}
    }

    const clearSearchData = () => {
        setSearchSuggestions([])
        setSearchResultDataList([])
        setPrevSearchTermLength(0)
        setApiCalled(() => false)
        clearTimeout(timer)
        setTimer(() => null)
        // setApiCalled(false)
    }

    const handleCardClick = async id => {
        try {
            let data = await getOrderBookDetailsHelper(id)

            setOrderDetailsData(data)
            handleDrawerToggle()
        } catch (err) {}
    }

    const handlePRDrawerToggle = () => {
        setPROpen(!setPROpen)
    }

    const convertDataForProductReceiving = orderBookData => {
        console.log('orderDetailData: ', orderDetailsData)
        let supplierData = orderDetailsData.pharma
        let productArray = orderDetailsData.product_details?.map(pDetails => {
            let productObj = {
                ...pDetails.product
            }

            if (
                PRODUCT_STRIP_MEDICAL_CATEGORY.includes(
                    pDetails.product?.[PRODUCT_DETAIL_FIELDS.MEDICAL_CATEGORY]
                )
            ) {
                productObj[PRODUCT_DETAIL_FIELDS.NUMBER_OF_STRIP] =
                    pDetails.quantity
            } else {
                productObj[PRODUCT_DETAIL_FIELDS.QUANTITY] = pDetails.quantity
            }

            return productObj
        })

        let prData = { supplierData, productArray }
        setProductReceivingData(prData)
    }

    const handleConfirmClick = () => {
        setViewDrawer(!viewDrawer)

        convertDataForProductReceiving()
        setPROpen(true)
    }

    const handlePRSubmit = data => {
        data = {
            ...data,
            order_book_id: orderDetailsData.id
        }

        addProductReceivingHelper(data)
            .then(res => {
                console.log(res)

                props.addProductReceivingAlertAction(true)
                setTimeout(() => {
                    props.addProductReceivingAlertAction(false)
                }, 5000)
                getOrderBook()
                setProductReceivingData({})
                handlePRDrawerToggle()
            })
            .catch(errMsg => {
                setServerError(errMsg)
            })
    }

    return (
        <>
            {isMobile && <MobileHeader headerOptions={headerOptions} />}
            {addProductReceivingAlert && (
                <AcknowledgeScreen
                    icon={<CheckPrimaryIcon />}
                    alertType="success"
                    alertMessage="New Product Receiving added Successfully!"
                    main="New Product Receiving added Successfully!"
                    sub="Lorem ipsum dolor sit amet consectetur. Ligula ut volutpat morbi ullamcorper"
                />
            )}
            {orderBookAlert && (
                <AcknowledgeScreen
                    icon={<CheckPrimaryIcon />}
                    main={'New Order placed Successfully!'}
                    sub="Lorem ipsum dolor sit amet consectetur. Ligula ut volutpat morbi ullamcorper"
                    success
                    alertType="success"
                    alertMessage={'New Order placed Successfully!'}
                />
            )}
            <div className="pageBodyRightPanel">
                {!isAddOrderActive && (
                    <>
                        <div className="pageBodyRightPanelHeader dFlex jcSpaceBetween aiCenter mb-5">
                            <div className="pageBodyRightPanelHeading">
                                Order Book
                            </div>
                            {permissions?.features?.[
                                PERMISSION_FEATURE.ORDER_BOOK
                            ] === PERMISSION_TYPE.MANAGE && (
                                <div className="addProductBtn dFlex aiCenter">
                                    <button
                                        className="btn btnPrimary"
                                        onClick={() =>
                                            setIsAddOrderActive(true)
                                        }>
                                        ADD
                                    </button>
                                    {/* <div className="pointer ml-4">
                                    <CloseIcon />
                                </div> */}
                                </div>
                            )}
                        </div>
                        <div className="productLlistWrap dFlex">
                            {orderBookData?.map(orderBook => {
                                return (
                                    <div className="cardWrap">
                                        <Card
                                            handleClick={() =>
                                                handleCardClick(orderBook.id)
                                            }
                                            cardCrown={convertToTitleCase(
                                                orderBook?.status || ' '
                                            )}
                                            primary={
                                                orderBook?.status ===
                                                'COMPLETED'
                                            }
                                            cardRight={`BDT ${orderBook.total_amount}`}
                                            headerSup={`${formatDate(
                                                orderBook?.created_at
                                            )} | ${formatTime(
                                                orderBook?.created_at
                                            )}`}
                                            headerMain={
                                                orderBook?.invoice_number &&
                                                `Invoice No: ${orderBook?.invoice_number}`
                                            }
                                            headerProduct={{
                                                productType: `Order No: ${orderBook?.order_no}`
                                            }}
                                            orderBook={{
                                                totalQuantity:
                                                    orderBook?.total_quantity
                                            }}
                                            productDesc={`Pharma name: ${orderBook?.pharma_name}`}
                                        />
                                    </div>
                                )
                            })}
                            {/* <div className="cardWrap">
                                <Card
                                    handleClick={handleDrawerToggle}
                                    cardCrown="Pending"
                                    cardRight="BDT 25,00,000"
                                    headerSup="28 Dec 2023 | 12:30 pm"
                                    headerMain="Invoice No: FFDR3456789"
                                    headerProduct={{ productType: '-' }}
                                    documents
                                    productDesc="Pharma name: Lorem ipose aet consectetur. Bibendum"
                                />
                            </div>
                            <div className="cardWrap">
                                <Card
                                    cardCrown="Online"
                                    online
                                    cardRight="BDT 25,00,000"
                                    headerSup="28 Dec 2023 | 12:30 pm"
                                    headerMain="Invoice No: FFDR3456789"
                                    headerProduct={{
                                        productType: 'Order No: FFDR3456789'
                                    }}
                                    documents
                                    productDesc="Pharma name: Lorem ipose aet consectetur. Bibendum"
                                />
                            </div>
                            <div className="cardWrap">
                                <Card
                                    cardCrown="Online"
                                    online
                                    cardRight="BDT 25,00,000"
                                    headerSup="28 Dec 2023 | 12:30 pm"
                                    headerMain="Invoice No: FFDR3456789"
                                    headerProduct={{
                                        productType: 'Order No: FFDR3456789'
                                    }}
                                    documents
                                    productDesc="Pharma name: Lorem ipose aet consectetur. Bibendum"
                                />
                            </div> */}
                        </div>
                    </>
                )}
                {/* <div className='pageBodyRightPanel'> */}
                {isAddOrderActive && (
                    <>
                        <div className="pageBodyRightPanelHeader dFlex jcSpaceBetween aiCenter mb-5">
                            <div className="pageBodyRightPanelHeading">
                                Add Order Book
                            </div>
                            <div className="addProductBtn dFlex aiCenter">
                                {searchResults && searchResults.length > 0 && (
                                    <button
                                        className="btn btnPrimary"
                                        onClick={() => addOrderBook()}>
                                        SAVE
                                    </button>
                                )}
                                <div
                                    className="pointer ml-4"
                                    onClick={() => setIsAddOrderActive(false)}>
                                    <CloseIcon />
                                </div>
                            </div>
                        </div>
                        {/* <div className=""> */}
                        <div className="dFlex flexNowrap ai-flex-start">
                            <div className="formGroup formFloating fgrow1 maxWidth">
                                <Select
                                    className="formSelect reactSelect"
                                    id="floatingSelect"
                                    // value={
                                    //     billDetails?.[
                                    //     BILL_DETAILS_FIELDS.PHARMA_COMPANY
                                    //     ]
                                    // }
                                    onChange={option => {
                                        setSearchType(option.name)
                                    }}
                                    options={[
                                        {
                                            label: 'Pharma Company',
                                            name: 'PHARMA_COMPANY'
                                        },
                                        {
                                            label: 'Product Name',
                                            name: 'PRODUCT_NAME'
                                        },
                                        {
                                            label: 'Product Generic Name',
                                            name: 'GENERIC_NAME'
                                        }
                                    ]}
                                    getOptionLabel={option => option.label}
                                    placeholder="Search By"
                                    styles={{
                                        control: base => ({
                                            border: 0,
                                            display: 'flex',
                                            paddingTop: '20px'
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused
                                                ? 'rgb(222, 235, 255)'
                                                : state.isSelected
                                                ? '#FFF'
                                                : 'inherit',
                                            color: '#212529',
                                            '&:hover': {
                                                backgroundColor:
                                                    state.isSelected
                                                        ? '#DEEBFF'
                                                        : 'rgb(222, 235, 255)'
                                            }
                                        })
                                    }}
                                    // menuIsOpen={true}
                                    // isClearable
                                />
                                <label for="floatingSelect">Search By</label>
                            </div>
                            <div className="formGroup formFloating maxWidth fgrow1 ml-4">
                                <Select
                                    className="formSelect reactSelect"
                                    id="floatingSelect"
                                    // value={
                                    //     billDetails?.[
                                    //     BILL_DETAILS_FIELDS.PHARMA_COMPANY
                                    //     ]
                                    // }
                                    onInputChange={
                                        option => {
                                            if (option) setSearchTerm(option)
                                        }
                                        // handleBillDetailUpdate(
                                        //     BILL_DETAILS_FIELDS.PHARMA_COMPANY,
                                        //     option
                                        // )
                                    }
                                    onChange={option => {
                                        setSelectedSearchTerm(option?.value)
                                    }}
                                    options={searchSuggestions}
                                    getOptionLabel={option => option.label}
                                    placeholder={`Select ${convertToTitleCase(
                                        searchType
                                    )}`}
                                    styles={{
                                        control: base => ({
                                            border: 0,
                                            display: 'flex',
                                            paddingTop: '20px'
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused
                                                ? 'rgb(222, 235, 255)'
                                                : state.isSelected
                                                ? '#FFF'
                                                : 'inherit',
                                            color: '#212529',
                                            '&:hover': {
                                                backgroundColor:
                                                    state.isSelected
                                                        ? '#DEEBFF'
                                                        : 'rgb(222, 235, 255)'
                                            }
                                        })
                                    }}
                                    // menuIsOpen={true}
                                    isClearable
                                />
                                <label for="floatingSelect">
                                    {`Select ${convertToTitleCase(searchType)}`}
                                </label>
                            </div>

                            <div className="addProductBtn dFlex aiCenter ml-4">
                                <button
                                    className="btn btnPrimary height"
                                    onClick={() => handleSearch()}>
                                    Search
                                </button>
                            </div>
                        </div>
                        {/* <div className="formGroup formFloating fgrow1 relative">
                            <input
                                className="autocomplete-input formControl"
                                placeholder="Search by Product Name OR Generic Name"
                                type="text"
                                id="location"
                            />
                            <label for="location">
                                Search by Product Name OR Generic Name
                            </label>
                        </div> */}
                        {!viewAddedProduct && (
                            <div className="mb-5">
                                {searchResults && searchResults.length > 0 && (
                                    <table className="orderBook">
                                        <thead>
                                            <tr>
                                                <td width="5%"></td>
                                                <td>Medicine</td>
                                                <td>Generic Name</td>
                                                <td>Pharma Company</td>
                                                <td>Type</td>
                                                <td>Cost/Unit</td>
                                                <td>Rate/Unit</td>
                                                <td>Stock</td>
                                                <td width="9%">Order Qty</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {searchResults.map(searchResult => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            <div class="formCheck">
                                                                <input
                                                                    class="formCheckInput"
                                                                    type="checkbox"
                                                                    name="flexRadioDefault"
                                                                    disabled={
                                                                        selectedProducts?.length >
                                                                            0 &&
                                                                        selectedProducts?.[0]
                                                                            ?.pharma_company !==
                                                                            searchResult?.pharma_company
                                                                    }
                                                                    checked={
                                                                        selectedProducts.findIndex(
                                                                            product =>
                                                                                product.id ===
                                                                                searchResult.id
                                                                        ) > -1
                                                                    }
                                                                    id=""
                                                                    onChange={event => {
                                                                        handleProductCheckboxClick(
                                                                            searchResult,
                                                                            event
                                                                                .target
                                                                                .checked
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {`${searchResult.name} ${searchResult?.dose}`}
                                                        </td>
                                                        <td>
                                                            {
                                                                searchResult.generic_name
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                searchResult.pharma_company
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                searchResult.category
                                                            }
                                                        </td>
                                                        <td className="nowrap">
                                                            {searchResult.cost_per_unit
                                                                ? `BDT ${searchResult.cost_per_unit}`
                                                                : '-'}
                                                            {/* {searchResult.cost_per_unit ? `BDT ${searchResult.cost_per_unit : ''}`} */}
                                                        </td>
                                                        <td className="nowrap">
                                                            {searchResult.cost_per_unit
                                                                ? `BDT ${searchResult.rate_per_unit}`
                                                                : '-'}
                                                            {/* {searchResult.cost_per_unit ? `BDT ${searchResult.cost_per_unit : ''}`} */}
                                                        </td>
                                                        <td>
                                                            {searchResult.stock}
                                                        </td>
                                                        {/* <td>10</td> */}
                                                        <td>
                                                            <div className="formGroup mb-0">
                                                                {selectedProducts.findIndex(
                                                                    product =>
                                                                        product.id ===
                                                                        searchResult.id
                                                                ) > -1 ? (
                                                                    <input
                                                                        className="formControl formsm"
                                                                        placeholder="Quantity"
                                                                        value={
                                                                            selectedProducts.filter(
                                                                                product =>
                                                                                    product.id ===
                                                                                    searchResult.id
                                                                            )?.[0]
                                                                                ?.quantity
                                                                        }
                                                                        onChange={event =>
                                                                            handleQuanitityChange(
                                                                                searchResult,
                                                                                event
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                    />
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            {/* <tr>
                                        <td>
                                            <div class="formCheck">
                                                <input
                                                    class="formCheckInput"
                                                    type="checkbox"
                                                    name="flexRadioDefault"
                                                    id=""
                                                />
                                            </div>
                                        </td>
                                        <td>Mesacol 300 mg</td>
                                        <td>Mesacol 300 mg</td>
                                        <td>Life Medi care</td>
                                        <td>Medicine</td>
                                        <td className="nowrap">BDT 20,000</td>
                                        <td className="nowrap">BDT 20,000</td>
                                        <td>200</td>
                                        <td>10</td>
                                    </tr> */}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        )}
                    </>
                )}
                {viewAddedProduct && (
                    <div className="mb-5">
                        <table className="orderBook">
                            <thead>
                                <tr>
                                    <td>Medicines</td>
                                    <td width="10%" className="alignright">
                                        Qty
                                    </td>
                                    <td width="20%" className="alignright">
                                        Amount
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedProducts?.map(product => {
                                    return (
                                        <tr>
                                            <td>{product?.name}</td>
                                            <td className="alignright">
                                                {product?.quantity}
                                            </td>
                                            <td className="nowrap alignright">
                                                {product?.cost_per_unit
                                                    ? `BDT ${
                                                          product?.cost_per_unit *
                                                          product?.quantity
                                                      }`
                                                    : 0}
                                            </td>
                                        </tr>
                                    )
                                })}

                                {/* <tr>
                                    <td>Mesacol 300 mg</td>
                                    <td className="alignright">200</td>
                                    <td className="nowrap alignright">
                                        BDT 20,000
                                    </td>
                                </tr> */}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Total</td>
                                    <td
                                        colSpan={2}
                                        className="alignright strong">
                                        {getSelectedProductTotalAmount()}
                                        {/* BDT 8765876 */}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                )}
            </div>
            <ViewOrderBook
                handleDrawerToggle={() => setViewDrawer(!viewDrawer)}
                open={viewDrawer}
                drawerButton={
                    permissions?.features?.[PERMISSION_FEATURE.ORDER_BOOK] ===
                    PERMISSION_TYPE.MANAGE
                }
                data={orderDetailsData}
                onConfirmClick={() => handleConfirmClick()}
            />
            {pROpen && (
                <AddProductReceiving
                    open={pROpen}
                    handleDrawerToggle={handlePRDrawerToggle}
                    handleSubmit={handlePRSubmit}
                    flow={ADD_PRODUCT_RECEIVING_FLOW.ORDER_BOOK}
                    defaultData={productReceivingData}
                    serverError={serverError}
                />
            )}
            {/* </div> */}
            {/* </div> */}
        </>
    )
}

const mapStateToProps = state => {
    return {
        alert: state.alertReducer.alert,
        loginData: state.loginDataReducer?.loginData
    }
}
const mapDispatchToProps = dispatch => {
    return {
        dispatchNewOrderBookAction: payload =>
            dispatch(newOrderBookAction(payload)),
        addProductReceivingAlertAction: payload =>
            dispatch(addProductReceivingAlertAction(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderBook)
