import React from 'react'

const SearchIcon = () => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Search">
            <path id="Vector" d="M11.6665 3.2085C13.265 3.2083 14.8307 3.66103 16.1824 4.51425C17.5341 5.36746 18.6164 6.58622 19.3038 8.0293C19.9912 9.47239 20.2556 11.0807 20.0664 12.6679C19.8773 14.2551 19.2422 15.7563 18.2348 16.9973L23.9515 22.714C24.1085 22.8699 24.2005 23.0795 24.2092 23.3006C24.2178 23.5217 24.1424 23.7378 23.998 23.9055C23.8537 24.0732 23.6512 24.18 23.4313 24.2044C23.2114 24.2288 22.9904 24.169 22.8128 24.037L22.7148 23.9518L16.997 18.2352C15.9457 19.0881 14.705 19.6763 13.3792 19.9502C12.0534 20.2241 10.6812 20.1758 9.37795 19.8093C8.07469 19.4428 6.87845 18.7688 5.88975 17.844C4.90106 16.9192 4.14879 15.7706 3.69618 14.4947C3.24357 13.2187 3.10384 11.8528 3.28872 10.5117C3.47361 9.17057 3.97771 7.89342 4.75867 6.78757C5.53963 5.68172 6.57462 4.77948 7.77667 4.15667C8.97872 3.53387 10.3127 3.2087 11.6665 3.2085ZM11.6665 4.9585C10.7856 4.9585 9.91323 5.13201 9.09934 5.46914C8.28545 5.80626 7.54593 6.30039 6.923 6.92332C6.30008 7.54625 5.80594 8.28577 5.46882 9.09966C5.13169 9.91355 4.95818 10.7859 4.95818 11.6668C4.95818 12.5478 5.13169 13.4201 5.46882 14.234C5.80594 15.0479 6.30008 15.7874 6.923 16.4103C7.54593 17.0333 8.28545 17.5274 9.09934 17.8645C9.91323 18.2016 10.7856 18.3752 11.6665 18.3752C13.4457 18.3752 15.152 17.6684 16.41 16.4103C17.6681 15.1523 18.3748 13.446 18.3748 11.6668C18.3748 9.88767 17.6681 8.18138 16.41 6.92332C15.152 5.66526 13.4457 4.9585 11.6665 4.9585Z" fill="#212121" />
        </g>
    </svg>

)

export default SearchIcon
