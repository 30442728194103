import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import ArrowDown from '../../assets/ArrowDown'
import ChevronUp from '../../assets/ChevronUp'
import { redirect } from '../../common/utils/redirectionUtils'
import Header from '../Common/Header/Header'
import SideMenu from '../Common/SideMenu/SideMenu'
import './Home.scss'
import {
    GET_PERMISSION_INITIAL_DATA,
    HOME_NAVIGATION_ITEMS,
    PAGE_CONST,
    PERMISSION_FEATURE,
    PERMISSION_SCREEN,
    PERMISSION_TYPE,
    REACT_NATIVE_WEBVIEW_CONST
} from '../../common/constant/page-constant'
import {
    activeHomeDetailNavigation,
    loginDataAction,
    updateBranchDataAction
} from '../../modules/actions/action'
import Stocks from '../Stocks/Stocks'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import MobileHeader from '../Common/Header/MobileHeader'
import {
    HEADER_CONFIG,
    HEADER_CONST,
    SIDEBAR_CONST
} from '../../common/constant/common-constant'
import { getStatisticsHelper } from '../../helper/home-helper'
import ChevronDown from '../../assets/ChevronDown'
import ReactSelect from 'react-select'
import {
    getBranchHelper,
    updateBranchHelper
} from '../../helper/branch-management-helper'
import { setLocalStorageCustom } from '../../common/utils/storageUtils'

const HOME = props => {
    const navigate = useNavigate()
    const isMobile = useCheckMobileScreen()
    const [statsData, setStatsData] = useState({})
    const [loginData, setLoginData] = useState({})
    const [menuIsOpen, setMenuIsOpen] = useState(false)
    const [branchDataList, setBranchDataList] = useState([])
    const [permissions, setPermissions] = useState({})
    const [manageStorepermissions, setManageStorePermissions] = useState({})

    const selectRef = useRef(null)

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search)
        const isReactNativeWebView =
            queryParams.get(REACT_NATIVE_WEBVIEW_CONST.REACT_NATIVE_PARAM) ===
            'true'
        if (isReactNativeWebView) {
            setLocalStorageCustom(
                REACT_NATIVE_WEBVIEW_CONST.REACT_NATIVE_FLOW_CONST,
                'true'
            )

            // window.ReactNativeWebView.postMessage('Hello from the react app')
        }
    }, [])

    useEffect(() => {
        getStatistics()
        getBranchData()
    }, [])

    useEffect(() => {
        setLoginData(props.loginData)

        if (props.loginData.permissions) {
            console.log(
                'props.loginData.permissions: ',
                props.loginData.permissions
            )

            let permission = props.loginData.permissions.reduce(
                (acc, permission) => {
                    acc.features = {
                        ...acc.features,
                        ...permission.features
                    }

                    return acc
                },
                {}
            )

            // let permission = props.loginData.permissions.filter(
            //     permission =>
            //         permission.screen === PERMISSION_SCREEN.HOME_SCREEN
            // )[0]

            console.log('home screen permission: ', permission)
            if (permission) setPermissions(permission)
        }
    }, [props.loginData])

    const handleOpenDropdown = () => {
        if (loginData?.user_type === 'OWNER') {
            setMenuIsOpen(true)
        }
    }

    const handleUpdateBranch = branch => {
        updateBranchHelper(branch?.id)
            .then(res => {
                console.log('res: ', res)

                // props.loginDataAction({ phoneNumber: phoneNumber })
                if (!res.user?.is_verified) {
                    redirect(navigate, PAGE_CONST.OTP_VERIFICATION)
                    return
                } else if (!res.pharmacy.is_verified) {
                    redirect(navigate, PAGE_CONST.DOCUMENT_UPLOAD)
                    return
                } else if (res.user?.is_verified && res.pharmacy?.is_verified) {
                    redirect(navigate, PAGE_CONST.HOME)
                    // props.loginDataAction(res.user)
                    setUserData(res.user)
                } else {
                    // some Error msg
                }

                getStatistics()
                getBranchData()
            })
            .catch(err => {
                console.log(err)
            })
    }

    const setUserData = user => {
        // set owner manage permissions
        if (user.user_type === 'OWNER') {
            let intialPermission = GET_PERMISSION_INITIAL_DATA()

            let updatedPermission = intialPermission.map(permissionScreen => {
                let features = permissionScreen.permissionList.reduce(
                    (acc, item) => {
                        acc[item.name] = PERMISSION_TYPE.MANAGE
                        return acc
                    },
                    {}
                )
                return {
                    screen: permissionScreen.name,
                    features: features
                }
            })

            user.permissions = updatedPermission
            console.log('updated Permission: ', updatedPermission)
        }

        props.loginDataAction(user)
    }

    const getBranchData = () => {
        getBranchHelper()
            .then(res => {
                console.log('br res: ', res)
                setBranchDataList(res)

                res?.map(branch => {
                    if (branch.is_active) {
                        props.updateBranchDataAction(branch)
                    }
                })
            })
            .catch(err => {
                console.log('err: ', err)
            })
    }

    const getStatistics = async () => {
        try {
            let stats = await getStatisticsHelper()
            setStatsData(stats)
        } catch (err) {
            console.log('errMsg: ', err)
        }
    }

    // const getPersonalDeta
    const [headerOptions] = useState({
        [HEADER_CONFIG.IS_BACK_VISIBLE]: false,
        [HEADER_CONFIG.TITLE]: HEADER_CONST.HOME
    })

    const handleItemClick = itemName => {
        props.activeHomeDetailNavigation(itemName)
        redirect(navigate, PAGE_CONST.HOME_DETAILS)
    }

    const handleHeaderBack = () => {
        redirect(navigate, PAGE_CONST.HOME)
    }

    return (
        <div className="containerHome">
            {!isMobile && <Header />}
            {isMobile && <MobileHeader headerOptions={headerOptions} />}
            <SideMenu
                activeItem={SIDEBAR_CONST.HOME}
                component={PAGE_CONST.HOME}
            />
            <div className="pageBody dFlex">
                <div className="homeLeftWrapper">
                    <div className="homeBar">
                        <div
                            className="centerDetailsWrap"
                            onClick={() => handleOpenDropdown()}>
                            <div className="centerOwner">{loginData?.name}</div>
                            <div className="centerName dFlex aiCenter jcSpaceBetween">
                                {loginData?.branch_name} <ArrowDown />
                            </div>
                        </div>
                        <div className="branch-select mt-2">
                            <ReactSelect
                                ref={selectRef}
                                className="formSelect reactSelect"
                                id="floatingSelect"
                                onChange={
                                    option => {
                                        handleUpdateBranch(option)
                                        setMenuIsOpen(false)
                                    }
                                    // handleBillDetailUpdate(
                                    //     BILL_DETAILS_FIELDS.PHARMA_COMPANY,
                                    //     option
                                    // )
                                }
                                options={branchDataList}
                                getOptionLabel={option => option.name}
                                placeholder="Select Pharma Company"
                                styles={{
                                    control: base => ({
                                        border: 0,
                                        // display: 'flex',
                                        display: 'none',
                                        paddingTop: '20px'
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused
                                            ? 'rgb(222, 235, 255)'
                                            : state.isSelected
                                            ? '#FFF'
                                            : 'inherit',
                                        color: '#212529',
                                        '&:hover': {
                                            backgroundColor: state.isSelected
                                                ? '#DEEBFF'
                                                : 'rgb(222, 235, 255)'
                                        }
                                    }),
                                    dropdownIndicator: base => ({
                                        ...base,
                                        display: 'none' // Hides the dropdown indicator
                                    }),
                                    indicatorSeparator: base => ({
                                        ...base,
                                        display: 'none' // Hides the separator between the control and dropdown indicator
                                    }),
                                    menu: base => ({
                                        ...base,
                                        marginTop: '0' // Adjusts the menu position
                                    }),
                                    placeholder: base => ({
                                        ...base,
                                        display: 'none' // Hides the placeholder text
                                    })
                                }}
                                menuIsOpen={menuIsOpen}
                                isClearable
                            />
                        </div>
                        {permissions?.features?.[PERMISSION_FEATURE.PROFIT] !==
                            PERMISSION_TYPE.RESTRICTED && (
                            <div className="moneyBar dFlex jcSpaceBetween">
                                <div className="moneyBarItem">
                                    <div className="moneyBarItemHeading">
                                        Purchase (BDT)
                                    </div>
                                    <div className="moneyBarValue dFlex aiCenter">
                                        <div className="headingValue">
                                            {statsData?.purchase?.amount}
                                        </div>
                                        <div className="headingValuePercent dFlex flexColumn jcSpaceBetween aiCenter">
                                            {statsData?.purchase?.delta < 0 ? (
                                                <ChevronDown />
                                            ) : (
                                                <ChevronUp />
                                            )}{' '}
                                            {(Math.abs(
                                                statsData?.purchase?.delta
                                            ) &&
                                                '%') ||
                                                '-'}{' '}
                                        </div>
                                    </div>
                                </div>
                                <div className="moneyBarItem">
                                    <div className="moneyBarItemHeading">
                                        Sales (BDT) Online
                                    </div>
                                    <div className="moneyBarValue dFlex aiCenter">
                                        <div className="headingValue">
                                            {statsData?.sales_online?.amount}
                                        </div>
                                        <div className="headingValuePercent dFlex flexColumn jcSpaceBetween aiCenter">
                                            {statsData?.sales_online?.delta <
                                            0 ? (
                                                <ChevronDown />
                                            ) : (
                                                <ChevronUp />
                                            )}{' '}
                                            {(Math.abs(
                                                statsData?.sales_online?.delta
                                            ) &&
                                                '%') ||
                                                '-'}{' '}
                                        </div>
                                    </div>
                                </div>
                                <div className="moneyBarItem">
                                    <div className="moneyBarItemHeading">
                                        Sales (BDT) In Store
                                    </div>
                                    <div className="moneyBarValue dFlex aiCenter">
                                        <div className="headingValue">
                                            {statsData?.sales_offline?.amount}
                                        </div>
                                        <div className="headingValuePercent dFlex flexColumn jcSpaceBetween aiCenter">
                                            {statsData?.sales_offline?.delta <
                                            0 ? (
                                                <ChevronDown />
                                            ) : (
                                                <ChevronUp />
                                            )}{' '}
                                            {(Math.abs(
                                                statsData?.sales_offline?.delta
                                            ) &&
                                                '%') ||
                                                '-'}{' '}
                                        </div>
                                    </div>
                                </div>
                                <div className="moneyBarItem">
                                    <div className="moneyBarItemHeading">
                                        Profit (BDT)
                                    </div>
                                    <div className="moneyBarValue dFlex aiCenter">
                                        <div className="headingValue">
                                            {statsData?.profit_amount?.amount}
                                        </div>
                                        <div className="headingValuePercent dFlex flexColumn jcSpaceBetween aiCenter">
                                            {statsData?.profit_amount?.delta <
                                            0 ? (
                                                <ChevronDown />
                                            ) : (
                                                <ChevronUp />
                                            )}{' '}
                                            {(Math.abs(
                                                statsData?.profit_amount?.delta
                                            ) &&
                                                '%') ||
                                                '-'}{' '}
                                        </div>
                                    </div>
                                </div>
                                <div className="moneyBarItem">
                                    <div className="moneyBarItemHeading">
                                        Profit (%)
                                    </div>
                                    <div className="moneyBarValue dFlex aiCenter">
                                        <div className="headingValue">
                                            {
                                                statsData?.profit_percentage
                                                    ?.amount
                                            }
                                        </div>
                                        <div className="headingValuePercent dFlex flexColumn jcSpaceBetween aiCenter">
                                            {statsData?.profit_percentage
                                                ?.delta < 0 ? (
                                                <ChevronDown />
                                            ) : (
                                                <ChevronUp />
                                            )}{' '}
                                            {(Math.abs(
                                                statsData?.profit_percentage
                                                    ?.delta
                                            ) &&
                                                '%') ||
                                                '-'}{' '}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="dashboardGrid dFlex">
                        {HOME_NAVIGATION_ITEMS.map(item => {
                            if (
                                permissions?.features?.[item.feature] !==
                                PERMISSION_TYPE.RESTRICTED
                            ) {
                                return (
                                    <div
                                        className="gridItem dFlex flexColumn aiCenter jcSpaceBetween"
                                        onClick={() =>
                                            handleItemClick(item.name)
                                        }>
                                        <div className="gridItemIcon">
                                            {item.homeIcon}
                                        </div>
                                        <div className="gridItemName">
                                            {item.name}
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>
                {!isMobile &&
                    permissions?.features?.[PERMISSION_FEATURE.BAR_GRAPH] !==
                        PERMISSION_TYPE.RESTRICTED && (
                        <div className="homeRightWrapper">
                            <ul className="tabs dFlex">
                                <li className="tabItem active">Stock</li>
                                {/* <li className="tabItem">Reminders</li> */}
                            </ul>
                            <div className="tabContent">
                                <div className="tabPane">
                                    <Stocks />
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        loginData: state.loginDataReducer?.loginData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        activeHomeDetailNavigation: payload =>
            dispatch(activeHomeDetailNavigation(payload)),
        loginDataAction: payload => dispatch(loginDataAction(payload)),
        updateBranchDataAction: payload =>
            dispatch(updateBranchDataAction(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HOME)
