import React from 'react'

const CheckPrimaryIcon = () => (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Check">
            <circle id="Ellipse 11" cx="40.0003" cy="40" r="23.3333" fill="white" />
            <path id="Vector" d="M40.0003 6.66663C58.4103 6.66663 73.3337 21.59 73.3337 40C73.3337 58.41 58.4103 73.3333 40.0003 73.3333C21.5903 73.3333 6.66699 58.41 6.66699 40C6.66699 21.59 21.5903 6.66663 40.0003 6.66663ZM50.7337 29.9L35.8337 44.8L29.267 38.2333C28.7931 37.7917 28.1663 37.5513 27.5186 37.5627C26.8709 37.5741 26.253 37.8365 25.7949 38.2946C25.3369 38.7526 25.0745 39.3706 25.0631 40.0182C25.0516 40.6659 25.2921 41.2927 25.7337 41.7666L34.067 50.1C34.5357 50.5681 35.1712 50.8311 35.8337 50.8311C36.4962 50.8311 37.1316 50.5681 37.6003 50.1L54.267 33.4333C54.5126 33.2044 54.7096 32.9284 54.8463 32.6218C54.9829 32.3151 55.0564 31.984 55.0623 31.6484C55.0682 31.3127 55.0065 30.9793 54.8807 30.668C54.755 30.3567 54.5678 30.0739 54.3305 29.8365C54.0931 29.5991 53.8103 29.412 53.499 29.2862C53.1877 29.1605 52.8543 29.0987 52.5186 29.1047C52.1829 29.1106 51.8519 29.184 51.5452 29.3207C51.2385 29.4573 50.9625 29.6543 50.7337 29.9Z" fill="#EF8658" />
        </g>
    </svg>
)

export default CheckPrimaryIcon
