import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { redirect } from '../../common/utils/redirectionUtils'
import {
    HEADER_CONFIG,
    HEADER_CONST,
    SIDEBAR_CONST
} from '../../common/constant/common-constant'
import {
    PAGE_CONST,
    PERMISSION_FEATURE,
    PERMISSION_SCREEN
} from '../../common/constant/page-constant'
import Header from '../Common/Header/Header'
import SideMenu from '../Common/SideMenu/SideMenu'

import './Help.scss'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import MobileHeader from '../Common/Header/MobileHeader'
import SideDrawer from '../Common/SideDrawer/SideDrawer'
import AddIcon from '../../assets/AddIcon'
import { addQueryHelper, getQueryHelper } from '../../helper/query-helper'
import { addQueryAlertAction } from '../../modules/actions/action'
import Alert from '../../components/Alert/Alert'
import { formatDate } from '../../common/utils/appUtils'
import AcknowledgeScreen from '../Common/AcknowledgeScreen/AcknowledgeScreen'
import CheckPrimaryIcon from '../../assets/CheckPrimaryIcon'

const Help = props => {
    const navigate = useNavigate()
    const isMobile = useCheckMobileScreen()
    const [viewDrawer, setViewDrawer] = useState(false)
    const [open, setOpen] = useState(false)
    const [headerOptions, setHeaderOptions] = useState({
        [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
        [HEADER_CONFIG.TITLE]: HEADER_CONST.HELP,
        [HEADER_CONFIG.HANDLE_BACK_FUNC]: () =>
            redirect(navigate, PAGE_CONST.PROFILE_MENU)
    })
    const [subject, setSubject] = useState(undefined)
    const [query, setQuery] = useState(undefined)
    const [queryList, setQueryList] = useState([])
    const [addQueryAlert, setAddQueryAlert] = useState(false)
    const [isFieldErrorVisible, setIsFieldErrorVisible] = useState(false)
    const [permissions, setPermissions] = useState({})

    useEffect(() => {
        let addQueryAlert = props.alert?.addQueryAlert
        // setAddQueryAlert(addQueryAlert)
    }, [props.alert?.addQueryAlert])

    useEffect(() => {
        getQuery()
    }, [])

    useEffect(() => {
        if (props.loginData.permissions) {
            let permission = props.loginData.permissions.filter(
                permission => permission.screen === PERMISSION_SCREEN.SETTINGS
            )[0]

            if (permission) setPermissions(permission)
        }
    }, [props.loginData])

    const getQuery = () => {
        getQueryHelper()
            .then(res => {
                setQueryList(res)
            })
            .catch(err => {
                console.log('get query err: ', err)
            })
    }

    const handleQuerySubmit = () => {
        if (!handleValidation()) {
            setIsFieldErrorVisible(true)
            return
        }

        setIsFieldErrorVisible(false)
        let data = {
            subject: subject,
            query: query
        }

        addQueryHelper(data)
            .then(res => {
                console.log('query submit res: ', res)

                props.addQueryAlertAction(true)

                setTimeout(() => {
                    props.addQueryAlertAction(false)
                }, 5000)

                resetDrawerData()
                if (isMobile) {
                    handleDrawerToggle()
                }

                getQuery()
            })
            .catch(err => {
                console.log('query submit err: ', err)
            })
    }

    const handleValidation = () => {
        if (subject && query) return true

        console.log('validation failed')
        return false
    }

    const handleDrawerToggle = () => {
        setViewDrawer(!viewDrawer)
        setOpen(!open)
    }

    const resetDrawerData = () => {
        setSubject('')
        setQuery('')
    }

    return (
        <>
            {isMobile && <MobileHeader headerOptions={headerOptions} />}

            {!isMobile && <Header />}
            {addQueryAlert && (
                <AcknowledgeScreen
                    icon={<CheckPrimaryIcon />}
                    alertType="success"
                    alertMessage="Query added Successfully!"
                    main="Query added Successfully!"
                    sub=""
                />
            )}
            <SideMenu
                activeItem={PAGE_CONST.HELP}
                component={PAGE_CONST.HELP}
            />
            <div className="pageBody">
                {isMobile && (
                    <div className="addProductBtnMobile dFlex aiCenter mwebVisible fixed">
                        <button
                            className="animated zoomIn btn btnPrimary"
                            onClick={handleDrawerToggle}>
                            <AddIcon />
                        </button>
                    </div>
                )}
                <div className="pageBodyRightPanelHeader dFlex jcSpaceBetween aiCenter webVisible mb-5">
                    <div className="pageBodyRightPanelHeading">Help</div>
                </div>
                <div className="pageBodyPanelWrap dFlex">
                    <div className="pageBodyLeftPanel">
                        {queryList?.map(query => {
                            return (
                                // use className active for future use-case
                                <div className="card helpCard">
                                    <div className="cardSection cardHeader">
                                        <small>Date</small>
                                        <div className="helpIssue">
                                            {formatDate(query.date)}
                                        </div>
                                    </div>
                                    <div className="cardSection cardBody">
                                        <small>Subject</small>
                                        <div className="helpIssue">
                                            {query.subject}
                                        </div>
                                    </div>
                                    <div className="cardSection cardFooter">
                                        <small>Query</small>
                                        <div className="helpIssue">
                                            {query.query}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {!isMobile && (
                        <div className="pageBodyRightPanel">
                            <form>
                                <div className="panelHeading">Add Query</div>
                                <div className="formGroup formFloating fgrow1">
                                    <input
                                        type="text"
                                        className="formControl"
                                        id="invoice"
                                        value={subject}
                                        onChange={event =>
                                            setSubject(event.target.value)
                                        }
                                        placeholder="Subject"
                                        required
                                    />
                                    <label for="invoice">Subject</label>
                                    {isFieldErrorVisible && !subject && (
                                        <div class="invalidFeedback">
                                            Please select a valid Subject
                                        </div>
                                    )}
                                </div>
                                <div className="formGroup formFloating">
                                    <textarea
                                        rows="2"
                                        type="text"
                                        value={query}
                                        onChange={event =>
                                            setQuery(event.target.value)
                                        }
                                        className="formControl"
                                        placeholder="Query"
                                        id="query"
                                        required></textarea>
                                    <label for="query">Query</label>
                                    {isFieldErrorVisible && !query && (
                                        <div class="invalidFeedback">
                                            Please enter query.
                                        </div>
                                    )}
                                </div>
                                <div
                                    className="actionRow"
                                    onClick={event => {
                                        event.preventDefault()
                                        handleQuerySubmit()
                                    }}>
                                    <button className="btn btnPrimary block">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
                <SideDrawer
                    handleDrawerToggle={handleDrawerToggle}
                    open={open}
                    drawerButton={true}
                    buttonText={'Submit'}
                    handleClick={handleQuerySubmit}
                    drawerHeading="Add Query"
                    mwebSideDrawerFooter>
                    <form>
                        <div className="formGroup formFloating fgrow1">
                            <input
                                type="text"
                                className="formControl"
                                id="invoice"
                                value={subject}
                                onChange={event =>
                                    setSubject(event.target.value)
                                }
                                placeholder="Subject"
                                required
                            />
                            <label for="invoice">Subject</label>
                            {isFieldErrorVisible && !subject && (
                                <div class="invalidFeedback">
                                    Please select a valid Subject
                                </div>
                            )}
                        </div>
                        <div className="formGroup formFloating">
                            <textarea
                                rows="2"
                                type="text"
                                value={query}
                                className="formControl"
                                onChange={event => setQuery(event.target.value)}
                                id="query"
                                required></textarea>
                            <label for="query">Query</label>
                            {isFieldErrorVisible && !query && (
                                <div class="invalidFeedback">
                                    Please enter query.
                                </div>
                            )}
                        </div>
                    </form>
                </SideDrawer>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        alert: state.alertReducer.alert,
        loginData: state.loginDataReducer?.loginData
    }
}
const mapDispatchToProps = dispatch => {
    return {
        addQueryAlertAction: payload => dispatch(addQueryAlertAction(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Help)
