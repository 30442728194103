import React from 'react'

const UserPermissionsBlack = () => (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="fluent:checkbox-person-24-regular">
            <path id="Vector" d="M16.6667 8C14.3681 8 12.1637 8.91309 10.5384 10.5384C8.91309 12.1637 8 14.3681 8 16.6667V47.3333C8 49.6319 8.91309 51.8363 10.5384 53.4616C12.1637 55.0869 14.3681 56 16.6667 56H35.1253C34.8202 55.0293 34.6655 54.0175 34.6667 53V52.7253C34.6667 52.48 34.68 52.24 34.7013 52H16.6667C15.429 52 14.242 51.5083 13.3668 50.6332C12.4917 49.758 12 48.571 12 47.3333V16.6667C12 14.0907 14.0907 12 16.6667 12H47.3333C49.9093 12 52 14.0907 52 16.6667V29.72C53.5164 30.1724 54.8928 31.0028 56 32.1333V16.6667C56 14.3681 55.0869 12.1637 53.4616 10.5384C51.8363 8.91309 49.6319 8 47.3333 8H16.6667ZM46.08 24.7467C46.2765 24.5636 46.4341 24.3428 46.5434 24.0974C46.6527 23.8521 46.7115 23.5873 46.7162 23.3187C46.721 23.0502 46.6716 22.7834 46.571 22.5344C46.4704 22.2854 46.3207 22.0591 46.1308 21.8692C45.9408 21.6793 45.7146 21.5296 45.4656 21.429C45.2166 21.3284 44.9498 21.279 44.6813 21.2838C44.4127 21.2885 44.1479 21.3473 43.9026 21.4566C43.6572 21.5659 43.4364 21.7235 43.2533 21.92L26.6587 38.496L20.7467 32.5867C20.3675 32.2334 19.8661 32.0411 19.3479 32.0502C18.8298 32.0593 18.3354 32.2692 17.969 32.6357C17.6026 33.0021 17.3927 33.4965 17.3835 34.0146C17.3744 34.5327 17.5667 35.0342 17.92 35.4133L25.24 42.736C25.615 43.1105 26.1233 43.3209 26.6533 43.3209C27.1833 43.3209 27.6917 43.1105 28.0667 42.736L46.0773 24.7493L46.08 24.7467ZM56 38.6667C56 40.4348 55.2976 42.1305 54.0474 43.3807C52.7971 44.631 51.1014 45.3333 49.3333 45.3333C47.5652 45.3333 45.8695 44.631 44.6193 43.3807C43.369 42.1305 42.6667 40.4348 42.6667 38.6667C42.6667 36.8986 43.369 35.2029 44.6193 33.9526C45.8695 32.7024 47.5652 32 49.3333 32C51.1014 32 52.7971 32.7024 54.0474 33.9526C55.2976 35.2029 56 36.8986 56 38.6667ZM61.3333 53C61.3333 57.1493 57.904 61.3333 49.3333 61.3333C40.7627 61.3333 37.3333 57.1653 37.3333 53V52.7253C37.3333 50.112 39.4507 48 42.0613 48H56.6053C59.2187 48 61.3333 50.1147 61.3333 52.7253V53Z" fill="#212121" />
        </g>
    </svg>
)

export default UserPermissionsBlack
