import axios from 'axios'
import axiosInstance from '../common/interceptor/axios-interceptor'

const BASE_URL =
    'https://www.backend-epharma.dghealthcare.online/api/pharmacystore/'
// 'https://df8bqa64qniyz.cloudfront.net/api/pharmacystore/'

// http://13.201.92.232:80/api/pharmacystore/
export const loginAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}sign-in`,
        data: data
    })
}

export const createAccountAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}create-account`,
        data: data
    })
}

export const sendOTPAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}send-otp`,
        data: data,
        authRequired: true
    })
}

export const sendUnAuthOTPAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}unauth/send-otp`,
        data: data
    })
}

export const validateUnAuthOTPAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}unauth/verify-otp`,
        data: data
    })
}

export const validateOTPAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}verify-otp`,
        data: data,
        authRequired: true
    })
}

export const forgotPasswordAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}unauth/forgot-password`,
        data: data
    })
}

export const documentUploadAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}document-upload`,
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        authRequired: true
    })
}

export const addBranchAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/add`,
        data: data,
        authRequired: true
    })
}

export const updateVisibilityAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/update-online-order-visibility`,
        data: data,
        authRequired: true
    })
}

// export const updateBranchAPI = async data => {
//     return axiosInstance({
//         method: 'post',
//         url: `${BASE_URL}branches/add`,
//         data: data,
//         authRequired: true
//     })
// }

export const getBranchAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}get-branches`,
        data: data,
        authRequired: true
    })
}

export const updateBranchAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}update-branch`,
        data: data,
        authRequired: true
    })
}

export const updateBranchDetailsAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}update-branch-details`,
        data: data,
        authRequired: true
    })
}

export const addUserAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/add-user`,
        data: data,
        authRequired: true
    })
}

export const updateUserAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/update-user`,
        data: data,
        authRequired: true
    })
}

export const getUserAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-users`,
        data: data,
        authRequired: true
    })
}

export const getProductsAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}products`,
        data: data,
        authRequired: true
    })
}

export const getProductDataAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}get-products`,
        data: data,
        authRequired: true
    })
}

export const getMedicalCategoryAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}get-product-categories`,
        data: data,
        authRequired: true
    })
}

export const updateProductDataAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}update-product-details`,
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        authRequired: true
    })
}

export const getSuppliersAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}get-suppliers`,
        data: data,
        authRequired: true
    })
}

export const addSuppliersAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/add-supplier`,
        data: data,
        authRequired: true
    })
}

export const updateSuppliersAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}update-supplier-details`,
        data: data,
        authRequired: true
    })
}

export const addProductReceivingAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/add-product-receiving`,
        data: data,
        authRequired: true
    })
}

export const getProductReceivingAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-product-receivings`,
        data: data,
        authRequired: true
    })
}

export const getAllCustomersAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-customers`,
        data: data,
        authRequired: true
    })
}

export const getCustomerAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-customer-by-mobile-no`,
        data: data,
        authRequired: true
    })
}

export const addCustomerAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/add-customer`,
        data: data,
        authRequired: true
    })
}

export const getProductDetailsByBarcodeAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-product-detail-from-barcode`,
        data: data,
        authRequired: true
    })
}

export const getDamageLossAdjustmentAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-damage-loss-adjustments`,
        data: data,
        authRequired: true
    })
}

export const addDamageLossAdjustmentAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/create-damage-loss-adjustment`,
        data: data,
        authRequired: true
    })
}

export const getInventoryDetailsAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-inventory`,
        data: data,
        authRequired: true
    })
}

export const getCampaignInventoryDetailsAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-discount-campaign-inventory`,
        data: data,
        authRequired: true
    })
}

export const getProductDetailsAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-product-details`,
        data: data,
        authRequired: true
    })
}

export const getInvoiceDetailsAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-product-receiving`,
        data: data,
        authRequired: true
    })
}

export const getAllSalesAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-sales-orders`,
        data: data,
        authRequired: true
    })
}

export const getSalesAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-sales-order-by-id`,
        data: data,
        authRequired: true
    })
}

export const createSalesAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/hold-or-create-sales-order`,
        data: data,
        authRequired: true
    })
}

export const updateSalesAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/update-sales-order`,
        data: data,
        authRequired: true
    })
}

export const getExpiredProductsAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-expired-products`,
        data: data,
        authRequired: true
    })
}

export const getExpiredProductDetailsAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-expired-product-details`,
        data: data,
        authRequired: true
    })
}

export const returnExpiredProductsAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/return-expired-product`,
        data: data,
        authRequired: true
    })
}

export const getPersonalDetailsAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}get-user-personal-details`,
        data: data,
        authRequired: true
    })
}

export const updatePersonalDetailsAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}update-user-personal-details`,
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        authRequired: true
    })
}

export const getNotificationsAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-notifications`,
        data: data,
        authRequired: true
    })
}

export const updateNotificationsAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/update-notifications`,
        data: data,
        authRequired: true
    })
}

export const getDocumentsAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}get-documents`,
        data: data,
        authRequired: true
    })
}

export const getSalesOrderByInvoiceNumberAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-sales-order-by-invoice-number`,
        data: data,
        authRequired: true
    })
}

export const createExchangeReturnAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/exchange-or-return-sales-order`,
        data: data,
        authRequired: true
    })
}

export const getAllExchangeReturnOrdersAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-exchange-or-return-sales-orders`,
        data: data,
        authRequired: true
    })
}

export const getExchangeReturnOrdersByIdAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-exchange-or-return-sales-order-by-id`,
        data: data,
        authRequired: true
    })
}

export const addQueryAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/add-query`,
        data: data,
        authRequired: true
    })
}

export const getQueriesAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-queries`,
        data: data,
        authRequired: true
    })
}

export const addReminderAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/add-query`,
        data: data,
        authRequired: true
    })
}

export const getReminderAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-queries`,
        data: data,
        authRequired: true
    })
}

export const getDeliveryPartnersAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-delivery-partners`,
        data: data,
        authRequired: true
    })
}

export const addDeliveryPartnerAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/add-delivery-partner`,
        data: data,
        authRequired: true
    })
}

export const confirmOnlineSalesOrderAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/confirm-online-sales-order`,
        data: data,
        authRequired: true
    })
}

export const getOrderBookAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-order-books`,
        data: data,
        authRequired: true
    })
}

export const addOrderBookAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/create-new-order-book`,
        data: data,
        authRequired: true
    })
}

export const getOrderBookDetailsAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-order-book-details`,
        data: data,
        authRequired: true
    })
}

export const getOrderBookSearchSuggestionsAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}get-search-suggestions`,
        data: data,
        authRequired: true
    })
}

export const getOrderBookSearchResultsAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}get-search-results`,
        data: data,
        authRequired: true
    })
}

export const getProductSearchSuggestionsAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-product-search-suggestions`,
        data: data,
        authRequired: true
    })
}

export const getProductDetailByIdAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-product-detail-by-id`,
        data: data,
        authRequired: true
    })
}

export const getProductSearchResultsAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-product-search-results`,
        data: data,
        authRequired: true
    })
}

export const createSingleProductDiscountAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/create-single-product-discount`,
        data: data,
        authRequired: true
    })
}

export const updateSingleProductDiscountAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/update-single-product-discount`,
        data: data,
        authRequired: true
    })
}

export const getSingleProductDiscountAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-single-product-discounts`,
        data: data,
        authRequired: true
    })
}

export const createCampaignDiscountAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/create-discount-campaign`,
        data: data,
        authRequired: true
    })
}

export const getCampaignDiscountAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-discount-campaigns`,
        data: data,
        authRequired: true
    })
}

export const getCampaignDiscountDetailAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-discount-campaign-details`,
        data: data,
        authRequired: true
    })
}

export const updateCampaignDiscountAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/update-discount-campaign`,
        data: data,
        authRequired: true
    })
}

export const getAccoutingAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-accouting`,
        data: data,
        authRequired: true
    })
}

export const enableAccountingAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/enable-accouting`,
        data: data,
        authRequired: true
    })
}

export const getVouchersAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-vouchers`,
        data: data,
        authRequired: true
    })
}

export const getVoucherDetailsAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-voucher-details`,
        data: data,
        authRequired: true
    })
}

export const createVoucherAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/create-voucher`,
        data: data,
        authRequired: true
    })
}

export const updateVoucherAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/update-voucher`,
        data: data,
        authRequired: true
    })
}

export const getProfitLossStatementAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-profit-loss-statement`,
        data: data,
        authRequired: true
    })
}

export const getBalanceSheetStatementAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-balance-sheet-statement`,
        data: data,
        authRequired: true
    })
}

export const getReceiptPaymentAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-receipts-and-payments`,
        data: data,
        authRequired: true
    })
}

export const getStatisticsAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-statistics`,
        data: data,
        authRequired: true
    })
}

export const getStoreStatusAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-online-order-visibility`,
        data: data,
        authRequired: true
    })
}

export const getStocksAndBarchartDataAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-stock-and-bar-chart-data`,
        data: data,
        authRequired: true
    })
}

export const updateDeliveryPartnerStatusAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/update-delivery-partner`,
        data: data,
        authRequired: true
    })
}

export const updatePrescriptionOrderAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/update-online-prescription-sales-order`,
        data: data,
        authRequired: true
    })
}

export const updatePasswordAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}update-password`,
        data: data,
        authRequired: true
    })
}

export const getReportAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-reports`,
        data: data,
        authRequired: true
    })
}

export const downloadReportExcelAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-reports-excel`,
        data: data,
        responseType: 'blob',
        authRequired: true
    })
}

export const downloadReportPdfAPI = async data => {
    return axiosInstance({
        method: 'post',
        url: `${BASE_URL}branches/get-reports-pdf`,
        data: data,
        responseType: 'blob',
        authRequired: true
    })
}
