import {
    addBranchAPI,
    getBranchAPI,
    updateBranchAPI,
    updateBranchDetailsAPI,
    updateVisibilityAPI
} from '../adapter/adapter'

export function getBranchHelper(data) {
    let promise = new Promise(function (resolve, reject) {
        getBranchAPI()
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data?.branches) {
                    resolve(res.data.data.branches)
                } else {
                    reject('Login Failure')
                }
            })
            .catch(err => {
                reject('Login Failure')
            })
    })

    return promise
}

export function addBranchHelper(data) {
    let reqData = data

    let promise = new Promise(function (resolve, reject) {
        addBranchAPI(reqData)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (
                    res?.data?.data &&
                    res?.data?.data?.is_branch_added
                ) {
                    resolve()
                } else {
                    reject('Login Failure')
                }
            })
            .catch(err => {
                reject('Login Failure')
            })
    })

    return promise
}

export function updateBranchHelper(id) {
    let reqData = {
        branch_id: id
    }

    let promise = new Promise(function (resolve, reject) {
        updateBranchAPI(reqData)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data) {
                    resolve(res.data.data)
                } else {
                    reject('Login Failure')
                }
            })
            .catch(err => {
                reject('Login Failure')
            })
    })

    return promise
}

export function updateBranchDetailsHelper(id, name, email) {
    let reqData = {
        branch_id: id,
        name: name,
        email: email
    }

    let promise = new Promise(function (resolve, reject) {
        updateBranchDetailsAPI(reqData)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data) {
                    resolve(res.data.data)
                } else {
                    reject('Branch Details Update Failure')
                }
            })
            .catch(err => {
                reject('Branch Details Update Failure')
            })
    })

    return promise
}

export function updateBranchVisibilityHelper(status) {
    let reqData = {
        status: status
    }

    let promise = new Promise(function (resolve, reject) {
        updateVisibilityAPI(reqData)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data) {
                    resolve(res.data.data)
                } else {
                    reject('Login Failure')
                }
            })
            .catch(err => {
                reject('Login Failure')
            })
    })

    return promise
}
