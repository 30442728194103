import DamageAndLossIcon from '../../assets/DamageAndLossIcon'
import DamageLossBlack from '../../assets/DamageLossBlack'
import ExchangeIcon from '../../assets/ExchangeIcon'
import ExipredProductsBlack from '../../assets/ExipredProductsBlack'
import ExpiredProductIcon from '../../assets/ExpiredProductIcon'
import ManageCustomerBlack from '../../assets/ManageCustomerBlack'
import ManageCustomersIcon from '../../assets/ManageCustomersIcon'
import ManageInventoryBlack from '../../assets/ManageInventoryBlack'
import ManageInventoryIcon from '../../assets/ManageInventoryIcon'
import PrimaryBranchIcon from '../../assets/PrimaryBranchIcon'
import PrimaryDiscountIcon from '../../assets/PrimaryDiscountIcon'
import PrimaryDocumentIcon from '../../assets/PrimaryDocumentIcon'
import PrimaryInfoIcon from '../../assets/PrimaryInfoIcon'
import PrimaryOrderBookIcon from '../../assets/PrimaryOrderBookIcon'
import PrimaryPrivacyPolicyIcon from '../../assets/PrimaryPrivacyPolicyIcon'
import PrimarySalesIcon from '../../assets/PrimarySalesIcon'
import PrimaryUserIcon from '../../assets/PrimaryUserIcon'
import ProductIcon from '../../assets/ProductIcon'
import ProductReceivingBlack from '../../assets/ProductReceivingBlack'
import ReportsBlack from '../../assets/ReportsBlack'
import ReportsIcon from '../../assets/ReportsIcon'
import ReturnBlack from '../../assets/ReturnBlack'
import SalesBlack from '../../assets/SalesBlack'
import SalesIcon from '../../assets/SalesIcon'
import UserIcon from '../../assets/UserIcon'
import UserPermissionIcon from '../../assets/UserPermissionIcon'
import UserPermissionsBlack from '../../assets/UserPermissionsBlack'
import PlatformCharges from '../../pages/PlatformCharges/PlatformCharges'

export const PAGE_CONST = {
    LOGIN: 'LOGIN',
    CREATE_ACCOUNT: 'CREATE_ACCOUNT',
    RESET_PASSWORD: 'RESET_PASSWORD',
    OTP_VERIFICATION: 'OTP_VERIFICATION',
    DOCUMENT_UPLOAD: 'DOCUMENT_UPLOAD',
    HOME: 'HOME',
    STOCKS: 'STOCKS',
    HOME_DETAILS: 'HOME_DETAILS',
    USER_PROFILE: 'USER_PROFILE',
    SETTINGS: 'SETTINGS',
    HELP: 'HELP',
    NOTIFICATION: 'NOTIFICATION',
    PROFILE_MENU: 'PROFILE_MENU'
}

export const REACT_NATIVE_WEBVIEW_CONST = {
    REACT_NATIVE_PARAM: 'reactNative',
    REACT_NATIVE_FLOW_CONST: 'isReactNativeWebView'
}

export const REACT_NATIVE_MESSAGE_TYPE = {
    IMAGE_DOWNLOAD: 'IMAGE_DOWNLOAD',
    GENERAL_MESSAGE: 'GENERAL_MESSAGE',
    LOGOUT: 'LOGOUT'
}

export const LOGIN_CONST = {
    ACCESS_TOKEN: 'accessToken',
    REFRESH_TOKEN: 'refreshToken',
    EMAIL: 'email',
    PHONE_NUMBER: 'mobileNumber',
    PASSWORD: 'password'
}

export const OTP_CONST = {
    SEND_OTP: 'SEND_OTP',
    VERIFY_OTP: 'VERIFY_OTP'
}

export const HOME_CONST = {
    PRODUCT_RECEIVING: 'Product Receiving',
    SALES: 'Sales',
    PRODUCT: 'Product',
    EXCHANGE_RETURN: 'Exchange/Return',
    INVENTORY: 'Manage Inventory',
    SUPPLIERS: 'Suppliers',
    CUSTOMERS: 'Manage Customers',
    REPORTS: 'Reports',
    BRANCH_MANAGEMENT: 'Branch Management',
    DAMAGE_LOST: 'Damage & Loss',
    TAX_ADJUSTMENT: 'Tax Adjustment',
    EXPIRED_PRODUCTS: 'Expired Products',
    USER_PERMISSION: 'User Permission'
}

export const PROFILE_CONST = {
    PERSONAL_DETAILS: 'Personal Details',
    DOCUMENTS: 'Documents',
    BRANCH_MANAGEMENT: 'Branch Management',
    DELIVERY_PARTNER: 'Delivery Partner',
    PLATFORM_CHARGES: 'Platform Charges',
    ACCOUNTING: 'Accounting',
    ORDER_BOOK: 'Order Book',
    ADD_ORDER_BOOK: 'Add Order Book',
    DISCOUNTS: 'Discount Settings',
    TERMS_AND_CONDITIONS: 'Terms and Conditions',
    PRIVACY_POLICY: 'Privacy Policy',
    ABOUT: 'About',
    HELP: 'Help'
}

export const DOCUMENTS_TYPE = {
    TRADE_LICENSE_NUMBER: 'TRADE_LICENSE_NUMBER',
    DGDA_LICENSE_NUMBER: 'DGDA_LICENSE_NUMBER',
    BIN_NUMBER: 'BIN_NUMBER',
    TIN_NUMBER: 'TIN_NUMBER',
    VAT_NUMBER: 'VAT_NUMBER'
}

export const PERMISSION_FEATURE = {
    MANAGE_USERS: 'MANAGE_USERS',
    PROFIT: 'PROFIT',
    BAR_GRAPH: 'BAR_GRAPH',
    PRODUCT_RECEIVING: 'PRODUCT_RECEIVING',
    SALES: 'SALES',
    EXCHANGE_RETURN: 'EXCHANGE_RETURN',
    ORDER_BOOK: 'ORDER_BOOK',
    INVENTORY: 'INVENTORY',
    EXPIRED_PRODUCTS: 'EXPIRED_PRODUCTS',
    DAMAGE_LOSS: 'DAMAGE_LOSS',
    REPORTS_VIEW: 'VIEW',
    CUSTOMERS: 'CUSTOMERS',
    BRANCH_MANAGEMENT: 'BRANCH_MANAGEMENT',
    ACCOUNTING: 'ACCOUNTING',
    DELIVERY_PARTNER: 'DELIVERY_PARTNER',
    PLATFORM_CHARGES: 'PLATFORM_CHARGES',
    DOCUMENTS: 'DOCUMENTS',
    REMINDER_SETTINGS: 'REMINDER_SETTINGS',
    DISCOUNT_SETTINGS: 'DISCOUNT_SETTINGS'
}

export const HOME_NAVIGATION_ITEMS = [
    {
        name: HOME_CONST.PRODUCT_RECEIVING,
        homeIcon: <ProductReceivingBlack />,
        homeDetailIcon: <ProductIcon />,
        defaultActive: true,
        feature: PERMISSION_FEATURE.PRODUCT_RECEIVING,
        id: 1
    },
    {
        name: HOME_CONST.SALES,
        homeIcon: <SalesBlack />,
        homeDetailIcon: <PrimarySalesIcon />,
        feature: PERMISSION_FEATURE.SALES,
        id: 2
    },
    {
        name: HOME_CONST.EXCHANGE_RETURN,
        homeIcon: <ReturnBlack />,
        homeDetailIcon: <ExchangeIcon />,
        feature: PERMISSION_FEATURE.EXCHANGE_RETURN,
        id: 3
    },
    {
        name: HOME_CONST.INVENTORY,
        homeIcon: <ManageInventoryBlack />,
        homeDetailIcon: <ManageInventoryIcon />,
        feature: PERMISSION_FEATURE.INVENTORY,
        id: 4
    },
    {
        name: HOME_CONST.CUSTOMERS,
        homeIcon: <ManageCustomerBlack />,
        homeDetailIcon: <ManageCustomersIcon />,
        feature: PERMISSION_FEATURE.CUSTOMERS,
        id: 5
    },
    {
        name: HOME_CONST.REPORTS,
        homeIcon: <ReportsBlack />,
        homeDetailIcon: <ReportsIcon />,
        feature: PERMISSION_FEATURE.REPORTS_VIEW,
        id: 6
    },
    {
        name: HOME_CONST.DAMAGE_LOST,
        homeIcon: <DamageLossBlack />,
        homeDetailIcon: <DamageAndLossIcon />,
        feature: PERMISSION_FEATURE.DAMAGE_LOSS,
        id: 7
    },
    {
        name: HOME_CONST.EXPIRED_PRODUCTS,
        homeIcon: <ExipredProductsBlack />,
        homeDetailIcon: <ExpiredProductIcon />,
        feature: PERMISSION_FEATURE.EXPIRED_PRODUCTS,
        id: 8
    },
    {
        name: HOME_CONST.USER_PERMISSION,
        homeIcon: <UserPermissionsBlack />,
        homeDetailIcon: <UserPermissionIcon />,
        feature: PERMISSION_FEATURE.MANAGE_USERS,
        id: 9
    }
]

export const PROFILE_NAVIGATION_ITEMS = [
    {
        name: PROFILE_CONST.PERSONAL_DETAILS,
        profileDetailIcon: <PrimaryUserIcon />,
        id: 1
    },
    {
        name: PROFILE_CONST.DOCUMENTS,
        profileDetailIcon: <PrimaryDocumentIcon />,
        feature: PERMISSION_FEATURE.DOCUMENTS,
        id: 2
    },
    {
        name: PROFILE_CONST.BRANCH_MANAGEMENT,
        profileDetailIcon: <PrimaryBranchIcon />,
        feature: PERMISSION_FEATURE.BRANCH_MANAGEMENT,
        id: 3
    },
    // {
    //     name: PROFILE_CONST.DELIVERY_PARTNER,
    //     profileDetailIcon: <PrimaryBranchIcon />,
    //     feature: PERMISSION_FEATURE.DELIVERY_PARTNER,
    //     id: 4
    // },
    {
        name: PROFILE_CONST.ACCOUNTING,
        profileDetailIcon: <PrimarySalesIcon />,
        feature: PERMISSION_FEATURE.ACCOUNTING,
        id: 5
    },
    {
        name: PROFILE_CONST.ORDER_BOOK,
        profileDetailIcon: <PrimaryOrderBookIcon />,
        feature: PERMISSION_FEATURE.ORDER_BOOK,
        id: 6
    },
    {
        name: PROFILE_CONST.DISCOUNTS,
        profileDetailIcon: <PrimaryDiscountIcon />,
        feature: PERMISSION_FEATURE.DISCOUNT_SETTINGS,
        id: 7
    },
    {
        name: PROFILE_CONST.PLATFORM_CHARGES,
        profileDetailIcon: <PrimarySalesIcon />,
        feature: PERMISSION_FEATURE.PLATFORM_CHARGES,
        id: 11
    },
    {
        name: PROFILE_CONST.TERMS_AND_CONDITIONS,
        profileDetailIcon: <PrimaryDocumentIcon />,
        id: 8
    },
    {
        name: PROFILE_CONST.PRIVACY_POLICY,
        profileDetailIcon: <PrimaryPrivacyPolicyIcon />,
        id: 9
    },
    {
        name: PROFILE_CONST.ABOUT,
        profileDetailIcon: <PrimaryInfoIcon />,
        id: 10
    }
]

export const ADD_PRODUCT_RECEIVING_FLOW = {
    ORDER_BOOK: 'ORDER_BOOK'
}

export const ADD_PRODUCT_RECEIVING_STAGES = {
    BILL_DETAILS: 'BILL_DETAILS',
    PRODUCT_DETAILS: 'PRODUCT_DETAILS',
    PAYMENT_DETAILS: 'PAYMENT_DETAILS'
}

export const BILL_DETAILS_FIELDS = {
    INVOICE_NUMBER: 'invoice_number',
    // DATE: 'date',
    PHARMA_COMPANY: 'pharma_company',
    PHONE_NUMBER: 'mobile_no',
    ADDRESS: 'address',
    IS_APPROVED: 'is_approved'
}

export const PRODUCT_DETAIL_FIELDS = {
    PRODUCT_ID: 'productId',
    PRODUCT_UNDERSCORE_ID: 'product_id',
    PRODUCT_CATEGORY: 'type',
    PRODUCT_NAME: 'name',
    PRODUCT_BARCODE: 'barcode',
    SELECTED_PRODUCT: 'selectedProduct',
    GENERIC_NAME: 'generic_name',
    PRODUCT_IMAGE: 'product_image',
    MEDICAL_CATEGORY: 'category',
    ORIGIN: 'origin',
    PRESCRIPTION_REQUIRED: 'is_prescription_required',
    BATCH_NUMBER: 'batch_number',
    EXPIRY_DATE: 'expiry_date',
    DOSE: 'dose',
    QUANTITY: 'quantity',
    BONUS_QUANITY: 'bonus_quantity',
    NUMBER_OF_STRIP: 'number_of_strip',
    MED_PER_STRIP: 'medicine_per_strips',
    BONUS_STRIP: 'bonus_strip',
    PRODUCT_COST: 'cost_per_unit',
    VAT_PERCENTAGE: 'vat',
    PRODUCT_NEW_COST: 'new_cost_per_unit',
    RATE_PER_UNIT: 'rate_per_unit',
    FINAL_PRICE: 'final_price',
    TOTAL_AMOUNT: 'total_amount',
    NEW_QUANTITY: 'new_quantity',
    CURRENT_QUANTITY: 'current_quantity',
    SELECTED_BATCH_DETAILS: 'selected_batch_details',
    STOCK: 'stock',
    INVENTORY: 'inventory'
}

export const PRODUCT_STRIP_MEDICAL_CATEGORY = [
    'CAPSULE',
    'TABLET',
    'DISPERSIBLE TABLET',
    'SACHET',
    'VAGINAL TABLET',
    'XR TABLET',
    'SR TABLET',
    'SR CAPSULE',
    'ORS TABLET',
    'ORODISPERSIBLE TABLET',
    'DR TABLET',
    'COZYCAP',
    'INHALATION CAPSULE',
    'CHEWABLE TABLET',
    'CR TABLET',
    'ER TABLET',
    'GRANULES FOR SUSPENSION',
    'MUPS TABLET',
    'MOUTH DISSOLVING TABLET',
    'SACHET POWDER',
    'TAB',
    'SOFT GELATIN CAPSULE',
    'RAPID TABLET',
    'EFFERVESCENT TABLET',
    'COMBIPACK',
    'EFFERVESCENT GRANULES',
    'PR TABLET',
    'DR GRANULES FOR SUSPENSION',
    'ER CAPSULE',
    'EXTENDED RELEASE CAPSULE',
    'CR CAPSULE',
    'ODT TABLET',
    'ORAL GRANULES',
    'DELAYED RELEASE CAPSULE',
    'MD TABLET',
    'SPRINKLE CAPSULE',
    'M R CAPSULE',
    'M R TABLET'
]

export const PRODUCT_ORIGIN = {
    FOREIGN: 'FOREIGN',
    LOCAL: 'LOCAL'
}

export const PRODUCT_CATEGORY = {
    PHARMACEUTICAL: 'PHARMACEUTICAL',
    NON_PHARMACEUTICAL: 'NON_PHARMACEUTICAL'
}

// export const PRODUCT_CATEGORY_UI = {
//     PHARMACEUTICAL: 'Pharmaceutical',
//     NON_PHARMACEUTICAL: 'nPharmaceutical'
// }

export const PAYMENT_DETAILS_FIELDS = {
    NOTES: 'notes',
    AMOUNT: 'amount',
    DISCOUNT: 'discount',
    DISCOUNT_AMOUNT: 'discount_amount',
    TRADE_DISCOUNT_PERCENTAGE: 'trade_discount_percentage',
    TRADE_DISCOUNT_AMOUNT: 'trade_discount_amount',
    VAT: 'vat',
    PAYMENT_MODE: 'payment_mode',
    TOTAL_AMOUNT: 'total_amount',
    AMOUNT_CARD: 'amount_card',
    AMOUNT_CASH: 'amount_cash'
    // RECEIVED_BY: 'receivedBy'
}

export const ADD_USER_STAGES = {
    USER_DETAILS: 'USER_DETAILS',
    PERMISSIONS: 'PERMISSIONS'
}

export const USER_FIELDS = {
    NAME: 'name',
    PHONE_NUMBER: 'mobileNumber',
    AREA_CODE: 'areaCode',
    EMAIL: 'email',
    BRANCH_ID: 'branchId',
    PASSWORD: 'password',
    CONFIRM_PASSWORD: 'confirmPassword'
}

export const DELIVERY_PERSON_FIELDS = {
    NAME: 'name',
    PHONE_NUMBER: 'mobile_no',
    AREA_CODE: 'areaCode',
    EMAIL: 'email',
    PASSWORD: 'password'
}

export const PERMISSION_TYPE = {
    VIEW: 'VIEW',
    MANAGE: 'MANAGE',
    RESTRICTED: 'RESTRICTED'
}

export const PERMISSION_NAME_MAPPING = {
    PERMISSIONS: 'Permissions',
    MANAGE_USERS: 'Manage Users',
    HOME_SCREEN: 'Home Screen',
    PROFIT: 'Purchase/Sales/Profit',
    BAR_GRAPH: 'Bar Graph',
    MANAGE_STORE: 'Manage Store',
    PRODUCT_RECEIVING: 'Product Receiving',
    SALES: 'Sales',
    EXCHANGE_RETURN: 'Exchange/Return',
    ORDER_BOOK: 'Order Book',
    INVENTORY: 'Inventory',
    EXPIRED_PRODUCTS: 'Expired Products',
    DAMAGE_LOSS: 'Damage/Loss',
    REPORTS: 'Reports',
    VIEW: 'View',
    OTHER: 'Other',
    SUPPLIERS: 'Suppliers',
    CUSTOMERS: 'Customers',
    BRANCH_MANAGEMENT: 'Branch Management',
    ACCOUNTING: 'Accounting',
    DOCUMENTS: 'Documents',
    DELIVERY_PARTNER: 'Delivery Partner',
    PLATFORM_CHARGES: 'Platform Charges',
    SETTINGS: 'Settings',
    REMINDER_SETTINGS: 'Reminder Settings',
    DISCOUNT_SETTINGS: 'Discount Settings'
}

export const PERMISSION_SCREEN = {
    PERMISSIONS: 'PERMISSIONS',
    HOME_SCREEN: 'HOME_SCREEN',
    MANAGE_STORE: 'MANAGE_STORE',
    REPORTS: 'REPORTS',
    OTHER: 'OTHER',
    SETTINGS: 'SETTINGS'
}

export function GET_PERMISSION_INITIAL_DATA() {
    return [
        {
            name: PERMISSION_SCREEN.PERMISSIONS,
            permissionList: [
                {
                    name: PERMISSION_FEATURE.MANAGE_USERS,
                    permissionType: PERMISSION_TYPE.RESTRICTED
                }
            ]
        },
        {
            name: PERMISSION_SCREEN.HOME_SCREEN,
            permissionList: [
                {
                    name: PERMISSION_FEATURE.PROFIT,
                    permissionType: PERMISSION_TYPE.RESTRICTED
                },
                {
                    name: PERMISSION_FEATURE.BAR_GRAPH,
                    permissionType: PERMISSION_TYPE.RESTRICTED
                }
            ]
        },
        {
            name: PERMISSION_SCREEN.MANAGE_STORE,
            permissionList: [
                {
                    name: PERMISSION_FEATURE.PRODUCT_RECEIVING,
                    permissionType: PERMISSION_TYPE.RESTRICTED
                },
                {
                    name: PERMISSION_FEATURE.SALES,
                    permissionType: PERMISSION_TYPE.RESTRICTED
                },
                {
                    name: PERMISSION_FEATURE.EXCHANGE_RETURN,
                    permissionType: PERMISSION_TYPE.RESTRICTED
                },
                {
                    name: PERMISSION_FEATURE.ORDER_BOOK,
                    permissionType: PERMISSION_TYPE.RESTRICTED
                },
                {
                    name: PERMISSION_FEATURE.INVENTORY,
                    permissionType: PERMISSION_TYPE.RESTRICTED
                },
                {
                    name: PERMISSION_FEATURE.EXPIRED_PRODUCTS,
                    permissionType: PERMISSION_TYPE.RESTRICTED
                },
                {
                    name: PERMISSION_FEATURE.DAMAGE_LOSS,
                    permissionType: PERMISSION_TYPE.RESTRICTED
                }
            ]
        },
        {
            name: PERMISSION_SCREEN.REPORTS,
            permissionList: [
                {
                    name: PERMISSION_FEATURE.REPORTS_VIEW,
                    permissionType: PERMISSION_TYPE.RESTRICTED
                }
            ]
        },
        {
            name: PERMISSION_SCREEN.OTHER,
            permissionList: [
                // {
                //     name: PERMISSION_FEATURE.SUPPLIERS,
                //     permissionType: PERMISSION_TYPE.RESTRICTED
                // },
                {
                    name: PERMISSION_FEATURE.CUSTOMERS,
                    permissionType: PERMISSION_TYPE.RESTRICTED
                },
                {
                    name: PERMISSION_FEATURE.BRANCH_MANAGEMENT,
                    permissionType: PERMISSION_TYPE.RESTRICTED
                },
                {
                    name: PERMISSION_FEATURE.ACCOUNTING,
                    permissionType: PERMISSION_TYPE.RESTRICTED
                },
                {
                    name: PERMISSION_FEATURE.DOCUMENTS,
                    permissionType: PERMISSION_TYPE.RESTRICTED
                },
                {
                    name: PERMISSION_FEATURE.DELIVERY_PARTNER,
                    permissionType: PERMISSION_TYPE.RESTRICTED
                },
                {
                    name: PERMISSION_FEATURE.PLATFORM_CHARGES,
                    permissionType: PERMISSION_TYPE.RESTRICTED
                }
            ]
        },
        {
            name: PERMISSION_SCREEN.SETTINGS,
            permissionList: [
                {
                    name: PERMISSION_FEATURE.REMINDER_SETTINGS,
                    permissionType: PERMISSION_TYPE.RESTRICTED
                },
                {
                    name: PERMISSION_FEATURE.DISCOUNT_SETTINGS,
                    permissionType: PERMISSION_TYPE.RESTRICTED
                }
            ]
        }
    ]
}

export const CUSTOMER_FIELDS = {
    NAME: 'name',
    PHONE_NUMBER: 'mobileNumber',
    BILLING_ADDRESS: 'billingAddress',
    SHIPPING_ADDRESS: 'shippingAddress'
}

export const DAMAGE_LOSS_FIELDS = {
    CHALLAN_NUMBER: 'ChallanNumber',
    BARCODE: 'barcode',
    QUANTITY: 'quantity',
    NOTES: 'notes'
}

export const INVENTORY_TABS = {
    PHARAMCEUTICAL: 'Pharamceutical',
    NON_PHARMACEUTICAL: 'Non Pharmaceutical'
}

export const INVENTORY_STAGES = {
    PRODUCT_LISTING: 'PRODUCT_LISTING',
    PRODUCT_DETAILS: 'PRODUCT_DETAILS'
}

export const ADD_SALES_STAGES = {
    CUSTOMER_DETAILS: 'CUSTOMER_DETAILS',
    PRODUCT_DETAILS: 'PRODUCT_DETAILS',
    PAYMENT_DETAILS: 'PAYMENT_DETAILS'
}

export const ONLINE_SALES_STAGES = {
    PRODUCT_DETAILS: 'PRODUCT_DETAILS',
    PAYMENT_DETAILS: 'PAYMENT_DETAILS'
}

export const SALES_FLOW = {
    OFFLINE: 'IN_STORE',
    OFFLINE_HOLD: 'OFFLINE_HOLD',
    ONLINE: 'ONLINE',
    ONLINE_PRESCRIPTION: 'ONLINE_PRESCRIPTION'
}

export const SALES_TYPE = {
    HOLD: 'HOLD',
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED',
    NON_HOLD_STATUS_LIST: ['COMPLETED', 'CANCELLED', 'CONFIRMED'],
    CONFIRMED_LIST: ['CONFIRMED', 'COMPLETED'],
    CONFIRMED: 'CONFIRMED'
}

export const SALES_TYPE_BACKEND_MAPPING = {
    IN_STORE: 'In Store',
    ONLINE: 'Online',
    CREATED: SALES_TYPE.COMPLETED
}

export const ADD_EXCHANGE_RETURN_FLOW = {
    EXCHANGE_FLOW: 'EXCHANGE',
    RETURN_FLOW: 'RETURN'
}

export const EXCHANGE_RETURN_UI_NAME = {
    EXCHANGE: 'Exchange',
    RETURN: 'Return'
}

export const ADD_EXCHANGE_STAGES = {
    INVOICE_NUMBER: 'INVOICE_NUMBER',
    EXCHANGE_PRODUCT_DETAILS: 'EXCHANGE_PRODUCT_DETAILS',
    NEW_PRODUCT_DETAILS: 'NEW_PRODUCT_DETAILS',
    PAYMENT_DETAILS: 'PAYMENT_DETAILS'
}

export const ADD_RETURN_STAGES = {
    RETURN_PRODUCT_DETAILS: 'EXCHANGE_PRODUCT_DETAILS',
    // NEW_PRODUCT_DETAILS: 'NEW_PRODUCT_DETAILS',
    RETURN_PAYMENT_DETAILS: 'RETURN_PAYMENT_DETAILS'
}

export const EXPIRED_PRODUCT_STATUS = {
    RETURN: 'RETURN',
    RETURNED: 'RETURNED'
}

export const MOBILE_SIDEMENU_VISIBLE_SCREENS = [
    PAGE_CONST.HOME,
    PAGE_CONST.STOCKS,
    PAGE_CONST.PROFILE_MENU
]

export const DISCOUNT_TYPE = {
    SINGLE_PRODUCT: 'SINGLE_PRODUCT',
    CAMPAIGN: 'CAMPAIGN'
}

export const ACCOUNTING_TYPE = {
    VOUCHER_REPORT: 'VOUCHER_REPORT',
    RECEIPT_PAYMENT: 'RECEIPT_PAYMENT',
    PROFIT_AND_LOSS: 'PROFIT_AND_LOSS',
    BALANCE_SHEET: 'BALANCE_SHEET'
}

export const ACCOUNTING_VOUCHER_CATEGORY = {
    MAIN_CATEGORY: [
        {
            name: 'CURRENT_ASSETS',
            label: 'Current Assets'
        },
        {
            name: 'FIXED_ASSETS',
            label: 'Fixed Assets'
        },

        {
            name: 'LIABILITIES',
            label: 'Liabilities'
        },
        {
            name: 'SHAREHOLDERS_EQUITY',
            label: "Shareholder's Equity"
        },
        {
            name: 'SALES',
            label: 'Sales'
        },
        {
            name: 'PURCHASE_DIRECT_EXPENSES',
            label: 'Purchase /Direct Expenses'
        },
        {
            name: 'SALARY_ALLOWANCES',
            label: 'Salary & Allowances'
        },
        {
            name: 'GENERAL_EXPENSES',
            label: 'General Expenses'
        }
    ],
    SUB_CATEGORY: {
        CURRENT_ASSETS: [
            {
                name: 'CASH_IN_HAND',
                label: 'Cash in Hand'
            },
            {
                name: 'CASH_IN_ACCOUNT',
                label: 'Cash in A/C'
            },
            {
                name: 'INVENTORY',
                label: 'Inventory'
            },
            {
                name: 'ADVANCES_DEPOSIT',
                label: 'Advances / Deposits'
            },
            {
                name: 'LOAN_TO_DIRECTOR_PROPRIETOR',
                label: 'Loan to Director/Proprietor'
            },
            {
                name: 'ACCOUNT_RECEIVABLES',
                label: 'Account Receivables'
            }
        ],
        FIXED_ASSETS: [
            {
                name: 'SHAREHOLDER_EQUITY',
                label: "Shareholder's Equity"
            },
            {
                name: 'LAND_FLAT_STORE',
                label: 'Land & Flat/Store'
            },
            {
                name: 'ELECTRICAL_ELECTRONIC_ITEMS',
                label: 'Electrical/Electronic Items'
            },
            {
                name: 'VEHICLES',
                label: 'Vehicles'
            },
            {
                name: 'FURNITURE_FIXTURES',
                label: 'Furniture & Fixtures'
            },
            {
                name: 'TERM_DEPOSITS',
                label: 'Term Deposits'
            }
        ],
        LIABILITIES: [
            {
                name: 'LOAN',
                label: 'Loan'
            },
            {
                name: 'DIRECTOR_PROPRIETORS_LOAN_TO_BUSINESS',
                label: "Director/Proprietor's Loan to business"
            },

            {
                name: 'INVOICE_FACTORING_PROVIDER',
                label: 'Invoice Factoring Provider'
            },
            {
                name: 'SUPPLIER_DUE_ACCOUNT_PAYABLE',
                label: "Supplier's Due / Account Payable"
            },
            {
                name: 'OTHER_LOANS_DUE',
                label: 'Other Loans/Due'
            }
        ],
        SHAREHOLDERS_EQUITY: [
            {
                name: 'PAID_UP_CAPITAL',
                label: 'Paid up capital'
            },
            {
                name: 'RETAINED EARNING',
                label: 'Retained Earning (Beginning Balance)'
            },

            {
                name: 'WITHDRAWALS_DIVIDEND_TO_SHAREHOLDERS',
                label: 'Withdrawals / Dividend to Shareholders'
            }
        ],
        SALES: [
            {
                name: 'PHARMA_PRODUCT_SALES',
                label: 'Pharma Product Sales'
            },
            {
                name: 'NON_PHARMA_PRODUCT_SALES',
                label: 'Non-Pharma Product Sales'
            },
            {
                name: 'OTHER_SALES',
                label: 'Other Sales'
            }
        ],
        PURCHASE_DIRECT_EXPENSES: [
            {
                name: 'PHARMA_PRODUCT_PURCHASE',
                label: 'Pharma Product Purchase'
            },
            {
                name: 'NON_PHARMA_PRODUCT_PURCHASE',
                label: 'Non-Pharma Product Purchase'
            }
        ],
        SALARY_ALLOWANCES: [
            {
                name: 'OWNER_ALLOWANCE',
                label: "Owner's Allowance"
            },
            {
                name: 'STAFF_SALARY',
                label: 'Staff Salary'
            },
            {
                name: 'STUFF_ALLOWANCE',
                label: 'Stuff Allowance'
            },
            {
                name: 'FESTIVAL_BONUS',
                label: 'Festival Bonus'
            }
        ],
        GENERAL_EXPENSES: [
            {
                name: 'SHOP_STORE_RENT',
                label: 'Shop/Store Rent'
            },
            {
                name: 'PLATFORM_EXPENSE',
                label: 'Platform Expense'
            },
            {
                name: 'DELIVERY_EXPENSE',
                label: 'Delivery Expense'
            },
            {
                name: 'INCOME_TAX_PAID_TO_GOVT',
                label: 'Income TAX paid to govt.'
            },
            {
                name: 'VAT_PAID_TO_GOVT',
                label: 'VAT paid to govt.'
            },
            {
                name: 'ELECTRICITY_BILL',
                label: 'Electricity Bill'
            },
            {
                name: 'WATER_BILL',
                label: 'Water Bill'
            },
            {
                name: 'CONNECTIVITY_BILL',
                label: 'Connectivity Bill'
            },
            {
                name: 'BCDS_MONTHLY_FEE',
                label: 'BCDS monthly Fee'
            },
            {
                name: 'ENTERTAINMENT',
                label: 'Entertainment'
            },
            {
                name: 'TRAVEL_CONVEYANCE',
                label: 'Travel / Conveyance'
            },
            {
                name: 'STATIONARY_COMPUTER_ACCESSORIES',
                label: 'Stationary / Computer Accessories'
            },
            {
                name: 'LICENCING_RENEWAL_FEE',
                label: 'Licencing & renewal fee'
            },
            {
                name: 'EXPIRED_PRODUCT_RETURN_LOSS ',
                label: 'Expired Product Return Loss '
            },
            {
                name: 'DAMAGE_LOST_LOSS',
                label: 'Damage/lost Loss'
            },
            {
                name: 'DEPRECIATION',
                label: 'Depreciation'
            },
            {
                name: 'POS_MACHINE_CHARGE',
                label: 'POS machine charge'
            },
            {
                name: 'INTEREST_EXPENSE',
                label: 'Interest Expense'
            },
            {
                name: 'BANK_CHARGES',
                label: 'Bank charges'
            },
            {
                name: 'LEGAL_CHARGE_FEES',
                label: 'Legal Charge & Fees'
            },
            {
                name: 'AUDIT_AND_TAX_CONSULTANCY',
                label: 'Audit and Tax Consultancy'
            },
            {
                name: 'MARKETING_ADVERTISING_EXPENSES',
                label: 'Marketing/Advertising Expenses'
            },
            {
                name: 'MISCELLANEOUS',
                label: 'Miscellaneous'
            }
        ]
    }
}

export const VOUCHER_FIELDS = {
    SUB_CATEGORY: 'sub_category',
    PARTICULARS: 'particulars',
    PAYMENT_MODE: 'payment_mode',
    CREDIT: 'credit',
    DEBIT: 'debit'
}

export const VOUCHER_PAYMENT_OPTION_LIST = [
    {
        name: 'CASH_IN_ACCOUNT',
        label: 'CIA (Cash in Account) '
    },
    {
        name: 'CASH_IN_HAND',
        label: 'CIH (Cash in Hand) '
    }
]

export const CARD_ACTION = {
    DELIVERY_PARTNER_ACTIVE: ['IN_ACTIVE', 'DELETE'],
    DELIVERY_PARTNER_INACTIVE: ['ACTIVE', 'DELETE'],
    BRANCH_MANAGEMENT: ['EDIT'],
    USER: ['EDIT', 'DELETE']
}

export const ADD_USER_FLOW = {
    EDIT: 'EDIT',
    DELETE: 'DELETE'
}

export const PAYMENT_MODE_OPTIONS = [
    {
        name: 'CASH',
        label: 'Cash'
    },
    {
        name: 'CARD',
        label: 'Card'
    },
    {
        name: 'ONLINE',
        label: 'Online'
    },
    {
        name: 'DUE_SALES',
        label: 'Due Sales'
    },
    {
        name: 'CASH_CARD',
        label: 'Cash & Card'
    }
]

export const PAYMENT_MODE_OPTIONS_PRODUCT_RECEIVING = [
    {
        name: 'CASH_IN_HAND',
        label: 'Cash in hand'
    },
    {
        name: 'CASH_IN_ACCOUNT',
        label: 'Cash in account'
    },
    {
        name: 'PAY_LATER',
        label: 'Pay Later'
    }
]

export const PRESCRIPTION_ORDER_MILESTONE = {
    PRESCRIPTION_PENDING_ON_CUSTOMER: 'PRESCRIPTION_PENDING_ON_CUSTOMER',
    PRESCRIPTION_PENDING_ON_STORE: 'PRESCRIPTION_PENDING_ON_STORE'
}

export const FORGOT_PASSWORD_STAGES = {
    SEND_OTP_STAGE: 'SEND_OTP_STAGE',
    VERIFY_OTP_STAGE: 'VERIFY_OTP_STAGE',
    PASSWORD_INPUT_STAGE: 'PASSWORD_INPUT_STAGE',
    SUCCESS_STAGE: 'SUCCESS_STAGE'
}
