import React from 'react'

const ReturnBlack = () => (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="fluent:arrow-hook-up-left-24-regular">
            <path id="Vector" d="M18.6665 51.3333C18.6665 52.4373 19.5625 53.3333 20.6665 53.3333H37.3332C42.6105 53.3333 46.6772 51.1386 49.3865 47.8026C52.0532 44.5146 53.3332 40.2133 53.3332 35.9999C53.3332 31.7866 52.0532 27.4853 49.3865 24.1973C46.6772 20.8613 42.6105 18.6666 37.3332 18.6666H20.1599L26.7465 12.0799C26.943 11.8968 27.1006 11.676 27.21 11.4307C27.3193 11.1854 27.378 10.9205 27.3828 10.652C27.3875 10.3834 27.3381 10.1167 27.2375 9.86766C27.1369 9.61863 26.9872 9.3924 26.7973 9.20249C26.6074 9.01257 26.3812 8.86285 26.1321 8.76226C25.8831 8.66167 25.6164 8.61227 25.3478 8.61701C25.0793 8.62175 24.8144 8.68053 24.5691 8.78984C24.3238 8.89915 24.103 9.05676 23.9199 9.25326L13.9199 19.2533C13.5453 19.6283 13.335 20.1366 13.335 20.6666C13.335 21.1966 13.5453 21.7049 13.9199 22.0799L23.9199 32.0799C24.103 32.2764 24.3238 32.434 24.5691 32.5433C24.8144 32.6527 25.0793 32.7114 25.3478 32.7162C25.6164 32.7209 25.8831 32.6715 26.1321 32.5709C26.3812 32.4703 26.6074 32.3206 26.7973 32.1307C26.9872 31.9408 27.1369 31.7146 27.2375 31.4655C27.3381 31.2165 27.3875 30.9497 27.3828 30.6812C27.378 30.4127 27.3193 30.1478 27.21 29.9025C27.1006 29.6572 26.943 29.4364 26.7465 29.2533L20.1599 22.6666H37.3332C41.3892 22.6666 44.3199 24.3066 46.2799 26.7199C48.2799 29.1813 49.3332 32.5466 49.3332 35.9999C49.3332 39.4533 48.2772 42.8186 46.2799 45.2799C44.3225 47.6933 41.3892 49.3333 37.3332 49.3333H20.6665C20.1361 49.3333 19.6274 49.544 19.2523 49.919C18.8772 50.2941 18.6665 50.8028 18.6665 51.3333Z" fill="#212121" />
        </g>
    </svg>
)

export default ReturnBlack
