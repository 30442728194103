import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    HEADER_CONFIG,
    HEADER_CONST
} from '../../common/constant/common-constant'
import { PAGE_CONST } from '../../common/constant/page-constant'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import { redirect } from '../../common/utils/redirectionUtils'
import MobileHeader from '../Common/Header/MobileHeader'

import './TermAndCondition.scss'

const TermAndCondition = props => {
    const navigate = useNavigate()
    const isMobile = useCheckMobileScreen()

    const [headerOptions, setHeaderOptions] = useState({
        [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
        [HEADER_CONFIG.TITLE]: HEADER_CONST.TERMS_AND_CONDITIONS,
        [HEADER_CONFIG.HANDLE_BACK_FUNC]: () => props?.onBackClick()
    })

    return (
        <>
            {isMobile && <MobileHeader headerOptions={headerOptions} />}
            <div className="pageBodyRightPanel">
                <div className="pageBodyRightPanelHeader dFlex jcSpaceBetween aiCenter">
                    <div className="pageBodyRightPanelHeading">
                        Terms and Conditions
                    </div>
                </div>
                <div className="informationCondition">
                    <ol className="mainInfoList">
                        <li className="mainInfoListItem firstLevel">
                            <span>Introduction</span>
                        </li>
                        <p className="infoPara">
                            Welcome to the official platform of DG e-Pharma.
                        </p>

                        <p className="infoPara">
                            By using the Platform, any natural or legal person
                            (the “User” or “You”) hereby agrees that they have
                            read these Terms and Conditions (the “Terms and
                            Conditions”) carefully and accept to be bound by the
                            Terms and Conditions and represent that they agree
                            to comply with these Terms and Conditions. If any
                            User does not agree to all of the Terms and
                            Conditions, he or she will not be able to access the
                            Platform or avail any of its services.
                        </p>

                        <p className="infoPara">
                            The Terms and Conditions are deemed effective upon
                            the use of the Platform, which signifies the User's
                            acceptance of these Terms and Conditions. If any
                            User does not want to be bound by the Terms and
                            Conditions, such User must not subscribe to or use
                            the Platform or the services provided by the
                            Platform.
                        </p>

                        <li className="mainInfoListItem firstLevel">
                            <span>Registration</span>
                            <ol className="mainInfoList">
                                <li className="mainInfoListItem">
                                    You will need to register for a DG e-Pharma
                                    Account (the “Pharmacy App”) to use the
                                    Platform. For the registration process, it
                                    requires some information/documents
                                    including trade license, DGDA license, BIN
                                    number, TIN number, email address, phone
                                    number and password.
                                </li>
                                <li className="mainInfoListItem">
                                    By registering and making use of the
                                    services, You attest that You are at least
                                    18 (eighteen) years old, You are not legally
                                    prohibited from entering into contracts, You
                                    have read and accepted the Terms and
                                    Conditions posted on the Platform, and the
                                    information You submitted during the
                                    registration process is accurate and
                                    complete. You pledge to quickly update Your
                                    registration information if anything
                                    changes.{' '}
                                </li>
                                <li className="mainInfoListItem">
                                    The Company may at any time in its sole and
                                    absolute discretion, invalidate, close,
                                    revoke, block etc. the Account of any User
                                    without giving any reason or prior notice.
                                </li>
                                <li className="mainInfoListItem">
                                    The User hereby gives unrestricted consent
                                    and legal authority to the Company to
                                    handle, share, store, access, process etc.
                                    of the User's information (personal and/or
                                    business information), which were provided
                                    during the registration of the Account or
                                    anytime thereafter.
                                </li>
                                <li className="mainInfoListItem">
                                    The User grants the Company an unrestricted
                                    right to disclose to third parties of the
                                    User's information (personal and/or business
                                    information).
                                </li>
                            </ol>
                        </li>

                        <li className="mainInfoListItem firstLevel">
                            <span>Accessing the Site</span>
                            <ol className="mainInfoList">
                                <li className="mainInfoListItem">
                                    The Company grants the User a
                                    non-transferable, revocable, and
                                    non-exclusive license to use the Site, in
                                    accordance with the Terms and Conditions
                                    described herein, for the purposes of
                                    purchasing the goods as well as to obtain
                                    any service as provided in the Site.
                                </li>
                                <li className="mainInfoListItem">
                                    If the User is registering an Account for
                                    and on behalf of an establishment/business
                                    entity, the User represents that they have
                                    the authority to bind that
                                    establishment/business entity to the Terms
                                    and Conditions. The Company may require such
                                    User to provide any documentary proof
                                    showing the authorization of such
                                    establishment/business entity.
                                </li>
                                <li className="mainInfoListItem">
                                    No natural or legal person shall register an
                                    Account with the Site more than once unless
                                    approved in writing by the Company. The
                                    email ID and/or phone number of the User
                                    cannot be used more than once for opening an
                                    Account, and the Company shall have the
                                    ultimate discretion to cancel, block,
                                    suspend, etc., any Account in this regard.
                                </li>
                                <li className="mainInfoListItem">
                                    Access to the Site may
                                    occasionally/temporarily be suspended or
                                    restricted for the purpose of repairing,
                                    maintenance, technical or business reasons
                                    or for the introduction of new facilities or
                                    services at any time, without prior notice.
                                    The Company reserves the right to give push
                                    notifications in this regard.
                                </li>
                            </ol>
                        </li>

                        <li className="mainInfoListItem firstLevel">
                            <span>Responsibilities of the User</span>
                            <ol className="mainInfoList">
                                <li className="mainInfoListItem">
                                    The User acknowledges and undertakes to
                                    access the Site and transact at his/her own
                                    risk and shall use his/her best and prudent
                                    judgment before entering into any
                                    transactions through the Site.
                                </li>
                                <li className="mainInfoListItem">
                                    The User acknowledges and undertakes that he
                                    or she is accessing the Site with his or her
                                    free will and without fraud, duress, undue
                                    influence or coercion of any kind or nature
                                    whatsoever from the Company or any other
                                    party.
                                </li>
                                <li className="mainInfoListItem">
                                    The User shall not engage in activities that
                                    could harm or potentially harm the Site, the
                                    Company, its employees, officers,
                                    representatives, stakeholders or any other
                                    party directly or indirectly associated with
                                    the Company or access to it to be
                                    interrupted, damaged or impaired in any way.{' '}
                                </li>
                                <li className="mainInfoListItem">
                                    The User shall be responsible for all
                                    electronic communications and content sent
                                    from their device to the Site and the
                                    Company;
                                </li>
                                <li className="mainInfoListItem">
                                    The User shall use the Site for lawful
                                    purposes only.{' '}
                                </li>
                                <li className="mainInfoListItem">
                                    The User shall be strictly prohibited from
                                    using the Site for fraudulent purposes, or
                                    in connection with a criminal offense or
                                    other unlawful activity. Any suspected
                                    unlawful or fraudulent activity will result
                                    in immediate cancellation of the Account
                                    and/or may also result in legal action by
                                    the Company.
                                </li>
                                <li className="mainInfoListItem">
                                    The User shall be responsible for
                                    maintaining the confidentiality of the
                                    details of their Account. The User shall
                                    ensure that the Account and its related
                                    details are maintained securely at all times
                                    and all necessary steps are taken to prevent
                                    misuse thereof. The User shall inform the
                                    Company immediately if there is reason to
                                    believe that the password to the Account has
                                    become known to any third party, or if the
                                    password is being, or is likely to be, used
                                    in an unauthorized manner.{' '}
                                </li>
                                <li className="mainInfoListItem">
                                    The User is under an obligation to ensure
                                    that the information provided to the Company
                                    is correct and complete at all times. The
                                    User shall update details in their Account
                                    regularly. The Company may at any time, at
                                    its sole and absolute discretion, request
                                    updating of information to any User.
                                </li>
                                <li className="mainInfoListItem">
                                    The User shall not use any false information
                                    to access the Site or impersonate others or
                                    otherwise mislead the Company or third
                                    parties in any way whatsoever.
                                </li>
                                <li className="mainInfoListItem">
                                    The User shall be bound to comply with the
                                    Terms and Conditions, despite having ordered
                                    the goods from the Site for or on behalf of
                                    any third party(s).
                                </li>
                            </ol>
                        </li>

                        <li className="mainInfoListItem firstLevel">
                            <span>Account deletion and restriction</span>
                            <ol className="mainInfoList">
                                <li className="mainInfoListItem">
                                    To delete Your Account, please send us an
                                    email. Your use of the Platform and/or Your
                                    Account may be restricted, suspended, or
                                    terminated by us if we have reasonable
                                    suspicion that You are:
                                    <ol className="mainInfoList">
                                        <li className="mainInfoListItem">
                                            publishing any content that violates
                                            any intellectual property rights,
                                            privacy, publicity, trade secret, or
                                            other rights;
                                        </li>
                                        <li className="mainInfoListItem">
                                            publishing content that is deemed by
                                            DG e-Pharma in its sole discretion
                                            to be illegal, obscene, defamatory,
                                            slanderous, threatening, harassing,
                                            violent, hateful, or discouraging to
                                            any other person or entity;
                                        </li>
                                        <li className="mainInfoListItem">
                                            publishing anything that, in DG
                                            e-Pharma's sole discretion,
                                            qualifies as cyberbullying;
                                        </li>
                                        <li className="mainInfoListItem">
                                            posting any malicious HTML code/link
                                            to the Platform that carries
                                            security risk;
                                        </li>
                                        <li className="mainInfoListItem">
                                            publishing anything that DG
                                            e-Pharma, at its sole discretion,
                                            deems to be "spam";
                                        </li>
                                        <li className="mainInfoListItem">
                                            posing as someone else when
                                            uploading/inserting
                                            content/information;
                                        </li>
                                        <li className="mainInfoListItem">
                                            gathering data about other people,
                                            including email addresses, in any
                                            way and without their permission;
                                        </li>
                                        <li className="mainInfoListItem">
                                            permitting any other individual or
                                            organization to view or post
                                            comments using Your identity;
                                        </li>
                                        <li className="mainInfoListItem">
                                            intimidating, frightening or
                                            mistreating any user of the
                                            Platform;
                                        </li>
                                        <li className="mainInfoListItem">
                                            participating in any other activity
                                            that prevents someone else from
                                            accessing or using the Site. Also,
                                            exposes any kind of information of
                                            DG e-Pharma or its vendors,
                                            customers or any others entities
                                            without the permission of DG
                                            e-Pharma.{' '}
                                        </li>
                                        <li className="mainInfoListItem">
                                            encouraging other persons to engage
                                            in any unlawful conduct.
                                        </li>
                                    </ol>
                                </li>
                                <li className="mainInfoListItem">
                                    DG e-Pharma may, but is not required to,
                                    take any or all of the following actions:
                                    <ol className="mainInfoList">
                                        <li className="mainInfoListItem">
                                            look into a claim that any
                                            content/information uploaded on the
                                            Platform violates the Terms and
                                            Conditions and decide, in its sole
                                            discretion, whether to have the
                                            content removed or have it removed;
                                        </li>
                                        <li className="mainInfoListItem">
                                            delete any content that violates the
                                            Terms and Conditions or is
                                            offensive, unlawful, disruptive, or
                                            otherwise not in line with the Terms
                                            and Conditions;
                                        </li>
                                        <li className="mainInfoListItem">
                                            in the event that the Terms and
                                            Conditions are violated, suspend or
                                            cancel a User's access to the
                                            Platform or their Account.
                                        </li>
                                    </ol>
                                </li>
                                <li className="mainInfoListItem">
                                    Where, in the Company’s opinion, the User
                                    has violated/breached these Terms and
                                    Conditions and the User’s actions in using
                                    the Site may cause loss or damage to other
                                    Users, third parties, the Company; and/or
                                    any information of such User is
                                    inappropriate or offensive; or the Company
                                    is unable to verify the User’s identity or
                                    any information the User has provided, the
                                    Company may in its sole discretion:
                                    <ol className="mainInfoList">
                                        <li className="mainInfoListItem">
                                            restrict or temporarily or
                                            indefinitely suspend the Account of
                                            the User;
                                        </li>
                                        <li className="mainInfoListItem">
                                            terminate the Account of the User;
                                        </li>
                                        <li className="mainInfoListItem">
                                            may prohibit the User from
                                            using/accessing the Site in the
                                            future.
                                        </li>
                                    </ol>
                                </li>
                                <li className="mainInfoListItem">
                                    If any such User commits any of the wrongful
                                    actions as mentioned in this Clause, the
                                    Company shall have all the right to take
                                    legal action against such wrongdoing User in
                                    accordance with the laws of Bangladesh.
                                </li>
                                <li className="mainInfoListItem">
                                    The User represents, warrants and
                                    unequivocally agree that:
                                    <ol className="mainInfoList">
                                        <li className="mainInfoListItem">
                                            the User is competent to enter into
                                            a legally binding contract;
                                        </li>
                                        <li className="mainInfoListItem">
                                            the User holds all corporate and
                                            legal authorisations to access the
                                            Site and place order to purchase the
                                            goods;
                                        </li>
                                        <li className="mainInfoListItem">
                                            all information that the User
                                            provides is not false, misleading,
                                            inaccurate or offensive;
                                        </li>
                                        <li className="mainInfoListItem">
                                            the User will not place any order
                                            unless the User is financially able
                                            to pay the full price of such goods;
                                        </li>
                                        <li className="mainInfoListItem">
                                            the User will not transfer or assign
                                            its Account to another party without
                                            the Company’s express written
                                            consent;
                                        </li>
                                        <li className="mainInfoListItem">
                                            the User will not infringe the
                                            copyright or other intellectual
                                            property rights of any other person;
                                        </li>
                                        <li className="mainInfoListItem">
                                            the User will not engage in spamming
                                            or other conduct involving the
                                            sending of bulk electronic or
                                            unsolicited communications, emails
                                            etc.;
                                        </li>
                                        <li className="mainInfoListItem">
                                            the User will not use any automated
                                            systems to harvest, access or
                                            analyse any information or content
                                            from the Site;
                                        </li>
                                        <li className="mainInfoListItem">
                                            the User will not distribute viruses
                                            or any other technology that may
                                            harm the Site or other Users of the
                                            Site and other customers of the
                                            Company;
                                        </li>
                                        <li className="mainInfoListItem">
                                            the User will not copy, reproduce,
                                            modify or create derivative works of
                                            the Site without the Company’s prior
                                            written consent;
                                        </li>
                                        <li className="mainInfoListItem">
                                            the User will not interfere or
                                            attempt to interfere with the
                                            orderly working of the Site;
                                        </li>
                                        <li className="mainInfoListItem">
                                            the User will comply with all the
                                            laws of Bangladesh; and
                                        </li>
                                        <li className="mainInfoListItem">
                                            the User will not copy, modify or
                                            distribute rights or content from
                                            the Site trademarks and copyright of
                                            the Company.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>

                        <li className="mainInfoListItem firstLevel m-0">
                            <span>Transaction</span>
                            <p className="infoPara">
                                Any transaction of unregistered local medicine,
                                unapproved foreign medicine or other
                                unauthorized products buy-sell using this
                                Platform is strictly prohibited. DG e-Pharma
                                will not be responsible for such transaction.
                            </p>
                        </li>

                        <li className="mainInfoListItem firstLevel">
                            <span>Third Party Links and Websites</span>
                            <p className="infoPara">
                                Occasionally, DG e-Pharma and other third
                                parties with whom we have a business
                                relationship may use the website, mobile
                                application, or other direct marketing campaigns
                                to advertise their products or services. They
                                may also make software and other materials
                                available for You to buy or download. While we
                                make an effort to encourage third parties to
                                provide high-quality goods, services, and
                                materials at reasonable costs, we have no
                                control over them or any other third parties,
                                and we neither recommend nor guarantee that the
                                goods or services they provide will meet Your
                                needs. You bear the responsibility of ensuring
                                Your own satisfaction in this regard, and we
                                disclaim any liability pertaining to them. All
                                promotions have time limits and unique terms and
                                conditions that apply in addition to the Terms
                                and Conditions.
                            </p>
                            <p className="infoPara">
                                You acknowledge that You are using the Platform
                                at your own risk when You click on any links it
                                may contain to websites operated by third
                                parties. These third-party websites and links
                                are not under the control of DG e-Pharma, and DG
                                e-Pharma disclaims all liability for the
                                information found on these linked pages. DG
                                e-Pharma disclaims all liability and
                                responsibility for any loss or harm You may
                                experience as a result of using these
                                third-party links and websites.
                            </p>
                        </li>

                        <li className="mainInfoListItem firstLevel">
                            <span>Payment Methods </span>
                            <ol className="mainInfoList">
                                <li className="mainInfoListItem">
                                    At any time, and at its sole discretion, DG
                                    e-Pharma retains the right to add new
                                    payment options and/or eliminate current
                                    ones. Should You opt for an online payment
                                    method, certain third-party payment service
                                    provider(s) will handle the payment
                                    processing.{' '}
                                </li>
                                <li className="mainInfoListItem">
                                    If You opt to make the payment by using any
                                    mobile/digital/electronic payment methods,
                                    DG e-Pharma shall not be responsible in any
                                    manner whatsoever for any non-payment and/or
                                    any error in this regard. This shall be
                                    solely between You and the relevant
                                    mobile/digital/electronic payment service
                                    provider/facilitator.
                                </li>
                                <li className="mainInfoListItem">
                                    DG e-Pharma reserves the right to cancel an
                                    order which may be suspected to be at risk
                                    of fraudulent use of
                                    mobile/digital/electronic payment methods or
                                    other reasons, without prior notice to You
                                    or any subsequent legal liability.
                                </li>
                            </ol>
                        </li>

                        <li className="mainInfoListItem firstLevel">
                            <span>Security</span>
                            <p className="infoPara">
                                We use security technology. Nevertheless, since
                                Internet transmissions are never totally private
                                or secure, there is a chance that any message or
                                information You send to us from/through the
                                Site/Platform could be intercepted and possibly
                                read by others.
                            </p>
                        </li>

                        <li className="mainInfoListItem firstLevel">
                            <span>Unauthorized party</span>
                            <p className="infoPara">
                                A person who is not a part of these Terms and
                                Conditions has no right to enforce them, and
                                nothing in them is intended to or shall confer a
                                benefit on any third party under the Terms and
                                Conditions.
                            </p>
                        </li>

                        <li className="mainInfoListItem firstLevel">
                            <span>Update</span>
                            <ol className="mainInfoList">
                                <li className="mainInfoListItem">
                                    DG e-Pharma reserves the right to change,
                                    modify, amend, replace. alter these Terms
                                    and Conditions from time to time without
                                    notice to the User. The User acknowledges
                                    and agrees that it is the User’s
                                    responsibility to review these Terms and
                                    Conditions from time to time and to be aware
                                    of any such modifications.
                                </li>
                                <li className="mainInfoListItem">
                                    The User’s use of the Site after such
                                    modifications will constitute User’s
                                    acknowledgment of and agreement to abide and
                                    be bound by the modified Terms and
                                    Conditions.
                                </li>
                                <li className="mainInfoListItem">
                                    The Company may provide push notification to
                                    the User in the Site regarding the
                                    modifications of the Terms and Conditions.{' '}
                                </li>
                            </ol>
                        </li>

                        <li className="mainInfoListItem firstLevel">
                            <span>Delivery Time</span>
                            <p className="infoPara">
                                Delivery time depends on the delivery company.
                                It might take longer than usual or delayed due
                                to some events includes but not limited to
                                political unrest, public/national holidays, poor
                                traffic condition, weather etc.{' '}
                            </p>
                        </li>

                        <li className="mainInfoListItem firstLevel">
                            <span>Selling of Unprescribed medicine</span>
                            <p className="infoPara">
                                The Platform will provide a list of prescribed
                                and unprescribed medicine to the pharmacy users.
                                The Users are not allowed to sell any
                                unprescribed medicine through the Platform and
                                DG e-Pharma shall not be held responsible for
                                any such activities.{' '}
                            </p>
                        </li>

                        <li className="mainInfoListItem firstLevel">
                            <span>Returned product</span>
                            <p className="infoPara">
                                {`Returned products amount to the manufacturer
                                will be shown in “Settings >> Advanced Payment
                                Module”. User will log in into the App then go
                                to "My Store > Expired Product > Select product
                                to return > Proceed. That amount will be given
                                as discount for the next order. And the status
                                will be updated as “Returned. `}
                            </p>
                        </li>

                        <li className="mainInfoListItem firstLevel">
                            <span>Cancel order</span>
                            <p className="infoPara">
                                You can cancel your order as long as the payment
                                is not done.
                            </p>
                        </li>

                        <li className="mainInfoListItem firstLevel">
                            <span>Platform charge</span>
                            <p className="infoPara">
                                If You have not paid system/platform charge with
                                in defined time; You cannot perform any activity
                                in the app except payment. Until the payment is
                                completed; Your Account will be deactivated. In
                                case if any order is in process; such orders
                                will be processed and delivered.
                            </p>
                        </li>

                        <li className="mainInfoListItem firstLevel">
                            <span>Vendor Liability </span>
                            <p className="infoPara">
                                Vendors are in charge of the goods' quality,
                                condition, and preparation. When it comes to
                                delivery, vendors are in charge of delivering
                                the orders and/or goods. Regarding any loss or
                                damage resulting from your contractual
                                arrangement with the vendor, DG e-Pharma shall
                                not be held responsible.
                            </p>
                        </li>

                        <li className="mainInfoListItem firstLevel">
                            <span>Force Majeure</span>
                            <ol className="mainInfoList">
                                <li className="mainInfoListItem">
                                    The delivery may be delayed due to any event
                                    of force majeure. DG e-Pharma will make all
                                    reasonable effort to notify You of any delay
                                    for force majeure events.
                                </li>
                                <li className="mainInfoListItem">
                                    DG e-Pharma shall not be liable to You for
                                    any delay or failure in performance under
                                    the Terms and Conditions, arising out of a
                                    cause beyond DG e-Pharma's control and
                                    without the fault or negligence of DG
                                    e-Pharma. Such causes may include, but are
                                    not limited to fires, floods, earthquakes,
                                    strikes, unavailability of necessary
                                    utilities, blackouts, acts of God, acts of
                                    declared or undeclared war, acts of
                                    regulatory agencies, or national disasters,
                                    lockdown, epidemic, pandemic etc.{' '}
                                </li>
                                <li className="mainInfoListItem">
                                    No force majeure event will relieve You from
                                    making the applicable payments to DG
                                    e-Pharma/vendor in a timely manner.
                                </li>
                            </ol>
                        </li>

                        <li className="mainInfoListItem firstLevel">
                            <span>No Warranty</span>
                            <ol className="mainInfoList">
                                <li className="mainInfoListItem">
                                    All information and materials provided by DG
                                    e-Pharma on the Site or to the User “AS IS”.
                                </li>
                                <li className="mainInfoListItem">
                                    The User acknowledges that DG e-Pharma makes
                                    no representation or warranty, whether
                                    express or implied, as to the accuracy or
                                    completeness of information and material
                                    provided on the Site or to the User, and DG
                                    e-Pharma disclaims any and all liability in
                                    this regard.
                                </li>
                                <li className="mainInfoListItem">
                                    DG e-Pharma does not warrant that the
                                    functions contained in the Site shall be
                                    uninterrupted or error-free or that those
                                    defects shall be corrected or the Site or
                                    server of the Site shall be free of viruses
                                    or bugs. DG e-Pharma does not warrant full
                                    functionality, accuracy or reliability of
                                    any material. DG e-Pharma may terminate,
                                    change, suspend or discontinue any aspect of
                                    the Site, including the availability of any
                                    features of the Site, at any time without
                                    notice or liability.
                                </li>
                            </ol>
                        </li>

                        <li className="mainInfoListItem firstLevel">
                            <span>Confidentiality</span>
                            <ol className="mainInfoList">
                                <li className="mainInfoListItem">
                                    The User shall exercise every reasonable
                                    precaution necessary and appropriate to
                                    guard the confidentiality of confidential
                                    information, including informing its
                                    employees who handle such confidential
                                    information that it is confidential and not
                                    to be disclosed to others.{' '}
                                </li>
                                <li className="mainInfoListItem">
                                    The confidential information is and shall at
                                    all times remain the property of DG
                                    e-Pharma. No use of any confidential
                                    information is permitted except as otherwise
                                    provided herein and no grant under any
                                    proprietary rights is hereby given or
                                    intended, including any license implied or
                                    otherwise.
                                </li>
                                <li className="mainInfoListItem">
                                    The User warrants that it applies reasonable
                                    safeguards against the unauthorised
                                    disclosure of confidential information and
                                    agrees to protect it in accordance with
                                    generally accepted standards or in the same
                                    manner and to the same degree that it
                                    protects its own confidential information,
                                    whichever is higher.
                                </li>
                                <li className="mainInfoListItem">
                                    The User shall be liable for the actions of
                                    its consultants, officers, employees and
                                    agents who it allows access to the
                                    confidential information and must ensure
                                    that the consultants, officers, employees
                                    and agents will be similarly bound by the
                                    obligations created under these Terms and
                                    Conditions.
                                </li>
                                <li className="mainInfoListItem">
                                    Any communication or material that the User
                                    transmits to the Site or DG e-Pharma,
                                    whether by electronic or other means, for
                                    any reason, will be treated as
                                    non-confidential and non-proprietary unless
                                    stated otherwise.
                                </li>
                            </ol>
                        </li>

                        <li className="mainInfoListItem firstLevel">
                            <span>Cookie Policy</span>
                            <ol className="mainInfoList">
                                <li className="mainInfoListItem">
                                    DG e-Pharma may use cookies and similar
                                    technologies, such as tags and pixels
                                    (“cookies”), to personalise and improve the
                                    User’s experience of using the Site and to
                                    provide the User with relevant online
                                    advertising.
                                </li>
                                <li className="mainInfoListItem">
                                    The User can use his/her browser settings to
                                    accept or reject new cookies and to delete
                                    existing cookies as well as set up
                                    appropriate cookie setting as the user deem
                                    fit.
                                </li>
                                <li className="mainInfoListItem">
                                    If the User chooses to disable some or all
                                    cookies, the User may not be able to make
                                    full use of the Site.
                                </li>
                                <li className="mainInfoListItem">
                                    Unless the User expressly disables or
                                    rejects the cookies, it is hereby agreed
                                    that the User allows, accepts and agrees to
                                    all cookies that the Site uses, sends or
                                    generates.
                                </li>
                            </ol>
                        </li>

                        <li className="mainInfoListItem firstLevel">
                            <span>Obscenity and Objectionable Content</span>
                            <ol className="mainInfoList">
                                <li className="mainInfoListItem">
                                    If it appears to the User that any content
                                    on the Site violates the laws of Bangladesh
                                    and/or the rights of the User and/or falls
                                    within the ambit of objectionable content
                                    (such as illegal of obscene, indecent
                                    content etc.), the User shall notify DG
                                    e-Pharma.
                                </li>
                                <li className="mainInfoListItem">
                                    Thereafter, DG e-Pharma shall make all
                                    practical endeavours to investigate and
                                    consider to remove, block, limit the access
                                    of such objectionable content within a
                                    reasonable amount of time.
                                </li>
                            </ol>
                        </li>

                        <li className="mainInfoListItem firstLevel">
                            <span>Advertisements</span>
                            <ol className="mainInfoList">
                                <li className="mainInfoListItem">
                                    The User hereby authorizes DG e-Pharma to
                                    conduct promotional activities via e-mail,
                                    SMS, phone call or by any other mode of
                                    communications.
                                </li>
                                <li className="mainInfoListItem">
                                    DG e-Pharma may display adverts and
                                    promotions to the Site. DG e-Pharma’s
                                    advertising methods, modes, and extent on
                                    the Site are subject to change from time to
                                    time.
                                </li>
                                <li className="mainInfoListItem">
                                    The inclusion of advertisements on the Site
                                    by third parties does not imply DG
                                    e-Pharma’s support of any advertised items
                                    or services. The User acknowledges and
                                    agrees that DG e-Pharma is not responsible
                                    or liable for any loss or damage of any kind
                                    incurred as a consequence of any such
                                    dealings or the inclusion of such
                                    advertising on the Site.
                                </li>
                            </ol>
                        </li>

                        <li className="mainInfoListItem firstLevel">
                            <span>Infringements</span>
                            <p className="infoPara">
                                The User shall notify DG e-Pharma in writing
                                immediately it becomes aware of any
                                infringements by any person or corporation of
                                the undertakings contained in these Terms and
                                Conditions.
                            </p>
                        </li>

                        <li className="mainInfoListItem firstLevel">
                            <span>Indemnity</span>
                            <p className="infoPara">
                                The User shall indemnify, defend and hold
                                harmless DG e-Pharma (including its employees
                                and agents) from and against any and all claims,
                                losses, liabilities, suits, damages, costs,
                                charges and expenses (including but not limited
                                to legal counsel’s fee, judgment, order, decree,
                                fines, penalty in any form whatsoever awarded by
                                or under the authority of any court or any
                                regulatory body) incurred by DG e-Pharma as a
                                result of the User’s default and/or breach of
                                the Terms and Conditions.
                            </p>
                        </li>

                        <li className="mainInfoListItem firstLevel">
                            <span>Limitation of Liability</span>
                            <ol className="mainInfoList">
                                <li className="mainInfoListItem">
                                    Notwithstanding anything in this Terms and
                                    Conditions to the contrary, DG e-Pharma
                                    shall not be liable in any circumstances for
                                    any indirect or consequential losses (which
                                    expression shall include, but not be limited
                                    to, loss of anticipated profits, loss of
                                    savings and other economic loss) to the User
                                    under the Terms and Conditions.
                                </li>
                                <li className="mainInfoListItem">
                                    DG e-Pharma shall not accept any liability
                                    for any delays, failures, errors or
                                    omissions or loss of transmitted
                                    information, viruses or other contamination
                                    or destructive properties transmitted to the
                                    User or the User’s computer system via the
                                    Site.
                                </li>
                            </ol>
                        </li>

                        <li className="mainInfoListItem firstLevel">
                            <span>Governing Law</span>
                            <p className="infoPara">
                                The validity, performance and all matters
                                relating to the effect of the Terms and
                                Conditions and any amendment hereto shall be
                                governed by the laws of Bangladesh.
                            </p>
                        </li>

                        <li className="mainInfoListItem firstLevel">
                            <span>Language</span>
                            <p className="infoPara">
                                In the event that the Terms and Conditions are
                                translated into any other language, the English
                                Language version hereof shall take precedence
                                and govern.
                            </p>
                        </li>

                        <li className="mainInfoListItem firstLevel">
                            <span>Severability</span>
                            <p className="infoPara">
                                If any of the provisions of the Terms and
                                Conditions becomes invalid, illegal or
                                unenforceable in any respect under any law, the
                                validity, legality and enforceability of the
                                remaining provisions shall not in any way be
                                affected or impaired and shall continue in full
                                force and effect.
                            </p>
                        </li>

                        <li className="mainInfoListItem firstLevel">
                            <span>Compliance with Laws</span>
                            <p className="infoPara">
                                The User shall be subject to and shall comply
                                with all laws, rules, ordinances, regulations,
                                executive orders of Government or Governmental
                                authority or agency having or asserting
                                jurisdiction over the subject matter hereof, and
                                expressed public policies.
                            </p>
                        </li>

                        <li className="mainInfoListItem firstLevel">
                            <span>Assignment and Transfers</span>
                            <p className="infoPara">
                                Unless otherwise agreed by DG e-Pharma in
                                writing, the rights and obligations of the User
                                pursuant to the provisions herein shall not be
                                transferred to or assigned to any other entity
                                and/or third parties.{' '}
                            </p>
                        </li>

                        <li className="mainInfoListItem firstLevel">
                            <span>Queries and Complaints</span>
                            <p className="infoPara">
                                DG e-Pharma is committed to providing a
                                high-quality service to all Users. If any User
                                has any query or complaint, the user is
                                requested to contact [insert name and/or email
                                and/or phone].{' '}
                            </p>
                        </li>

                        <p className="infoPara">
                            By clicking on the "I have read and accept the terms
                            and conditions" box at the bottom of the
                            registration form, You acknowledge and agree to be
                            bound by the Terms and Conditions listed below,
                            including any additional guidelines/conditions and
                            future modifications. You may not access or use the
                            information if You decide at any point that You do
                            not agree to these Terms and Conditions.
                        </p>
                    </ol>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TermAndCondition)
