import React from 'react'

const HomeFillIcon = () => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Home">
            <path id="Vector" d="M15.6917 2.95516C15.2183 2.55619 14.6191 2.33737 14 2.33737C13.3809 2.33737 12.7817 2.55619 12.3083 2.95516L4.43333 9.59933C4.14104 9.84568 3.90607 10.1529 3.74486 10.4995C3.58365 10.8461 3.50008 11.2237 3.5 11.606V22.4618C3.5 23.5888 4.41467 24.5035 5.54167 24.5035H9.04167C9.58315 24.5035 10.1025 24.2884 10.4853 23.9055C10.8682 23.5226 11.0833 23.0033 11.0833 22.4618V17.7917C11.0833 16.9983 11.7157 16.3543 12.5032 16.3333H15.4968C15.8769 16.3434 16.238 16.5014 16.5032 16.7738C16.7684 17.0463 16.9168 17.4115 16.9167 17.7917V22.4618C16.9167 23.5888 17.8313 24.5035 18.9583 24.5035H22.4583C22.9998 24.5035 23.5191 24.2884 23.902 23.9055C24.2849 23.5226 24.5 23.0033 24.5 22.4618V11.6048C24.4999 11.2226 24.4164 10.8449 24.2551 10.4983C24.0939 10.1517 23.859 9.84451 23.5667 9.59816L15.6917 2.95516Z" fill="#212121" />
        </g>
    </svg>
)

export default HomeFillIcon
