import React from 'react'

const FilterIcon = (props) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.7002 12.2998H20.3002" stroke="black" stroke-width="1.4" stroke-linecap="round" />
        <path d="M4.7002 18.2998H20.3002" stroke="black" stroke-width="1.4" stroke-linecap="round" />
        <path d="M4.7002 6.2998H20.3002" stroke="black" stroke-width="1.4" stroke-linecap="round" />
        <path d="M9.0002 8.3002C10.2705 8.3002 11.3002 7.27045 11.3002 6.0002C11.3002 4.72994 10.2705 3.7002 9.0002 3.7002C7.72994 3.7002 6.7002 4.72994 6.7002 6.0002C6.7002 7.27045 7.72994 8.3002 9.0002 8.3002Z" fill="white" stroke="black" stroke-width="1.4" />
        <path d="M16.0002 14.3002C17.2705 14.3002 18.3002 13.2705 18.3002 12.0002C18.3002 10.7299 17.2705 9.7002 16.0002 9.7002C14.7299 9.7002 13.7002 10.7299 13.7002 12.0002C13.7002 13.2705 14.7299 14.3002 16.0002 14.3002Z" fill="white" stroke="black" stroke-width="1.4" />
        <path d="M9.0002 20.3002C10.2705 20.3002 11.3002 19.2705 11.3002 18.0002C11.3002 16.7299 10.2705 15.7002 9.0002 15.7002C7.72994 15.7002 6.7002 16.7299 6.7002 18.0002C6.7002 19.2705 7.72994 20.3002 9.0002 20.3002Z" fill="white" stroke="black" stroke-width="1.4" />
        <g clip-path="url(#clip0_462_27565)">
            <rect x="12" width="12" height="12" rx="6" fill="#EF8658" />
            <path d="M17.8698 3.424C17.9125 3.408 17.9525 3.4 17.9898 3.4H18.7578C18.7845 3.4 18.8058 3.41067 18.8218 3.432C18.8432 3.448 18.8538 3.46933 18.8538 3.496V8.904C18.8538 8.93067 18.8432 8.95467 18.8218 8.976C18.8058 8.992 18.7845 9 18.7578 9H18.0218C17.9952 9 17.9712 8.992 17.9498 8.976C17.9338 8.95467 17.9258 8.93067 17.9258 8.904V4.36C17.9258 4.34933 17.9205 4.34133 17.9098 4.336C17.8992 4.32533 17.8885 4.32267 17.8778 4.328L17.0778 4.576C17.0672 4.58133 17.0538 4.584 17.0378 4.584C17.0165 4.584 16.9978 4.576 16.9818 4.56C16.9712 4.544 16.9658 4.52267 16.9658 4.496L16.9418 3.968C16.9418 3.91467 16.9632 3.87733 17.0058 3.856L17.8698 3.424Z" fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_462_27565">
                <rect x="12" width="12" height="12" rx="6" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export default FilterIcon
