import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    HEADER_CONFIG,
    HEADER_CONST
} from '../../common/constant/common-constant'
import { PAGE_CONST } from '../../common/constant/page-constant'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import { redirect } from '../../common/utils/redirectionUtils'
import MobileHeader from '../Common/Header/MobileHeader'

import './About.scss'
const About = props => {
    const navigate = useNavigate()
    const isMobile = useCheckMobileScreen()

    const [headerOptions, setHeaderOptions] = useState({
        [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
        [HEADER_CONFIG.TITLE]: HEADER_CONST.ABOUT,
        [HEADER_CONFIG.HANDLE_BACK_FUNC]: () => props?.onBackClick()
    })

    return (
        <>
            {isMobile && <MobileHeader headerOptions={headerOptions} />}
            <div className="pageBodyRightPanel">
                <div className="pageBodyRightPanelHeader dFlex jcSpaceBetween aiCenter">
                    <div className="pageBodyRightPanelHeading">About Us</div>
                </div>
                <div className="informationCondition">
                    <p className="infoPara">
                        DG e-pharma is the first concern of DIPON Group in the
                        Healthcare Sector. DIPON group is a leading and
                        prestigious business house originating in Bangladesh
                        came into existence in 1970 with the formation of Dipon
                        Gas Company Ltd with the specific objective of providing
                        end-to-end solutions in oil and gas pipeline
                        construction works.
                    </p>

                    <p className="infoPara">
                        Since its inception, DIPON have grown manifolds. It has
                        diversified its business activity in Engineering and
                        Construction, Project Investment and Developments,
                        Shipping and Logistics as well as IT and ITES.
                    </p>

                    <p className="infoPara">
                        DIPON has ventured outside of Bangladesh and established
                        its presence in India, Singapore, Malaysia, UAE, Saudia
                        Arabia and African countries through their regional and
                        project offices.
                    </p>

                    <p className="infoPara">
                        <em>DG e-Pharma</em> is focused on creating a seamless
                        digital medicine marketplace. By providing genuine
                        medicine with the aim to eliminate counterfeit medicine
                        from the country.{' '}
                    </p>
                    <br />

                    <address className="addressAnchor">
                        DG e-Pharma <br />
                        Address: Rangs FC Enclave (Level 11), <br />
                        Plot 6/A, road no 32, <br />
                        Gulshan Avenue, Dhaka-1212
                        <br />
                        Email:{' '}
                        <a
                            className="action"
                            href="mailto:dgpharma@example.com">
                            dgpharma@example.com
                        </a>
                        <br />
                        Website:{' '}
                        <a className="action" href="www.dgpharma.com">
                            DG e-pharma
                        </a>
                    </address>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(About)
