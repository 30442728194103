import moment from 'moment'
import { getLocalStorageCustom } from './storageUtils'
import { REACT_NATIVE_WEBVIEW_CONST } from '../constant/page-constant'

export function formatDate(date) {
    try {
        return moment.parseZone(date).format('DD MMM YYYY')
    } catch (err) {
        return date
    }
}

export function formatTime(date) {
    try {
        return moment.parseZone(date).format('hh:mm A')
    } catch (err) {
        return date
    }
}

export function convertToTitleCase(str) {
    if (!str) return ''
    return str
        .split(/[\s_]+/) // Split the string into an array of words
        .map(word => {
            if (word.length > 0) {
                return (
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
            } else {
                return ''
            }
        })
        .join(' ') // Join the words back into a single string with spaces
}

export function placeComponentToAddress(place) {
    if (!place) return

    let addressComponent = {}
    place?.address_components.forEach(function (component) {
        switch (component.types[0]) {
            case 'street_number':
                addressComponent.streetNumber =
                    component?.long_name || component?.short_name
                break
            case 'route':
                addressComponent.streetName =
                    component?.long_name || component?.short_name
                break
            case 'neighborhood':
            case 'locality': // North Hollywood or Los Angeles?
                addressComponent.city =
                    component?.long_name || component?.short_name
                break
            case 'administrative_area_level_1': //  Note some countries don't have states
                addressComponent.state =
                    component?.long_name || component?.short_name
                break
            case 'postal_code':
                addressComponent.zip =
                    component?.long_name || component?.short_name
                break
            case 'country':
                addressComponent.country =
                    component?.long_name || component?.short_name
                break
        }
    })

    console.log('addressComponent: ', addressComponent)
    return addressComponent
}

export function isReactNativeWebView() {
    return (
        getLocalStorageCustom(
            REACT_NATIVE_WEBVIEW_CONST.REACT_NATIVE_FLOW_CONST
        ) === 'true'
    )
}
