import { Types } from '../actions/types'
import initialState from '../store/initial.state'

export const appDataReducer = (state = initialState.dataReducer, action) => {
    switch (action.type) {
        case Types.SALES_DATA: {
            let data = action.payload
            return {
                ...state,
                salesData: data
            }
        }
        case Types.NEW_SALES_DATA: {
            let currentSales = [...state.salesData] || []
            let newSales = action.payload

            if (newSales) currentSales?.unshift(newSales)
            return {
                ...state,
                salesData: currentSales
            }
        }
        case Types.UPDATE_SALES_DATA: {
            let currentSales = [...state.salesData] || []
            let updatedSale = action.payload

            if (updatedSale) {
                let index = currentSales.findIndex(
                    sale => sale.id === updatedSale.id
                )
                currentSales[index] = updatedSale
            }

            return {
                ...state,
                salesData: currentSales
            }
        }
        default:
            return state
    }
}
