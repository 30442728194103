import React from 'react'

const DotsIcon = () => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="More">
            <path id="Vector" d="M14.0002 9.04134C13.732 9.04134 13.4666 8.98853 13.2189 8.88593C12.9711 8.78333 12.7461 8.63294 12.5565 8.44335C12.3669 8.25376 12.2165 8.02869 12.1139 7.78099C12.0113 7.53328 11.9585 7.26779 11.9585 6.99967C11.9585 6.73156 12.0113 6.46607 12.1139 6.21836C12.2165 5.97066 12.3669 5.74558 12.5565 5.556C12.7461 5.36641 12.9711 5.21602 13.2189 5.11342C13.4666 5.01082 13.732 4.95801 14.0002 4.95801C14.5416 4.95801 15.061 5.17311 15.4438 5.556C15.8267 5.93888 16.0418 6.45819 16.0418 6.99967C16.0418 7.54116 15.8267 8.06046 15.4438 8.44335C15.061 8.82624 14.5416 9.04134 14.0002 9.04134ZM14.0002 16.0413C13.4587 16.0413 12.9394 15.8262 12.5565 15.4434C12.1736 15.0605 11.9585 14.5412 11.9585 13.9997C11.9585 13.4582 12.1736 12.9389 12.5565 12.556C12.9394 12.1731 13.4587 11.958 14.0002 11.958C14.5416 11.958 15.061 12.1731 15.4438 12.556C15.8267 12.9389 16.0418 13.4582 16.0418 13.9997C16.0418 14.5412 15.8267 15.0605 15.4438 15.4434C15.061 15.8262 14.5416 16.0413 14.0002 16.0413ZM11.9585 20.9997C11.9585 21.5412 12.1736 22.0605 12.5565 22.4433C12.9394 22.8262 13.4587 23.0413 14.0002 23.0413C14.5416 23.0413 15.061 22.8262 15.4438 22.4433C15.8267 22.0605 16.0418 21.5412 16.0418 20.9997C16.0418 20.4582 15.8267 19.9389 15.4438 19.556C15.061 19.1731 14.5416 18.958 14.0002 18.958C13.4587 18.958 12.9394 19.1731 12.5565 19.556C12.1736 19.9389 11.9585 20.4582 11.9585 20.9997Z" fill="black" />
        </g>
    </svg>
)

export default DotsIcon
