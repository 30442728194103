import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ReactSelect from 'react-select'
import AddIcon from '../../assets/AddIcon'
import BarcodeIcon from '../../assets/BarcodeIcon'
import CloseIcon from '../../assets/CloseIcon'
import DamageLossBlack from '../../assets/DamageLossBlack'
import SortIcon from '../../assets/SortIcon'
import {
    HEADER_CONFIG,
    HEADER_CONST
} from '../../common/constant/common-constant'
import {
    DAMAGE_LOSS_FIELDS,
    PAGE_CONST,
    PERMISSION_FEATURE,
    PERMISSION_SCREEN,
    PERMISSION_TYPE,
    PRODUCT_CATEGORY,
    PRODUCT_DETAIL_FIELDS
} from '../../common/constant/page-constant'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import { formatDate } from '../../common/utils/appUtils'
import { redirect } from '../../common/utils/redirectionUtils'
import Alert from '../../components/Alert/Alert'
import BarcodeScanner from '../../components/ZXingScanner/BarcodeScanner'
import {
    addDamageLossAdjustmentHelper,
    getDamageLossAdjustmentHelper,
    getProductDetailsByBarcodeHelper
} from '../../helper/home-helper'
import {
    getProductDetailByIdHelper,
    getProductSearchSuggestionsHelper
} from '../../helper/personal-details-helper'
import { addDamageLossAlertAction } from '../../modules/actions/action'
import Card from '../Common/Card/Card'
import MobileHeader from '../Common/Header/MobileHeader'
import SideDrawer from '../Common/SideDrawer/SideDrawer'

import './DamageLoss.scss'

const DamageLoss = props => {
    const navigate = useNavigate()
    const isMobile = useCheckMobileScreen()

    const [viewDrawer, setViewDrawer] = useState(false)
    const [open, setOpen] = useState(false)
    const [isSorting, setIsSorting] = useState(false)
    const [productData, setProductData] = useState(undefined)
    const [damageLossData, setDamageLossData] = useState(undefined)
    const [managedDamageLossData, setManagedDamageLossData] =
        useState(undefined)
    const [isFieldErrorVisible, setIsFieldErrorVisible] = useState(false)
    const [serverError, setServerError] = useState(undefined)
    const [addDamageLossAlert, setAddDamageLossAlert] = useState(false)
    const [headerOptions, setHeaderOptions] = useState({
        [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
        [HEADER_CONFIG.TITLE]: HEADER_CONST.DAMAGE_LOSS_ADJUSTMENT,
        [HEADER_CONFIG.HANDLE_BACK_FUNC]: () =>
            redirect(navigate, PAGE_CONST.HOME)
    })

    const [searchTerm, setSearchTerm] = useState('')
    const [selectedSearchTerm, setSelectedSearchTerm] = useState(undefined)
    const [searchSuggestions, setSearchSuggestions] = useState([])
    const [prevSearchTermLength, setPrevSearchTermLength] = useState(0)
    const [timer, setTimer] = useState()
    const [isScannerVisible, setIsScannerVisible] = useState(false)
    const [permissions, setPermissions] = useState({})

    useEffect(() => {
        let addDamageLossAlert = props.alert?.addDamageLossAlert
        setAddDamageLossAlert(addDamageLossAlert)
    }, [props.alert?.addDamageLossAlert])

    useEffect(() => {
        getDamageLossAdjustmentData()
    }, [])

    useEffect(() => {
        if (!open) {
            handleHeaderConfigUpdate(HEADER_CONST.DAMAGE_LOSS_ADJUSTMENT, () =>
                redirect(navigate, PAGE_CONST.HOME)
            )
            return
        }

        handleHeaderConfigUpdate(HEADER_CONST.ADD_DAMAGE_LOSS_ADJUSTMENT, () =>
            handleDrawerToggle()
        )
    }, [open])

    useEffect(() => {
        handleSearchSuggestions(searchTerm)
        return () => clearTimeout(timer)
    }, [searchTerm])

    useEffect(() => {
        if (selectedSearchTerm) {
            handleSearch(selectedSearchTerm)
        }
    }, [selectedSearchTerm])

    useEffect(() => {
        if (props.loginData.permissions) {
            let permission = props.loginData.permissions.filter(
                permission =>
                    permission.screen === PERMISSION_SCREEN.MANAGE_STORE
            )[0]

            if (permission) setPermissions(permission)
        }
    }, [props.loginData])

    const handleSearchSuggestions = searchTerm => {
        console.log('searchTerm : ', searchTerm, ' !searchTerm', !searchTerm)
        if (!searchTerm || searchTerm?.length === 0) {
            clearSearchData()
            return
        }

        const fetchSearchResults = async () => {
            console.log('in fetchSearchResults')
            try {
                const results = await getProductSearchSuggestionsHelper(
                    searchTerm
                )
                // dispatchRecentSearchListAction(searchTerm)
                setSearchSuggestions(results)
            } catch (error) {
                console.error('Error fetching search results:', error)
            }
            // setIsLoading(false)
        }

        // Check if search term length changes by more than 2 characters
        if (
            searchTerm?.length > 0 &&
            Math.abs(searchTerm.length - prevSearchTermLength) > 2
        ) {
            clearTimeout(timer)
            setTimer(null)
            // isTimerSet = false
            console.log('hitting api after 2 character change')
            setPrevSearchTermLength(() => searchTerm.length)
            fetchSearchResults()
            // setApiCalled(() => apiCalled)
        } else {
            console.log('in else timer: ', timer)
            if (!timer) {
                let tr = setTimeout(() => {
                    if (searchTerm.length > 0) {
                        console.log('hitting api after 1 sec')
                        setPrevSearchTermLength(searchTerm.length)
                        fetchSearchResults()
                        // isTimerSet = false
                    }
                }, 1000)
                // setApiCalled(true);
                setTimer(tr)
            } else {
                // Clear the existing timer and set a new one
                console.log('clearing timeout and setting a new one')
                clearTimeout(timer)
                let tr = setTimeout(() => {
                    if (searchTerm.length > 0) {
                        console.log('hitting api after 1 sec')
                        setPrevSearchTermLength(searchTerm.length)
                        fetchSearchResults()
                        // isTimerSet = false
                    }
                }, 1000)
                setTimer(tr)
            }
        }
    }

    const handleSearch = async () => {
        try {
            let data = await getProductDetailByIdHelper(selectedSearchTerm)

            // setSearchResults(data)
            setProductData(data)

            setDamageLossData(damageLossData => {
                return {
                    ...damageLossData,
                    [DAMAGE_LOSS_FIELDS.BARCODE]:
                        data?.[PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE]
                }
            })
        } catch (err) {}
    }

    const clearSearchData = () => {
        setSearchSuggestions([])
        setSelectedSearchTerm(undefined)
        setPrevSearchTermLength(0)
        clearTimeout(timer)
        setTimer(() => null)
    }

    const handleHeaderConfigUpdate = (title, handleBackFunc) => {
        setHeaderOptions({
            [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
            [HEADER_CONFIG.TITLE]: title,
            [HEADER_CONFIG.HANDLE_BACK_FUNC]: () => handleBackFunc()
        })
    }

    const getDamageLossAdjustmentData = () => {
        getDamageLossAdjustmentHelper()
            .then(res => {
                console.log('mc res: ', res)
                setManagedDamageLossData(res)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleDrawerToggle = () => {
        setViewDrawer(!viewDrawer)
        setOpen(!open)
    }

    const getProductDetailsByBarcode = bCode => {
        setServerError('')

        let barCode = bCode || damageLossData?.[DAMAGE_LOSS_FIELDS.BARCODE]
        if (!barCode) {
            return
        }
        getProductDetailsByBarcodeHelper(barCode)
            .then(res => {
                setProductData(undefined)
                if (res) setProductData(res)
            })
            .catch(err => {
                setServerError(err)
                setProductData(undefined)
                setDamageLossData({
                    ...damageLossData,
                    [PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE]: barCode,
                    [PRODUCT_DETAIL_FIELDS.SELECTED_BATCH_DETAILS]: undefined,
                    product_batch_inventory_id: undefined
                })
                // console.log("")
            })
    }

    const handleDamageLossDetailUpdate = (key, value) => {
        setDamageLossData(damageLossData => {
            return {
                ...damageLossData,
                [key]: value
            }
        })
    }

    const checkValidation = () => {
        if (
            damageLossData?.[DAMAGE_LOSS_FIELDS.CHALLAN_NUMBER] &&
            damageLossData?.[DAMAGE_LOSS_FIELDS.BARCODE] &&
            damageLossData?.[DAMAGE_LOSS_FIELDS.QUANTITY] &&
            damageLossData?.[DAMAGE_LOSS_FIELDS.NOTES] &&
            damageLossData?.product_batch_inventory_id &&
            parseInt(damageLossData?.[PRODUCT_DETAIL_FIELDS.QUANTITY]) <=
                parseInt(
                    damageLossData?.[
                        PRODUCT_DETAIL_FIELDS.SELECTED_BATCH_DETAILS
                    ]?.[PRODUCT_DETAIL_FIELDS.STOCK]
                )
        ) {
            return true
        }

        return false
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            setIsFieldErrorVisible(true)
            return
        }

        setIsFieldErrorVisible(false)

        let data = {
            damageLossData,
            productData
        }

        addDamageLossAdjustmentHelper(data)
            .then(res => {
                console.log(res)
                props.addDamageLossAlertAction(true)

                setTimeout(() => {
                    props.addDamageLossAlertAction(false)
                }, 5000)
                getDamageLossAdjustmentData()
                resetDrawerData()
                handleDrawerToggle()
            })
            .catch(err => {
                setServerError(err)
            })
    }

    const resetDrawerData = () => {
        setDamageLossData(undefined)
        setProductData(undefined)
    }

    const handleScan = result => {
        if (result) {
            console.log('result in handleScan: ', result)
            // setScannedResult(result)
            handleDamageLossDetailUpdate(
                PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE,
                result
            )
            setIsScannerVisible(false) // Hide the scanner after a successful scan

            getProductDetailsByBarcode(result)
        }
    }

    return (
        <>
            {isMobile && <MobileHeader headerOptions={headerOptions} />}
            {addDamageLossAlert && (
                <Alert
                    success
                    alertType="success"
                    alertMessage="New Damage/Loss Adjustment added Successfully!"
                />
            )}
            <div className="pageBodyRightPanel DamageLoss">
                {isMobile &&
                    permissions?.features?.[PERMISSION_FEATURE.DAMAGE_LOSS] ===
                        PERMISSION_TYPE.MANAGE && (
                        <div className="addProductBtnMobile dFlex flexColumnReverse aiCenter mwebVisible fixed">
                            <button
                                className="animated zoomIn btn btnPrimary"
                                onClick={handleDrawerToggle}>
                                <AddIcon />
                            </button>
                            <button
                                className="animated zoomIn btn btnSecondary pointer mb-2"
                                onClick={() => setIsSorting(true)}>
                                <SortIcon />
                            </button>
                        </div>
                    )}
                {!isMobile && (
                    <div className="pageBodyRightPanelHeader dFlex jcSpaceBetween aiCenter">
                        <div className="pageBodyRightPanelHeading">
                            Damage/Loss Adjustment
                        </div>
                        <div className="addProductBtn dFlex aiCenter">
                            {permissions?.features?.[
                                PERMISSION_FEATURE.DAMAGE_LOSS
                            ] === PERMISSION_TYPE.MANAGE && (
                                <button
                                    className="btn btnPrimary"
                                    onClick={handleDrawerToggle}>
                                    ADD
                                </button>
                            )}
                            <div
                                className="pointer ml-4"
                                onClick={() => setIsSorting(true)}>
                                <SortIcon />
                            </div>
                            <div
                                className="pointer ml-4"
                                onClick={() => props.onClose()}>
                                <CloseIcon />
                            </div>
                        </div>
                    </div>
                )}
                <div className="productLlistWrap dFlex">
                    {managedDamageLossData &&
                        managedDamageLossData.map(damageLossData => {
                            return (
                                <div className="cardWrap">
                                    <Card
                                        product={{
                                            productName:
                                                damageLossData.product_name,
                                            dose: damageLossData.dose,
                                            quantity: damageLossData.quantity
                                        }}
                                        productDesc={damageLossData?.notes}
                                        headerSup={formatDate(
                                            damageLossData?.created_at
                                        )}
                                        headerMain={
                                            damageLossData.challan_number
                                        }
                                        cardRight={
                                            damageLossData
                                                ? `BDT ${parseFloat(
                                                      damageLossData.amount
                                                  ).toFixed(2)}`
                                                : '---'
                                        }
                                    />
                                </div>
                            )
                        })}
                </div>
                {/* side drawer for filter and sorting  */}
                <SideDrawer
                    handleDrawerToggle={() => setIsSorting(!isSorting)}
                    open={isSorting}
                    drawerButton={true}
                    drawerHeading="Sort"
                    mwebSideDrawerFooter>
                    <form className="mt-5">
                        <div className="formGroup formFloating">
                            <select className="formSelect" id="floatingSelect">
                                <option value="none" selected disabled hidden>
                                    Quantity
                                </option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                            </select>
                            <label for="floatingSelect">Quantity</label>
                            {false && (
                                <div class="invalidFeedback">
                                    Please choose Quantity.
                                </div>
                            )}
                        </div>
                        <div className="formGroup formFloating">
                            <select className="formSelect" id="floatingSelect">
                                <option value="none" selected disabled hidden>
                                    Amount
                                </option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                            </select>
                            <label for="floatingSelect">Amount</label>
                            {false && (
                                <div class="invalidFeedback">
                                    Please choose Amount.
                                </div>
                            )}
                        </div>
                    </form>
                </SideDrawer>
                <SideDrawer
                    handleDrawerToggle={handleDrawerToggle}
                    open={open}
                    drawerButton={true}
                    drawerHeading="Add Damage/Loss"
                    handleClick={() => handleSubmit()}>
                    <ul class="WizardStepsWrap">
                        {/* <li className={`wizardStep`}>
                            <div class="circle">1</div>
                        </li>
                        <li className={`wizardStep`}>
                            <div class="circle">2</div>
                        </li>
                        <li className={`wizardStep`}>
                            <div class="circle">3</div>
                        </li> */}
                    </ul>
                    <div className="wizardFieldset">
                        {serverError && (
                            <div class="invalidFeedback mb-4">
                                {serverError}
                            </div>
                        )}
                        <div className="consolidateStrip dFlex jcSpaceBetween aiCenter mb-5">
                            <div className="consolidatedDataIcon dFlex">
                                <DamageLossBlack />
                            </div>
                            <div className="consolidateDataText">
                                Damage Loss Amount <small>BDT</small>
                            </div>
                            <div className="consolidateData">
                                {productData &&
                                productData.cost_per_unit &&
                                damageLossData?.[DAMAGE_LOSS_FIELDS.QUANTITY]
                                    ? productData?.[
                                          PRODUCT_DETAIL_FIELDS.PRODUCT_NEW_COST
                                      ] *
                                      damageLossData?.[
                                          DAMAGE_LOSS_FIELDS.QUANTITY
                                      ]
                                    : '---'}
                            </div>
                        </div>
                        <form>
                            <div className="formGroup formFloating fgrow1">
                                <input
                                    type="text"
                                    className="formControl"
                                    id="invoice"
                                    placeholder="Challan Number"
                                    value={
                                        damageLossData?.[
                                            DAMAGE_LOSS_FIELDS.CHALLAN_NUMBER
                                        ]
                                    }
                                    onChange={e =>
                                        handleDamageLossDetailUpdate(
                                            DAMAGE_LOSS_FIELDS.CHALLAN_NUMBER,
                                            e.target.value
                                        )
                                    }
                                    required
                                />
                                <label for="invoice">Challan Number</label>
                                {isFieldErrorVisible &&
                                    !damageLossData?.[
                                        DAMAGE_LOSS_FIELDS.CHALLAN_NUMBER
                                    ] && (
                                        <div class="invalidFeedback">
                                            Please enter a Challan Number.
                                        </div>
                                    )}
                            </div>
                            <div className="formSection">
                                <div className="formGroup formFloating fgrow1">
                                    <input
                                        type="text"
                                        className="formControl"
                                        id="invoice"
                                        placeholder="Product Barcode/QR Code"
                                        value={
                                            damageLossData?.[
                                                DAMAGE_LOSS_FIELDS.BARCODE
                                            ] || ''
                                        }
                                        onChange={e =>
                                            handleDamageLossDetailUpdate(
                                                DAMAGE_LOSS_FIELDS.BARCODE,
                                                e.target.value
                                            )
                                        }
                                        onBlur={e =>
                                            getProductDetailsByBarcode()
                                        }
                                        required
                                    />
                                    <label for="invoice">
                                        Product Barcode/QR Code
                                    </label>
                                    {isFieldErrorVisible &&
                                        (!damageLossData?.[
                                            DAMAGE_LOSS_FIELDS.BARCODE
                                        ] ||
                                            !productData?.product_id) && (
                                            <div class="invalidFeedback">
                                                Please enter a valid Product
                                                Barcode/QR Code.
                                            </div>
                                        )}
                                    <div
                                        className="positionedIcon"
                                        onClick={() =>
                                            setIsScannerVisible(
                                                !isScannerVisible
                                            )
                                        }>
                                        <BarcodeIcon />
                                    </div>
                                    {isScannerVisible && (
                                        <BarcodeScanner onScan={handleScan} />
                                    )}
                                </div>
                            </div>
                            <div className="formGroup formFloating">
                                <ReactSelect
                                    className="formSelect reactSelect"
                                    id="floatingSelect"
                                    value={{
                                        label:
                                            productData?.[
                                                PRODUCT_DETAIL_FIELDS
                                                    .PRODUCT_NAME
                                            ] || ''
                                    }}
                                    // readonly={props?.isBarcodeDisabled}
                                    onInputChange={
                                        option => {
                                            if (option) setSearchTerm(option)
                                        }
                                        // handleBillDetailUpdate(
                                        //     BILL_DETAILS_FIELDS.PHARMA_COMPANY,
                                        //     option
                                        // )
                                    }
                                    onChange={option => {
                                        setSelectedSearchTerm(option?.id)
                                    }}
                                    options={searchSuggestions}
                                    getOptionLabel={option => option.label}
                                    placeholder="Product Name"
                                    styles={{
                                        control: base => ({
                                            border: 0,
                                            display: 'flex',
                                            paddingTop: '20px'
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused
                                                ? 'rgb(222, 235, 255)'
                                                : state.isSelected
                                                ? '#FFF'
                                                : 'inherit',
                                            color: '#212529',
                                            '&:hover': {
                                                backgroundColor:
                                                    state.isSelected
                                                        ? '#DEEBFF'
                                                        : 'rgb(222, 235, 255)'
                                            }
                                        })
                                    }}
                                    // isClearable
                                />
                                <label for="floatingSelect">Product Name</label>
                                {isFieldErrorVisible &&
                                    !productData?.[
                                        PRODUCT_DETAIL_FIELDS.PRODUCT_NAME
                                    ] && (
                                        <div className="invalidFeedback">
                                            Product Name.
                                        </div>
                                    )}
                            </div>
                            <div className="formGroup formFloating">
                                <ReactSelect
                                    className="formSelect reactSelect"
                                    id="floatingSelect"
                                    value={
                                        damageLossData?.[
                                            PRODUCT_DETAIL_FIELDS
                                                .SELECTED_BATCH_DETAILS
                                        ] || ''
                                    }
                                    onChange={option => {
                                        handleDamageLossDetailUpdate(
                                            'product_batch_inventory_id',
                                            option?.product_batch_inventory_id
                                        )
                                        handleDamageLossDetailUpdate(
                                            PRODUCT_DETAIL_FIELDS.SELECTED_BATCH_DETAILS,
                                            option
                                        )
                                    }}
                                    options={productData?.inventory || []}
                                    getOptionLabel={option =>
                                        `Batch: ${
                                            option?.[
                                                PRODUCT_DETAIL_FIELDS
                                                    .BATCH_NUMBER
                                            ]
                                        }, 
                                        Expiry: ${formatDate(
                                            option?.[
                                                PRODUCT_DETAIL_FIELDS
                                                    .EXPIRY_DATE
                                            ]
                                        )}
                                        `
                                    }
                                    placeholder="Select Product Batch"
                                    styles={{
                                        control: base => ({
                                            border: 0,
                                            display: 'flex',
                                            paddingTop: '20px'
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused
                                                ? 'rgb(222, 235, 255)'
                                                : state.isSelected
                                                ? '#FFF'
                                                : 'inherit',
                                            color: '#212529',
                                            '&:hover': {
                                                backgroundColor:
                                                    state.isSelected
                                                        ? '#DEEBFF'
                                                        : 'rgb(222, 235, 255)'
                                            }
                                        })
                                    }}
                                />
                                <label for="floatingSelect">
                                    Select Product Batch
                                </label>
                                {isFieldErrorVisible &&
                                    !damageLossData?.[
                                        PRODUCT_DETAIL_FIELDS
                                            .SELECTED_BATCH_DETAILS
                                    ]?.product_batch_inventory_id && (
                                        <div className="invalidFeedback">
                                            Please Select Product Batch.
                                        </div>
                                    )}
                                {damageLossData?.[
                                    PRODUCT_DETAIL_FIELDS.SELECTED_BATCH_DETAILS
                                ] && (
                                    <div className="inputSuggestionsWrap">
                                        <ul className="inputSuggestion dFlex">
                                            <li className="suggestion">
                                                Selected Batch Stock:{' '}
                                                {
                                                    damageLossData?.[
                                                        PRODUCT_DETAIL_FIELDS
                                                            .SELECTED_BATCH_DETAILS
                                                    ]?.[
                                                        PRODUCT_DETAIL_FIELDS
                                                            .STOCK
                                                    ]
                                                }
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                            {/* <div className="formGroup formFloating">
                                <select
                                    className="formSelect"
                                    id="floatingSelect"
                                    disabled={true}>
                                    <option
                                        value="none"
                                        selected
                                        disabled
                                        hidden>
                                        {
                                            productData?.[
                                                PRODUCT_DETAIL_FIELDS
                                                    .PRODUCT_NAME
                                            ]
                                        }
                                    </option>
                                    {/* <option value="1">Product Name 1</option>
                                    <option value="2">Product Name 2</option> */}
                            {/*} </select>
                                <label for="floatingSelect">Product Name</label>
                                {false && (
                                    <div class="invalidFeedback">
                                        Please choose Product Name.
                                    </div>
                                )}
                            </div> */}
                            <div className="formInline dFlex flexNowrap">
                                <div className="formGroup formFloating fgrow1">
                                    <input
                                        type="text"
                                        className="formControl"
                                        id="quantity"
                                        placeholder="Quantity"
                                        value={
                                            damageLossData?.[
                                                DAMAGE_LOSS_FIELDS.QUANTITY
                                            ]
                                        }
                                        onChange={e =>
                                            handleDamageLossDetailUpdate(
                                                DAMAGE_LOSS_FIELDS.QUANTITY,
                                                e.target.value
                                            )
                                        }
                                        required
                                    />
                                    <label for="Quantity">Quantity</label>
                                    {isFieldErrorVisible &&
                                        (!damageLossData?.[
                                            DAMAGE_LOSS_FIELDS.QUANTITY
                                        ] ||
                                            parseInt(
                                                damageLossData?.[
                                                    PRODUCT_DETAIL_FIELDS
                                                        .QUANTITY
                                                ]
                                            ) >
                                                parseInt(
                                                    damageLossData?.[
                                                        PRODUCT_DETAIL_FIELDS
                                                            .SELECTED_BATCH_DETAILS
                                                    ]?.[
                                                        PRODUCT_DETAIL_FIELDS
                                                            .STOCK
                                                    ]
                                                )) && (
                                            <div class="invalidFeedback">
                                                Please select a valid Quantity.
                                            </div>
                                        )}
                                </div>
                                <div className="formGroup formFloating ml-4 fgrow1">
                                    <input
                                        type="text"
                                        className="formControl"
                                        id="dose"
                                        placeholder="Dose"
                                        value={
                                            productData
                                                ? productData?.[
                                                      PRODUCT_DETAIL_FIELDS.DOSE
                                                  ]
                                                : ''
                                        }
                                        disabled={true}
                                        required
                                    />
                                    <label for="Dose">Dose</label>
                                    {false && (
                                        <div class="invalidFeedback">
                                            Please select a valid Dose.
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="formSection dFlex mb-5">
                                <div class="formCheck">
                                    <input
                                        class="formCheckInput"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="Pharmaceutical"
                                        disabled={true}
                                        checked={
                                            productData?.[
                                                PRODUCT_DETAIL_FIELDS
                                                    .PRODUCT_CATEGORY
                                            ] ===
                                            PRODUCT_CATEGORY.PHARMACEUTICAL
                                        }
                                    />
                                    <label
                                        class="formCheckLabel"
                                        for="Pharmaceutical">
                                        Pharmaceutical
                                    </label>
                                </div>
                                <div class="formCheck ml-5">
                                    <input
                                        class="formCheckInput"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="nPharmaceutical"
                                        disabled={true}
                                        checked={
                                            productData?.[
                                                PRODUCT_DETAIL_FIELDS
                                                    .PRODUCT_CATEGORY
                                            ] ===
                                            PRODUCT_CATEGORY.NON_PHARMACEUTICAL
                                        }
                                    />
                                    <label
                                        class="formCheckLabel"
                                        for="nPharmaceutical">
                                        Non-Pharmaceutical
                                    </label>
                                </div>
                                {false && (
                                    <div class="invalidFeedback">
                                        Please select Product Category.
                                    </div>
                                )}
                            </div>
                            <div className="formGroup formFloating formInputText">
                                <div className="preInputGroup backColor">
                                    <div className="preInputText">BDT</div>
                                </div>
                                <input
                                    type="text"
                                    className="formControl"
                                    id="cost_unit"
                                    placeholder="Cost/Unit"
                                    value={
                                        damageLossData?.[
                                            PRODUCT_DETAIL_FIELDS
                                                .SELECTED_BATCH_DETAILS
                                        ]?.[
                                            PRODUCT_DETAIL_FIELDS.PRODUCT_COST
                                        ] || ''
                                    }
                                    disabled={true}
                                    required
                                />
                                <label for="address">Cost/Unit</label>
                                {false && (
                                    <div className="invalidFeedback">
                                        Please enter BDT.
                                    </div>
                                )}
                            </div>
                        </form>
                    </div>
                    <div className="wizardFieldset">
                        <form>
                            <div className="formGroup formFloating">
                                <textarea
                                    rows="2"
                                    type="text"
                                    className="formControl"
                                    id="notes"
                                    value={
                                        damageLossData?.[
                                            DAMAGE_LOSS_FIELDS.NOTES
                                        ]
                                    }
                                    onChange={e =>
                                        handleDamageLossDetailUpdate(
                                            DAMAGE_LOSS_FIELDS.NOTES,
                                            e.target.value
                                        )
                                    }
                                    required></textarea>
                                <label for="notes">Notes</label>
                                {isFieldErrorVisible &&
                                    !damageLossData?.[
                                        DAMAGE_LOSS_FIELDS.NOTES
                                    ] && (
                                        <div class="invalidFeedback">
                                            Please enter notes.
                                        </div>
                                    )}
                            </div>
                        </form>
                    </div>
                </SideDrawer>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        alert: state.alertReducer.alert,
        loginData: state.loginDataReducer?.loginData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addDamageLossAlertAction: payload =>
            dispatch(addDamageLossAlertAction(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DamageLoss)
