import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import ArrowDown from '../../assets/ArrowDown'
import GraphUp from '../../assets/GraphUp'
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts'

import Header from '../Common/Header/Header'
import SideMenu from '../Common/SideMenu/SideMenu'

import './Stocks.scss'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import MobileHeader from '../Common/Header/MobileHeader'
import {
    HEADER_CONFIG,
    HEADER_CONST,
    SIDEBAR_CONST
} from '../../common/constant/common-constant'
import { PAGE_CONST } from '../../common/constant/page-constant'
import { getStocksAndBarchartDataHelper } from '../../helper/home-helper'

const Stocks = props => {
    const isMobile = useCheckMobileScreen()
    const [headerOptions] = useState({
        [HEADER_CONFIG.IS_BACK_VISIBLE]: false,
        [HEADER_CONFIG.TITLE]: HEADER_CONST.STOCKS
    })
    const [stocksData, setStocksData] = useState({})
    const [loginData, setLoginData] = useState({})
    const [activeTab, setActiveTab] = useState('1W')
    const [chartData, setChartData] = useState([])

    const data = [
        {
            name: 'MON',
            purchase: 400,
            sales: 240,
            profit: 220,
            amt: 2400
        },
        {
            name: 'TUE',
            purchase: 300,
            sales: 139,
            profit: 220,
            amt: 221
        },
        {
            name: 'WED',
            purchase: 200,
            sales: 980,
            profit: 320,
            amt: 229
        },
        {
            name: 'THU',
            purchase: 278,
            sales: 390,
            profit: 244,
            amt: 200
        },
        {
            name: 'FRI',
            purchase: 189,
            sales: 480,
            profit: 780,
            amt: 418
        },
        {
            name: 'SAT',
            purchase: 239,
            sales: 380,
            profit: 120,
            amt: 250
        },
        {
            name: 'SUN',
            purchase: 349,
            sales: 1300,
            profit: 820,
            amt: 210
        }
    ]

    useEffect(() => {
        getStocksAndBarChartData()
    }, [])

    useEffect(() => {
        switch (activeTab) {
            case '1W':
                setChartData(stocksData?.bar_chart?.week)
                break
            case '1M':
                setChartData(stocksData?.bar_chart?.month)
                break
            default:
                setChartData([])
        }
    }, [activeTab, stocksData])

    useEffect(() => {
        setLoginData(props.loginData)
        getStocksAndBarChartData()
    }, [props.loginData])

    const getStocksAndBarChartData = async () => {
        try {
            let data = await getStocksAndBarchartDataHelper()
            setStocksData(data)
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            {isMobile && (
                <>
                    <MobileHeader headerOptions={headerOptions} />
                    <div className="containerHome">
                        {isMobile && (
                            <SideMenu
                                activeItem={SIDEBAR_CONST.STOCKS}
                                component={PAGE_CONST.STOCKS}
                            />
                        )}
                        <div className="pageBody dFlex">
                            <div className="homeLeftWrapper">
                                <div className="centerDetailsWrap">
                                    <div className="centerOwner">
                                        {loginData?.name}
                                    </div>
                                    <div className="centerName dFlex aiCenter jcSpaceBetween">
                                        {loginData?.branch_name} <ArrowDown />
                                    </div>
                                </div>
                                <div className="stock-bar">
                                    <div className="consolidateStrip dFlex aiCenter mt-5">
                                        <div className="consolidatedDataIcon">
                                            <GraphUp />
                                        </div>
                                        <div className="consolidateDataText">
                                            Total Stock Value <small>BDT</small>
                                        </div>
                                        <div className="consolidateData">
                                            {stocksData?.stock_data}
                                        </div>
                                    </div>
                                </div>
                                <div className="graphFilterTabs">
                                    <ul className="graphTabs dFlex jcSpaceBetween">
                                        <li
                                            className={`tabItem ${
                                                activeTab === '1W'
                                                    ? 'active'
                                                    : ''
                                            }`}
                                            onClick={() => setActiveTab('1W')}>
                                            1W
                                        </li>
                                        <li
                                            className={`tabItem ${
                                                activeTab === '1M'
                                                    ? 'active'
                                                    : ''
                                            }`}
                                            onClick={() => setActiveTab('1M')}>
                                            1M
                                        </li>
                                        <li
                                            className={`tabItem ${
                                                activeTab === '3M'
                                                    ? 'active'
                                                    : ''
                                            }`}
                                            onClick={() => setActiveTab('3M')}>
                                            3M
                                        </li>
                                        <li
                                            className={`tabItem ${
                                                activeTab === '6M'
                                                    ? 'active'
                                                    : ''
                                            }`}
                                            onClick={() => setActiveTab('6M')}>
                                            6M
                                        </li>
                                        <li
                                            className={`tabItem ${
                                                activeTab === '1Y'
                                                    ? 'active'
                                                    : ''
                                            }`}
                                            onClick={() => setActiveTab('1Y')}>
                                            1Y
                                        </li>
                                    </ul>
                                </div>
                                <ResponsiveContainer
                                    width="100%"
                                    height={400}
                                    minWidth={300}>
                                    <BarChart
                                        width={550}
                                        height={450}
                                        data={chartData}
                                        scale={'linear'}
                                        margin={{
                                            top: 20,
                                            right: 30,
                                            left: 20,
                                            bottom: 5
                                        }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis
                                            yAxisId="left"
                                            orientation="left"
                                            stroke="#6E6E6E"
                                        />
                                        <YAxis
                                            yAxisId="right"
                                            orientation="right"
                                            stroke="#6E6E6E"
                                        />
                                        <Tooltip />
                                        <Legend />
                                        <Bar
                                            yAxisId="left"
                                            dataKey="purchase"
                                            fill="#3A3A3A"
                                        />
                                        <Bar
                                            yAxisId="left"
                                            dataKey="sales"
                                            fill="#bababa"
                                        />
                                        <Bar
                                            yAxisId="left"
                                            dataKey="profit"
                                            fill="#EF8658"
                                        />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {!isMobile && (
                <>
                    <div className="consolidateStrip dFlex aiCenter mt-5">
                        <div className="consolidatedDataIcon">
                            <GraphUp />
                        </div>
                        <div className="consolidateDataText">
                            Total Stock Value <small>BDT</small>
                        </div>
                        <div className="consolidateData">
                            {stocksData?.stock_data}
                        </div>
                    </div>
                    <div className="graphFilterTabs">
                        <ul className="graphTabs dFlex jcSpaceBetween">
                            <li
                                className={`tabItem ${
                                    activeTab === '1W' ? 'active' : ''
                                }`}
                                onClick={() => setActiveTab('1W')}>
                                1W
                            </li>
                            <li
                                className={`tabItem ${
                                    activeTab === '1M' ? 'active' : ''
                                }`}
                                onClick={() => setActiveTab('1M')}>
                                1M
                            </li>
                            <li
                                className={`tabItem ${
                                    activeTab === '3M' ? 'active' : ''
                                }`}
                                onClick={() => setActiveTab('3M')}>
                                3M
                            </li>
                            <li
                                className={`tabItem ${
                                    activeTab === '6M' ? 'active' : ''
                                }`}
                                onClick={() => setActiveTab('6M')}>
                                6M
                            </li>
                            <li
                                className={`tabItem ${
                                    activeTab === '1Y' ? 'active' : ''
                                }`}
                                onClick={() => setActiveTab('1Y')}>
                                1Y
                            </li>
                        </ul>
                    </div>
                    <ResponsiveContainer
                        width="100%"
                        height={450}
                        minWidth={300}>
                        <BarChart
                            width={550}
                            height={450}
                            data={chartData}
                            scale={'log'}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5
                            }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="day" />
                            <YAxis
                                yAxisId="left"
                                orientation="left"
                                stroke="#6E6E6E"
                            />
                            <YAxis
                                yAxisId="right"
                                orientation="right"
                                stroke="#6E6E6E"
                            />
                            <Tooltip />
                            <Legend />
                            <Bar
                                yAxisId="left"
                                dataKey="purchase"
                                fill="#3A3A3A"
                                scaleToFit={true}
                            />
                            <Bar
                                yAxisId="left"
                                dataKey="sales"
                                fill="#bababa"
                            />
                            <Bar
                                yAxisId="left"
                                dataKey="profit"
                                fill="#EF8658"
                                scaleToFit={true}
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </>
            )}
        </>
    )
}

const mapStateToProps = state => {
    return {
        loginData: state.loginDataReducer?.loginData
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Stocks)
