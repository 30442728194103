
import CreateAccount from "../../pages/CreateAccount/CreateAccount";
import DocumentUpload from "../../pages/CreateAccount/DocumentUpload";
import OtpVerification from "../../pages/CreateAccount/OtpVerification";
import ForgotPassword from "../../pages/ForgotPassword/ForgotPassword";
import Help from "../../pages/Help/Help";
import Home from "../../pages/Home/Home";
import HomeDetails from "../../pages/HomeDetails/HomeDetails";
import Login from "../../pages/Login/Login";
import Notification from "../../pages/Notification/Notification";
import ProductReceiving from "../../pages/ProductReceiving/ProductReceiving";
import ProfileMenu from "../../pages/ProfileMenu/ProfileMenu";
import Settings from "../../pages/Settings/Settings";
import Stocks from "../../pages/Stocks/Stocks";
import UserProfile from "../../pages/UserProfile/UserProfile";

export const routes = [{
    key: "login",
    path: '/login',
    exact: true,
    authNeeded: false,
    element: <Login />
},
{
    key: "create-account",
    path: '/create-account',
    exact: true,
    authNeeded: false,
    element: <CreateAccount />
},
{
    key: "reset-password",
    path: '/reset-password',
    exact: true,
    authNeeded: false,
    element: <ForgotPassword />
},
{
    key: "otp-verification",
    path: '/otp-verification',
    exact: true,
    authNeeded: false,
    element: <OtpVerification />
},
{
    key: "document-upload",
    path: '/document-upload',
    exact: true,
    authNeeded: false,
    element: <DocumentUpload />
},
{
    key: "home",
    path: '/home',
    exact: true,
    authNeeded: false,
    element: <Home />
},
{
    key: "stocks",
    path: '/stocks',
    exact: true,
    authNeeded: false,
    element: <Stocks />
},
{
    key: "home-details",
    path: '/home/details',
    exact: true,
    authNeeded: false,
    element: <HomeDetails />
},
{
    key: "product-receiving",
    path: '/home/productReceiving',
    exact: true,
    authNeeded: false,
    element: <ProductReceiving />
},
{
    key: "profile",
    path: '/profile',
    exact: true,
    authNeeded: false,
    element: <UserProfile />
},
{
    key: "settings",
    path: '/settings',
    exact: true,
    authNeeded: false,
    element: <Settings />
},
{
    key: "help",
    path: '/help',
    exact: true,
    authNeeded: false,
    element: <Help />
},
{
    key: "notification",
    path: '/notification',
    exact: true,
    authNeeded: false,
    element: <Notification />
},
{
    key: "profile-menu",
    path: '/profilemenu',
    exact: true,
    authNeeded: false,
    element: <ProfileMenu />
}
]
