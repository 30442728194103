import React from 'react'

const EditIconActive = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18.9519 1.04794C18.2809 0.376956 17.3708 0 16.4219 0C15.4729 0 14.5629 0.376956 13.8919 1.04794L1.93987 12.9999C1.53366 13.4062 1.24808 13.9171 1.11487 14.4759L0.0198673 19.0779C-0.00978109 19.2026 -0.00700134 19.3328 0.0279416 19.456C0.0628845 19.5793 0.128825 19.6916 0.219475 19.7821C0.310125 19.8727 0.422462 19.9385 0.545771 19.9733C0.66908 20.0081 0.79925 20.0107 0.923867 19.9809L5.52487 18.8849C6.08405 18.7519 6.59536 18.4663 7.00187 18.0599L18.9519 6.10994C19.6229 5.43894 19.9998 4.52887 19.9998 3.57994C19.9998 2.63101 19.6229 1.72095 18.9519 1.04994V1.04794ZM14.9519 2.10794C15.1449 1.9149 15.3741 1.76177 15.6263 1.6573C15.8785 1.55282 16.1489 1.49905 16.4219 1.49905C16.6949 1.49905 16.9652 1.55282 17.2174 1.6573C17.4696 1.76177 17.6988 1.9149 17.8919 2.10794C18.0849 2.30099 18.238 2.53016 18.3425 2.78239C18.447 3.03461 18.5008 3.30494 18.5008 3.57794C18.5008 3.85095 18.447 4.12128 18.3425 4.3735C18.238 4.62572 18.0849 4.8549 17.8919 5.04794L16.9999 5.93894L14.0599 2.99994L14.9519 2.10894V2.10794ZM12.9999 4.06194L15.9399 6.99994L5.93987 16.9999C5.72987 17.2099 5.46587 17.3569 5.17687 17.4259L1.76087 18.2399L2.57387 14.8239C2.64287 14.5339 2.79087 14.2699 3.00087 14.0599L12.9999 4.05994V4.06194Z"
            fill="#EF8658"
        />
        <circle cx="18" cy="18" r="2" fill="#EF8658" />
    </svg>
)

export default EditIconActive
