import React from 'react'

const StockIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Stock">
            <path id="Vector" d="M4.5 3.75C4.5 3.55109 4.42098 3.36032 4.28033 3.21967C4.13968 3.07902 3.94891 3 3.75 3C3.55109 3 3.36032 3.07902 3.21967 3.21967C3.07902 3.36032 3 3.55109 3 3.75V17.75C3 18.612 3.34241 19.4386 3.9519 20.0481C4.5614 20.6576 5.38805 21 6.25 21H20.25C20.4489 21 20.6397 20.921 20.7803 20.7803C20.921 20.6397 21 20.4489 21 20.25C21 20.0511 20.921 19.8603 20.7803 19.7197C20.6397 19.579 20.4489 19.5 20.25 19.5H6.25C5.78587 19.5 5.34075 19.3156 5.01256 18.9874C4.68437 18.6592 4.5 18.2141 4.5 17.75V3.75ZM14.75 6C14.5511 6 14.3603 6.07902 14.2197 6.21967C14.079 6.36032 14 6.55109 14 6.75C14 6.94891 14.079 7.13968 14.2197 7.28033C14.3603 7.42098 14.5511 7.5 14.75 7.5H17.94L13.25 12.19L11.28 10.22C11.1394 10.0795 10.9488 10.0007 10.75 10.0007C10.5512 10.0007 10.3606 10.0795 10.22 10.22L6.47 13.97C6.39631 14.0387 6.33721 14.1215 6.29622 14.2135C6.25523 14.3055 6.23319 14.4048 6.23141 14.5055C6.22963 14.6062 6.24816 14.7062 6.28588 14.7996C6.3236 14.893 6.37974 14.9778 6.45096 15.049C6.52218 15.1203 6.60701 15.1764 6.7004 15.2141C6.79379 15.2518 6.89382 15.2704 6.99452 15.2686C7.09523 15.2668 7.19454 15.2448 7.28654 15.2038C7.37854 15.1628 7.46134 15.1037 7.53 15.03L10.75 11.81L12.72 13.78C12.8606 13.9205 13.0512 13.9993 13.25 13.9993C13.4488 13.9993 13.6394 13.9205 13.78 13.78L19.002 8.56V11.755C19.002 11.9539 19.081 12.1447 19.2217 12.2853C19.3623 12.426 19.5531 12.505 19.752 12.505C19.9509 12.505 20.1417 12.426 20.2823 12.2853C20.423 12.1447 20.502 11.9539 20.502 11.755V6.75C20.502 6.55109 20.423 6.36032 20.2823 6.21967C20.1417 6.07902 19.9509 6 19.752 6H14.75Z" fill="black" />
        </g>
    </svg>
)

export default StockIcon
