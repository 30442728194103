import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { redirect } from '../../common/utils/redirectionUtils'
import {
    HEADER_CONFIG,
    HEADER_CONST
} from '../../common/constant/common-constant'
import {
    PAGE_CONST,
    PERMISSION_FEATURE,
    PERMISSION_SCREEN,
    PERMISSION_TYPE
} from '../../common/constant/page-constant'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import Header from '../Common/Header/Header'
import MobileHeader from '../Common/Header/MobileHeader'
import SideMenu from '../Common/SideMenu/SideMenu'

import './Settings.scss'
import SideDrawer from '../Common/SideDrawer/SideDrawer'
import AddIcon from '../../assets/AddIcon'
import { addReminderAlertAction } from '../../modules/actions/action'
import Alert from '../../components/Alert/Alert'

const Settings = props => {
    const navigate = useNavigate()
    const isMobile = useCheckMobileScreen()
    const [viewDrawer, setViewDrawer] = useState(false)
    const [open, setOpen] = useState(false)
    const [headerOptions, setHeaderOptions] = useState({
        [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
        [HEADER_CONFIG.TITLE]: HEADER_CONST.SETTINGS,
        [HEADER_CONFIG.HANDLE_BACK_FUNC]: () =>
            redirect(navigate, PAGE_CONST.HOME)
    })
    const [addReminderAlert, setAddReminderAlert] = useState(false)
    const [reminderList, setReminderList] = useState([])
    const [title, setTitle] = useState('')
    const [date, setDate] = useState('')
    const [repeat, setRepeat] = useState('')
    const [priority, setPriority] = useState('')
    const [permissions, setPermissions] = useState({})

    useEffect(() => {
        let addReminderAlert = props.alert?.addQueryAlert
        setAddReminderAlert(addReminderAlert)
    }, [props.alert?.addReminderAlert])

    useEffect(() => {
        if (props.loginData.permissions) {
            console.log(
                'props.loginData.permissions: ',
                props.loginData.permissions
            )

            let permission = props.loginData.permissions.filter(
                permission => permission.screen === PERMISSION_SCREEN.SETTINGS
            )[0]

            console.log('home screen permission: ', permission)
            if (permission) setPermissions(permission)
        }
    }, [props.loginData])

    const handleDrawerToggle = () => {
        setViewDrawer(!viewDrawer)
        setOpen(!open)
    }
    return (
        <>
            {isMobile && <MobileHeader headerOptions={headerOptions} />}
            {!isMobile && <Header />}
            {addReminderAlert && (
                <Alert
                    success
                    alertType="success"
                    alertMessage="Reminder added Successfully!"
                />
            )}
            <SideMenu
                activeItem={PAGE_CONST.SETTINGS}
                component={PAGE_CONST.SETTINGS}
            />
            <div className="pageBody">
                {isMobile && (
                    <div className="addProductBtnMobile dFlex aiCenter mwebVisible fixed">
                        <button
                            className="animated zoomIn btn btnPrimary"
                            onClick={handleDrawerToggle}>
                            <AddIcon />
                        </button>
                    </div>
                )}
                <div className="pageBodyRightPanelHeader dFlex jcSpaceBetween aiCenter webVisible mb-5">
                    <div className="pageBodyRightPanelHeading">
                        Reminder Settings
                    </div>
                </div>
                <div className="pageBodyPanelWrap dFlex">
                    <div className="pageBodyLeftPanel">
                        {reminderList?.map(reminder => {
                            return (
                                <div className="card helpCard">
                                    <div className="cardHeader">
                                        <small>high</small>
                                        <div className="helpIssue">
                                            Due Platform Charges
                                        </div>
                                    </div>
                                    <div className="cardFooter">
                                        <ul className="helpDuration dFlex">
                                            <li className="duration">
                                                12/10/2023
                                            </li>
                                            <li className="duration">
                                                Monthly
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )
                        })}
                        <div className="card helpCard">
                            <div className="cardHeader">
                                <small>high</small>
                                <div className="helpIssue">
                                    Due Platform Charges
                                </div>
                            </div>
                            <div className="cardFooter">
                                <ul className="helpDuration dFlex">
                                    <li className="duration">12/10/2023</li>
                                    <li className="duration">Monthly</li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="card helpCard">
                            <div className="cardHeader">
                                <small>high</small>
                                <div className="helpIssue">
                                    Expired Products
                                </div>
                            </div>
                            <div className="cardFooter">
                                <ul className="helpDuration dFlex">
                                    <li className="duration">12/10/2023</li>
                                    <li className="duration">Monthly</li>
                                </ul>
                            </div>
                        </div>
                        <div className="card helpCard active">
                            <div className="cardHeader">
                                <small>high</small>
                                <div className="helpIssue">
                                    Due Platform Charges
                                </div>
                            </div>
                            <div className="cardFooter">
                                <ul className="helpDuration dFlex">
                                    <li className="duration">12/10/2023</li>
                                    <li className="duration">Monthly</li>
                                </ul>
                            </div>
                        </div>
                        <div className="card helpCard">
                            <div className="cardHeader">
                                <small>high</small>
                                <div className="helpIssue">
                                    Expired Products
                                </div>
                            </div>
                            <div className="cardFooter">
                                <ul className="helpDuration dFlex">
                                    <li className="duration">12/10/2023</li>
                                    <li className="duration">Monthly</li>
                                </ul>
                            </div>
                        </div>
                        <div className="card helpCard">
                            <div className="cardHeader">
                                <small>high</small>
                                <div className="helpIssue">
                                    Due Platform Charges
                                </div>
                            </div>
                            <div className="cardFooter">
                                <ul className="helpDuration dFlex">
                                    <li className="duration">12/10/2023</li>
                                    <li className="duration">Monthly</li>
                                </ul>
                            </div>
                        </div>
                        <div className="card helpCard">
                            <div className="cardHeader">
                                <small>high</small>
                                <div className="helpIssue">
                                    Expired Products
                                </div>
                            </div>
                            <div className="cardFooter">
                                <ul className="helpDuration dFlex">
                                    <li className="duration">12/10/2023</li>
                                    <li className="duration">Monthly</li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                    {!isMobile &&
                        permissions?.features?.[
                            PERMISSION_FEATURE.REMINDER_SETTINGS
                        ] === PERMISSION_TYPE.MANAGE && (
                            <div className="pageBodyRightPanel">
                                <form>
                                    <div className="panelHeading">
                                        Add Reminder
                                    </div>
                                    <div className="formGroup formFloating fgrow1">
                                        <input
                                            type="text"
                                            className="formControl"
                                            id="invoice"
                                            placeholder="Reminder Title"
                                            required
                                        />
                                        <label for="invoice">
                                            Reminder Title
                                        </label>
                                        {false && (
                                            <div class="invalidFeedback">
                                                Please select a valid Reminder
                                                Title.
                                            </div>
                                        )}
                                    </div>
                                    <div className="panelHeading">Details</div>
                                    <div className="formGroup formFloating fgrow1">
                                        <input
                                            type="date"
                                            className="formControl"
                                            id="invoice"
                                            placeholder="Date"
                                            required
                                        />
                                        <label for="invoice">Date</label>
                                        {false && (
                                            <div class="invalidFeedback">
                                                Please select a valid Date.
                                            </div>
                                        )}
                                    </div>
                                    <div className="formGroup formFloating fgrow1 maxWidth">
                                        <select
                                            className="formSelect"
                                            id="floatingSelect">
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                        </select>
                                        <label for="floatingSelect">
                                            Repeat
                                        </label>
                                        {false && (
                                            <div class="invalidFeedback">
                                                Please choose Repeat
                                            </div>
                                        )}
                                    </div>
                                    <div className="panelHeading">Priority</div>
                                    <div className="formSection dFlex mb-5">
                                        <div class="formCheck">
                                            <input
                                                class="formCheckInput"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="High"
                                            />
                                            <label
                                                class="formCheckLabel"
                                                for="High">
                                                High
                                            </label>
                                        </div>
                                        <div class="formCheck">
                                            <input
                                                class="formCheckInput"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="medium"
                                            />
                                            <label
                                                class="formCheckLabel"
                                                for="medium">
                                                Medium
                                            </label>
                                        </div>
                                        <div class="formCheck">
                                            <input
                                                class="formCheckInput"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="low"
                                            />
                                            <label
                                                class="formCheckLabel"
                                                for="low">
                                                Low
                                            </label>
                                        </div>
                                        {false && (
                                            <div class="invalidFeedback">
                                                Please select Product Category.
                                            </div>
                                        )}
                                    </div>
                                    <div className="actionRow">
                                        <button className="btn btnPrimary block">
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        )}
                </div>
                <SideDrawer
                    handleDrawerToggle={handleDrawerToggle}
                    open={
                        open &&
                        permissions?.features?.[
                            PERMISSION_FEATURE.REMINDER_SETTINGS
                        ] === PERMISSION_TYPE.MANAGE
                    }
                    drawerButton={true}
                    drawerHeading="Add Reminder"
                    mwebSideDrawerFooter>
                    <form>
                        <div className="formGroup formFloating fgrow1">
                            <input
                                type="text"
                                className="formControl"
                                id="invoice"
                                placeholder="Reminder Title"
                                required
                            />
                            <label for="invoice">Reminder Title</label>
                            {false && (
                                <div class="invalidFeedback">
                                    Please select a valid Reminder Title.
                                </div>
                            )}
                        </div>
                        <div className="panelHeading">Details</div>
                        <div className="formGroup formFloating fgrow1">
                            <input
                                type="date"
                                className="formControl"
                                id="invoice"
                                placeholder="Date"
                                required
                            />
                            <label for="invoice">Date</label>
                            {false && (
                                <div class="invalidFeedback">
                                    Please select a valid Date.
                                </div>
                            )}
                        </div>
                        <div className="formGroup formFloating fgrow1 maxWidth">
                            <select className="formSelect" id="floatingSelect">
                                <option value="1">Yes</option>
                                <option value="2">No</option>
                            </select>
                            <label for="floatingSelect">Repeat</label>
                            {false && (
                                <div class="invalidFeedback">
                                    Please choose Repeat
                                </div>
                            )}
                        </div>
                        <div className="panelHeading">Priority</div>
                        <div className="formSection dFlex mb-5">
                            <div class="formCheck">
                                <input
                                    class="formCheckInput"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="High"
                                />
                                <label class="formCheckLabel" for="High">
                                    High
                                </label>
                            </div>
                            <div class="formCheck">
                                <input
                                    class="formCheckInput"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="medium"
                                />
                                <label class="formCheckLabel" for="medium">
                                    Medium
                                </label>
                            </div>
                            <div class="formCheck">
                                <input
                                    class="formCheckInput"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="low"
                                />
                                <label class="formCheckLabel" for="low">
                                    Low
                                </label>
                            </div>
                            {false && (
                                <div class="invalidFeedback">
                                    Please select Product Category.
                                </div>
                            )}
                        </div>
                    </form>
                </SideDrawer>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        loginData: state.loginDataReducer?.loginData
    }
}
const mapDispatchToProps = dispatch => {
    return {
        addReminderAlertAction: payload =>
            dispatch(addReminderAlertAction(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
