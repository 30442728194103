import React from 'react'

const CloseIcon = () => (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Close">
            <path id="Vector" d="M18 18.5L6 6.5M18 6.5L6 18.5" stroke="black" strokeWidth="1.6" stroke-linecap="round" stroke-linejoin="round" />
        </g>
    </svg>
)

export default CloseIcon
