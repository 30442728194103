import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import './AcknowledgeScreen.scss'
import useCheckMobileScreen from '../../../common/hooks/use-check-mobile-screen-hook'
import CheckIcon from '../../../assets/CheckIcon'

const AcknowledgeScreen = props => {
    const isMobile = useCheckMobileScreen()
    return (
        <>
            {!isMobile || props.onlyAlert ? (
                <div
                    className={`alert dFlex aiCenter ${props.alertType}`}
                    role="alert">
                    {props.success && <CheckIcon />}
                    {props.alertMessage}
                </div>
            ) : (
                <div className="acknowledgeScreen dFlex flexColumn aiCenter jcContentCenter">
                    <div className="icon">{props.icon}</div>
                    <div className="acknowledgeScreenMain">{props.main}</div>
                    <div className="acknowledgeScreenSub textCenter">
                        {props.sub}
                    </div>
                </div>
            )}
        </>
    )
}

const mapStateToProps = state => {
    return {}
}
const mapDispatchToProps = dispatch => {
    return {}
}

AcknowledgeScreen.propTypes = {
    main: PropTypes.string,
    sub: PropTypes.string,
    icon: PropTypes.node,
    alertType: PropTypes.string,
    alertMessage: PropTypes.string,
    success: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps)(AcknowledgeScreen)
