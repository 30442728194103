import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PAGE_CONST } from '../../common/constant/page-constant';
import { redirect } from '../../common/utils/redirectionUtils';
import { documentUploadHelper } from '../../helper/create-account-helper';
import LoginLeft from '../Login/LoginLeft';


import "./DocumentUpload.scss";
import DocumentUploadForm from './DocumentUploadForm';

const DocumentUpload = (props) => {

    return (
        <main className="loginPage dFlex jcSpaceBetween flexNowrap">
            <LoginLeft />
            <div className="loginRight">
                <div className='loginRightControl'>
                    <div className="login-content">
                        <div className='welcomeHeading textCenter'>Upload Documents</div>
                    </div>
                    <DocumentUploadForm btnView />
                </div>
            </div>
        </main>
    );
}


const mapStateToProps = (state) => {

    return state;
}

const mapDispatchToProps = (dispatch) => {

    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentUpload);