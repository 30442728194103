import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    HEADER_CONFIG,
    HEADER_CONST
} from '../../common/constant/common-constant'
import { PAGE_CONST } from '../../common/constant/page-constant'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import { redirect } from '../../common/utils/redirectionUtils'
import MobileHeader from '../Common/Header/MobileHeader'

const PrivacyPolicy = props => {
    const navigate = useNavigate()
    const isMobile = useCheckMobileScreen()

    const [headerOptions, setHeaderOptions] = useState({
        [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
        [HEADER_CONFIG.TITLE]: HEADER_CONST.PRIVACY_POLICY,
        [HEADER_CONFIG.HANDLE_BACK_FUNC]: () => props?.onBackClick()
    })
    const [htmlContent, setHtmlContent] = useState('')

    // useEffect(() => {
    //     const fetchHTML = async () => {
    //         try {
    //             const response = await axios.get(
    //                 'https://legal-html-documents.s3.ap-south-1.amazonaws.com/PharmacyStorePrivacyPolicy.html'
    //             )

    //             console.log(response)
    //             setHtmlContent(response.data)
    //         } catch (error) {
    //             console.error('Error fetching HTML file:', error)
    //         }
    //     }

    //     fetchHTML()
    // }, [])

    return (
        <>
            {isMobile && <MobileHeader headerOptions={headerOptions} />}
            <div className="pageBodyRightPanel">
                <div className="pageBodyRightPanelHeader dFlex jcSpaceBetween aiCenter">
                    <div className="pageBodyRightPanelHeading">
                        Privacy Policy
                    </div>
                </div>
                <div className="informationCondition">
                    <p className="infoPara">Last updated: 25/02/24</p>

                    <p className="infoPara">
                        This Privacy Policy describes Our policies and
                        procedures on the collection, use and disclosure of Your
                        information when You use the Service and tells You about
                        Your privacy rights and how the law protects You. Unless
                        defined herein separately, the abbreviations/capitalized
                        terms used herein shall bear the same meaning as the
                        Terms and Conditions of the Site [insert link]. These
                        privacy policies should be read in conjunction with the
                        Terms and Conditions of the Site.
                    </p>

                    <p className="infoPara">
                        We use Your Personal data to provide and improve the
                        Service. By using the Service, you agree to the
                        collection and use of information in accordance with
                        this Privacy Policy.{' '}
                    </p>

                    <p className="infoPara">
                        <em>Interpretation and Definitions</em>
                    </p>
                    <p className="infoPara">
                        <em>Definitions</em>
                    </p>
                    <p className="infoPara">
                        For the purposes of this Privacy Policy:
                    </p>
                    <ul className="pl-8">
                        <li className="infoPara">
                            <em>Account</em> means a unique account created for
                            You to access our Service or parts of our Service.
                        </li>
                        <li className="infoPara">
                            <em>Application </em>refers to DG e-Pharma, the
                            software program provided by the Company.
                        </li>
                        <li className="infoPara">
                            <em>Company </em>(referred to as either "the
                            Company", "We", "Us" or "Our" in this Agreement)
                            refers to DG e-Pharma Ltd, Rangs FC Enclave (Level
                            11), Plot 6/A, road no 32, Gulshan Avenue,
                            Dhaka-1212.{' '}
                        </li>
                        <li className="infoPara">
                            <em>Country </em>refers to: Bangladesh
                        </li>
                        <li className="infoPara">
                            <em>Device </em>means any device that can access the
                            Service such as a computer, a cellphone or a digital
                            tablet.
                        </li>
                        <li className="infoPara">
                            <em>Personal Data </em>is any information that
                            relates to an identified or identifiable individual.
                            Personal Data shall also include any external,
                            biological or physical information or any other
                            information which singly or jointly can identify a
                            natural or legal person or a system, such as name,
                            photograph, residence/business address, email
                            address, billing address, shipping address, contact
                            number, date of birth, mother’s name, father’s name,
                            gender, signature, national identity card, birth and
                            death registration number, finger print, passport
                            number, bank account details, transaction records of
                            the User with the Site, driving license, e-TIN
                            number, electronic or digital signature, IP address,
                            browser and device information, internet connection
                            details, GPS location, cookies and other similar
                            technologies, username, credit or debit card number,
                            voice print, retina image, iris image, DNA profile,
                            security-related question or any other
                            identification which are available for advance
                            technology.
                        </li>
                        <li className="infoPara">
                            <em>Service </em>refers to the Application.
                        </li>
                        <li className="infoPara">
                            <em>Service Provider </em>means any natural or legal
                            person who processes the data on behalf of the
                            Company. It refers to third-party companies or
                            individuals employed by the Company to facilitate
                            the Service, to provide the Service on behalf of the
                            Company, to perform services related to the Service
                            or to assist the Company in analyzing how the
                            Service is used.
                        </li>
                        <li className="infoPara">
                            <em>Usage Data </em>refers to data collected
                            automatically, either generated by the use of the
                            Service or from the Service infrastructure itself
                            (for example, the duration of a page visit).
                        </li>
                        <li className="infoPara">
                            <em>You </em>means the individual accessing or using
                            the Service, or the company, or other legal entity
                            on behalf of which such individual is accessing or
                            using the Service, as applicable.{' '}
                        </li>
                    </ul>

                    <p className="infoPara">
                        <em>Collecting and Using Your Personal Data</em>
                    </p>
                    <p className="infoPara">
                        <em>Types of Data Collected</em>
                    </p>
                    <p className="infoPara">
                        <em>
                            <i>Personal Data </i>
                        </em>
                    </p>
                    <p className="infoPara">
                        While using Our Service, we may ask You to provide Us
                        with certain personally identifiable information that
                        can be used to contact or identify You. Personally
                        identifiable information may include, but is not limited
                        to: Email address, first name and last name, phone
                        number, payment details, payment card details or bank
                        account details, address, state, province, zip/postal
                        code, city, payment details, payment card details or
                        bank account details etc
                    </p>
                    <p className="infoPara">
                        <em>
                            DG e-Pharma may collect the following information if
                            you are a buyer
                        </em>
                    </p>
                    <ul className="pl-8 mainInfoList">
                        <li>
                            Identity information, for example, your name,
                            profile picture, and date of birth;
                        </li>
                        <li>
                            Contact information, for example, delivery location,
                            billing address, email address and telephone
                            numbers;
                        </li>
                        <li>
                            To facilitate voice search function, we may ask your
                            biometric data include your voice files, face
                            recognition, and facial features;
                        </li>
                        <li>
                            Billing account data: Credit card or bank account
                            information and installment data (such record
                            information may likewise be gathered straight by our
                            subsidiaries and third-party payment service
                            providers);
                        </li>
                        <li>
                            Technical data, such as Internet protocol (IP)
                            address, your login data, browser type and version,
                            time zone setting and location, device information,
                            browser plug-in types and versions, operating system
                            and platform, international mobile equipment
                            identity, device identifier, IMEI, MAC address,
                            cookies (where applicable) and other information and
                            technology on the devices you use to access the
                            Site;{' '}
                        </li>
                        <li>
                            Profile information, for example, your username,
                            account settings, orders connected with you, client
                            research, your interests, feedback or other
                            responses;
                        </li>
                        <li>
                            Usage data, for example, data on how you utilize the
                            Webpage, items and Administrations or your time
                            spent on the Website, search history, access times
                            and dates, as well as sites you were visiting before
                            you came to the Webpage and other comparable
                            measurements;{' '}
                        </li>
                        <li>
                            data about your location, such as when you upload
                            photos or videos of your location to the Site and
                            share it with us;{' '}
                        </li>
                        <li>
                            and any additional information—such as copies of
                            government-issued identification—that we may ask you
                            to provide for due diligence checks or that is
                            required by relevant authorities for identity
                            verification or if we believe you are in violation
                            of our Customer Terms and Conditions or Privacy
                            Policy.
                        </li>
                    </ul>

                    <p className="infoPara">
                        <em>
                            DG e-Pharma will collect the following information
                            if you are a seller or rider
                        </em>
                    </p>

                    <ul className="pl-8 mainInfoList">
                        <li>
                            Identity information, for example, your name,
                            profile picture, and date of birth or incorporation,
                            company/organization name, other business-related
                            information/documents including trade license, DGDA
                            license, BIN number, TIN number etc.
                        </li>
                        <li>
                            Contact information, for example, delivery location,
                            billing address, email address and telephone
                            numbers;
                        </li>
                        <li>
                            To facilitate voice search function, we may ask your
                            biometric data include your voice files, face
                            recognition, and facial features;
                        </li>
                        <li>
                            Billing account data: Credit card or bank account
                            information and installment data (such record
                            information may likewise be gathered straight by our
                            subsidiaries and third-party payment service
                            providers);
                        </li>
                        <li>
                            Technical data, such as Internet protocol (IP)
                            address, your login data, browser type and version,
                            time zone setting and location, device information,
                            browser plug-in types and versions, operating system
                            and platform, international mobile equipment
                            identity, device identifier, IMEI, MAC address,
                            cookies (where applicable) and other information and
                            technology on the devices you use to access the
                            Site;
                        </li>
                        <li>
                            Profile information, for example, your username,
                            account settings, orders connected with you, client
                            research, your interests, feedback or other
                            responses;
                        </li>
                        <li>
                            Usage data, for example, data on how you utilize the
                            Webpage, items and Administrations or your time
                            spent on the Website, search history, access times
                            and dates, as well as sites you were visiting before
                            you came to the Webpage and other comparable
                            measurements. We may also collect information that
                            Your browser sends whenever You visit our Service or
                            when You access the Service by or through a mobile
                            device
                        </li>
                        <li>
                            data about your location, such as when you upload
                            photos or videos of your location to the Site and
                            share it with us;
                        </li>
                        <li>
                            and any additional information—such as copies of
                            government-issued identification—that we may ask you
                            to provide for due diligence checks or that is
                            required by relevant authorities for identity
                            verification or if we believe you are in violation
                            of our Customer Terms and Conditions or Privacy
                            Policy.
                        </li>
                    </ul>

                    <p className="infoPara">
                        <em>
                            Information Collected while Using the Application
                        </em>
                    </p>

                    <p className="infoPara">
                        While using Our Application, in order to provide
                        features of Our Application, we may collect, with Your
                        prior permission:
                    </p>
                    <ul className="pl-8">
                        <li>- Information regarding your location</li>
                        <li>
                            - Pictures and other information from your Device's
                            camera and photo library
                        </li>
                    </ul>
                    <p className="infoPara">
                        We use this information to provide features of our
                        Service, to improve and customize Our Service. The
                        information may be uploaded to the Company's servers
                        and/or a Service Provider's server or it may be simply
                        stored on Your device.
                    </p>

                    <p className="infoPara">
                        You can enable or disable access to this information at
                        any time, through Your Device settings.
                    </p>

                    <p className="infoPara">
                        <em>Use of Your Personal Data</em>
                    </p>

                    <p className="infoPara">
                        The Company may use Personal Data for the following
                        purposes:
                    </p>

                    <p className="infoPara">
                        <em>If you are a buyer:</em>
                    </p>

                    <ul className="pl-8 mainInfoList">
                        <li>
                            <em>To processing your orders for products</em>
                        </li>
                        <li>
                            <em>To deliver the product you have purchased</em>
                        </li>
                        <li>
                            <em>To provide and maintain our Service, </em>
                            including to monitor the usage of our Service.
                        </li>
                        <li>
                            <em>To manage Your Account: </em>to manage Your
                            registration as a user of the Service. The Personal
                            Data You provide can give You access to different
                            functionalities of the Service that are available to
                            You as a registered user.
                        </li>
                        <li>
                            <em>Legal and operational purposes: </em>to compare
                            and ensure that all the information is correct{' '}
                        </li>
                        <li>
                            <em>For the performance of a contract: </em>the
                            development, compliance and undertaking of the
                            purchase contract for the products, items or
                            services You have purchased or of any other contract
                            with Us through the Service.
                        </li>
                        <li>
                            <em>To contact You: </em>To contact You by email,
                            telephone calls, SMS, or other equivalent forms of
                            electronic communication, such as a mobile
                            application's push notifications regarding updates
                            or informative communications related to the
                            functionalities, products or contracted services,
                            including the security updates, when necessary or
                            reasonable for their implementation.
                        </li>
                        <li>
                            <em>To provide You </em>with news, special offers
                            and general information about other goods, services
                            and events which we offer that are similar to those
                            that you have already purchased or enquired about
                            unless You have opted not to receive such
                            information.
                        </li>
                        <li>
                            <em>To manage Your requests: </em>To attend and
                            manage Your requests to Us.
                        </li>
                        <li>
                            <em>For business transfers: </em>We may use Your
                            information to evaluate or conduct a merger,
                            divestiture, restructuring, reorganization,
                            dissolution, or other sale or transfer of some or
                            all of Our assets, whether as a going concern or as
                            part of bankruptcy, liquidation, or similar
                            proceeding, in which Personal Data held by Us about
                            our Service users is among the assets transferred.
                        </li>
                        <li>
                            <em>For other purposes: </em>We may use Your
                            information for other purposes, such as data
                            analysis, identifying usage trends, determining the
                            effectiveness of our promotional campaigns and to
                            evaluate and improve our Service, products,
                            services, marketing and your experience.
                        </li>
                    </ul>

                    <p className="infoPara">
                        <em>If you are a seller or rider:</em>
                    </p>

                    <ul className="pl-8 mainInfoList">
                        <li>
                            <em>To transport or deliver </em>the items you have
                            recorded or sold through the Site.
                        </li>
                        <li>
                            To work with the <em>returned products</em>
                        </li>
                        <li>
                            <em>To store & back up your data</em>
                        </li>
                        <li>
                            <em>To comprehend the client experience </em>with
                            the Administrations and the Site
                        </li>
                        <li>
                            <em>To provide and maintain our Service, </em>
                            including to monitor the usage of our Service.
                        </li>
                        <li>
                            <em>To manage Your Account: </em>to manage Your
                            registration as a user of the Service. The Personal
                            Data You provide can give You access to different
                            functionalities of the Service that are available to
                            You as a registered user.
                        </li>
                        <li>
                            <em>Legal and operational purposes: </em>to compare
                            and ensure that all the information is correct{' '}
                        </li>
                        <li>
                            <em>For the performance of a contract: </em>the
                            development, compliance and undertaking of the
                            purchase contract for the products, items or
                            services You have purchased or of any other contract
                            with Us through the Service.
                        </li>
                        <li>
                            <em>To contact You: </em>To contact You by email,
                            telephone calls, SMS, or other equivalent forms of
                            electronic communication, such as a mobile
                            application's push notifications regarding updates
                            or informative communications related to the
                            functionalities, products or contracted services,
                            including the security updates, when necessary or
                            reasonable for their implementation.
                        </li>
                        <li>
                            <em>To provide You </em>with news, special offers
                            and general information about other goods, services
                            and events which we offer that are similar to those
                            that you have already purchased or enquired about
                            unless You have opted not to receive such
                            information.
                        </li>
                        <li>
                            <em>To manage Your requests: </em>To attend and
                            manage Your requests to Us.
                        </li>
                        <li>
                            <em>For business transfers: </em>We may use Your
                            information to evaluate or conduct a merger,
                            divestiture, restructuring, reorganization,
                            dissolution, or other sale or transfer of some or
                            all of Our assets, whether as a going concern or as
                            part of bankruptcy, liquidation, or similar
                            proceeding, in which Personal Data held by Us about
                            our Service users is among the assets transferred.
                        </li>
                        <li>
                            <em>For other purposes: </em>We may use Your
                            information for other purposes, such as data
                            analysis, identifying usage trends, determining the
                            effectiveness of our promotional campaigns and to
                            evaluate and improve our Service, products,
                            services, marketing and your experience.
                        </li>
                    </ul>

                    <p>
                        We may share Your personal information in the following
                        situations:
                    </p>

                    <ul>
                        <li>
                            <em>With Service Providers: </em>We may share Your
                            personal information with Service Providers to
                            monitor and analyze the use of our Service, to
                            contact You.
                        </li>
                        <li>
                            <em>For business transfers: </em>We may share or
                            transfer Your personal information in connection
                            with, or during negotiations of, any merger, sale of
                            Company assets, financing, or acquisition of all or
                            a portion of Our business to another company.
                        </li>
                        <li>
                            <em>With Affiliates: </em>We may share Your
                            information with Our affiliates, in which case we
                            will require those affiliates to honor this Privacy
                            Policy. Affiliates include Our parent company and
                            any other subsidiaries, joint venture partners or
                            other companies that We control or that are under
                            common control with Us.
                        </li>
                        <li>
                            <em>With business partners: </em>We may share Your
                            information with Our business partners to offer You
                            certain products, services or promotions.
                        </li>
                        <li>
                            <em>With other users: </em>when You share personal
                            information or otherwise interact in the public
                            areas with other users, such information may be
                            viewed by all users and may be publicly distributed
                            outside.
                        </li>
                        <li>
                            <em>With Your consent: </em>We may disclose Your
                            personal information for any other purpose with Your
                            consent.
                        </li>
                    </ul>

                    <p className="infoPara">
                        <em>Retention of Your Personal Data</em>
                    </p>
                    <p className="infoPara">
                        The Company will retain Your Personal Data only for as
                        long as is necessary for the purposes set out in this
                        Privacy Policy. We will retain and use Your Personal
                        Data to the extent necessary to comply with our legal
                        obligations (for example, if we are required to retain
                        your data to comply with applicable laws), resolve
                        disputes, and enforce our legal agreements and policies.
                    </p>
                    <p className="infoPara">
                        The Company will also retain Usage Data for internal
                        analysis purposes. Usage Data is generally retained for
                        a shorter period of time, except when this data is used
                        to strengthen the security or to improve the
                        functionality of Our Service, or We are legally
                        obligated to retain this data for longer time periods.
                    </p>

                    <p className="infoPara">
                        <em>Transfer of Your Personal Data</em>
                    </p>
                    <p className="infoPara">
                        Your information, including Personal Data, is processed
                        at the Company's operating offices and in any other
                        places where the parties involved in the processing are
                        located. It means that this information may be
                        transferred to — and maintained on — computers located
                        outside of Your state, province, country or other
                        governmental jurisdiction where the data protection laws
                        may differ than those from Your jurisdiction.
                    </p>
                    <p className="infoPara">
                        Your consent to this Privacy Policy followed by Your
                        submission of such information represents Your agreement
                        to that transfer.
                    </p>
                    <p className="infoPara">
                        The Company will take all steps reasonably necessary to
                        ensure that Your data is treated securely and in
                        accordance with this Privacy Policy and no transfer of
                        Your Personal Data will take place to an organization or
                        a country unless there are adequate controls in place
                        including the security of Your data and other personal
                        information.
                    </p>

                    <p className="infoPara">
                        <em>Delete Your Personal Data</em>
                    </p>
                    <p className="infoPara">
                        You have the right to delete or request that We assist
                        in deleting the Personal Data that We have collected
                        about You.
                    </p>
                    <p className="infoPara">
                        Our Service may give You the ability to delete certain
                        information about You from within the Service.
                    </p>
                    <p className="infoPara">
                        You may update, amend, or delete Your information at any
                        time by signing in to Your Account, if you have one, and
                        visiting the account settings section that allows you to
                        manage Your personal information. You may also contact
                        Us to request access to, correct, or delete any personal
                        information that You have provided to Us.
                    </p>
                    <p className="infoPara">
                        Please note, however, that We may need to retain certain
                        information when we have a legal obligation or lawful
                        basis to do so.
                    </p>

                    <p className="infoPara">
                        <em>Disclosure of Your Personal Data</em>
                    </p>

                    <p className="infoPara">
                        <em>
                            <i>Business Transactions</i>
                        </em>
                    </p>
                    <p className="infoPara">
                        If the Company is involved in a merger, acquisition or
                        asset sale, Your Personal Data may be transferred. We
                        will provide notice before Your Personal Data is
                        transferred and becomes subject to a different Privacy
                        Policy.
                    </p>

                    <p className="infoPara">
                        <em>
                            <i>Law enforcement</i>
                        </em>
                    </p>
                    <p className="infoPara">
                        Under certain circumstances, the Company may be required
                        to disclose Your Personal Data if required to do so by
                        law or in response to valid requests by public
                        authorities (e.g. a court or a government agency).
                    </p>

                    <p className="infoPara">
                        <em>
                            <i>Other legal requirements</i>
                        </em>
                    </p>
                    <p className="infoPara">
                        The Company may disclose Your Personal Data in the good
                        faith belief that such action is necessary to:
                    </p>

                    <ul className="ml-8">
                        <li>Comply with a legal obligation</li>
                        <li>
                            Protect and defend the rights or property of the
                            Company
                        </li>
                        <li>
                            Prevent or investigate possible wrongdoing in
                            connection with the Service
                        </li>
                        <li>
                            Protect the personal safety of Users of the Service
                            or the public
                        </li>
                        <li>Protect against legal liability</li>
                    </ul>

                    <p className="infoPara">
                        <em>Security of Your Personal Data</em>
                    </p>

                    <p className="infoPara">
                        The security of Your Personal Data is important to Us,
                        but remember that no method of transmission over the
                        Internet, or method of electronic storage is 100%
                        secure. While We strive to use commercially acceptable
                        means to protect Your Personal Data, we cannot guarantee
                        its absolute security. The Company shall not accept any
                        liability for any delays, failures, errors or omissions
                        or loss of transmitted information, viruses or other
                        contamination or destructive properties transmitted to
                        You or Your computer system via the Application. The
                        Company shall not be responsible for any unauthorized
                        access of any Personal Data which has come into the
                        public domain through no fault of the Company.
                    </p>

                    <p className="infoPara">
                        You are responsible for protecting your account and
                        related details, device, and Personal Data against any
                        unauthorized access. The Company shall not assume
                        liability for any unauthorized use and/or access of the
                        user’s account, device, and Personal Data.
                    </p>

                    <p className="infoPara">
                        You shall provide valid and accurate information to the
                        Application which is not misleading. The user shall be
                        responsible for informing the Company in case of any
                        changes in Personal Data. The User shall not use any
                        false information to access the site/Application, or
                        impersonate others or otherwise mislead the Company or
                        third parties in any way whatsoever.
                    </p>

                    <p className="infoPara">
                        <em>User Acceptance</em>
                    </p>

                    <p className="infoPara">
                        You hereby authorize and consent to the collection and
                        preservation of the required Personal Data by the
                        Company with regards to the use of the Application and
                        availing the services offered by the
                        Company/Application.
                    </p>
                    <p className="infoPara">
                        You hereby consent to receive transactional,
                        promotional, commercial and/or other communications from
                        the Company with respect to the usage and/or the orders
                        placed on the Application.
                    </p>
                    <p className="infoPara">
                        You consent that by consenting to use the services of
                        any third party under the Terms and Conditions or on the
                        Sire, You may be subject to the separate policies, terms
                        and conditions and fees not directly associated with the
                        Company.
                    </p>
                    <p className="infoPara">
                        You may unsubscribe from any transactional, promotional,
                        commercial and/or other communications from the Company
                        upon communicating with the Company or making requests.
                    </p>
                    <p className="infoPara">
                        Any submissions made to the Site and/or the Company,
                        including but not limited to, questions, reviews,
                        comments, and suggestions shall be the sole and
                        exclusive property of the Company and shall be
                        non-returnable. The Company may remove or edit any such
                        submissions without any notice or legal course
                        applicable to the Company in this regard.
                    </p>

                    <p className="infoPara">
                        <em>Children's Privacy</em>
                    </p>

                    <p className="infoPara">
                        Our Service does not address anyone under the age of 18.
                        We do not knowingly collect personally identifiable
                        information from anyone under the age of 18. If You are
                        a parent or guardian and You are aware that Your child
                        has provided Us with Personal Data, please contact Us.
                        If We become aware that We have collected Personal Data
                        from anyone under the age of 18 without verification of
                        parental consent, we take steps to remove that
                        information from Our servers.
                    </p>

                    <p className="infoPara">
                        <em>Links to Other Websites</em>
                    </p>

                    <p className="infoPara">
                        Our Service may contain links to other websites that are
                        not operated by Us. If You click on a third-party link,
                        you will be directed to that third party's site. We
                        strongly advise You to review the Privacy Policy of
                        every site You visit.
                    </p>
                    <p className="infoPara">
                        We have no control over and assume no responsibility for
                        the content, privacy policies or practices of any
                        third-party sites or services.
                    </p>

                    <p className="infoPara">
                        <em>Changes to this Privacy Policy</em>
                    </p>

                    <p className="infoPara">
                        We may update Our Privacy Policy from time to time. We
                        will notify You of any changes by posting the new
                        Privacy Policy on this page.
                    </p>
                    <p className="infoPara">
                        We will let You know via email and/or a prominent notice
                        on Our Service, prior to the change becoming effective
                        and update the "Last updated" date at the top of this
                        Privacy Policy. The Company may provide push
                        notifications to You regarding the modifications of
                        these privacy policies.
                    </p>
                    <p className="infoPara">
                        You are advised to review this Privacy Policy
                        periodically for any changes. Changes to this Privacy
                        Policy are effective when they are posted on this page.
                    </p>

                    <p className="infoPara">
                        <em>Contact Us</em>
                    </p>

                    <p className="infoPara">
                        If you have any questions about this Privacy Policy, you
                        can contact us:
                    </p>

                    <ul className="pl-8">
                        <li>By email: </li>
                        <li>By visiting this page on our website: </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy)
