import { logoutAction } from '../../modules/actions/action'
import { store } from '../../modules/store/store'

const axios = require('axios')
const { LOGIN_CONST } = require('../constant/page-constant')
const { getCookieCustom, deleteTokens } = require('../utils/storageUtils')

const axiosInstance = axios.create()
axiosInstance.interceptors.request.use(
    async config => {
        if (config.authRequired) {
            if (
                getCookieCustom(LOGIN_CONST.ACCESS_TOKEN) &&
                getCookieCustom(LOGIN_CONST.REFRESH_TOKEN)
            ) {
                config.headers['Authorization'] = `Bearer ${getCookieCustom(
                    LOGIN_CONST.ACCESS_TOKEN
                )}`
            } else {
                window.location = '/login'
            }
        }

        return config
    },
    error => {
        console.log('error in interceptor request :: ' + error)
        return Promise.reject(error)
    }
)

axiosInstance.interceptors.response.use(
    response => {
        return response
    },
    async (error, res) => {
        console.log('error response in interceptor', error)
        if (error && error.response && 401 === error.response.status) {
            store.dispatch(logoutAction())
            window.location = '/login'
        } else {
            return Promise.reject(error)
        }
        return Promise.reject(error.message)
    }
)

export default axiosInstance
