import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { redirect } from '../../common/utils/redirectionUtils'

import './ForgotPassword.scss'
import LoginLeft from '../Login/LoginLeft'
import {
    FORGOT_PASSWORD_STAGES,
    LOGIN_CONST,
    PAGE_CONST
} from '../../common/constant/page-constant'
import EyeClose from '../../assets/EyeClose'
import EyeOpen from '../../assets/EyeOpen'
import {
    forgotPasswordHelper,
    sendOTPHelper,
    sendUnAuthOTPHelper,
    validateOTPHelper,
    validateUnAuthOTPHelper
} from '../../helper/create-account-helper'
import SuccessCheck from '../../assets/SuccessCheck'
import { sendUnAuthOTPAPI } from '../../adapter/adapter'

function ForgotPassword(props) {
    const navigate = useNavigate()

    const [isFieldErrorVisible, setIsFieldErrorVisible] = useState(false)
    const [areaCode, setAreaCode] = useState(undefined)
    const [phoneNumber, setPhoneNumber] = useState(undefined)
    const [email, setEmail] = useState(undefined)
    const [otp, setOtp] = useState(['', '', '', ''])
    const [password, setPassword] = useState(undefined)
    const [confirmPassword, setConfirmPassword] = useState(undefined)
    const [err, setErr] = useState('')
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
        useState(false)
    const [forgotPasswordStage, setForgotPasswordStage] = useState(
        FORGOT_PASSWORD_STAGES.PASSWORD_INPUT_STAGE
    )
    const [activeTab, setActiveTab] = useState(LOGIN_CONST.PHONE_NUMBER)
    const otpInputs = useRef([])
    const [serverError, setServerError] = useState(undefined)
    const [transactionId, setTransactionId] = useState(undefined)

    useEffect(() => {
        setDefault()
        console.log('setting default')

        // return console.log('test 1')
    }, [])

    const setDefault = () => {
        setPhoneNumber(undefined)
        setEmail(undefined)
        setOtp(['', '', '', ''])
        setPassword(undefined)
        setConfirmPassword(undefined)
        setIsPasswordVisible(false)
        setIsConfirmPasswordVisible(false)
        setForgotPasswordStage(FORGOT_PASSWORD_STAGES.SEND_OTP_STAGE)
    }

    const handleSignIn = () => {
        redirect(navigate, PAGE_CONST.LOGIN)
    }

    const handleNext = () => {
        if (!handleValidation()) {
            return
        }

        switch (forgotPasswordStage) {
            case FORGOT_PASSWORD_STAGES.SEND_OTP_STAGE:
                handleSendOTP()
                // setForgotPasswordStage(FORGOT_PASSWORD_STAGES.VERIFY_OTP_STAGE)
                break
            case FORGOT_PASSWORD_STAGES.VERIFY_OTP_STAGE:
                handleVerifyOTP()
                // setForgotPasswordStage(
                //     FORGOT_PASSWORD_STAGES.PASSWORD_INPUT_STAGE
                // )
                break
            case FORGOT_PASSWORD_STAGES.PASSWORD_INPUT_STAGE:
                handleForgotPassword()
                // setForgotPasswordStage(FORGOT_PASSWORD_STAGES.SUCCESS_STAGE)
                break
            case FORGOT_PASSWORD_STAGES.SUCCESS_STAGE:
                console.log('success stage next click')
                redirect(navigate, PAGE_CONST.LOGIN)
                break
        }
    }

    const handleInputChange = (index, value) => {
        setServerError(undefined)

        const updatedOtp = [...otp]
        updatedOtp[index] = value

        setOtp(updatedOtp)

        if (value && index < 3) {
            otpInputs.current[index + 1].focus()
        }
    }

    const handleInputBackspace = (index, event) => {
        if (event.keyCode === 8 && !otp[index] && index > 0) {
            otpInputs.current[index - 1].focus()
        }
    }

    const handleSendOTP = () => {
        setServerError(undefined)

        sendUnAuthOTPHelper(phoneNumber)
            .then(res => {
                setForgotPasswordStage(FORGOT_PASSWORD_STAGES.VERIFY_OTP_STAGE)
                console.log(res)
            })
            .catch(err => {
                setServerError(err)
                console.log(err)
            })
    }

    const handleVerifyOTP = () => {
        setServerError(undefined)

        const otpValue = otp.join('')
        if (otpValue.length !== 4) {
            setIsFieldErrorVisible(true)
            return
        }

        validateUnAuthOTPHelper(phoneNumber, otpValue)
            .then(res => {
                setTransactionId(res.txn_id)
                setForgotPasswordStage(
                    FORGOT_PASSWORD_STAGES.PASSWORD_INPUT_STAGE
                )
            })
            .catch(err => {
                setServerError(err)
            })
    }

    const handleForgotPassword = () => {
        setServerError(undefined)

        const otpValue = otp.join('')

        if (otpValue.length !== 4) {
            setIsFieldErrorVisible(true)
            return
        }

        forgotPasswordHelper(transactionId, phoneNumber, password)
            .then(res => {
                setForgotPasswordStage(FORGOT_PASSWORD_STAGES.SUCCESS_STAGE)
            })
            .catch(err => {
                setServerError(err)
            })
    }

    const handleResendOTP = () => {
        handleSendOTP()
    }

    const handleValidation = () => {
        const otpValue = otp.join('')

        setServerError(undefined)

        if (forgotPasswordStage === FORGOT_PASSWORD_STAGES.SEND_OTP_STAGE) {
            if (
                (phoneNumber &&
                    activeTab === LOGIN_CONST.PHONE_NUMBER &&
                    [10, 11].includes(phoneNumber.length)) ||
                (email && activeTab === LOGIN_CONST.EMAIL)
            ) {
                return true
            }

            setServerError('Please enter a valid Phone Number !!')
        } else if (
            forgotPasswordStage === FORGOT_PASSWORD_STAGES.VERIFY_OTP_STAGE
        ) {
            if (otpValue.length === 4) {
                return true
            }
        } else if (
            forgotPasswordStage === FORGOT_PASSWORD_STAGES.PASSWORD_INPUT_STAGE
        ) {
            if (password && confirmPassword && password === confirmPassword) {
                return true
            }

            setServerError("Password doesn't match")
        } else if (
            forgotPasswordStage === FORGOT_PASSWORD_STAGES.SUCCESS_STAGE
        ) {
            return true
        }

        setIsFieldErrorVisible(true)
        return false
    }

    const getSubmitButtonText = () => {
        let buttonText
        switch (forgotPasswordStage) {
            case FORGOT_PASSWORD_STAGES.SEND_OTP_STAGE:
                buttonText = 'SEND OTP'
                break
            case FORGOT_PASSWORD_STAGES.VERIFY_OTP_STAGE:
                buttonText = 'VERIFY'
                break
            case FORGOT_PASSWORD_STAGES.PASSWORD_INPUT_STAGE:
                buttonText = 'UPDATE PASSWORD'
                break
            case FORGOT_PASSWORD_STAGES.SUCCESS_STAGE:
                buttonText = 'LOGIN'
                break
        }

        return buttonText
    }

    return (
        <>
            <main className="loginPage createAccount dFlex jcSpaceBetween flexNowrap">
                <LoginLeft />
                <div className="loginRight">
                    <div className="loginRightControl verticalCenter">
                        <div className="login-content">
                            {forgotPasswordStage !==
                                FORGOT_PASSWORD_STAGES.SUCCESS_STAGE && (
                                <div className="welcomeHeading">
                                    <span>RESET PASSWORD</span>
                                </div>
                            )}
                            {forgotPasswordStage ===
                                FORGOT_PASSWORD_STAGES.VERIFY_OTP_STAGE && (
                                <>
                                    <div className="welcomeSubHeading">
                                        OTP Verification
                                    </div>
                                    <div className="welcomeDesc">
                                        The OTP has been sent to your{' '}
                                        {activeTab === LOGIN_CONST.PHONE_NUMBER
                                            ? 'Phone Number: '
                                            : 'Email Id: '}
                                        {phoneNumber || email}
                                        <br />
                                        <button
                                            onClick={() =>
                                                setForgotPasswordStage(
                                                    FORGOT_PASSWORD_STAGES.SEND_OTP_STAGE
                                                )
                                            }>
                                            <span className="textPrimary">
                                                CHANGE{' '}
                                                {activeTab ===
                                                LOGIN_CONST.PHONE_NUMBER
                                                    ? 'PHONE NUMBER'
                                                    : 'EMAIL ID'}
                                            </span>
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                        <form onSubmit={e => e.preventDefault()}>
                            {forgotPasswordStage ===
                                FORGOT_PASSWORD_STAGES.SEND_OTP_STAGE && (
                                <div className="formSection dFlex mb-5">
                                    <div class="formCheck">
                                        <input
                                            class="formCheckInput"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="Phone"
                                            onClick={() => {
                                                setPhoneNumber(undefined)
                                                setEmail(undefined)
                                                setActiveTab(
                                                    LOGIN_CONST.PHONE_NUMBER
                                                )
                                            }}
                                            disabled={false}
                                            checked={
                                                activeTab ===
                                                LOGIN_CONST.PHONE_NUMBER
                                            }
                                        />
                                        <label
                                            class="formCheckLabel"
                                            for="Phone">
                                            Phone Number
                                        </label>
                                    </div>
                                    <div class="formCheck ml-5">
                                        <input
                                            class="formCheckInput"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="email"
                                            onClick={() => {
                                                setPhoneNumber(undefined)
                                                setEmail(undefined)
                                                setActiveTab(LOGIN_CONST.EMAIL)
                                            }}
                                            disabled={false}
                                            checked={
                                                activeTab === LOGIN_CONST.EMAIL
                                            }
                                        />
                                        <label
                                            class="formCheckLabel"
                                            for="email">
                                            Email ID
                                        </label>
                                    </div>
                                    {false && (
                                        <div class="invalidFeedback">
                                            Please select Choice.
                                        </div>
                                    )}
                                </div>
                            )}
                            {forgotPasswordStage ===
                                FORGOT_PASSWORD_STAGES.SEND_OTP_STAGE && (
                                <div className="formSection">
                                    {activeTab === LOGIN_CONST.PHONE_NUMBER && (
                                        <div className="formInline dFlex flexNowrap">
                                            <div className="formGroup formFloating fgrow1 maxWidth mb-0">
                                                <select
                                                    className="formSelect"
                                                    id="floatingSelect"
                                                    onChange={e => {
                                                        setErr('')
                                                        setAreaCode(
                                                            e.target.value
                                                        )
                                                    }}>
                                                    {/* <option value="1">+91</option> */}
                                                    <option value="2">
                                                        +88
                                                    </option>
                                                </select>
                                                <label for="floatingSelect">
                                                    Country
                                                </label>
                                                {false && (
                                                    <div class="invalidFeedback">
                                                        Please choose country
                                                        code.
                                                    </div>
                                                )}
                                            </div>
                                            <div className="formGroup formFloating ml-4 fgrow1 mb-0">
                                                <input
                                                    type="text"
                                                    className="formControl"
                                                    id="phone"
                                                    pattern="[0-9]{10,11}"
                                                    maxLength={11}
                                                    minLength={10}
                                                    value={phoneNumber}
                                                    onChange={event => {
                                                        setPhoneNumber(
                                                            event.target.value
                                                        )
                                                    }}
                                                    placeholder="Phone Number"
                                                    required
                                                />
                                                <label for="phone">
                                                    Phone Number
                                                </label>
                                                {false && (
                                                    <div class="invalidFeedback">
                                                        Please select a valid
                                                        Phone Number.
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {activeTab === LOGIN_CONST.EMAIL && (
                                        <div className="formGroup formFloating fgrow1">
                                            <input
                                                type="text"
                                                className="formControl"
                                                id="email"
                                                value={email}
                                                onChange={event => {
                                                    setEmail(event.target.value)
                                                }}
                                                placeholder="Email"
                                                required
                                            />
                                            <label for="name">Email</label>
                                            {false && (
                                                <div class="invalidFeedback">
                                                    Please Enter Email.
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                            {forgotPasswordStage ===
                                FORGOT_PASSWORD_STAGES.VERIFY_OTP_STAGE && (
                                <div>
                                    <div className="formInline dFlex flexNowrap jcContentCenter">
                                        {Array.from({ length: 4 }).map(
                                            (_, index) => (
                                                <div
                                                    className="formGroup ml-2 mr-2 otpInput"
                                                    key={index}>
                                                    <input
                                                        type="text"
                                                        className="formControl"
                                                        maxLength="1"
                                                        placeholder="0"
                                                        onChange={e =>
                                                            handleInputChange(
                                                                index,
                                                                e.target.value
                                                            )
                                                        }
                                                        onKeyDown={e =>
                                                            handleInputBackspace(
                                                                index,
                                                                e
                                                            )
                                                        }
                                                        ref={input =>
                                                            (otpInputs.current[
                                                                index
                                                            ] = input)
                                                        }
                                                        value={otp[index]}
                                                        required
                                                    />
                                                </div>
                                            )
                                        )}
                                    </div>
                                    {isFieldErrorVisible &&
                                        !otp.join('').trim() && (
                                            <div class="invalidFeedback textCenter">
                                                Please enter a valid OTP
                                            </div>
                                        )}
                                    <p className="otpInfo textCenter">
                                        OTP valid for 2 mins.
                                    </p>

                                    <div class="approvedFeedback strong textCenter">
                                        VERIFIED
                                    </div>
                                    <div
                                        className="createAccountWrap"
                                        onClick={() => handleResendOTP()}>
                                        <div className="createAccount">
                                            RESEND OTP
                                        </div>
                                    </div>
                                </div>
                            )}
                            {forgotPasswordStage ===
                                FORGOT_PASSWORD_STAGES.PASSWORD_INPUT_STAGE && (
                                <div className="formSection">
                                    <div className="formGroup formFloating formInputText">
                                        <input
                                            type={`${
                                                isPasswordVisible
                                                    ? 'text'
                                                    : 'password'
                                            }`}
                                            className="formControl"
                                            id="password"
                                            placeholder="Password"
                                            onChange={event =>
                                                setPassword(event.target.value)
                                            }
                                            required
                                        />
                                        <div className="postInputGroup">
                                            <div className="postInputIcon">
                                                <button
                                                    onClick={() =>
                                                        setIsPasswordVisible(
                                                            !isPasswordVisible
                                                        )
                                                    }>
                                                    {isPasswordVisible ? (
                                                        <EyeClose />
                                                    ) : (
                                                        <EyeOpen />
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                        <label for="password">Password</label>
                                        {isFieldErrorVisible && !password && (
                                            <div class="invalidFeedback">
                                                Please enter a valid Password.
                                            </div>
                                        )}
                                    </div>
                                    <div className="formGroup formFloating formInputText">
                                        <input
                                            type={`${
                                                isConfirmPasswordVisible
                                                    ? 'text'
                                                    : 'password'
                                            }`}
                                            className="formControl"
                                            id="confirmpassword"
                                            placeholder="Confirm Password"
                                            onChange={event =>
                                                setConfirmPassword(
                                                    event.target.value
                                                )
                                            }
                                            required
                                        />
                                        <div className="postInputGroup">
                                            <div className="postInputIcon">
                                                <button
                                                    onClick={() =>
                                                        setIsConfirmPasswordVisible(
                                                            !isConfirmPasswordVisible
                                                        )
                                                    }>
                                                    {isConfirmPasswordVisible ? (
                                                        <EyeClose />
                                                    ) : (
                                                        <EyeOpen />
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                        <label for="confirmpassword">
                                            Confirm Password
                                        </label>
                                        {false && (
                                            <div class="invalidFeedback">
                                                The password does not match.
                                                Please try again.
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            {forgotPasswordStage ===
                                FORGOT_PASSWORD_STAGES.SUCCESS_STAGE && (
                                <div className="dFlex jcSpaceBetween d-col aiCenter">
                                    <SuccessCheck />
                                    <div className="welcomeHeading textCenter mt-5">
                                        <span>
                                            Password changed successfully
                                        </span>
                                    </div>
                                </div>
                            )}
                            {serverError && (
                                <div class="invalidFeedback">{serverError}</div>
                            )}
                            <button
                                className="btn btnPrimary block space"
                                onClick={() => handleNext()}>
                                {getSubmitButtonText()}
                            </button>
                        </form>
                        {forgotPasswordStage !==
                            FORGOT_PASSWORD_STAGES.SUCCESS_STAGE && (
                            <div className="createAccountWrap">
                                <div className="createAccountText">
                                    Already a member?
                                </div>
                                <div
                                    className="createAccount"
                                    onClick={handleSignIn}>
                                    SIGN IN
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </main>
        </>
    )
}

const mapStateToProps = state => {
    return {}
}
const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
