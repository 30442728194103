import React from 'react'

const ExchangeIcon = () => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="fluent:arrow-hook-up-left-24-regular">
            <path id="Vector" d="M8.16667 22.458C8.16667 22.941 8.55867 23.333 9.04167 23.333H16.3333C18.6422 23.333 20.4213 22.3729 21.6067 20.9134C22.7733 19.4749 23.3333 17.593 23.3333 15.7497C23.3333 13.9064 22.7733 12.0245 21.6067 10.586C20.4213 9.12652 18.6422 8.16636 16.3333 8.16636H8.82L11.7017 5.28469C11.7876 5.20459 11.8566 5.10798 11.9044 5.00065C11.9522 4.89332 11.978 4.77745 11.98 4.65997C11.9821 4.54248 11.9605 4.42578 11.9165 4.31683C11.8725 4.20787 11.807 4.1089 11.7239 4.02581C11.6408 3.94272 11.5418 3.87722 11.4329 3.83321C11.3239 3.78921 11.2072 3.76759 11.0897 3.76967C10.9722 3.77174 10.8564 3.79746 10.749 3.84528C10.6417 3.8931 10.5451 3.96206 10.465 4.04802L6.09 8.42302C5.92614 8.58709 5.83411 8.80948 5.83411 9.04136C5.83411 9.27323 5.92614 9.49563 6.09 9.65969L10.465 14.0347C10.5451 14.1207 10.6417 14.1896 10.749 14.2374C10.8564 14.2853 10.9722 14.311 11.0897 14.313C11.2072 14.3151 11.3239 14.2935 11.4329 14.2495C11.5418 14.2055 11.6408 14.14 11.7239 14.0569C11.807 13.9738 11.8725 13.8748 11.9165 13.7659C11.9605 13.6569 11.9821 13.5402 11.98 13.4227C11.978 13.3053 11.9522 13.1894 11.9044 13.0821C11.8566 12.9747 11.7876 12.8781 11.7017 12.798L8.82 9.91636H16.3333C18.1078 9.91636 19.39 10.6339 20.2475 11.6897C21.1225 12.7665 21.5833 14.2389 21.5833 15.7497C21.5833 17.2605 21.1213 18.7329 20.2475 19.8097C19.3912 20.8655 18.1078 21.583 16.3333 21.583H9.04167C8.80961 21.583 8.58705 21.6752 8.42295 21.8393C8.25886 22.0034 8.16667 22.226 8.16667 22.458Z" fill="#EF8658" />
        </g>
    </svg>
)

export default ExchangeIcon
