import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    HEADER_CONFIG,
    HEADER_CONST
} from '../../common/constant/common-constant'
import {
    ACCOUNTING_VOUCHER_CATEGORY,
    PAGE_CONST,
    VOUCHER_FIELDS,
    VOUCHER_PAYMENT_OPTION_LIST
} from '../../common/constant/page-constant'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import { redirect } from '../../common/utils/redirectionUtils'
import Card from '../Common/Card/Card'
import MobileHeader from '../Common/Header/MobileHeader'

import './Accounting.scss'
import CloseIcon from '../../assets/CloseIcon'
import AddIcon from '../../assets/AddIcon'
import TrashIcon from '../../assets/TrashIcon'
import Select from 'react-select'
import PopupCard from '../Common/PopupCard/PopupCard'
import {
    createVoucherHelper,
    getVoucherDetailsHelper,
    updateVoucherHelper
} from '../../helper/personal-details-helper'
import { convertToTitleCase, formatDate } from '../../common/utils/appUtils'
import DownloadIcon from '../../assets/DownloadIcon'
import html2canvas from 'html2canvas'

const AddAccounting = props => {
    const navigate = useNavigate()
    const isMobile = useCheckMobileScreen()

    const [headerOptions, setHeaderOptions] = useState({
        [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
        [HEADER_CONFIG.TITLE]: HEADER_CONST.ACCOUNTING,
        [HEADER_CONFIG.HANDLE_BACK_FUNC]: () =>
            redirect(navigate, PAGE_CONST.HOME)
    })
    const [isAddVoucherReceiptVisible, setIsAddVoucherReceiptVisible] =
        useState(false)
    const [voucherCategory, setVoucherCategory] = useState(undefined)

    const [addedVoucherList, setAddedVoucherList] = useState([
        {
            [VOUCHER_FIELDS.SUB_CATEGORY]: '',
            [VOUCHER_FIELDS.PARTICULARS]: '',
            [VOUCHER_FIELDS.PAYMENT_MODE]: '',
            [VOUCHER_FIELDS.CREDIT]: '',
            [VOUCHER_FIELDS.DEBIT]: ''
        }
    ])
    const [isFieldErrorVisible, setIsFieldErrorVisible] = useState(false)
    const [deleteVoucherPopup, setDeleteVoucherPopup] = useState(false)
    const [voucherDetails, setVoucherDetails] = useState({})
    const [voucherId, setVoucherId] = useState(undefined)
    const [serverError, setServerError] = useState(undefined)
    const ref = useRef(null)

    useEffect(() => {
        console.log('props.isEditVoucher: ', props.isEditVoucher)
        if (props.isEditVoucher) {
            let vDetailsData = props.vouchersDetailsData

            fillEditData(vDetailsData)
        }

        // set
    }, [props.isEditVoucher])

    useEffect(() => {
        console.log('voucherViewOnly id: ', props.voucherViewOnlyId)
        if (props.voucherViewOnlyId) {
            setIsAddVoucherReceiptVisible(true)
            setVoucherId(props.voucherViewOnlyId)
            getVoucherDetails(props.voucherViewOnlyId)
        } else {
            // setIsAddVoucherReceiptVisible(false)
            // setVoucherId(undefined)
            // setVoucherDetails(undefined)
        }
    }, [props.voucherViewOnlyId])

    const fillEditData = vDetailsData => {
        let vCategory = ACCOUNTING_VOUCHER_CATEGORY.MAIN_CATEGORY.filter(
            obj => obj.name === vDetailsData?.category
        )[0]
        console.log(vCategory)

        let aVoucherList = []
        vDetailsData.voucher_details?.map(voucher => {
            let subCategory = ACCOUNTING_VOUCHER_CATEGORY.SUB_CATEGORY[
                vDetailsData?.category
            ].filter(obj => obj.name === voucher?.sub_category)[0]

            let paymentMode = VOUCHER_PAYMENT_OPTION_LIST.filter(
                obj => voucher?.payment_mode === obj.name
            )[0]

            let voucherObj = {
                [VOUCHER_FIELDS.SUB_CATEGORY]: subCategory,
                [VOUCHER_FIELDS.PARTICULARS]: voucher?.particulars,
                [VOUCHER_FIELDS.PAYMENT_MODE]: paymentMode,
                [VOUCHER_FIELDS.CREDIT]: voucher?.credit,
                [VOUCHER_FIELDS.DEBIT]: voucher?.debit
            }

            aVoucherList.push(voucherObj)
        })

        setAddedVoucherList(aVoucherList)
        setVoucherCategory(vCategory)
        setVoucherId(vDetailsData.id)
    }

    const captureScreenshot = () => {
        try {
            var canvasPromise = html2canvas(ref.current, {
                useCORS: true,
                allowTaint: true,
                letterRendering: true,
                scale: window.devicePixelRatio * 3
            })

            canvasPromise.then(canvas => {
                var paddedCanvas = document.createElement('canvas')
                var context = paddedCanvas.getContext('2d')
                var padding = 30
                paddedCanvas.width = canvas.width + 2 * padding
                paddedCanvas.height = canvas.height + 2 * padding
                context.fillStyle = '#ffffff' // background color
                context.fillRect(0, 0, paddedCanvas.width, paddedCanvas.height)
                context.drawImage(canvas, padding, padding)

                // Convert the modified canvas to a data URL
                var dataURL = paddedCanvas.toDataURL('image/png')

                var a = document.createElement('a')
                a.innerHTML = 'DOWNLOAD'
                a.target = '_blank'
                a.href = dataURL
                // Set the download attribute of the link
                a.download = 'accounting.png' // filename
                document.body.appendChild(a)

                a.click()
                document.body.removeChild(a)
            })
        } catch (errMsg) {
            console.log('errMsg: ', errMsg)
        }
    }

    const handleAddClick = () => {
        setAddedVoucherList([
            ...addedVoucherList,
            {
                [VOUCHER_FIELDS.SUB_CATEGORY]: '',
                [VOUCHER_FIELDS.PARTICULARS]: '',
                [VOUCHER_FIELDS.PAYMENT_MODE]: '',
                [VOUCHER_FIELDS.CREDIT]: '',
                [VOUCHER_FIELDS.DEBIT]: ''
            }
        ])
    }

    const handleVoucherDataChange = (index, field, value) => {
        let aVoucherList = addedVoucherList

        aVoucherList[index][field] = value

        setAddedVoucherList([...aVoucherList])
    }

    const handleRemoveVoucher = index => {
        let aVoucherList = addedVoucherList

        aVoucherList.splice(index, 1)

        setAddedVoucherList([...aVoucherList])
        setDeleteVoucherPopup(false)
    }

    const handleValidation = () => {
        return addedVoucherList.every(voucher => {
            if (
                voucher?.[VOUCHER_FIELDS.SUB_CATEGORY] &&
                voucher?.[VOUCHER_FIELDS.PARTICULARS] &&
                voucher?.[VOUCHER_FIELDS.PAYMENT_MODE] &&
                (voucher?.[VOUCHER_FIELDS.CREDIT] ||
                    voucher?.[VOUCHER_FIELDS.DEBIT])
            ) {
                return true
            }
        })
    }

    const getTotalDebit = () => {
        let totalDebit = 0

        addedVoucherList.forEach(item => {
            totalDebit += parseFloat(item[VOUCHER_FIELDS.DEBIT] || 0)
        })

        return totalDebit
    }

    const getTotalCredit = () => {
        let totalCredit = 0

        addedVoucherList.forEach(item => {
            totalCredit += parseFloat(item[VOUCHER_FIELDS.CREDIT] || 0)
        })

        return totalCredit
    }

    const getVoucherDetails = async id => {
        try {
            let vDetails = await getVoucherDetailsHelper(id)

            setVoucherDetails(vDetails)
            setIsAddVoucherReceiptVisible(true)
        } catch (err) {}
    }

    const handleSave = async () => {
        if (!handleValidation()) {
            setIsFieldErrorVisible(true)
            return
        }

        try {
            let data = addedVoucherList.map(item => ({
                ...item,
                credit: item.credit || null,
                debit: item.debit || null,
                particulars: item.particulars,
                payment_mode: item.payment_mode.name,
                sub_category: item.sub_category.name
            }))

            let reqData = {
                accounting_id: props.accountingId,
                voucher_category: voucherCategory?.name,
                total_credit: getTotalCredit(),
                total_debit: getTotalDebit(),
                voucher_details: data
            }

            let vId = voucherId
            if (props.isEditVoucher) {
                reqData = {
                    ...reqData,
                    voucher_id: vId,
                    type: 'EDIT'
                }

                vId = await updateVoucherHelper(reqData)
            } else {
                vId = await createVoucherHelper(reqData)
            }

            getVoucherDetails(vId)
        } catch (err) {
            setServerError(err)
        }
    }

    return (
        <>
            {/* {isMobile && <MobileHeader headerOptions={headerOptions} />} */}
            <div className="pageBodyRightPanel">
                <div className="pageBodyRightPanelHeader dFlex jcSpaceBetween aiCenter">
                    {isMobile && (
                        <div className="addProductBtnMobile dFlex aiCenter mwebVisible fixed">
                            <button className="animated zoomIn btn btnPrimary">
                                <AddIcon />
                            </button>
                        </div>
                    )}
                    {!isMobile && (
                        <div className="pageBodyRightPanelHeading">
                            {isAddVoucherReceiptVisible
                                ? voucherDetails?.voucher_id
                                : 'Add Voucher'}
                        </div>
                    )}
                    <div className="chooseTimeline dFlex aiCenter">
                        {!isAddVoucherReceiptVisible && (
                            <div className="formInline dFlex flexNowrap">
                                <button
                                    className="btn btnPrimary ml-4"
                                    onClick={handleSave}>
                                    Save
                                </button>
                            </div>
                        )}
                        {!isMobile && (
                            <div
                                className="pointer ml-4"
                                onClick={() => props.onCancel()}>
                                <CloseIcon />
                            </div>
                        )}
                    </div>
                </div>
                <div className="accountingBodyWrapper addAccountingBodyWrapper">
                    {!isAddVoucherReceiptVisible && (
                        <>
                            <div className="formGroup formFloating voucherWidth">
                                <Select
                                    className="formSelect reactSelect"
                                    id="floatingSelect"
                                    getOptionLabel={option => option.label}
                                    placeholder="Voucher Category"
                                    styles={{
                                        control: base => ({
                                            border: 0,
                                            display: 'flex',
                                            paddingTop: '20px'
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused
                                                ? 'rgb(222, 235, 255)'
                                                : state.isSelected
                                                ? '#FFF'
                                                : 'inherit',
                                            color: '#212529',
                                            '&:hover': {
                                                backgroundColor:
                                                    state.isSelected
                                                        ? '#DEEBFF'
                                                        : 'rgb(222, 235, 255)'
                                            }
                                        })
                                    }}
                                    value={voucherCategory}
                                    options={
                                        ACCOUNTING_VOUCHER_CATEGORY.MAIN_CATEGORY
                                    }
                                    onChange={option =>
                                        setVoucherCategory(option)
                                    }
                                    isClearable
                                />
                                <label for="floatingSelect">
                                    Voucher Category
                                </label>
                            </div>
                            {serverError && (
                                <div class="invalidFeedback">{serverError}</div>
                            )}
                            <div className="">
                                <table className="table accounting addAccounting">
                                    <thead>
                                        <tr>
                                            <td className="width">Sl. No.</td>
                                            <td width="22%">Sub Head</td>
                                            <td width="26%">Particulars</td>
                                            <td width="15%">Payment Mode</td>
                                            <td width="14%">
                                                Dr/Payment (BDT){' '}
                                            </td>
                                            <td width="14%">
                                                Cr/Received (BDT){' '}
                                            </td>
                                            <td></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {addedVoucherList?.map(
                                            (addedVoucher, index) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                <div className="formGroup formFloating maxWidth mb-0">
                                                                    <Select
                                                                        className="formSelect reactSelect"
                                                                        id="floatingSelect"
                                                                        getOptionLabel={option =>
                                                                            option.label
                                                                        }
                                                                        options={
                                                                            ACCOUNTING_VOUCHER_CATEGORY
                                                                                .SUB_CATEGORY[
                                                                                voucherCategory
                                                                                    ?.name
                                                                            ]
                                                                        }
                                                                        placeholder="Sub Category"
                                                                        styles={{
                                                                            control:
                                                                                base => ({
                                                                                    border: 0,
                                                                                    display:
                                                                                        'flex',
                                                                                    paddingTop:
                                                                                        '20px'
                                                                                }),
                                                                            option: (
                                                                                provided,
                                                                                state
                                                                            ) => ({
                                                                                ...provided,
                                                                                backgroundColor:
                                                                                    state.isFocused
                                                                                        ? 'rgb(222, 235, 255)'
                                                                                        : state.isSelected
                                                                                        ? '#FFF'
                                                                                        : 'inherit',
                                                                                color: '#212529',
                                                                                '&:hover':
                                                                                    {
                                                                                        backgroundColor:
                                                                                            state.isSelected
                                                                                                ? '#DEEBFF'
                                                                                                : 'rgb(222, 235, 255)'
                                                                                    }
                                                                            })
                                                                        }}
                                                                        value={
                                                                            addedVoucher[
                                                                                VOUCHER_FIELDS
                                                                                    .SUB_CATEGORY
                                                                            ]
                                                                        }
                                                                        onChange={option => {
                                                                            handleVoucherDataChange(
                                                                                index,
                                                                                VOUCHER_FIELDS.SUB_CATEGORY,
                                                                                option
                                                                            )
                                                                        }}
                                                                        // isClearable
                                                                    />
                                                                    <label for="floatingSelect">
                                                                        Sub
                                                                        Category
                                                                    </label>
                                                                    {isFieldErrorVisible &&
                                                                        !addedVoucher[
                                                                            VOUCHER_FIELDS
                                                                                .SUB_CATEGORY
                                                                        ] && (
                                                                            <div class="invalidFeedback">
                                                                                Please
                                                                                select
                                                                                a
                                                                                voucher
                                                                                category.
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="formGroup formFloating">
                                                                    <input
                                                                        type="text"
                                                                        className="formControl"
                                                                        id="parti"
                                                                        placeholder="Particulars"
                                                                        required
                                                                        value={
                                                                            addedVoucherList[
                                                                                index
                                                                            ][
                                                                                VOUCHER_FIELDS
                                                                                    .PARTICULARS
                                                                            ]
                                                                        }
                                                                        onChange={event => {
                                                                            handleVoucherDataChange(
                                                                                index,
                                                                                VOUCHER_FIELDS.PARTICULARS,
                                                                                event
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }}
                                                                    />
                                                                    <label for="Particulars">
                                                                        Particulars
                                                                    </label>
                                                                    {isFieldErrorVisible &&
                                                                        !addedVoucher[
                                                                            VOUCHER_FIELDS
                                                                                .PARTICULARS
                                                                        ] && (
                                                                            <div class="invalidFeedback">
                                                                                Please
                                                                                add
                                                                                particular.
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="formGroup formFloating">
                                                                    <Select
                                                                        className="formSelect reactSelect"
                                                                        id="floatingSelect"
                                                                        getOptionLabel={option =>
                                                                            option.label
                                                                        }
                                                                        options={
                                                                            VOUCHER_PAYMENT_OPTION_LIST
                                                                        }
                                                                        placeholder="Mode"
                                                                        styles={{
                                                                            control:
                                                                                base => ({
                                                                                    border: 0,
                                                                                    display:
                                                                                        'flex',
                                                                                    paddingTop:
                                                                                        '20px'
                                                                                }),
                                                                            option: (
                                                                                provided,
                                                                                state
                                                                            ) => ({
                                                                                ...provided,
                                                                                backgroundColor:
                                                                                    state.isFocused
                                                                                        ? 'rgb(222, 235, 255)'
                                                                                        : state.isSelected
                                                                                        ? '#FFF'
                                                                                        : 'inherit',
                                                                                color: '#212529',
                                                                                '&:hover':
                                                                                    {
                                                                                        backgroundColor:
                                                                                            state.isSelected
                                                                                                ? '#DEEBFF'
                                                                                                : 'rgb(222, 235, 255)'
                                                                                    }
                                                                            })
                                                                        }}
                                                                        value={
                                                                            addedVoucherList[
                                                                                index
                                                                            ][
                                                                                VOUCHER_FIELDS
                                                                                    .PAYMENT_MODE
                                                                            ]
                                                                        }
                                                                        onChange={option => {
                                                                            handleVoucherDataChange(
                                                                                index,
                                                                                VOUCHER_FIELDS.PAYMENT_MODE,
                                                                                option
                                                                            )
                                                                        }}
                                                                        // isClearable
                                                                    />
                                                                    <label for="floatingSelect">
                                                                        Mode
                                                                    </label>
                                                                    {isFieldErrorVisible &&
                                                                        !addedVoucher[
                                                                            VOUCHER_FIELDS
                                                                                .PAYMENT_MODE
                                                                        ] && (
                                                                            <div class="invalidFeedback">
                                                                                Please
                                                                                select
                                                                                a
                                                                                payment
                                                                                mode.
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="formGroup formFloating formInputText mb-0">
                                                                    <input
                                                                        type="text"
                                                                        className={`formControl ${
                                                                            addedVoucherList[
                                                                                index
                                                                            ][
                                                                                VOUCHER_FIELDS
                                                                                    .DEBIT
                                                                            ] &&
                                                                            'primaryImportant'
                                                                        }`}
                                                                        id="bdt"
                                                                        placeholder="BDT"
                                                                        required
                                                                        value={
                                                                            addedVoucherList[
                                                                                index
                                                                            ][
                                                                                VOUCHER_FIELDS
                                                                                    .DEBIT
                                                                            ] ||
                                                                            addedVoucherList[
                                                                                index
                                                                            ][
                                                                                VOUCHER_FIELDS
                                                                                    .CREDIT
                                                                            ]
                                                                        }
                                                                        onChange={event => {
                                                                            handleVoucherDataChange(
                                                                                index,
                                                                                VOUCHER_FIELDS.DEBIT,
                                                                                event
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }}
                                                                    />
                                                                    <label for="bdt">
                                                                        BDT
                                                                    </label>
                                                                    {isFieldErrorVisible &&
                                                                        !addedVoucher[
                                                                            VOUCHER_FIELDS
                                                                                .DEBIT
                                                                        ] &&
                                                                        !addedVoucher[
                                                                            VOUCHER_FIELDS
                                                                                .CREDIT
                                                                        ] && (
                                                                            <div className="invalidFeedback">
                                                                                Please
                                                                                enter
                                                                                valid
                                                                                debit.
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="formGroup formFloating formInputText">
                                                                    <input
                                                                        type="text"
                                                                        className={`formControl ${
                                                                            addedVoucherList[
                                                                                index
                                                                            ][
                                                                                VOUCHER_FIELDS
                                                                                    .CREDIT
                                                                            ] &&
                                                                            'primaryImportant'
                                                                        }`}
                                                                        id="bdt"
                                                                        placeholder="BDT"
                                                                        required
                                                                        value={
                                                                            addedVoucherList[
                                                                                index
                                                                            ][
                                                                                VOUCHER_FIELDS
                                                                                    .CREDIT
                                                                            ] ||
                                                                            addedVoucherList[
                                                                                index
                                                                            ][
                                                                                VOUCHER_FIELDS
                                                                                    .DEBIT
                                                                            ]
                                                                        }
                                                                        onChange={event => {
                                                                            handleVoucherDataChange(
                                                                                index,
                                                                                VOUCHER_FIELDS.CREDIT,
                                                                                event
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }}
                                                                    />
                                                                    <label for="bdt">
                                                                        BDT
                                                                    </label>
                                                                    {isFieldErrorVisible &&
                                                                        !addedVoucher[
                                                                            VOUCHER_FIELDS
                                                                                .CREDIT
                                                                        ] &&
                                                                        !addedVoucher[
                                                                            VOUCHER_FIELDS
                                                                                .DEBIT
                                                                        ] && (
                                                                            <div className="invalidFeedback">
                                                                                Please
                                                                                enter
                                                                                valid
                                                                                credit.
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <TrashIcon
                                                                    onClick={() =>
                                                                        setDeleteVoucherPopup(
                                                                            true
                                                                        )
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                        {deleteVoucherPopup && (
                                                            <PopupCard
                                                                header="Delete Voucher ?"
                                                                body="Lorem ipsum dolor sit t consectetur. Nulla bibendum ac ornare sodales.?">
                                                                <button
                                                                    className="btn btnPrimaryOutlined dFlex"
                                                                    onClick={() =>
                                                                        setDeleteVoucherPopup(
                                                                            false
                                                                        )
                                                                    }>
                                                                    Cancel
                                                                </button>
                                                                <button
                                                                    className="btn btnPrimary dFlex"
                                                                    onClick={() =>
                                                                        handleRemoveVoucher(
                                                                            index
                                                                        )
                                                                    }>
                                                                    Delete
                                                                </button>
                                                            </PopupCard>
                                                        )}
                                                    </>
                                                )
                                            }
                                        )}
                                        <tr>
                                            <td colSpan="7">
                                                <div className=" dFlex jc-flex-end">
                                                    <button
                                                        className="btn btnPrimaryOutlined ml-4"
                                                        onClick={
                                                            handleAddClick
                                                        }>
                                                        ADD
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan="4">Total</td>
                                            <td className="textBold">
                                                {`BDT ${
                                                    getTotalDebit() ||
                                                    getTotalCredit()
                                                }`}
                                            </td>
                                            <td className="textBold">
                                                {`BDT ${
                                                    getTotalCredit() ||
                                                    getTotalDebit()
                                                }`}
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </>
                    )}

                    {isAddVoucherReceiptVisible && (
                        <>
                            <div className="addProductBtnMobile dFlex aiCenter fixed">
                                <button
                                    className="animated zoomIn btn btnPrimary"
                                    onClick={() => captureScreenshot()}>
                                    <DownloadIcon />
                                </button>
                            </div>
                            <div ref={ref}>
                                <div className="addedAccountingDetails">
                                    <div className="pharmacyName">
                                        {voucherDetails?.branch?.name}
                                    </div>
                                    <div className="contactDetails">
                                        {`${voucherDetails?.branch?.location} | 
                                    ${voucherDetails?.branch?.email} |
                                    ${voucherDetails?.branch?.mobile_no}
                                    
                                    `}
                                    </div>
                                    <table className="table voucherDetails">
                                        <tbody>
                                            <tr>
                                                <td width="50%">
                                                    Voucher Category:{' '}
                                                    {convertToTitleCase(
                                                        voucherDetails?.category
                                                    )}
                                                </td>
                                                <td width="50%">
                                                    Date:{' '}
                                                    {formatDate(
                                                        voucherDetails?.created_at
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="50%">
                                                    Created by:{' '}
                                                    {voucherDetails?.created_by}
                                                </td>
                                                <td width="50%">
                                                    Voucher No:{' '}
                                                    {voucherDetails?.voucher_id}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="">
                                    <table className="table accounting addAccounting">
                                        <thead>
                                            <tr>
                                                <td>Sl. No.</td>
                                                <td width="30%">Sub Head</td>
                                                <td width="20%">Particulars</td>
                                                <td width="20%">
                                                    Payment Mode
                                                </td>
                                                <td width="15%">Dr/Payment</td>
                                                <td width="15%">Cr/Received</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {voucherDetails?.voucher_details?.map(
                                                (voucher, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                {convertToTitleCase(
                                                                    voucher.sub_category
                                                                )}
                                                            </td>
                                                            <td>
                                                                {
                                                                    voucher.particulars
                                                                }
                                                            </td>
                                                            <td>
                                                                {convertToTitleCase(
                                                                    voucher.payment_mode
                                                                )}
                                                            </td>
                                                            <td
                                                                className={
                                                                    voucher.debit &&
                                                                    'primaryImportant'
                                                                }>{`BDT ${
                                                                voucher.debit ||
                                                                voucher.credit
                                                            }`}</td>
                                                            <td
                                                                className={
                                                                    voucher.credit &&
                                                                    'primaryImportant'
                                                                }>{`BDT ${
                                                                voucher.credit ||
                                                                voucher.debit
                                                            }`}</td>
                                                        </tr>
                                                    )
                                                }
                                            )}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan="4">Total</td>
                                                <td className="textBold">
                                                    {`BDT ${
                                                        voucherDetails?.total_debit ||
                                                        voucherDetails?.total_credit
                                                    }`}
                                                </td>
                                                <td className="textBold">
                                                    {`BDT ${
                                                        voucherDetails?.total_credit ||
                                                        voucherDetails?.total_debit
                                                    }`}
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {}
}
const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAccounting)
