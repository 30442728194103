import React from 'react'

const PrimarySalesIcon = () => (
    <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g id="fluent:currency-dollar-rupee-24-regular">
            <path
                id="Vector"
                d="M18.09 10.5V9H9.59003V4.5C9.59003 4.10218 9.43199 3.72064 9.15069 3.43934C8.86938 3.15804 8.48785 3 8.09003 3C7.6922 3 7.31067 3.15804 7.02937 3.43934C6.74806 3.72064 6.59003 4.10218 6.59003 4.5C6.59003 4.89782 6.74806 5.27936 7.02937 5.56066C7.31067 5.84196 7.6922 6 8.09003 6V9H5.09003V10.5H8.09003V16.7C8.09003 19.06 10 20.97 12.34 21C14.68 20.96 16.54 19.04 16.5 16.7C16.5 15.11 15.75 13.61 14.5 12.62C14.2839 12.4391 14.0493 12.2816 13.8 12.15C13.58 12.05 13.34 12 13.1 12C12.39 12 11.74 12.39 11.39 13C11.2 13.3 11.1 13.65 11.1 14C11.11 15.1 12 16 13.11 16C13.73 16 14.31 15.69 14.69 15.2C14.9 15.67 15 16.18 15 16.7C15.04 18.2 13.86 19.45 12.34 19.5C10.81 19.5 9.58003 18.23 9.59003 16.7V10.5H18.09Z"
                fill="#EF8658"
            />
        </g>
    </svg>
)

export default PrimarySalesIcon

{
    /* <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
        id="Vector"
            d="M18.09 10.5V9H9.59003V4.5C9.59003 4.10218 9.43199 3.72064 9.15069 3.43934C8.86938 3.15804 8.48785 3 8.09003 3C7.6922 3 7.31067 3.15804 7.02937 3.43934C6.74806 3.72064 6.59003 4.10218 6.59003 4.5C6.59003 4.89782 6.74806 5.27936 7.02937 5.56066C7.31067 5.84196 7.6922 6 8.09003 6V9H5.09003V10.5H8.09003V16.7C8.09003 19.06 10 20.97 12.34 21C14.68 20.96 16.54 19.04 16.5 16.7C16.5 15.11 15.75 13.61 14.5 12.62C14.2839 12.4391 14.0493 12.2816 13.8 12.15C13.58 12.05 13.34 12 13.1 12C12.39 12 11.74 12.39 11.39 13C11.2 13.3 11.1 13.65 11.1 14C11.11 15.1 12 16 13.11 16C13.73 16 14.31 15.69 14.69 15.2C14.9 15.67 15 16.18 15 16.7C15.04 18.2 13.86 19.45 12.34 19.5C10.81 19.5 9.58003 18.23 9.59003 16.7V10.5H18.09Z"
            fill="#EF8658"
        />
    </svg> */
}
