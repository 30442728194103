import React from 'react'

const ReportsBlack = () => (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="fluent:document-copy-24-regular">
            <path id="Vector" d="M14.6745 12.3388L14.6665 18.0002V46.0108C14.6665 48.3094 15.5796 50.5138 17.2049 52.1391C18.8302 53.7644 21.0346 54.6775 23.3332 54.6775H46.3092C45.8951 55.8474 45.1287 56.8602 44.1153 57.5765C43.102 58.2928 41.8915 58.6775 40.6505 58.6775H23.3332C19.9738 58.6775 16.7519 57.343 14.3765 54.9675C12.001 52.5921 10.6665 49.3702 10.6665 46.0108V18.0002C10.6665 15.3868 12.3385 13.1602 14.6745 12.3388ZM35.0078 5.3335C36.5998 5.3335 38.1278 5.9655 39.2532 7.0935L51.5678 19.4135C52.6932 20.5388 53.3252 22.0668 53.3252 23.6562V46.0108C53.3252 47.6021 52.693 49.1283 51.5678 50.2535C50.4426 51.3787 48.9165 52.0108 47.3252 52.0108H23.3385C21.7472 52.0108 20.2211 51.3787 19.0959 50.2535C17.9706 49.1283 17.3385 47.6021 17.3385 46.0108V11.3335C17.3385 9.7422 17.9706 8.21607 19.0959 7.09086C20.2211 5.96564 21.7472 5.3335 23.3385 5.3335H35.0078ZM34.6665 9.3335H23.3385C22.8081 9.3335 22.2994 9.54421 21.9243 9.91928C21.5492 10.2944 21.3385 10.8031 21.3385 11.3335V46.0108C21.3385 47.1148 22.2345 48.0108 23.3385 48.0108H47.3252C47.8556 48.0108 48.3643 47.8001 48.7394 47.425C49.1145 47.05 49.3252 46.5413 49.3252 46.0108V24.0082H40.6665C39.1466 24.0084 37.6832 23.4318 36.572 22.3948C35.4608 21.3578 34.7845 19.9378 34.6798 18.4215L34.6665 18.0108V9.3335ZM38.6665 12.1628L38.6692 18.0108C38.6692 19.0242 39.4212 19.8588 40.3972 19.9922L40.6692 20.0108L46.5092 20.0082L38.6692 12.1628H38.6665Z" fill="#212121" />
        </g>
    </svg>
)

export default ReportsBlack
