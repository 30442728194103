import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import CloseIcon from '../../assets/CloseIcon'
import EditIcon from '../../assets/EditIcon'
import {
    PAGE_CONST,
    PERMISSION_TYPE,
    PROFILE_CONST,
    PROFILE_NAVIGATION_ITEMS
} from '../../common/constant/page-constant'
import Header from '../Common/Header/Header'
import SideDrawer from '../Common/SideDrawer/SideDrawer'
import SideMenu from '../Common/SideMenu/SideMenu'
import BranchManagement from '../BranchManagement/BranchManagement'

import './UserProfile.scss'
import Card from '../Common/Card/Card'
import LocationIcon from '../../assets/LocationIcon'
import { activeProfileDetailNavigation } from '../../modules/actions/action'
import PersonalDetails from '../PersonalDetails/PersonalDetails'
import Accounting from '../Accounting/Accounting'
import TermAndCondition from '../TermAndCondition/TermAndCondition'
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy'
import About from '../About/About'
import Documents from '../Documents/Documents'
import PlatformCharges from '../PlatformCharges/PlatformCharges'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import MobileHeader from '../Common/Header/MobileHeader'
import { SIDEBAR_CONST } from '../../common/constant/common-constant'
import DeliveryPartners from '../DeliveryPartners/DeliveryPartners'
import OrderBook from '../OrderBook/OrderBook'
import AddNewOrder from '../OrderBook/AddNewOrder'
import Discount from '../Discounts/Discount'
import { redirect } from '../../common/utils/redirectionUtils'
import { useNavigate } from 'react-router-dom'

const UserProfile = props => {
    const navigate = useNavigate()
    const isMobile = useCheckMobileScreen()

    const [viewDrawer, setViewDrawer] = useState(false)
    const [activeItem, setActiveItem] = useState(undefined)
    const [open, setOpen] = useState(false)
    const [permissions, setPermissions] = useState({})

    const headerOptions = {
        isBackVisible: false,
        title: 'User Profile'
    }

    const handleDrawerToggle = () => {
        setViewDrawer(!viewDrawer)
        setOpen(!open)
    }

    useEffect(() => {
        if (props.config?.activeProfileDetailNavigation) {
            setActiveItem(props.config.activeProfileDetailNavigation)
        }
    }, [props.config?.activeProfileDetailNavigation])

    const handleItemClick = itemName => {
        props.activeProfileDetailNavigation(itemName)
    }

    const handleClose = () => {
        redirect(navigate, PAGE_CONST.HOME)
    }

    useEffect(() => {
        if (props.loginData.permissions) {
            let permission = props.loginData.permissions.reduce(
                (acc, permission) => {
                    acc.features = {
                        ...acc.features,
                        ...permission.features
                    }

                    return acc
                },
                {}
            )

            if (permission) setPermissions(permission)
        }
    }, [props.loginData])

    return (
        <div className="containerHome">
            {!isMobile && <Header />}
            {isMobile && <MobileHeader headerOptions={headerOptions} />}
            <SideMenu
                activeItem={SIDEBAR_CONST.USER_PROFILE}
                component={PAGE_CONST.USER_PROFILE}
            />
            <div className="pageBody dFlex">
                <div className="pageBodyLeftPanel sticky">
                    {!isMobile && (
                        <div className="chainNavigationWrapper">
                            <ul className="chainNavigation">
                                {PROFILE_NAVIGATION_ITEMS.map(item => {
                                    if (
                                        permissions?.features?.[
                                            item.feature
                                        ] !== PERMISSION_TYPE.RESTRICTED
                                    ) {
                                        return (
                                            <li
                                                className={`chainItem ${
                                                    activeItem === item.name
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                onClick={() =>
                                                    handleItemClick(item.name)
                                                }>
                                                {item.profileDetailIcon}
                                                <span>{item.name}</span>
                                            </li>
                                        )
                                    }
                                })}
                            </ul>
                        </div>
                    )}
                </div>
                {activeItem === PROFILE_CONST.PERSONAL_DETAILS && (
                    <PersonalDetails onClose={() => handleClose()} />
                )}
                {activeItem === PROFILE_CONST.DOCUMENTS && (
                    <Documents onClose={() => handleClose()} />
                )}
                {activeItem === PROFILE_CONST.PLATFORM_CHARGES && (
                    <PlatformCharges onClose={() => handleClose()} />
                )}
                {activeItem === PROFILE_CONST.BRANCH_MANAGEMENT && (
                    <BranchManagement onClose={() => handleClose()} />
                )}
                {activeItem === PROFILE_CONST.DELIVERY_PARTNER && (
                    <DeliveryPartners onClose={() => handleClose()} />
                )}
                {activeItem === PROFILE_CONST.ACCOUNTING && <Accounting />}
                {activeItem === PROFILE_CONST.ORDER_BOOK && <OrderBook />}
                {activeItem === PROFILE_CONST.DISCOUNTS && <Discount />}
                {activeItem === PROFILE_CONST.TERMS_AND_CONDITIONS && (
                    <TermAndCondition />
                )}
                {activeItem === PROFILE_CONST.PRIVACY_POLICY && (
                    <PrivacyPolicy />
                )}
                {activeItem === PROFILE_CONST.ABOUT && <About />}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        config: state.configReducer.config,
        loginData: state.loginDataReducer?.loginData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        activeProfileDetailNavigation: payload =>
            dispatch(activeProfileDetailNavigation(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)
