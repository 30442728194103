import React from 'react'

const ReportsIcon = () => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="fluent:document-copy-24-regular">
            <path id="Vector" d="M6.42 5.39809L6.4165 7.87492V20.1296C6.4165 21.1352 6.81598 22.0996 7.52706 22.8107C8.23813 23.5218 9.20256 23.9213 10.2082 23.9213H20.2602C20.079 24.4331 19.7437 24.8762 19.3004 25.1896C18.857 25.503 18.3274 25.6712 17.7845 25.6713H10.2082C8.73843 25.6713 7.32888 25.0874 6.28962 24.0481C5.25036 23.0089 4.6665 21.5993 4.6665 20.1296V7.87492C4.6665 6.73159 5.398 5.75742 6.42 5.39809ZM15.3158 2.33325C16.0123 2.33325 16.6808 2.60975 17.1732 3.10325L22.5608 8.49325C23.0532 8.98559 23.3297 9.65409 23.3297 10.3494V20.1296C23.3297 20.8258 23.0531 21.4935 22.5608 21.9857C22.0685 22.478 21.4009 22.7546 20.7047 22.7546H10.2105C9.51431 22.7546 8.84663 22.478 8.35435 21.9857C7.86207 21.4935 7.5855 20.8258 7.5855 20.1296V4.95825C7.5855 4.26206 7.86207 3.59438 8.35435 3.1021C8.84663 2.60981 9.51431 2.33325 10.2105 2.33325H15.3158ZM15.1665 4.08325H10.2105C9.97844 4.08325 9.75588 4.17544 9.59179 4.33953C9.42769 4.50363 9.3355 4.72619 9.3355 4.95825V20.1296C9.3355 20.6126 9.7275 21.0046 10.2105 21.0046H20.7047C20.9367 21.0046 21.1593 20.9124 21.3234 20.7483C21.4875 20.5842 21.5797 20.3617 21.5797 20.1296V10.5034H17.7915C17.1265 10.5035 16.4863 10.2513 16.0002 9.79758C15.514 9.34391 15.2181 8.72263 15.1723 8.05925L15.1665 7.87959V4.08325ZM16.9165 5.32109L16.9177 7.87959C16.9177 8.32292 17.2467 8.68809 17.6737 8.74642L17.7927 8.75459L20.3477 8.75342L16.9177 5.32109H16.9165Z" fill="#EF8658" />
        </g>
    </svg>
)

export default ReportsIcon
