import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AddIcon from '../../assets/AddIcon'
import CloseIcon from '../../assets/CloseIcon'
import {
    HEADER_CONFIG,
    HEADER_CONST,
    WIZARD_STEPS
} from '../../common/constant/common-constant'
import {
    CARD_ACTION,
    DELIVERY_PERSON_FIELDS,
    PAGE_CONST,
    PERMISSION_FEATURE,
    PERMISSION_SCREEN,
    PERMISSION_TYPE
} from '../../common/constant/page-constant'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import { convertToTitleCase } from '../../common/utils/appUtils'
import { redirect } from '../../common/utils/redirectionUtils'
import Alert from '../../components/Alert/Alert'
import { getBranchHelper } from '../../helper/branch-management-helper'
import { addUserHelper, getUserHelper } from '../../helper/home-helper'
import {
    addDeliveryPartnerHelper,
    getDeliveryPartnersHelper,
    updateDeliveryPartnerStatusHelper
} from '../../helper/personal-details-helper'
import {
    addDeliveryPersonAlertAction,
    addUserAlertAction
} from '../../modules/actions/action'
import Card from '../Common/Card/Card'
import Header from '../Common/Header/Header'
import MobileHeader from '../Common/Header/MobileHeader'
import SideDrawer from '../Common/SideDrawer/SideDrawer'

import './DeliveryPartners.scss'

const DeliveryPartners = props => {
    const navigate = useNavigate()
    const isMobile = useCheckMobileScreen()

    const [viewDrawer, setViewDrawer] = useState(false)
    const [open, setOpen] = useState(false)
    const [addUserState, setAddUserState] = useState(undefined)
    const [deliveryPartnerData, setDeliveryPartnerData] = useState(undefined)
    const [isFieldErrorVisible, setIsFieldErrorVisible] = useState(false)
    const [addDeliveryPartnerAlert, setAddDeliveryPartnerAlert] =
        useState(false)
    const [headerOptions, setHeaderOptions] = useState({
        [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
        [HEADER_CONFIG.TITLE]: HEADER_CONST.DELIVERY_PARTNER,
        [HEADER_CONFIG.HANDLE_BACK_FUNC]: () =>
            redirect(navigate, PAGE_CONST.PROFILE_MENU)
    })
    const [manageDeliveryPartnerData, setManageDeliveryPartnerData] = useState(
        []
    )
    const [updateDeliveryFlow, setUpdateDeliveryFlow] = useState(false)
    const [permissions, setPermissions] = useState({})

    useEffect(() => {
        if (!open) {
            handleHeaderConfigUpdate(HEADER_CONST.DELIVERY_PARTNER, () =>
                props?.onBackClick()
            )
            return
        }

        handleHeaderConfigUpdate(HEADER_CONST.ADD_DELIVERY_PARTNER, () =>
            handleDrawerToggle()
        )
    }, [open])

    useEffect(() => {
        let addDeliveryPersonAlert = props.alert?.addDeliveryPersonAlert
        setAddDeliveryPartnerAlert(addDeliveryPersonAlert)
    }, [props.alert?.addDeliveryPersonAlert])

    useEffect(() => {
        getDeliveryPartnerData()
    }, [])

    useEffect(() => {
        if (props.loginData.permissions) {
            let permission = props.loginData.permissions.filter(
                permission => permission.screen === PERMISSION_SCREEN.OTHER
            )[0]

            if (permission) setPermissions(permission)
        }
    }, [props.loginData])

    const handleHeaderConfigUpdate = (title, handleBackFunc) => {
        setHeaderOptions({
            [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
            [HEADER_CONFIG.TITLE]: title,
            [HEADER_CONFIG.HANDLE_BACK_FUNC]: () => handleBackFunc()
        })
    }

    const getDeliveryPartnerData = () => {
        getDeliveryPartnersHelper()
            .then(res => {
                console.log('dpd res: ', res)
                setManageDeliveryPartnerData(res)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleDrawerToggle = () => {
        setViewDrawer(!viewDrawer)
        setOpen(!open)
    }

    const resetDrawerData = () => {
        setDeliveryPartnerData({})
    }

    const handleDeliveryPersonDetailUpdate = (key, value) => {
        setDeliveryPartnerData({
            ...deliveryPartnerData,
            [key]: value
        })
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            setIsFieldErrorVisible(true)
            return
        }

        setIsFieldErrorVisible(false)
        let data = { ...deliveryPartnerData }

        addDeliveryPartnerHelper(data)
            .then(res => {
                console.log(res)

                props.addDeliveryPersonAlertAction(true)

                setTimeout(() => {
                    props.addDeliveryPersonAlertAction(false)
                }, 5000)
                getDeliveryPartnerData()
                resetDrawerData()
                handleDrawerToggle()
            })
            .catch(() => {})
    }

    const checkValidation = () => {
        if (
            deliveryPartnerData[DELIVERY_PERSON_FIELDS.NAME] &&
            deliveryPartnerData[DELIVERY_PERSON_FIELDS.PHONE_NUMBER] &&
            deliveryPartnerData[DELIVERY_PERSON_FIELDS.EMAIL] &&
            deliveryPartnerData[DELIVERY_PERSON_FIELDS.PASSWORD]
        ) {
            return true
        }

        return false
    }

    const updateDeliveryPartnerStatus = (id, action) => {
        console.log('id: ', id, ' action: ', action)

        setUpdateDeliveryFlow(true)
        updateDeliveryPartnerStatusHelper(id, action)
            .then(res => {
                props.addDeliveryPersonAlertAction(true)
                setTimeout(() => {
                    props.addDeliveryPersonAlertAction(false)
                    setUpdateDeliveryFlow(false)
                }, 5000)

                getDeliveryPartnerData()
                console.log('res: ', res)
            })
            .catch(err => {
                console.log('err: ', err)
            })
    }

    return (
        <>
            {isMobile && <MobileHeader headerOptions={headerOptions} />}
            {addDeliveryPartnerAlert && (
                <Alert
                    success
                    alertType="success"
                    alertMessage={`${
                        updateDeliveryFlow
                            ? 'Delivery Partner updated'
                            : 'New Delivery Partner added'
                    } Successfully!`}
                />
            )}
            <div className="pageBodyRightPanel">
                {isMobile &&
                    permissions?.features?.[
                        PERMISSION_FEATURE.DELIVERY_PARTNER
                    ] === PERMISSION_TYPE.MANAGE && (
                        <div className="addProductBtnMobile dFlex flexColumnReverse aiCenter mwebVisible fixed">
                            <button
                                className="animated zoomIn btn btnPrimary"
                                onClick={handleDrawerToggle}>
                                <AddIcon />
                            </button>
                        </div>
                    )}
                {!isMobile && (
                    <div className="pageBodyRightPanelHeader dFlex jcSpaceBetween aiCenter">
                        <div className="pageBodyRightPanelHeading">
                            Delivery Partners
                        </div>
                        <div className="addProductBtn dFlex aiCenter">
                            {permissions?.features?.[
                                PERMISSION_FEATURE.DELIVERY_PARTNER
                            ] === PERMISSION_TYPE.MANAGE && (
                                <button
                                    className="btn btnPrimary"
                                    onClick={handleDrawerToggle}>
                                    ADD
                                </button>
                            )}
                            <div
                                className="pointer ml-4"
                                onClick={() => props?.onClose()}>
                                <CloseIcon />
                            </div>
                        </div>
                    </div>
                )}
                <div className="productLlistWrap dFlex">
                    {manageDeliveryPartnerData &&
                        manageDeliveryPartnerData.map(user => {
                            return (
                                <div className="cardWrap">
                                    <Card
                                        users
                                        cardAction={
                                            permissions?.features?.[
                                                PERMISSION_FEATURE
                                                    .DELIVERY_PARTNER
                                            ] === PERMISSION_TYPE.MANAGE &&
                                            (user.is_active
                                                ? CARD_ACTION.DELIVERY_PARTNER_ACTIVE
                                                : CARD_ACTION.DELIVERY_PARTNER_INACTIVE)
                                        }
                                        onCardAction={action =>
                                            updateDeliveryPartnerStatus(
                                                user.id,
                                                action
                                            )
                                        }
                                        userInitials={convertToTitleCase(
                                            user?.name?.[0]
                                        )}
                                        productDesc={`Status: ${
                                            user.is_active
                                                ? 'Active'
                                                : 'Inactive'
                                        }`}
                                        headerMain={user.name}
                                        phone={user.mobile_no}
                                        email={user.email}
                                    />
                                </div>
                            )
                        })}
                </div>
            </div>
            <SideDrawer
                handleDrawerToggle={handleDrawerToggle}
                open={open}
                drawerButton={true}
                drawerHeading="Add Delivery Partner"
                handleClick={() => handleSubmit()}>
                <div className="wizardFieldset">
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="formGroup formFloating fgrow1">
                            <input
                                type="text"
                                className="formControl"
                                id="invoice"
                                placeholder="Name"
                                value={
                                    deliveryPartnerData?.[
                                        DELIVERY_PERSON_FIELDS.NAME
                                    ]
                                }
                                onChange={e =>
                                    handleDeliveryPersonDetailUpdate(
                                        DELIVERY_PERSON_FIELDS.NAME,
                                        e.target.value
                                    )
                                }
                                required
                            />
                            <label for="invoice">Name</label>
                            {isFieldErrorVisible &&
                                !deliveryPartnerData?.[
                                    DELIVERY_PERSON_FIELDS.NAME
                                ] && (
                                    <div class="invalidFeedback">
                                        Please enter a name.
                                    </div>
                                )}
                        </div>
                        <div className="formInline dFlex flexNowrap">
                            <div className="formGroup formFloating fgrow1 maxWidth">
                                <select
                                    className="formSelect"
                                    id="floatingSelect"
                                    value={
                                        deliveryPartnerData?.[
                                            DELIVERY_PERSON_FIELDS.AREA_CODE
                                        ]
                                    }
                                    onChange={e =>
                                        handleDeliveryPersonDetailUpdate(
                                            DELIVERY_PERSON_FIELDS.AREA_CODE,
                                            e.target.value
                                        )
                                    }>
                                    {/* <option value="none" selected disabled hidden>Select an Option</option> */}
                                    {/* <option value="1">+91</option> */}
                                    <option value="2">+88</option>
                                </select>
                                <label for="floatingSelect">Country</label>
                                {false && (
                                    <div class="invalidFeedback">
                                        Please choose country code.
                                    </div>
                                )}
                            </div>
                            <div className="formGroup formFloating ml-4 fgrow1">
                                <input
                                    type="text"
                                    className="formControl"
                                    id="phone"
                                    placeholder="Phone"
                                    value={
                                        deliveryPartnerData?.[
                                            DELIVERY_PERSON_FIELDS.PHONE_NUMBER
                                        ]
                                    }
                                    onChange={e =>
                                        handleDeliveryPersonDetailUpdate(
                                            DELIVERY_PERSON_FIELDS.PHONE_NUMBER,
                                            e.target.value
                                        )
                                    }
                                    required
                                />
                                <label for="phone">Phone</label>
                                {isFieldErrorVisible &&
                                    !deliveryPartnerData?.[
                                        DELIVERY_PERSON_FIELDS.PHONE_NUMBER
                                    ] && (
                                        <div class="invalidFeedback">
                                            Please select a valid Phone.
                                        </div>
                                    )}
                            </div>
                        </div>
                        <div className="formGroup formFloating">
                            <input
                                type="text"
                                className="formControl"
                                id="email"
                                placeholder="Email Address"
                                value={
                                    deliveryPartnerData?.[
                                        DELIVERY_PERSON_FIELDS.EMAIL
                                    ]
                                }
                                onChange={e =>
                                    handleDeliveryPersonDetailUpdate(
                                        DELIVERY_PERSON_FIELDS.EMAIL,
                                        e.target.value
                                    )
                                }
                                required
                            />
                            <label for="address">Email Address</label>
                            {isFieldErrorVisible &&
                                !deliveryPartnerData?.[
                                    DELIVERY_PERSON_FIELDS.EMAIL
                                ] && (
                                    <div class="invalidFeedback">
                                        Please enter a valid Email Address.
                                    </div>
                                )}
                        </div>
                        <div className="formGroup formFloating">
                            <input
                                type="password"
                                className="formControl"
                                id="password"
                                placeholder="Password"
                                value={
                                    deliveryPartnerData?.[
                                        DELIVERY_PERSON_FIELDS.PASSWORD
                                    ]
                                }
                                onChange={e =>
                                    handleDeliveryPersonDetailUpdate(
                                        DELIVERY_PERSON_FIELDS.PASSWORD,
                                        e.target.value
                                    )
                                }
                                required
                            />
                            <label for="password">Password</label>
                            {isFieldErrorVisible &&
                                !deliveryPartnerData?.[
                                    DELIVERY_PERSON_FIELDS.PASSWORD
                                ] && (
                                    <div class="invalidFeedback">
                                        Please enter a valid Password.
                                    </div>
                                )}
                        </div>
                    </form>
                </div>
            </SideDrawer>
        </>
    )
}

const mapStateToProps = state => {
    return {
        alert: state.alertReducer.alert,
        loginData: state.loginDataReducer?.loginData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addDeliveryPersonAlertAction: payload =>
            dispatch(addDeliveryPersonAlertAction(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryPartners)
