import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

import './ExpiredProducts.scss'
import SideDrawer from '../Common/SideDrawer/SideDrawer'
import LocationIcon from '../../assets/LocationIcon'
import {
    EXPIRED_PRODUCT_STATUS,
    PAYMENT_DETAILS_FIELDS,
    PAYMENT_MODE_OPTIONS,
    PERMISSION_FEATURE,
    PERMISSION_SCREEN,
    PERMISSION_TYPE,
    PRODUCT_DETAIL_FIELDS
} from '../../common/constant/page-constant'
import { convertToTitleCase, formatDate } from '../../common/utils/appUtils'

function ViewExpiredProducts({
    open,
    handleDrawerToggle,
    onConfirmClick,
    data,
    loginData
}) {
    const navigate = useNavigate()
    const [returnLoss, setReturnLoss] = useState('')
    const [amountReceivable, setAmountReceivable] = useState('')
    const [isFieldErrorVisible, setIsFieldErrorVisible] = useState(false)
    const [permissions, setPermissions] = useState({})
    const [expiryType, setExpiryType] = useState('OFFLINE')
    const [notes, setNotes] = useState('')

    useEffect(() => {
        if (!open) resetData()
    }, [open])

    useEffect(() => {
        if (data?.status === EXPIRED_PRODUCT_STATUS.RETURNED) {
            setAmountReceivable(data?.receivable_amount)
        } else if (data?.product_details.length > 0) {
            const totalAmount = getTotalAmount()
            const newAmountReceivable = totalAmount - returnLoss

            setAmountReceivable(newAmountReceivable)
        } else {
            setAmountReceivable('')
        }
    }, [returnLoss, data])

    useEffect(() => {
        if (loginData.permissions) {
            console.log('loginData.permissions: ', loginData.permissions)

            let permission = loginData.permissions.filter(
                permission =>
                    permission.screen === PERMISSION_SCREEN.MANAGE_STORE
            )[0]

            console.log('home screen permission: ', permission)
            if (permission) setPermissions(permission)
        }
    }, [loginData])

    const handleSubmit = id => {
        if (!returnLoss) {
            setIsFieldErrorVisible(true)
            return
        }

        if (!expiryType) {
            setIsFieldErrorVisible(true)
            return
        }

        setIsFieldErrorVisible(false)
        let data = {
            id: id,
            returnLoss: returnLoss,
            amountReceivable: amountReceivable,
            type: expiryType,
            notes: notes
        }

        onConfirmClick(data)
    }

    const resetData = () => {
        setReturnLoss('')
        setAmountReceivable('')
        setExpiryType('')
    }

    const getTotalAmount = () => {
        let totalAmount = 0
        data.product_details.forEach(product => {
            totalAmount += product.total_amount
        })
        return totalAmount
    }

    return (
        <SideDrawer
            handleDrawerToggle={handleDrawerToggle}
            open={open}
            drawerHeading={`Expired Product ${
                data?.status === 'RETURNED'
                    ? convertToTitleCase(data?.status)
                    : ''
            }`}
            drawerButton={
                data?.status === 'RETURN' &&
                permissions?.features?.[PERMISSION_FEATURE.EXPIRED_PRODUCTS] ===
                    PERMISSION_TYPE.MANAGE
            }
            handleClick={() => handleSubmit(data?.id)}
            buttonText={'Confirm'}>
            {data && (
                <div className="viewProductWrap">
                    <div className="viewProductHeader">
                        <div className="contactDetailsRow dFlex">
                            <div className="productSupplierName">
                                {data.pharma_company}
                            </div>
                        </div>
                        <div className="productSupplierAddress dFlex aiCenter">
                            <LocationIcon className="icon" />
                            {data.pharma_address}
                        </div>
                    </div>
                    <div className="productDescriptionWrap">
                        <table className="invoiceTable">
                            <thead>
                                <tr>
                                    <td>Sl. No</td>
                                    <td width="20%">Product Name</td>
                                    <td>Category</td>
                                    <td>Batch no.</td>
                                    <td>Expired Dt.</td>
                                    <td>Cost/Ut</td>
                                    <td>Rate/Ut</td>
                                    <td>QTY</td>
                                    <td>Total Cost</td>
                                </tr>
                            </thead>
                            <tbody>
                                {data.product_details &&
                                    data.product_details.map(
                                        (product, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        {product.product_name +
                                                            '-' +
                                                            product.product_dose}
                                                    </td>
                                                    <td>
                                                        {
                                                            product.product_category
                                                        }
                                                    </td>
                                                    <td>
                                                        {product?.batch_number}
                                                    </td>
                                                    <td>
                                                        {formatDate(
                                                            product.expiry_date
                                                        )}
                                                    </td>
                                                    <td>
                                                        {product.cost_per_unit.toFixed(
                                                            2
                                                        )}
                                                    </td>
                                                    <td>
                                                        {product?.rate_per_unit.toFixed(
                                                            2
                                                        )}
                                                    </td>
                                                    <td>{product?.quantity}</td>
                                                    <td>
                                                        {product?.total_amount.toFixed(
                                                            2
                                                        )}
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    )}
                            </tbody>
                        </table>

                        <table
                            className="table productPrice"
                            cellPadding={2}
                            cellSpacing={2}>
                            <thead>
                                <tr>
                                    <td colSpan="2">Details</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Amount (BDT)</td>
                                    <td align="right">
                                        {data?.product_details &&
                                            getTotalAmount().toFixed(2)}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Return Loss (BDT)</td>
                                    {EXPIRED_PRODUCT_STATUS.RETURN ===
                                        data?.status && (
                                        <td align="right">
                                            <div className="formInline dFlex flexNowrap aiCenter rowReverse">
                                                <div className="formGroup">
                                                    <input
                                                        className="formControl inline formsm"
                                                        type="text"
                                                        name="flexRadioDefault"
                                                        value={returnLoss}
                                                        onChange={e =>
                                                            setReturnLoss(
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder="Enter Return Loss"
                                                    />
                                                    {isFieldErrorVisible &&
                                                        !returnLoss && (
                                                            <div class="invalidFeedback">
                                                                Please enter a
                                                                return Loss
                                                                Amount.
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                        </td>
                                    )}

                                    {EXPIRED_PRODUCT_STATUS.RETURNED ===
                                        data?.status && (
                                        <td align="right">
                                            {data.return_loss || 0}
                                        </td>
                                    )}
                                </tr>
                                {EXPIRED_PRODUCT_STATUS.RETURN ===
                                    data?.status && (
                                    <tr>
                                        <td>Choose Return Type</td>

                                        <td align="right">
                                            <div className="formInline dFlex flexNowrap aiCenter rowReverse">
                                                <div className="formGroup">
                                                    <select
                                                        className="formControl inline formsm"
                                                        value={expiryType}
                                                        onChange={e =>
                                                            setExpiryType(
                                                                e.target.value
                                                            )
                                                        }>
                                                        <option value="">
                                                            Select Return Type
                                                        </option>
                                                        {/* <option value="ONLINE">
                                                            Online
                                                        </option> */}
                                                        <option value="OFFLINE">
                                                            Offline
                                                        </option>
                                                    </select>
                                                    {isFieldErrorVisible &&
                                                        !expiryType && (
                                                            <div class="invalidFeedback">
                                                                Please enter a
                                                                expiry type.
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <td className="strong">
                                        Receivable Amt (BDT)
                                    </td>
                                    <td className="strong" align="right">
                                        {data?.amount_receivable?.toFixed(2) ||
                                            parseFloat(
                                                amountReceivable
                                            )?.toFixed(2)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {EXPIRED_PRODUCT_STATUS.RETURNED === data?.status &&
                            data?.notes && (
                                <div className="disclaimerNotes">
                                    <div className="disclaimerNotesHeading">
                                        Notes
                                    </div>
                                    <div className="disclaimerNotesDesc">
                                        {data.notes}
                                    </div>
                                </div>
                            )}
                        {EXPIRED_PRODUCT_STATUS.RETURN === data?.status && (
                            <div className="formGroup formFloating">
                                <textarea
                                    rows="2"
                                    type="text"
                                    className="formControl"
                                    id="notes"
                                    value={notes}
                                    onChange={e => setNotes(e.target.value)}
                                    required></textarea>
                                <label for="notes">Notes</label>
                                {false && !notes && (
                                    <div class="invalidFeedback">
                                        Please enter notes.
                                    </div>
                                )}
                            </div>
                        )}
                        {data.payment_mode && (
                            <div className="disclaimerNotes">
                                <div className="disclaimerNotesHeading">
                                    Payment Mode
                                </div>
                                <div className="disclaimerNotesDesc">
                                    {PAYMENT_MODE_OPTIONS.find(
                                        item => item.name === data.payment_mode
                                    )?.label || data?.payment_mode}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </SideDrawer>
    )
}

const mapStateToProps = state => {
    return {
        loginData: state.loginDataReducer?.loginData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // testAction: (payload) => dispatch(testAction(payload))
    }
}

ViewExpiredProducts.propTypes = {
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewExpiredProducts)
