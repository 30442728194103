import { loginAPI } from '../adapter/adapter'
import { LOGIN_CONST } from '../common/constant/page-constant'
import { setCookieCustom } from '../common/utils/storageUtils'

export function loginHelper(data) {
    let reqData = {
        mobile_no: data.phoneNumber,
        password: data.password
    }

    let promise = new Promise(function (resolve, reject) {
        loginAPI(reqData)
            .then(res => {
                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data && res?.data?.data) {
                    let accessToken = res.data.data.access_token
                    let refreshToken = res.data.data.refresh_token

                    if (accessToken && refreshToken) {
                        setCookieCustom(LOGIN_CONST.ACCESS_TOKEN, accessToken)
                        setCookieCustom(LOGIN_CONST.REFRESH_TOKEN, refreshToken)
                    }
                    resolve(res.data.data)
                } else {
                    reject('Login Failure')
                }
            })
            .catch(err => {
                reject('Login Failure')
            })
    })

    return promise
}
