import React from 'react'

const DamageAndLossIcon = () => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="fluent:box-24-regular">
            <path id="Vector" d="M12.3563 2.93189C13.4105 2.50447 14.5898 2.50447 15.644 2.93189L24.3917 6.47856C24.7685 6.63118 25.0911 6.89282 25.3183 7.22995C25.5455 7.56708 25.6668 7.96436 25.6668 8.37089V19.6327C25.6666 20.0391 25.5451 20.4361 25.318 20.773C25.0908 21.1099 24.7683 21.3713 24.3917 21.5239L15.644 25.0706C14.5898 25.498 13.4105 25.498 12.3563 25.0706L3.60866 21.5239C3.23187 21.3713 2.90921 21.1096 2.68204 20.7725C2.45486 20.4354 2.33349 20.0381 2.3335 19.6316V8.37206C2.33349 7.96553 2.45486 7.56825 2.68204 7.23112C2.90921 6.89399 3.23187 6.63235 3.60866 6.47973L12.3563 2.93189ZM14.986 4.55356C14.3537 4.29736 13.6466 4.29736 13.0143 4.55356L10.7802 5.46006L19.5617 8.87489L22.6755 7.67323L14.9848 4.55473L14.986 4.55356ZM17.1397 9.80939L8.41766 6.41673L5.35516 7.65806L14.0013 11.0204L17.1397 9.80939ZM4.0835 19.6327C4.08365 19.6906 4.10104 19.7472 4.13345 19.7952C4.16586 19.8432 4.21183 19.8805 4.2655 19.9022L13.0143 23.4489C13.0505 23.4641 13.0878 23.4781 13.1252 23.4909V12.5581L4.0835 9.04173V19.6327ZM14.986 23.4489L23.7348 19.9022C23.7887 19.8804 23.8348 19.8429 23.8672 19.7947C23.8996 19.7465 23.9169 19.6897 23.9168 19.6316V9.06973L14.8752 12.5604V23.4921C14.9124 23.4789 14.9494 23.4638 14.986 23.4489Z" fill="#EF8658" />
            <path id="Vector 3" d="M9.61245 19.4289L9.37354 19.7313C9.16829 19.9911 8.75539 19.7757 8.85091 19.4588L9.41467 17.5877C9.4892 17.3404 9.82069 17.2966 9.95686 17.5162L10.1372 17.8068C10.2841 18.0438 10.6466 17.9696 10.6887 17.6939L10.7295 17.426C10.7545 17.2622 10.9075 17.1497 11.0713 17.1747L11.5765 17.2517C11.7653 17.2805 11.9325 17.1281 11.9213 16.9375L11.812 15.0837C11.7974 14.8362 12.0722 14.6788 12.2783 14.8167L14.2763 16.1533C14.3986 16.2351 14.5622 16.2162 14.6625 16.1086L17.0794 13.5166C17.2143 13.372 17.4496 13.3951 17.5538 13.5631L19.1221 16.0915C19.2037 16.2231 19.372 16.271 19.5106 16.202L20.9343 15.4943C21.2362 15.3442 21.5179 15.7295 21.2833 15.9717L19.2206 18.1006C19.0847 18.2408 18.8531 18.2159 18.7502 18.0499L17.8227 16.5547C17.6937 16.3467 17.3835 16.3723 17.2904 16.5986L15.7207 20.4111C15.6609 20.5564 15.4991 20.6309 15.3498 20.5819L11.8542 19.4344C11.7226 19.3912 11.5785 19.4437 11.5056 19.5615L11.0919 20.2291C10.9746 20.4184 10.6993 20.4185 10.5819 20.2293L10.1028 19.4568C9.99317 19.2801 9.74137 19.2658 9.61245 19.4289Z" fill="#EF8658" />
        </g>
    </svg>
)

export default DamageAndLossIcon
