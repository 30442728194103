import React from 'react'

const PrimaryInfoIcon = () => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Info">
            <path id="Vector" d="M14.0002 2.33105C20.4448 2.33105 25.6692 7.55539 25.6692 14.0001C25.6692 20.4436 20.4448 25.6667 14.0002 25.6667C7.5555 25.6679 2.3335 20.4436 2.3335 14.0001C2.33233 7.55539 7.5555 2.33105 14.0002 2.33105ZM14.0002 4.08105C12.6886 4.06683 11.3873 4.31287 10.1714 4.80494C8.95558 5.29701 7.84942 6.02533 6.91693 6.94775C5.98445 7.87017 5.24418 8.96838 4.73895 10.1788C4.23373 11.3892 3.97359 12.6878 3.97359 13.9995C3.97359 15.3111 4.23373 16.6097 4.73895 17.8201C5.24418 19.0306 5.98445 20.1288 6.91693 21.0512C7.84942 21.9736 8.95558 22.7019 10.1714 23.194C11.3873 23.6861 12.6886 23.9321 14.0002 23.9179C16.6121 23.8896 19.1074 22.8321 20.9443 20.9751C22.7813 19.1181 23.8116 16.6115 23.8116 13.9995C23.8116 11.3874 22.7813 8.8808 20.9443 7.02382C19.1074 5.16683 16.6121 4.10938 14.0002 4.08105ZM13.9955 12.2477C14.2071 12.2474 14.4117 12.3239 14.5713 12.4629C14.7309 12.6019 14.8347 12.794 14.8635 13.0037L14.8717 13.1227L14.8763 19.5417C14.8786 19.765 14.7954 19.9807 14.6438 20.1446C14.4923 20.3086 14.2837 20.4084 14.061 20.4236C13.8382 20.4388 13.6181 20.3683 13.4456 20.2264C13.2731 20.0846 13.1614 19.8822 13.1333 19.6607L13.1263 19.5429L13.1217 13.1239C13.1217 12.8918 13.2139 12.6693 13.3779 12.5052C13.542 12.3411 13.7646 12.2489 13.9967 12.2489L13.9955 12.2477ZM14.0013 8.16789C14.1574 8.16295 14.3129 8.18942 14.4585 8.24574C14.6042 8.30206 14.737 8.38708 14.8492 8.49574C14.9613 8.6044 15.0505 8.73449 15.1114 8.87828C15.1723 9.02208 15.2037 9.17665 15.2037 9.3328C15.2037 9.48896 15.1723 9.64353 15.1114 9.78733C15.0505 9.93112 14.9613 10.0612 14.8492 10.1699C14.737 10.2785 14.6042 10.3635 14.4585 10.4199C14.3129 10.4762 14.1574 10.5027 14.0013 10.4977C13.6988 10.4881 13.4118 10.3612 13.2011 10.1438C12.9905 9.92638 12.8727 9.63553 12.8727 9.3328C12.8727 9.03008 12.9905 8.73923 13.2011 8.52181C13.4118 8.30439 13.6988 8.17746 14.0013 8.16789Z" fill="#EF8658" />
        </g>
    </svg>
)

export default PrimaryInfoIcon
