import {
    createAccountAPI,
    documentUploadAPI,
    forgotPasswordAPI,
    sendOTPAPI,
    sendUnAuthOTPAPI,
    validateOTPAPI,
    validateUnAuthOTPAPI
} from '../adapter/adapter'
import { LOGIN_CONST } from '../common/constant/page-constant'
import { setCookieCustom } from '../common/utils/storageUtils'

export function createAccountHelper(data) {
    let reqData = {
        pharmacy: {
            name: data.pharmacyName,
            category: data.category,
            lat: data.latitude,
            long: data.longitude,
            place_id: data.placeId,
            location: data.locationString,
            address_component: data.addressComponent
        },
        owner: {
            name: data.ownerName,
            mobile_no: data.ownerPhoneNumber,
            email: data.ownerEmail,
            password: data.password
        }
    }

    let promise = new Promise(function (resolve, reject) {
        createAccountAPI(reqData)
            .then(res => {
                console.log(res.data)

                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (
                    res?.data?.data &&
                    res?.data?.data?.is_account_created
                ) {
                    resolve()
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('Login Failure')
                }
            })
            .catch(err => {
                reject('Login Failure')
            })
    })

    return promise
}

export function sendOTPHelper(data) {
    let reqData = data

    let promise = new Promise(function (resolve, reject) {
        sendOTPAPI(reqData)
            .then(res => {
                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else {
                    resolve()
                }
            })
            .catch(err => {
                reject('Login Failure')
            })
    })

    return promise
}

export function sendUnAuthOTPHelper(mobileNumber) {
    let reqData = {
        mobile_no: mobileNumber
    }

    let promise = new Promise(function (resolve, reject) {
        sendUnAuthOTPAPI(reqData)
            .then(res => {
                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else {
                    resolve()
                }
            })
            .catch(err => {
                reject('Login Failure')
            })
    })

    return promise
}

export function validateOTPHelper(otp) {
    let reqData = { otp }

    let promise = new Promise(function (resolve, reject) {
        validateOTPAPI(reqData)
            .then(res => {
                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data) {
                    resolve(res.data.data)
                } else {
                    reject('Login Failure')
                }
            })
            .catch(err => {
                reject('Login Failure')
            })
    })

    return promise
}

export function validateUnAuthOTPHelper(mobileNumber, otp) {
    let reqData = { mobile_no: mobileNumber, otp: otp }

    let promise = new Promise(function (resolve, reject) {
        validateUnAuthOTPAPI(reqData)
            .then(res => {
                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data) {
                    resolve(res.data.data)
                } else {
                    reject('Login Failure')
                }
            })
            .catch(err => {
                reject('Login Failure')
            })
    })

    return promise
}

export function forgotPasswordHelper(txnId, mobileNumber, password) {
    let reqData = {
        mobile_no: mobileNumber,
        txn_id: txnId,
        password: password
    }

    let promise = new Promise(function (resolve, reject) {
        forgotPasswordAPI(reqData)
            .then(res => {
                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data) {
                    resolve(res.data.data)
                } else {
                    reject('Login Failure')
                }
            })
            .catch(err => {
                reject('Login Failure')
            })
    })

    return promise
}

export function documentUploadHelper(data) {
    let reqData = new FormData()
    reqData.append('trade_license_number', data.tradeLicenceFile)
    reqData.append('dgda_license_number', data.dgdaLicenceFile)
    reqData.append('bin_number', data.binFile)
    reqData.append('tin_number', data.tinFile)
    reqData.append('vat_number', data.vatFile)
    reqData.append('trade_license_number_input', data.tradeLicenceFile?.number)
    reqData.append('dgda_license_number_input', data.dgdaLicenceFile?.number)
    reqData.append('bin_number_input', data.binFile?.number)
    reqData.append('tin_number_input', data.tinFile?.number)
    reqData.append('vat_number_input', data.vatFile?.number)

    let promise = new Promise(function (resolve, reject) {
        documentUploadAPI(reqData)
            .then(res => {
                if (res?.data?.error) {
                    reject(res.data.error.message)
                } else if (res?.data?.data) {
                    resolve(res.data.data)
                } else if (res?.data?.error) {
                    reject(res?.data?.error?.message)
                } else {
                    reject('Document Upload Failure')
                }
            })
            .catch(err => {
                reject('Login Failure')
            })
    })

    return promise
}
