import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AddIcon from '../../assets/AddIcon'
import ArrowDown from '../../assets/ArrowDown'
import CheckPrimaryIcon from '../../assets/CheckPrimaryIcon'
import CloseIcon from '../../assets/CloseIcon'
import LocationIcon from '../../assets/LocationIcon'
import ProductReceivingBlack from '../../assets/ProductReceivingBlack'
import TrashIcon from '../../assets/TrashIcon'
import {
    HEADER_CONFIG,
    HEADER_CONST,
    WIZARD_STEPS
} from '../../common/constant/common-constant'
import {
    ADD_EXCHANGE_RETURN_FLOW,
    ADD_EXCHANGE_STAGES,
    ADD_RETURN_STAGES,
    BILL_DETAILS_FIELDS,
    EXCHANGE_RETURN_UI_NAME,
    PAGE_CONST,
    PAYMENT_DETAILS_FIELDS,
    PAYMENT_MODE_OPTIONS,
    PERMISSION_FEATURE,
    PERMISSION_SCREEN,
    PERMISSION_TYPE,
    PRODUCT_DETAIL_FIELDS,
    SALES_FLOW,
    SALES_TYPE_BACKEND_MAPPING
} from '../../common/constant/page-constant'
import { SocketContext } from '../../common/context/socket'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import {
    convertToTitleCase,
    formatDate,
    formatTime
} from '../../common/utils/appUtils'
import { redirect } from '../../common/utils/redirectionUtils'
import WizardSteps from '../../components/WizardSteps/WizardSteps'
import {
    addProductReceivingHelper,
    createExchangeReturnHelper,
    getAllExchangeReturnOrdersHelper,
    getExchangeReturnOrderByIdHelper,
    getExchangeReturnOrdersHelper,
    getProductDetailsByBarcodeHelper,
    getSalesOrderByInvoiceNumberHelper,
    getSalesOrderHelper
} from '../../helper/home-helper'
import { createExchangeReturnAlertAction } from '../../modules/actions/action'
import AcknowledgeScreen from '../Common/AcknowledgeScreen/AcknowledgeScreen'
import Card from '../Common/Card/Card'
import MobileHeader from '../Common/Header/MobileHeader'
import PopupCard from '../Common/PopupCard/PopupCard'
import SideDrawer from '../Common/SideDrawer/SideDrawer'
import AddProduct from '../ProductReceiving/AddProduct'
import Select from 'react-select'
import Product from '../Sales/Product'

import './ExchangeReturn.scss'
import ExchangeReturnProduct from './ExchangeReturnProduct'
import ViewExchangeReturn from './ViewExchangeReturn'

const ExchangeReturn = props => {
    const navigate = useNavigate()
    const isMobile = useCheckMobileScreen()

    const [viewDrawer, setViewDrawer] = useState(false)
    const [open, setOpen] = useState(false)
    const [addProductActive, setAddProductActive] = useState(false)
    const [isNextActive, setIsNextActive] = useState(false)
    const [isFieldErrorVisible, setIsFieldErrorVisible] = useState(false)
    const [serverError, setServerError] = useState(undefined)
    const [isViewProductActive, setViewProductActive] = useState(false)
    const [deleteProductPopup, setDeleteProductPopup] = useState(false)

    const [wizardState, setWizardState] = useState(undefined)
    const [addExchangeReturnState, setAddExchangeReturnState] =
        useState(undefined)
    const [createExchangeReturnAlert, setCreateExchangeReturnAlert] =
        useState(undefined)
    const [addedProductVisibleId, setAddedProductDetailsId] = useState({
        id: undefined
    })
    const [addExchangeReturnFlow, setAddExchangeReturnFlow] =
        useState(undefined)

    const [invoiceNumber, setInvoiceNumber] = useState()
    const [invoiceDetails, setInvoiceDetails] = useState({})
    const [oldProductDetailsArray, setOldProductDetailsArray] = useState([])
    const [newProductDetails, setNewProductDetails] = useState({})
    const [newProductDetailsArray, setNewProductDetailsArray] = useState([])
    const [paymentDetails, setPaymentDetails] = useState({})
    const [viewExchangeReturn, setViewExchangeReturn] = useState(false)
    const [oldProductAmount, setOldProductAmount] = useState(0)
    const [oldProductAmountBeforeDiscount, setOldProductAmountBeforeDiscount] =
        useState(0)
    const [newProductAmount, setNewProductAmount] = useState(0)
    const [newProductAmountAfterDiscount, setNewProductAmountAfterDiscount] =
        useState(0)
    const [returnAmount, setReturnAmount] = useState(0)
    const [exchangeReturnsOrders, setExchangeReturnOrders] = useState([])
    const [exchangeReturnDetail, setExchangeReturnDetail] = useState({})
    const [errMsg, setErrMsg] = useState('')
    const [loginData, setLoginData] = useState({})
    const [permissions, setPermissions] = useState({})

    const [headerOptions, setHeaderOptions] = useState({
        [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
        [HEADER_CONFIG.TITLE]: HEADER_CONST.EXCHANGE_RETURN,
        [HEADER_CONFIG.HANDLE_BACK_FUNC]: () =>
            redirect(navigate, PAGE_CONST.HOME)
    })

    useEffect(() => {
        let createExchangeReturnAlert = props.alert?.createExchangeReturnAlert
        setCreateExchangeReturnAlert(createExchangeReturnAlert)
    }, [props.alert?.createExchangeReturnAlert])

    useEffect(() => {
        getAllExchangeReturnOrders()
    }, [])

    useEffect(() => {
        resetDrawerData()
        if (!open && !viewExchangeReturn) {
            handleHeaderConfigUpdate(HEADER_CONST.EXCHANGE_RETURN, () =>
                redirect(navigate, PAGE_CONST.HOME)
            )
            return
        }

        handleWizardState(WIZARD_STEPS.STEP_1)
        setAddExchangeReturnState(ADD_EXCHANGE_STAGES.INVOICE_NUMBER)

        if (open) {
            handleHeaderConfigUpdate(HEADER_CONST.ADD_EXCHANGE_RETURN, () =>
                handleDrawerToggle()
            )
        } else if (viewExchangeReturn) {
            handleHeaderConfigUpdate(HEADER_CONST.VIEW_EXCHANGE_RETURN, () =>
                setViewExchangeReturn(!viewExchangeReturn)
            )
        }
    }, [open, viewExchangeReturn])

    useEffect(() => {
        getOldProductAmount()
    }, [oldProductDetailsArray])

    useEffect(() => {
        getNewProductAmount()
    }, [newProductDetailsArray])

    useEffect(() => {
        setLoginData(props.loginData)

        if (props.loginData.permissions) {
            console.log(
                'props.loginData.permissions: ',
                props.loginData.permissions
            )

            let permission = props.loginData.permissions.filter(
                permission =>
                    permission.screen === PERMISSION_SCREEN.MANAGE_STORE
            )[0]

            console.log('home screen permission: ', permission)
            if (permission) setPermissions(permission)
        }
    }, [props.loginData])

    useEffect(() => {
        setServerError(undefined)

        switch (addExchangeReturnState) {
            case ADD_EXCHANGE_STAGES.INVOICE_NUMBER:
                handleWizardState(WIZARD_STEPS.STEP_1)
                break
            case ADD_EXCHANGE_STAGES.EXCHANGE_PRODUCT_DETAILS:
                handleWizardState(WIZARD_STEPS.STEP_2)
                break
            case ADD_EXCHANGE_STAGES.NEW_PRODUCT_DETAILS:
                handleWizardState(WIZARD_STEPS.STEP_2)
                break
            case ADD_EXCHANGE_STAGES.PAYMENT_DETAILS:
                handleWizardState(WIZARD_STEPS.STEP_3)
                break
            case ADD_RETURN_STAGES.RETURN_PRODUCT_DETAILS:
                handleWizardState(WIZARD_STEPS.STEP_2)
                break
            case ADD_RETURN_STAGES.RETURN_PAYMENT_DETAILS:
                handleWizardState(WIZARD_STEPS.STEP_3)
                break
        }
    }, [addExchangeReturnState])

    useEffect(() => {
        if (addExchangeReturnFlow == ADD_EXCHANGE_RETURN_FLOW.RETURN_FLOW)
            return
        let totalAmount = getTotalAmount()

        if (
            totalAmount != paymentDetails?.[PAYMENT_DETAILS_FIELDS.TOTAL_AMOUNT]
        ) {
            handlePaymentDetailsUpdate(
                PAYMENT_DETAILS_FIELDS.TOTAL_AMOUNT,
                getTotalAmount()
            )
        }
    }, [
        paymentDetails?.[PAYMENT_DETAILS_FIELDS.AMOUNT],
        newProductAmount,
        paymentDetails?.[PAYMENT_DETAILS_FIELDS.VAT],
        paymentDetails?.[PAYMENT_DETAILS_FIELDS.DISCOUNT]
    ])

    const getAllExchangeReturnOrders = () => {
        getAllExchangeReturnOrdersHelper()
            .then(res => {
                setExchangeReturnOrders(res)
                console.log('res: ', res)
            })
            .catch(err => {
                console.log('err: ', err)
            })
    }

    const getExchangeReturnDetailById = id => {
        getExchangeReturnOrderByIdHelper(id)
            .then(res => {
                setExchangeReturnDetail(res)
                console.log('res: ', res)
            })
            .catch(err => {
                console.log('err: ', err)
            })
    }

    const handleHeaderConfigUpdate = (title, handleBackFunc) => {
        setHeaderOptions({
            [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
            [HEADER_CONFIG.TITLE]: title,
            [HEADER_CONFIG.HANDLE_BACK_FUNC]: () => handleBackFunc()
        })
    }

    const handleWizardState = state => {
        switch (state) {
            case WIZARD_STEPS.STEP_3:
                setWizardState({
                    [WIZARD_STEPS.STEP_1]: true,
                    [WIZARD_STEPS.STEP_2]: true,
                    [WIZARD_STEPS.STEP_3]: false
                })
                break
            case WIZARD_STEPS.STEP_2:
                setWizardState({
                    [WIZARD_STEPS.STEP_1]: true,
                    [WIZARD_STEPS.STEP_2]: false,
                    [WIZARD_STEPS.STEP_3]: false
                })
                break
            case WIZARD_STEPS.STEP_1:
                setWizardState({
                    [WIZARD_STEPS.STEP_1]: false,
                    [WIZARD_STEPS.STEP_2]: false,
                    [WIZARD_STEPS.STEP_3]: false
                })
                break
        }
    }

    const onWizardStepClick = step => {
        switch (step) {
            case WIZARD_STEPS.STEP_1:
                setAddExchangeReturnState(ADD_EXCHANGE_STAGES.INVOICE_NUMBER)
                break
            case WIZARD_STEPS.STEP_2:
                setAddExchangeReturnState(
                    ADD_EXCHANGE_STAGES.EXCHANGE_PRODUCT_DETAILS
                )
                break
            case WIZARD_STEPS.STEP_3:
                if (
                    addExchangeReturnFlow ===
                    ADD_EXCHANGE_RETURN_FLOW.EXCHANGE_FLOW
                ) {
                    setAddExchangeReturnState(
                        ADD_EXCHANGE_STAGES.PAYMENT_DETAILS
                    )
                } else {
                    setPaymentDetailsForReturn(invoiceDetails)
                    setAddExchangeReturnState(
                        ADD_RETURN_STAGES.RETURN_PAYMENT_DETAILS
                    )
                }
                break
        }
    }

    const getInvoiceDetails = async () => {
        try {
            let res = await getSalesOrderByInvoiceNumberHelper(invoiceNumber)
            console.log('getSalesOrderByInvoiceNumberHelper: ', res)
            setInvoiceDetails(res)
            if (!res.status) throw 'Invoice Number Not Found'
        } catch (err) {
            throw err
        }
    }

    const handleNextClick = async () => {
        const isValidState = checkValidation()
        if (!isValidState) {
            setIsFieldErrorVisible(true)
            return
        }

        setIsFieldErrorVisible(false)
        switch (addExchangeReturnState) {
            case ADD_EXCHANGE_STAGES.INVOICE_NUMBER:
                try {
                    await getInvoiceDetails()
                } catch (errMsg) {
                    setServerError(errMsg)
                    return
                }

                setAddExchangeReturnState(
                    ADD_EXCHANGE_STAGES.EXCHANGE_PRODUCT_DETAILS
                )
                break
            case ADD_EXCHANGE_STAGES.EXCHANGE_PRODUCT_DETAILS:
                if (
                    addExchangeReturnFlow ===
                    ADD_EXCHANGE_RETURN_FLOW.EXCHANGE_FLOW
                ) {
                    setAddExchangeReturnState(
                        ADD_EXCHANGE_STAGES.NEW_PRODUCT_DETAILS
                    )
                } else {
                    setPaymentDetailsForReturn(invoiceDetails)
                    setAddExchangeReturnState(
                        ADD_RETURN_STAGES.RETURN_PAYMENT_DETAILS
                    )
                }

                break
            case ADD_RETURN_STAGES.RETURN_PAYMENT_DETAILS:
                handleSubmit()
                break
            case ADD_EXCHANGE_STAGES.NEW_PRODUCT_DETAILS:
                setAddExchangeReturnState(ADD_EXCHANGE_STAGES.PAYMENT_DETAILS)
                break
            case ADD_EXCHANGE_STAGES.PAYMENT_DETAILS:
                handleSubmit()
                break
        }
    }

    const setPaymentDetailsForReturn = invoiceDetails => {
        let paymentDetails = {
            [PAYMENT_DETAILS_FIELDS.DISCOUNT]: invoiceDetails.discount,
            // [PAYMENT_DETAILS_FIELDS.TRADE_DISCOUNT]:
            //     invoiceDetails.trade_discount || 0,
            [PAYMENT_DETAILS_FIELDS.VAT]: invoiceDetails.vat,
            [PAYMENT_DETAILS_FIELDS.AMOUNT]: oldProductAmountBeforeDiscount,
            // [PAYMENT_DETAILS_FIELDS.DISCOUNT_AMOUNT]: parseFloat(
            //     (invoiceDetails.discount / 100) * oldProductAmount
            // ).toFixed(2),
            [PAYMENT_DETAILS_FIELDS.TOTAL_AMOUNT]: oldProductAmount
        }

        console.log(
            'Returnnn vat: ',
            invoiceDetails.vat,
            ' discount: ',
            invoiceDetails.discount,
            ' oldProductAmountBeforeDiscount: ',
            oldProductAmountBeforeDiscount,
            ' oldProductAmount: ',
            oldProductAmount
        )

        // let totalAmount = getTotalAmount(paymentDetails)
        // paymentDetails[PAYMENT_DETAILS_FIELDS.TOTAL_AMOUNT] = totalAmount

        // console.log(
        //     'totalAmount: ',
        //     totalAmount,
        //     ' oldProductAmount: ',
        //     oldProductAmount
        // )
        console.log('paymentDetails for return: ', paymentDetails)
        setPaymentDetails(paymentDetails)
    }

    const handleSubmit = () => {
        let data = {
            salesOrderId: invoiceDetails.id,
            addExchangeReturnFlow: addExchangeReturnFlow,
            customerDetails: invoiceDetails?.customer,
            oldProductDetailsArray: oldProductDetailsArray,
            newProductDetailsArray: [],
            paymentDetails: {
                notes: paymentDetails[PAYMENT_DETAILS_FIELDS.NOTES],
                new_amount: 0,
                old_amount: Number(oldProductAmount),
                discount: Number(
                    paymentDetails[PAYMENT_DETAILS_FIELDS.DISCOUNT]
                ),
                payment_mode:
                    paymentDetails[PAYMENT_DETAILS_FIELDS.PAYMENT_MODE],
                // trade_discount: Number(
                //     paymentDetails[PAYMENT_DETAILS_FIELDS.TRADE_DISCOUNT]
                // ),
                vat: Number(paymentDetails[PAYMENT_DETAILS_FIELDS.VAT]),
                total_amount: Number(
                    paymentDetails[PAYMENT_DETAILS_FIELDS.TOTAL_AMOUNT]
                )
            }
        }

        if (addExchangeReturnFlow === ADD_EXCHANGE_RETURN_FLOW.EXCHANGE_FLOW) {
            data.newProductDetailsArray = newProductDetailsArray
            data.paymentDetails.new_amount = newProductAmount
            data.paymentDetails.new_amount_total = newProductAmountAfterDiscount
        }

        console.log('data: ', data)
        // return
        createExchangeReturnHelper(data)
            .then(res => {
                console.log(res)

                props.createExchangeReturnAlertAction(true)

                setTimeout(() => {
                    props.createExchangeReturnAlertAction(false)
                }, 5000)
                resetDrawerData()
                handleDrawerToggle()
                getAllExchangeReturnOrders()
            })
            .catch(errMsg => {
                setServerError(errMsg)
            })
    }

    const checkValidation = () => {
        // return true;
        if (addExchangeReturnState === ADD_EXCHANGE_STAGES.INVOICE_NUMBER) {
            if (invoiceNumber && addExchangeReturnFlow) {
                return true
            }

            return false
        } else if (
            addExchangeReturnState ===
            ADD_EXCHANGE_STAGES.EXCHANGE_PRODUCT_DETAILS
        ) {
            if (oldProductDetailsArray && oldProductDetailsArray.length > 0) {
                let allValid = oldProductDetailsArray.every(product => {
                    if (
                        parseInt(product[PRODUCT_DETAIL_FIELDS.NEW_QUANTITY]) <=
                        parseInt(product.quantity)
                    )
                        return true
                    else
                        setServerError(
                            'New Quantity greater than available quantity !!'
                        )
                })

                if (allValid) return true

                return false
            } else {
                return false
            }
        } else if (
            addExchangeReturnState === ADD_EXCHANGE_STAGES.NEW_PRODUCT_DETAILS
        ) {
            // if (paymentDetails) {
            //     if (paymentDetails[PAYMENT_DETAILS_FIELDS.AMOUNT]
            //         && paymentDetails[PAYMENT_DETAILS_FIELDS.DISCOUNT]
            //         && paymentDetails[PAYMENT_DETAILS_FIELDS.TRADE_DISCOUNT]
            //         && paymentDetails[PAYMENT_DETAILS_FIELDS.VAT]
            //         && paymentDetails[PAYMENT_DETAILS_FIELDS.TOTAL_AMOUNT]
            //         && paymentDetails[PAYMENT_DETAILS_FIELDS.RECEIVED_BY]
            //     ) {
            //         return true;
            //     }
            // }

            return true
        } else if (
            [
                ADD_EXCHANGE_STAGES.PAYMENT_DETAILS,
                ADD_RETURN_STAGES.RETURN_PAYMENT_DETAILS
            ].includes(addExchangeReturnState)
        ) {
            // if (paymentDetails) {
            //     if (paymentDetails[PAYMENT_DETAILS_FIELDS.AMOUNT]
            //         && paymentDetails[PAYMENT_DETAILS_FIELDS.DISCOUNT]
            //         && paymentDetails[PAYMENT_DETAILS_FIELDS.TRADE_DISCOUNT]
            //         && paymentDetails[PAYMENT_DETAILS_FIELDS.VAT]
            //         && paymentDetails[PAYMENT_DETAILS_FIELDS.TOTAL_AMOUNT]
            //         && paymentDetails[PAYMENT_DETAILS_FIELDS.RECEIVED_BY]
            //     ) {
            //         return true;
            //     }
            // }
            if (paymentDetails) {
                if (
                    paymentDetails[PAYMENT_DETAILS_FIELDS.PAYMENT_MODE] ===
                    'CASH_CARD'
                        ? parseFloat(
                              paymentDetails[
                                  PAYMENT_DETAILS_FIELDS.AMOUNT_CARD
                              ] || 0
                          ) +
                              parseFloat(
                                  paymentDetails[
                                      PAYMENT_DETAILS_FIELDS.AMOUNT_CASH
                                  ] || 0
                              ) ===
                          paymentDetails[PAYMENT_DETAILS_FIELDS.TOTAL_AMOUNT]
                        : true
                ) {
                    return true
                }
            }

            return false
        }
    }

    const checkAddProductValidation = () => {
        if (newProductDetails) {
            if (
                newProductDetails[PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE] &&
                // && newProductDetails[PRODUCT_DETAIL_FIELDS.PRODUCT_CATEGORY]
                newProductDetails[PRODUCT_DETAIL_FIELDS.PRODUCT_NAME] &&
                // && newProductDetails[PRODUCT_DETAIL_FIELDS.MEDICAL_CATEGORY]
                // && newProductDetails[PRODUCT_DETAIL_FIELDS.BATCH_NUMBER]
                // && newProductDetails[PRODUCT_DETAIL_FIELDS.EXPIRY_DATE]
                newProductDetails[PRODUCT_DETAIL_FIELDS.QUANTITY] &&
                newProductDetails[PRODUCT_DETAIL_FIELDS.DOSE]
                // && newProductDetails[PRODUCT_DETAIL_FIELDS.BONUS_QUANITY]
            )
                return true
        }

        return false
    }

    const handleAddProductClick = () => {
        const isValidState = checkAddProductValidation()
        if (!isValidState) {
            setIsFieldErrorVisible(true)
            return
        }

        let pdArray = [...newProductDetailsArray] || []
        pdArray.push(newProductDetails)
        setNewProductDetailsArray(pdArray)
        setNewProductDetails({})
        setAddProductActive(false)
    }

    const handleRemoveProductClick = product => {
        let pdArray = [...newProductDetailsArray]

        const objWithIdIndex = pdArray.findIndex(
            obj => obj.product_id === product.product_id
        )

        if (objWithIdIndex > -1) {
            pdArray.splice(objWithIdIndex, 1)
        }

        setNewProductDetailsArray(pdArray)
        setDeleteProductPopup(false)
    }

    const handleProductDetailsUpdate = (id, key, value) => {
        setNewProductDetails(productDetails => {
            return {
                ...productDetails,
                [key]: value
            }
        })
    }

    const handlePaymentDetailsUpdate = (key, value) => {
        setPaymentDetails(prevDetails => {
            let updatedDetails = { ...prevDetails }

            // Update the payment details based on the key and value
            if (key === PAYMENT_DETAILS_FIELDS.DISCOUNT_AMOUNT) {
                let discountPercentage = parseFloat(
                    (value / newProductAmount) * 100
                ).toFixed(2)
                updatedDetails = {
                    ...updatedDetails,
                    [PAYMENT_DETAILS_FIELDS.DISCOUNT]:
                        parseFloat(discountPercentage)
                }
            } else if (key === PAYMENT_DETAILS_FIELDS.DISCOUNT) {
                let discountAmount = parseFloat(
                    (value / 100) * newProductAmount
                ).toFixed(2)
                updatedDetails = {
                    ...updatedDetails,
                    [PAYMENT_DETAILS_FIELDS.DISCOUNT_AMOUNT]: discountAmount
                }
            }

            // Finally, update the key that was passed in
            updatedDetails[key] = value
            console.log('Updated payment details:', updatedDetails)
            return updatedDetails
        })

        // setPaymentDetails({
        //     ...paymentDetails,
        //     [key]: value
        // })
    }

    const handleExistingProductUpdate = (id = undefined, key, value) => {
        let pDetails = newProductDetailsArray || []

        let productIndex = pDetails.findIndex(obj => obj.product_id == id)
        if (productIndex > -1) {
            pDetails[productIndex][key] = value
        } else {
            let newProduct = {
                id: Math.floor(Math.random() * 100),
                [key]: value
            }

            pDetails.push(newProduct)
        }

        setNewProductDetailsArray([...pDetails])
    }

    const handleOldProducts = (checked, product) => {
        let opArray = [...oldProductDetailsArray]
        let index = opArray.findIndex(oldProducts => {
            return product && product.id && oldProducts.id === product.id
        })

        if (checked) {
            if (index < 0) opArray.push(product)
        } else {
            opArray.splice(index, 1)
        }

        setOldProductDetailsArray(opArray)
    }

    const handleOldProductsQuantity = (newQuantity, product) => {
        let opArray = [...oldProductDetailsArray]
        let index = opArray.findIndex(oldProducts => {
            return product && product.id && oldProducts.id === product.id
        })

        if (index > -1)
            opArray[index][PRODUCT_DETAIL_FIELDS.NEW_QUANTITY] = newQuantity

        setOldProductDetailsArray(opArray)
    }

    const getProductDetailsByBarcode = bCode => {
        setServerError('')
        let barCode =
            bCode || newProductDetails?.[PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE]
        if (!barCode) {
            return
        }
        getProductDetailsByBarcodeHelper(barCode)
            .then(res => {
                if (res) {
                    let data = {
                        ...newProductDetails,
                        ...res
                    }
                    setNewProductDetails(data)
                }
            })
            .catch(err => {
                setServerError(err)
                setNewProductDetails({
                    [PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE]:
                        bCode ||
                        newProductDetails?.[
                            PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE
                        ]
                })
                // console.log("")
            })
    }

    const getNewProductAmount = () => {
        let npdArray = newProductDetailsArray

        let amount = 0

        npdArray.map(productDetail => {
            amount +=
                parseFloat(
                    productDetail?.[
                        PRODUCT_DETAIL_FIELDS.SELECTED_BATCH_DETAILS
                    ]?.[PRODUCT_DETAIL_FIELDS.RATE_PER_UNIT]
                ).toFixed(2) *
                parseFloat(
                    productDetail[PRODUCT_DETAIL_FIELDS.QUANTITY]
                ).toFixed(2)
        })

        setNewProductAmount(amount)
    }

    const getOldProductAmount = () => {
        let opdArray = oldProductDetailsArray

        let amount = 0
        let amountBeforeDiscount = 0

        opdArray.map(productDetail => {
            amount +=
                parseFloat(productDetail[PRODUCT_DETAIL_FIELDS.FINAL_PRICE]) *
                parseFloat(productDetail[PRODUCT_DETAIL_FIELDS.NEW_QUANTITY])

            amountBeforeDiscount +=
                parseFloat(productDetail[PRODUCT_DETAIL_FIELDS.RATE_PER_UNIT]) *
                parseFloat(productDetail[PRODUCT_DETAIL_FIELDS.NEW_QUANTITY])
        })

        setOldProductAmount(parseFloat(amount).toFixed(2))
        setOldProductAmountBeforeDiscount(
            parseFloat(amountBeforeDiscount).toFixed(2)
        )
    }

    // const getReturnAmount = () => {

    //     let opAmount = oldProductAmount;

    //     let newProductAmount =
    //         oldProductAmount - paymentDetails[PAYMENT_DETAILS_FIELDS.DISCOUNT]

    //     const getOldProductAmount = () => {
    //         let opdArray = oldProductDetailsArray;

    //         let amount = 0;

    //         opdArray.map((productDetail) => {

    //             amount +=
    //                 parseFloat(productDetail[PRODUCT_DETAIL_FIELDS.RATE_PER_UNIT])
    //                 *(parseFloat(productDetail[PRODUCT_DETAIL_FIELDS.NEW_QUANTITY]));
    //         })

    //         setOldProductAmount(amount);
    //     }
    // }

    const getDiscountedAmount = () => {
        let initialAmount = newProductAmount
            ? newProductAmount
            : paymentDetails?.[PAYMENT_DETAILS_FIELDS.AMOUNT] || 0

        let discountPercentage =
            paymentDetails?.[PAYMENT_DETAILS_FIELDS.DISCOUNT] || 0

        return parseFloat((discountPercentage / 100) * initialAmount).toFixed(2)
    }

    const getTradeDiscountedAmount = () => {
        let initialAmount = newProductAmount
            ? newProductAmount
            : paymentDetails?.[PAYMENT_DETAILS_FIELDS.AMOUNT] || 0

        let tradeDiscountPercentage =
            paymentDetails?.[PAYMENT_DETAILS_FIELDS.TRADE_DISCOUNT] || 0

        return (tradeDiscountPercentage / 100) * initialAmount
    }

    const getVatAmount = () => {
        let initialAmount =
            addExchangeReturnFlow === ADD_EXCHANGE_RETURN_FLOW.EXCHANGE_FLOW
                ? newProductAmount
                : paymentDetails?.[PAYMENT_DETAILS_FIELDS.AMOUNT] || 0
        let discount =
            paymentDetails?.[PAYMENT_DETAILS_FIELDS.DISCOUNT_AMOUNT] ||
            (paymentDetails?.[PAYMENT_DETAILS_FIELDS.DISCOUNT] *
                initialAmount) /
                100 ||
            0
        // let tradeDiscount = getTradeDiscountedAmount() || 0

        let remainingAmount = initialAmount - discount

        let vatPercentage = paymentDetails?.[PAYMENT_DETAILS_FIELDS.VAT] || 0

        return parseFloat((vatPercentage / 100) * remainingAmount).toFixed(2)
    }

    const getTotalAmount = data => {
        if (!data) data = paymentDetails

        let remainingAmount
        let discountedAmount =
            parseFloat(data?.[PAYMENT_DETAILS_FIELDS.DISCOUNT_AMOUNT]) ||
            getDiscountedAmount()
        let vat = parseFloat(getVatAmount()) || 0

        // let initialAmount = newProductAmount
        //     ? newProductAmount - oldProductAmount
        //     : oldProductAmount
        remainingAmount =
            newProductAmount - discountedAmount + vat - oldProductAmount

        setNewProductAmountAfterDiscount(
            newProductAmount - discountedAmount + vat
        )
        console.log(
            'Total Amount - newProductAmount',
            newProductAmount,
            ' ,oldProductAmount ',
            oldProductAmount,
            'vat: ',
            vat,
            ' discountedAmount: ',
            discountedAmount,
            'remainingAmount: ',
            remainingAmount
        )

        return parseFloat(remainingAmount).toFixed(2)
    }

    const handleExchangeDetailsCardClick = exchangeReturnOrder => {
        if (
            exchangeReturnOrder.reason_type ===
                ADD_EXCHANGE_RETURN_FLOW.EXCHANGE_FLOW &&
            exchangeReturnOrder.type === SALES_FLOW.ONLINE
        ) {
            // check status and complete online exchange flow
        } else {
            getExchangeReturnDetailById(exchangeReturnOrder.id)
            setViewExchangeReturn(true)
        }
    }

    const handleDrawerToggle = () => {
        setViewDrawer(!viewDrawer)
        setOpen(!open)
    }

    const resetDrawerData = () => {
        setOldProductDetailsArray([])
        setNewProductDetails({})
        setNewProductDetailsArray([])
        // setAddExchangeReturnState(undefined)
        setInvoiceDetails({})
        setOldProductAmount(0)
        setNewProductAmount(0)
        setPaymentDetails({})
    }

    return (
        <>
            {isMobile && <MobileHeader headerOptions={headerOptions} />}
            {createExchangeReturnAlert && (
                <AcknowledgeScreen
                    icon={<CheckPrimaryIcon />}
                    alertType="success"
                    alertMessage={`New ${EXCHANGE_RETURN_UI_NAME[addExchangeReturnFlow]} added Successfully!`}
                    main={`New ${EXCHANGE_RETURN_UI_NAME[addExchangeReturnFlow]} added Successfully!`}
                    sub=""
                />
            )}
            <div className="pageBodyRightPanel ExchanegReturn">
                {isMobile && (
                    <div className="addProductBtnMobile dFlex aiCenter mwebVisible fixed">
                        <button
                            className="animated zoomIn btn btnPrimary"
                            onClick={handleDrawerToggle}>
                            <AddIcon />
                        </button>
                    </div>
                )}
                {!isMobile && (
                    <div className="pageBodyRightPanelHeader dFlex jcSpaceBetween aiCenter">
                        <div className="pageBodyRightPanelHeading">
                            Exchange/Return
                        </div>
                        <div className="addProductBtn dFlex aiCenter">
                            {permissions?.features?.[
                                PERMISSION_FEATURE.EXCHANGE_RETURN
                            ] === PERMISSION_TYPE.MANAGE && (
                                <button
                                    className="btn btnPrimary"
                                    onClick={handleDrawerToggle}>
                                    ADD
                                </button>
                            )}
                            <div
                                className="pointer ml-4"
                                onClick={() => props.onClose()}>
                                <CloseIcon />
                            </div>
                        </div>
                    </div>
                )}
                <div className="productLlistWrap dFlex">
                    {exchangeReturnsOrders?.map(exchangeReturnOrder => {
                        return (
                            <div className="cardWrap mt-5">
                                <Card
                                    cardCrown={exchangeReturnOrder.reason_type}
                                    online
                                    productDesc
                                    nowrap
                                    exchangeItem={
                                        exchangeReturnOrder.old_invoice_number
                                    }
                                    headerSup={`${
                                        SALES_TYPE_BACKEND_MAPPING[
                                            exchangeReturnOrder.type
                                        ]
                                    } | ${formatDate(
                                        exchangeReturnOrder.created_at
                                    )}`}
                                    headerMain={
                                        exchangeReturnOrder.invoice_number
                                    }
                                    cardRight={`BDT ${exchangeReturnOrder.total_amount}`}
                                    sales={`Customer: ${exchangeReturnOrder.customer_name}`}
                                    handleClick={() =>
                                        handleExchangeDetailsCardClick(
                                            exchangeReturnOrder
                                        )
                                    }
                                />
                            </div>
                        )
                    })}
                </div>
                {open && (
                    <SideDrawer
                        handleDrawerToggle={handleDrawerToggle}
                        open={open}
                        drawerButton={true}
                        drawerHeading="New Exchange/Return"
                        isNextActive={isNextActive}
                        buttonText={
                            addProductActive &&
                            addExchangeReturnState ===
                                ADD_EXCHANGE_STAGES.NEW_PRODUCT_DETAILS
                                ? 'Save'
                                : 'Next'
                        }
                        handleClick={
                            addProductActive &&
                            addExchangeReturnState ===
                                ADD_EXCHANGE_STAGES.NEW_PRODUCT_DETAILS
                                ? () => handleAddProductClick()
                                : () => handleNextClick()
                        }>
                        <WizardSteps
                            wizardState={wizardState}
                            onStepClick={step => onWizardStepClick(step)}
                        />
                        <div className="wizardFieldset">
                            {serverError && (
                                <div class="invalidFeedback mb-4">
                                    {serverError}
                                </div>
                            )}
                            {addExchangeReturnState ===
                                ADD_EXCHANGE_STAGES.INVOICE_NUMBER && (
                                <form onSubmit={e => e.preventDefault()}>
                                    <div className="formSection dFlex mb-5">
                                        <div className="formCheck">
                                            <input
                                                className="formCheckInput"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="Exchange"
                                                checked={
                                                    addExchangeReturnFlow ===
                                                    ADD_EXCHANGE_RETURN_FLOW.EXCHANGE_FLOW
                                                }
                                                onChange={e => {
                                                    resetDrawerData()
                                                    setAddExchangeReturnFlow(
                                                        ADD_EXCHANGE_RETURN_FLOW.EXCHANGE_FLOW
                                                    )
                                                }}
                                            />
                                            <label
                                                className="formCheckLabel"
                                                for="Exchange">
                                                Exchange
                                            </label>
                                        </div>
                                        <div className="formCheck ml-5">
                                            <input
                                                className="formCheckInput"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="Return"
                                                checked={
                                                    addExchangeReturnFlow ===
                                                    ADD_EXCHANGE_RETURN_FLOW.RETURN_FLOW
                                                }
                                                onChange={e => {
                                                    resetDrawerData()
                                                    setAddExchangeReturnFlow(
                                                        ADD_EXCHANGE_RETURN_FLOW.RETURN_FLOW
                                                    )
                                                }}
                                            />
                                            <label
                                                className="formCheckLabel"
                                                for="Return">
                                                Return
                                            </label>
                                        </div>
                                        {isFieldErrorVisible &&
                                            !addExchangeReturnFlow && (
                                                <div className="invalidFeedback">
                                                    Please select
                                                    Exchange/Return.
                                                </div>
                                            )}
                                    </div>
                                    <div className="formGroup formFloating fgrow1">
                                        <input
                                            type="text"
                                            className="formControl"
                                            id="invoice"
                                            value={invoiceNumber}
                                            placeholder="Invoice Number"
                                            onChange={e =>
                                                setInvoiceNumber(e.target.value)
                                            }
                                            required
                                        />
                                        <label for="invoice">
                                            Invoice Number
                                        </label>
                                        {isFieldErrorVisible &&
                                            !invoiceNumber && (
                                                <div className="invalidFeedback">
                                                    Please select a valid
                                                    Invoice Number.
                                                </div>
                                            )}
                                    </div>
                                </form>
                            )}
                            {addExchangeReturnState ===
                                ADD_EXCHANGE_STAGES.EXCHANGE_PRODUCT_DETAILS && (
                                <form onSubmit={e => e.preventDefault()}>
                                    {/* <div className="formGroup formFloating fgrow1">
                                    <input
                                        type="text"
                                        className="formControl"
                                        id="invoice"
                                        value={billDetails?.[BILL_DETAILS_FIELDS.INVOICE_NUMBER]}
                                        placeholder="Invoice Number"
                                        onChange={(e) => handleBillDetailUpdate(BILL_DETAILS_FIELDS.INVOICE_NUMBER, e.target.value)}
                                        required />
                                    <label for="invoice">Invoice Number</label>
                                    {isFieldErrorVisible && !billDetails?.[BILL_DETAILS_FIELDS.INVOICE_NUMBER] && <div className="invalidFeedback">
                                        Please select a valid Invoice Number.
                                    </div>}
                                </div> */}
                                    {oldProductAmount > 0 && (
                                        <div className="consolidateStrip dFlex jcSpaceBetween aiCenter">
                                            <div className="consolidateDataIcon dFlex">
                                                <ProductReceivingBlack />
                                            </div>
                                            <div className="consolidateDataText">
                                                {
                                                    EXCHANGE_RETURN_UI_NAME[
                                                        addExchangeReturnFlow
                                                    ]
                                                }{' '}
                                                Amount <small>BDT</small>
                                            </div>
                                            <div className="consolidateData">
                                                {oldProductAmount}
                                            </div>
                                        </div>
                                    )}
                                    <div className="formSection dFlex mb-5">
                                        <div className="formCheck">
                                            <input
                                                className="formCheckInput"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="Exchange"
                                                checked={
                                                    addExchangeReturnFlow ===
                                                    ADD_EXCHANGE_RETURN_FLOW.EXCHANGE_FLOW
                                                }
                                                readOnly={true}
                                            />
                                            <label
                                                className="formCheckLabel"
                                                for="Exchange">
                                                Exchange
                                            </label>
                                        </div>
                                        <div className="formCheck ml-5">
                                            <input
                                                className="formCheckInput"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="Return"
                                                checked={
                                                    addExchangeReturnFlow ===
                                                    ADD_EXCHANGE_RETURN_FLOW.RETURN_FLOW
                                                }
                                                readOnly={true}
                                            />
                                            <label
                                                className="formCheckLabel"
                                                for="Return">
                                                Return
                                            </label>
                                        </div>
                                        {/* {props.isFieldErrorVisible &&
                                        !props.product?.[
                                            PRODUCT_DETAIL_FIELDS
                                                .PRODUCT_CATEGORY
                                        ] && (
                                            <div className="invalidFeedback">
                                                Please select Product Category.
                                            </div>
                                        )} */}
                                    </div>
                                    <div className="formGroup formFloating fgrow1">
                                        <input
                                            type="text"
                                            className="formControl"
                                            id="invoice"
                                            value={invoiceNumber}
                                            placeholder="Old Invoice Number"
                                            readOnly={true}
                                            required
                                        />
                                        <label for="invoice">
                                            Old Invoice Number
                                        </label>
                                        {/* {isFieldErrorVisible && !billDetails?.[BILL_DETAILS_FIELDS.INVOICE_NUMBER] && <div className="invalidFeedback">
                                        Please select a valid Old Invoice Number.
                                    </div>} */}
                                    </div>
                                    <div className="exchangeReturnDetails">
                                        <div className="dFlex">
                                            <div className="exchangeReturnName">
                                                {invoiceDetails?.customer?.name}
                                            </div>
                                            <div className="exchangeReturnContact">
                                                {
                                                    invoiceDetails?.customer
                                                        ?.mobile_no
                                                }
                                            </div>
                                        </div>
                                        <div className="exchangeReturnLocation dFlex flexNowrap aiCenter">
                                            <LocationIcon />
                                            {
                                                invoiceDetails?.customer
                                                    ?.billing_address
                                            }
                                        </div>
                                    </div>
                                    <table className="table exchange">
                                        <thead>
                                            <tr>
                                                <td></td>
                                                <td
                                                    width={`${
                                                        isMobile ? '50%' : '70%'
                                                    }`}>
                                                    Medicine
                                                </td>
                                                <td
                                                    width={`${
                                                        isMobile ? '70%' : '50%'
                                                    }`}>
                                                    Qty
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {invoiceDetails?.product_details?.map(
                                                product => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <div className="formCheck">
                                                                    <input
                                                                        className="formCheckInput"
                                                                        type="checkbox"
                                                                        name="flexRadioDefault"
                                                                        checked={
                                                                            oldProductDetailsArray.findIndex(
                                                                                oldProduct => {
                                                                                    return (
                                                                                        oldProduct.product_id ===
                                                                                        product.product_id
                                                                                    )
                                                                                }
                                                                            ) >
                                                                            -1
                                                                        }
                                                                        onChange={e =>
                                                                            handleOldProducts(
                                                                                e
                                                                                    .target
                                                                                    .checked,
                                                                                product
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td className="textLeft">{`${
                                                                product?.[
                                                                    PRODUCT_DETAIL_FIELDS
                                                                        .PRODUCT_NAME
                                                                ]
                                                            } ${
                                                                product?.[
                                                                    PRODUCT_DETAIL_FIELDS
                                                                        .DOSE
                                                                ]
                                                            }`}</td>
                                                            <td>
                                                                <div className="formInline dFlex flexNowrap ai-flex-end">
                                                                    {oldProductDetailsArray.findIndex(
                                                                        oldProduct => {
                                                                            return (
                                                                                oldProduct.id ===
                                                                                product.id
                                                                            )
                                                                        }
                                                                    ) > -1 && (
                                                                        <div className="formGroup">
                                                                            <input
                                                                                className="formControl inline formsm"
                                                                                type="text"
                                                                                name="flexRadioDefault"
                                                                                value={
                                                                                    oldProductDetailsArray.filter(
                                                                                        oldProduct => {
                                                                                            return (
                                                                                                oldProduct.id ===
                                                                                                product.id
                                                                                            )
                                                                                        }
                                                                                    )[0][
                                                                                        PRODUCT_DETAIL_FIELDS
                                                                                            .NEW_QUANTITY
                                                                                    ]
                                                                                }
                                                                                onChange={e =>
                                                                                    handleOldProductsQuantity(
                                                                                        e
                                                                                            .target
                                                                                            .value,
                                                                                        product
                                                                                    )
                                                                                }
                                                                                placeholder="Qty"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    <div className="formGroup">
                                                                        <span>
                                                                            &nbsp;/
                                                                            {
                                                                                product.quantity
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                </form>
                            )}
                            {addExchangeReturnState ===
                                ADD_EXCHANGE_STAGES.NEW_PRODUCT_DETAILS && (
                                <div className="addedProductsListWrap">
                                    {newProductAmount > 0 && (
                                        <div className="consolidateStrip dFlex jcSpaceBetween aiCenter">
                                            <div className="consolidateDataIcon dFlex">
                                                <ProductReceivingBlack />
                                            </div>
                                            <div className="consolidateDataText">
                                                New Product Amount{' '}
                                                <small>BDT</small>
                                            </div>
                                            <div className="consolidateData">
                                                {newProductAmount}
                                            </div>
                                        </div>
                                    )}
                                    <ul className="addedProductsList">
                                        {newProductDetailsArray?.length > 0 &&
                                            newProductDetailsArray.map(
                                                product => {
                                                    return (
                                                        // <li className='addedProduct'>
                                                        //     <div className='dFlex jcSpaceBetween aiCenter'>
                                                        //         <div className='addedProductName'>{product[PRODUCT_DETAIL_FIELDS.PRODUCT_NAME]}</div>
                                                        //         <div className='addedProductAction'>
                                                        //             <div className='trashIcon' onClick={() => handleRemoveProductClick(product)}>
                                                        //                 <TrashIcon />
                                                        //             </div>
                                                        //             <ArrowDown
                                                        //                 onClick={() => {
                                                        //                     if (product.id === addedProductVisibleId) setAddedProductDetailsId(undefined);
                                                        //                     else setAddedProductDetailsId(product.id)
                                                        //                 }
                                                        //                 } />
                                                        //         </div>
                                                        //     </div>
                                                        //     {addedProductVisibleId === product.id &&
                                                        //         // !addProductActive &&
                                                        //         <Product
                                                        //             isFieldErrorVisible={isFieldErrorVisible}
                                                        //             handleProductUpdate={(id, key, value) => handleExistingProductUpdate(id, key, value)}
                                                        //             product={product}
                                                        //         />}
                                                        // </li>

                                                        <li className="addedProduct">
                                                            <div className="dFlex jcSpaceBetween aiCenter">
                                                                <div className="addedProductName">
                                                                    {
                                                                        product?.[
                                                                            PRODUCT_DETAIL_FIELDS
                                                                                .PRODUCT_NAME
                                                                        ]
                                                                    }
                                                                </div>
                                                                <div className="addedProductAction dFlex">
                                                                    <div
                                                                        className="trashIcon"
                                                                        onClick={() =>
                                                                            setDeleteProductPopup(
                                                                                true
                                                                            )
                                                                        }>
                                                                        <TrashIcon />
                                                                    </div>

                                                                    <div
                                                                        className={`toggleArrow ${
                                                                            product.product_id ===
                                                                            addedProductVisibleId
                                                                                ? 'show'
                                                                                : 'hide'
                                                                        }`}
                                                                        onClick={() => {
                                                                            if (
                                                                                product.product_id ===
                                                                                addedProductVisibleId
                                                                            )
                                                                                setAddedProductDetailsId(
                                                                                    undefined
                                                                                )
                                                                            else
                                                                                setAddedProductDetailsId(
                                                                                    product.product_id
                                                                                )
                                                                        }}>
                                                                        <ArrowDown />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {deleteProductPopup && (
                                                                <PopupCard
                                                                    header="Delete Product 1?"
                                                                    body="Lorem ipsum dolor sit t consectetur. Nulla bibendum ac ornare sodales.?">
                                                                    <button
                                                                        className="btn btnPrimaryOutlined dFlex"
                                                                        onClick={() =>
                                                                            setDeleteProductPopup(
                                                                                false
                                                                            )
                                                                        }>
                                                                        Cancel
                                                                    </button>
                                                                    <button
                                                                        className="btn btnPrimary dFlex"
                                                                        onClick={() =>
                                                                            handleRemoveProductClick(
                                                                                product
                                                                            )
                                                                        }>
                                                                        Delete
                                                                    </button>
                                                                </PopupCard>
                                                            )}
                                                            {addedProductVisibleId ===
                                                                product.product_id && (
                                                                <Product
                                                                    isFieldErrorVisible={
                                                                        isFieldErrorVisible
                                                                    }
                                                                    handleProductUpdate={(
                                                                        id,
                                                                        key,
                                                                        value
                                                                    ) =>
                                                                        handleExistingProductUpdate(
                                                                            id,
                                                                            key,
                                                                            value
                                                                        )
                                                                    }
                                                                    product={
                                                                        product
                                                                    }
                                                                    isBarcodeDisabled={
                                                                        true
                                                                    }
                                                                />
                                                            )}
                                                        </li>
                                                    )
                                                }
                                            )}
                                        {addProductActive && (
                                            <li className="addedProduct">
                                                <Product
                                                    isFieldErrorVisible={
                                                        isFieldErrorVisible
                                                    }
                                                    handleProductUpdate={(
                                                        id,
                                                        key,
                                                        value
                                                    ) =>
                                                        handleProductDetailsUpdate(
                                                            id,
                                                            key,
                                                            value
                                                        )
                                                    }
                                                    clearProduct={() => {
                                                        setNewProductDetails(
                                                            undefined
                                                        )
                                                        setAddProductActive(
                                                            false
                                                        )
                                                    }}
                                                    getProductDetailsByBarcode={barCode =>
                                                        getProductDetailsByBarcode(
                                                            barCode
                                                        )
                                                    }
                                                    product={newProductDetails}
                                                    updateProductDetails={pDetails =>
                                                        setNewProductDetails(
                                                            pDetails
                                                        )
                                                    }
                                                />
                                            </li>
                                        )}
                                    </ul>
                                    {!addProductActive && (
                                        <div>
                                            {newProductDetailsArray?.length <=
                                                0 && (
                                                <div className="itemCenter dFlex flexColumn">
                                                    <TrashIcon />
                                                    <div className="p-2">
                                                        Add medications as per
                                                        prescription to the cart
                                                    </div>
                                                </div>
                                            )}
                                            <div
                                                className="addItemButton"
                                                onClick={() =>
                                                    setAddProductActive(true)
                                                }>
                                                <button className="btn btnPrimary dFlex">
                                                    <AddIcon />
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {addExchangeReturnState ===
                                ADD_EXCHANGE_STAGES.PAYMENT_DETAILS && (
                                <form>
                                    <div className="formGroup formFloating">
                                        <textarea
                                            rows="2"
                                            type="text"
                                            className="formControl"
                                            id="notes"
                                            value={
                                                paymentDetails?.[
                                                    PAYMENT_DETAILS_FIELDS.NOTES
                                                ]
                                            }
                                            onChange={e =>
                                                handlePaymentDetailsUpdate(
                                                    PAYMENT_DETAILS_FIELDS.NOTES,
                                                    e.target.value
                                                )
                                            }
                                            required></textarea>
                                        <label for="notes">Notes</label>
                                        {false &&
                                            !paymentDetails?.[
                                                PAYMENT_DETAILS_FIELDS.NOTES
                                            ] && (
                                                <div className="invalidFeedback">
                                                    Please enter notes.
                                                </div>
                                            )}
                                    </div>

                                    <div className="formGroup formFloating formInputText">
                                        <div className="preInputGroup backColor">
                                            <div className="preInputText">
                                                BDT
                                            </div>
                                        </div>
                                        <input
                                            type="text"
                                            className="formControl"
                                            id="amount"
                                            placeholder="New Product Amount"
                                            value={newProductAmount}
                                            readOnly={true}
                                            required
                                        />
                                        <label for="amount">
                                            New Product Amount (BDT)
                                        </label>
                                        {/* {isFieldErrorVisible && !paymentDetails?.[PAYMENT_DETAILS_FIELDS.AMOUNT] && <div className="invalidFeedback">
                                    Please enter a valid Amount.
                                </div>} */}
                                    </div>
                                    {/* <div className="formGroup formFloating formInputText">
                                <div className='preInputGroup backColor'>
                                    <div className='preInputText'>BDT</div>
                                </div>
                                <input
                                    type="text"
                                    className="formControl"
                                    id="amount"
                                    placeholder="Amount"
                                    value={paymentDetails?.[PAYMENT_DETAILS_FIELDS.AMOUNT]}
                                    onChange={(e) => handlePaymentDetailsUpdate(PAYMENT_DETAILS_FIELDS.AMOUNT, e.target.value)}
                                    required />
                                <label for="amount">Amount(BDT)</label>
                                {isFieldErrorVisible && !paymentDetails?.[PAYMENT_DETAILS_FIELDS.AMOUNT] && <div className="invalidFeedback">
                                    Please enter a valid Amount.
                                </div>}
                            </div> */}
                                    <div className="formInline dFlex flexNowrap">
                                        <div className="formGroup formFloating formInputText">
                                            <div className="preInputGroup backColor">
                                                <div className="preInputText">
                                                    %
                                                </div>
                                            </div>
                                            <input
                                                type="text"
                                                className="formControl"
                                                id="amount"
                                                placeholder="Discount"
                                                value={
                                                    paymentDetails?.[
                                                        PAYMENT_DETAILS_FIELDS
                                                            .DISCOUNT
                                                    ]
                                                }
                                                onChange={e =>
                                                    handlePaymentDetailsUpdate(
                                                        PAYMENT_DETAILS_FIELDS.DISCOUNT,
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <label for="amount">
                                                Discount(%)
                                            </label>
                                            {isFieldErrorVisible &&
                                                !paymentDetails?.[
                                                    PAYMENT_DETAILS_FIELDS
                                                        .DISCOUNT
                                                ] && (
                                                    <div className="invalidFeedback">
                                                        Please enter a valid
                                                        Discount.
                                                    </div>
                                                )}
                                        </div>

                                        <div className="formGroup formFloating formInputText fgrow1 ml-4">
                                            <div className="preInputGroup backColor">
                                                <div className="preInputText">
                                                    BDT
                                                </div>
                                            </div>
                                            <input
                                                type="text"
                                                className="formControl"
                                                id="amount"
                                                placeholder="Discount"
                                                value={
                                                    paymentDetails?.[
                                                        PAYMENT_DETAILS_FIELDS
                                                            .DISCOUNT_AMOUNT
                                                    ]
                                                }
                                                onChange={e =>
                                                    handlePaymentDetailsUpdate(
                                                        PAYMENT_DETAILS_FIELDS.DISCOUNT_AMOUNT,
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <label for="amount">
                                                Discount(BDT)
                                            </label>
                                            {false && (
                                                <div class="invalidFeedback">
                                                    Please enter a valid
                                                    Discount.
                                                </div>
                                            )}
                                        </div>

                                        {/* <div className="amountPercentage">
                                            <div className="currencyType">
                                                BDT
                                            </div>
                                            <div className="currencyValue">
                                                {getDiscountedAmount()}
                                            </div>
                                        </div> */}
                                    </div>
                                    {/* <div className="formInline dFlex flexNowrap">
                                    <div className="formGroup formFloating formInputText">
                                        <div className="preInputGroup backColor">
                                            <div className="preInputText">
                                                %
                                            </div>
                                        </div>
                                        <input
                                            type="text"
                                            className="formControl"
                                            id="amount"
                                            placeholder="Trade Discount"
                                            value={
                                                paymentDetails?.[
                                                    PAYMENT_DETAILS_FIELDS
                                                        .TRADE_DISCOUNT
                                                ]
                                            }
                                            onChange={e =>
                                                handlePaymentDetailsUpdate(
                                                    PAYMENT_DETAILS_FIELDS.TRADE_DISCOUNT,
                                                    e.target.value
                                                )
                                            }
                                            required
                                        />
                                        <label for="amount">
                                            Trade Discount(%)
                                        </label>
                                        {isFieldErrorVisible &&
                                            !paymentDetails?.[
                                                PAYMENT_DETAILS_FIELDS
                                                    .TRADE_DISCOUNT
                                            ] && (
                                                <div className="invalidFeedback">
                                                    Please enter a valid Trade
                                                    Discount.
                                                </div>
                                            )}
                                    </div>
                                    <div className="amountPercentage">
                                        <div className="currencyType">BDT</div>
                                        <div className="currencyValue">
                                            {getTradeDiscountedAmount()}
                                        </div>
                                    </div>
                                </div> */}
                                    <div className="formInline dFlex flexNowrap">
                                        <div className="formGroup formFloating formInputText">
                                            <div className="preInputGroup backColor">
                                                <div className="preInputText">
                                                    %
                                                </div>
                                            </div>
                                            <input
                                                type="text"
                                                className="formControl"
                                                id="amount"
                                                placeholder="VAT"
                                                value={
                                                    paymentDetails?.[
                                                        PAYMENT_DETAILS_FIELDS
                                                            .VAT
                                                    ]
                                                }
                                                onChange={e =>
                                                    handlePaymentDetailsUpdate(
                                                        PAYMENT_DETAILS_FIELDS.VAT,
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <label for="amount">VAT(%)</label>
                                            {isFieldErrorVisible &&
                                                !paymentDetails?.[
                                                    PAYMENT_DETAILS_FIELDS.VAT
                                                ] && (
                                                    <div className="invalidFeedback">
                                                        Please enter a valid
                                                        VAT.
                                                    </div>
                                                )}
                                        </div>
                                        <div className="amountPercentage">
                                            <div className="currencyType">
                                                BDT
                                            </div>
                                            <div className="currencyValue">
                                                {Math.abs(getVatAmount())}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="formGroup formFloating formInputText">
                                        <div className="preInputGroup backColor">
                                            <div className="preInputText">
                                                BDT
                                            </div>
                                        </div>
                                        <input
                                            type="text"
                                            className="formControl"
                                            id="amount"
                                            placeholder="Exchange Amount (BDT)"
                                            value={oldProductAmount}
                                            readOnly={true}
                                            required
                                        />
                                        <label for="amount">
                                            Exchange Amount (BDT)
                                        </label>
                                    </div>
                                    <div className="formGroup formFloating formInputText">
                                        <div className="preInputGroup backColor">
                                            <div className="preInputText">
                                                BDT
                                            </div>
                                        </div>
                                        <input
                                            type="text"
                                            className="formControl"
                                            id="totalamount"
                                            placeholder="Total Amount"
                                            value={
                                                paymentDetails?.[
                                                    PAYMENT_DETAILS_FIELDS
                                                        .TOTAL_AMOUNT
                                                ]
                                            }
                                            onChange={e =>
                                                handlePaymentDetailsUpdate(
                                                    PAYMENT_DETAILS_FIELDS.TOTAL_AMOUNT,
                                                    e.target.value
                                                )
                                            }
                                            required
                                        />
                                        <label for="totalamount">
                                            Amount Payable/Receivable (BDT)
                                        </label>
                                        {isFieldErrorVisible &&
                                            !paymentDetails?.[
                                                PAYMENT_DETAILS_FIELDS
                                                    .TOTAL_AMOUNT
                                            ] && (
                                                <div className="invalidFeedback">
                                                    Please enter a valid Total
                                                    Amount.
                                                </div>
                                            )}
                                    </div>
                                    <div className="formGroup formFloating fgrow1 maxWidth">
                                        <Select
                                            className="formSelect reactSelect"
                                            id="floatingSelect"
                                            value={{
                                                label: convertToTitleCase(
                                                    paymentDetails?.[
                                                        PAYMENT_DETAILS_FIELDS
                                                            .PAYMENT_MODE
                                                    ]
                                                )
                                            }}
                                            onChange={option =>
                                                handlePaymentDetailsUpdate(
                                                    PAYMENT_DETAILS_FIELDS.PAYMENT_MODE,
                                                    option.name
                                                )
                                            }
                                            options={PAYMENT_MODE_OPTIONS}
                                            getOptionLabel={option =>
                                                option?.label
                                            }
                                            placeholder="Payment Mode"
                                            styles={{
                                                control: base => ({
                                                    border: 0,
                                                    display: 'flex',
                                                    paddingTop: '20px'
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor:
                                                        state.isFocused
                                                            ? 'rgb(222, 235, 255)'
                                                            : state.isSelected
                                                            ? '#FFF'
                                                            : 'inherit',
                                                    color: '#212529',
                                                    '&:hover': {
                                                        backgroundColor:
                                                            state.isSelected
                                                                ? '#DEEBFF'
                                                                : 'rgb(222, 235, 255)'
                                                    }
                                                })
                                            }}
                                            // menuIsOpen={true}
                                            isClearable
                                        />
                                        <label for="floatingSelect">
                                            Payment Mode
                                        </label>
                                        {isFieldErrorVisible &&
                                            !paymentDetails?.[
                                                PAYMENT_DETAILS_FIELDS
                                                    .PAYMENT_MODE
                                            ] && (
                                                <div class="invalidFeedback">
                                                    Please choose Payment Mode.
                                                </div>
                                            )}
                                    </div>
                                    {paymentDetails?.[
                                        PAYMENT_DETAILS_FIELDS.PAYMENT_MODE
                                    ] === 'CASH_CARD' && (
                                        <div className="formInline dFlex flexNowrap">
                                            <div className="formGroup formFloating formInputText fgrow1">
                                                <div className="preInputGroup backColor">
                                                    <div className="preInputText">
                                                        BDT
                                                    </div>
                                                </div>
                                                <input
                                                    type="text"
                                                    className="formControl"
                                                    id="amountCash"
                                                    placeholder="Amount in Cash"
                                                    value={
                                                        paymentDetails?.[
                                                            PAYMENT_DETAILS_FIELDS
                                                                .AMOUNT_CASH
                                                        ]
                                                    }
                                                    onChange={e =>
                                                        handlePaymentDetailsUpdate(
                                                            PAYMENT_DETAILS_FIELDS.AMOUNT_CASH,
                                                            e.target.value
                                                        )
                                                    }
                                                    required
                                                />
                                                <label for="amountCash">
                                                    Amount in Cash
                                                </label>
                                                {false && (
                                                    <div class="invalidFeedback">
                                                        Please enter a valid
                                                        Cash amount.
                                                    </div>
                                                )}
                                            </div>
                                            <div className="formGroup formFloating formInputText fgrow1 ml-4">
                                                <div className="preInputGroup backColor">
                                                    <div className="preInputText">
                                                        BDT
                                                    </div>
                                                </div>
                                                <input
                                                    type="text"
                                                    className="formControl"
                                                    id="amountCard"
                                                    placeholder="Amount in Card"
                                                    value={
                                                        paymentDetails?.[
                                                            PAYMENT_DETAILS_FIELDS
                                                                .AMOUNT_CARD
                                                        ]
                                                    }
                                                    onChange={e =>
                                                        handlePaymentDetailsUpdate(
                                                            PAYMENT_DETAILS_FIELDS.AMOUNT_CARD,
                                                            e.target.value
                                                        )
                                                    }
                                                    required
                                                />
                                                <label for="amountCard">
                                                    Amount in Card
                                                </label>
                                                {false && (
                                                    <div class="invalidFeedback">
                                                        Please enter a valid
                                                        Card Amount.
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </form>
                            )}
                            {addExchangeReturnState ===
                                ADD_RETURN_STAGES.RETURN_PAYMENT_DETAILS && (
                                <div className="wizardFieldset">
                                    <div className="returnProductsDetails">
                                        <div className="viewProductWrap">
                                            <div className="viewProductHeader">
                                                <div className="headerRow dFlex jcSpaceBetween aiCenter">
                                                    <div className="headerRowLeft">
                                                        Return
                                                    </div>
                                                    <div className="headerRowRight">
                                                        {formatDate(
                                                            new moment()
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="dFlex jcSpaceBetween">
                                                    <div className="ProductUniqueTag">
                                                        {invoiceDetails.invoice_number?.substring(
                                                            0,
                                                            10
                                                        )}
                                                    </div>
                                                    <div className="moreDetails">
                                                        <small>
                                                            {formatTime(
                                                                new moment()
                                                            )}
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="viewProductHeader p-2 m-0">
                                                <table
                                                    className="table productPrice pt-4"
                                                    cellPadding={2}
                                                    cellSpacing={2}>
                                                    <tbody>
                                                        {/* <tr>
                                                <td>Exchange Amount (BDT)</td>
                                                <td align='right'>20,000</td>
                                            </tr> */}
                                                        <tr>
                                                            <td>
                                                                Amount (BDT)
                                                            </td>
                                                            <td align="right">
                                                                {
                                                                    paymentDetails[
                                                                        PAYMENT_DETAILS_FIELDS
                                                                            .AMOUNT
                                                                    ]
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{`Discount (${
                                                                paymentDetails?.[
                                                                    PAYMENT_DETAILS_FIELDS
                                                                        .DISCOUNT
                                                                ] || 0
                                                            }%)`}</td>
                                                            <td align="right">
                                                                {getDiscountedAmount()}
                                                            </td>
                                                        </tr>
                                                        {/* <tr>
                                                            <td>{`Trade Discount (${
                                                                paymentDetails?.[
                                                                    PAYMENT_DETAILS_FIELDS
                                                                        .TRADE_DISCOUNT
                                                                ] || 0
                                                            }%)`}</td>
                                                            <td align="right">
                                                                {getTradeDiscountedAmount()}
                                                            </td>
                                                        </tr> */}
                                                        <tr>
                                                            <td>{`VAT (${
                                                                paymentDetails?.[
                                                                    PAYMENT_DETAILS_FIELDS
                                                                        .VAT
                                                                ] || 0
                                                            }%)`}</td>
                                                            <td align="right">
                                                                {getVatAmount()}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="strong primaryText">
                                                                Total Payable
                                                                (BDT)
                                                            </td>
                                                            <td
                                                                className={`strong primaryText `}
                                                                align="right">
                                                                {
                                                                    paymentDetails[
                                                                        PAYMENT_DETAILS_FIELDS
                                                                            .TOTAL_AMOUNT
                                                                    ]
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* <div className='viewProductHeader'>
                                    <div className='ProductUniqueTag'>Payment Method</div>
                                    <div className='moreDetails'>Online</div>
                                </div>
                                <div className='viewProductHeader'>
                                    <div className="formGroup formFloating">
                                        <select className="formSelect" id="floatingSelect">
                                            <option value="1">Delivery Partner One</option>
                                            <option value="2">Delivery Partner Two</option>
                                        </select>
                                        <label for="floatingSelect">Delivery Partner</label>
                                        {false && <div className="invalidFeedback">
                                            Please choose Delivery Partner.
                                        </div>}
                                    </div>
                                </div> */}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </SideDrawer>
                )}
                <ViewExchangeReturn
                    handleDrawerToggle={() =>
                        setViewExchangeReturn(!viewExchangeReturn)
                    }
                    open={viewExchangeReturn}
                    data={exchangeReturnDetail}
                />

                <SideDrawer
                    handleDrawerToggle={handleDrawerToggle}
                    open={false}
                    drawerButton={true}
                    drawerHeading="New Exchange/Return"
                    isNextActive={isNextActive}
                    buttonText={
                        addProductActive &&
                        addExchangeReturnState ===
                            ADD_EXCHANGE_STAGES.PRODUCT_DETAILS
                            ? 'Save'
                            : 'Next'
                    }
                    handleClick={
                        addProductActive &&
                        addExchangeReturnState ===
                            ADD_EXCHANGE_STAGES.PRODUCT_DETAILS
                            ? () => handleAddProductClick()
                            : () => handleNextClick()
                    }>
                    <WizardSteps wizardState={wizardState} />

                    {addExchangeReturnState ===
                        ADD_EXCHANGE_STAGES.BILL_DETAILS && (
                        <div className="wizardFieldset">
                            <form onSubmit={e => e.preventDefault()}>
                                <div className="formGroup formFloating fgrow1">
                                    <input
                                        type="text"
                                        className="formControl"
                                        id="invoice"
                                        value={invoiceNumber}
                                        placeholder="Invoice Number"
                                        onChange={setInvoiceNumber}
                                        required
                                    />
                                    <label for="invoice">Invoice Number</label>
                                    {isFieldErrorVisible && !invoiceNumber && (
                                        <div className="invalidFeedback">
                                            Please select a valid Invoice
                                            Number.
                                        </div>
                                    )}
                                </div>
                                <div className="consolidateStrip dFlex jcSpaceBetween aiCenter">
                                    <div className="consolidateDataIcon dFlex">
                                        <ProductReceivingBlack />
                                    </div>
                                    <div className="consolidateDataText">
                                        Exchange Amount <small>BDT</small>
                                    </div>
                                    <div className="consolidateData">
                                        38,500
                                    </div>
                                </div>
                                <div className="formSection dFlex mb-5">
                                    <div className="formCheck">
                                        <input
                                            className="formCheckInput"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="Exchange"
                                        />
                                        <label
                                            className="formCheckLabel"
                                            for="Exchange">
                                            Exchange
                                        </label>
                                    </div>
                                    <div className="formCheck ml-5">
                                        <input
                                            className="formCheckInput"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="Return"
                                        />
                                        <label
                                            className="formCheckLabel"
                                            for="Return">
                                            Return
                                        </label>
                                    </div>
                                    {props.isFieldErrorVisible &&
                                        !props.product?.[
                                            PRODUCT_DETAIL_FIELDS
                                                .PRODUCT_CATEGORY
                                        ] && (
                                            <div className="invalidFeedback">
                                                Please select Product Category.
                                            </div>
                                        )}
                                </div>
                                <div className="formGroup formFloating fgrow1">
                                    <input
                                        type="text"
                                        className="formControl"
                                        id="invoice"
                                        value={invoiceNumber}
                                        placeholder="Old Invoice Number"
                                        // onChange={(e) => handleBillDetailUpdate(BILL_DETAILS_FIELDS.INVOICE_NUMBER, e.target.value)}
                                        required
                                    />
                                    <label for="invoice">
                                        Old Invoice Number
                                    </label>
                                    {/* {isFieldErrorVisible && !billDetails?.[BILL_DETAILS_FIELDS.INVOICE_NUMBER] && <div className="invalidFeedback">
                                        Please select a valid Old Invoice Number.
                                    </div>} */}
                                </div>
                                <div className="exchangeReturnDetails">
                                    <div className="dFlex">
                                        <div className="exchangeReturnName">
                                            Ahnaf Sabbir
                                        </div>
                                        <div className="exchangeReturnContact">
                                            +880 76543245678
                                        </div>
                                    </div>
                                    <div className="exchangeReturnLocation dFlex flexNowrap aiCenter">
                                        <LocationIcon />
                                        Lorem ipsum dose amet consectetur{' '}
                                    </div>
                                </div>
                                <table className="table exchange">
                                    <thead>
                                        <tr>
                                            <td></td>
                                            <td width="70%">Medicine</td>
                                            <td width="30%">Qty</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="formCheck">
                                                    <input
                                                        className="formCheckInput"
                                                        type="checkbox"
                                                        name="flexRadioDefault"
                                                    />
                                                </div>
                                            </td>
                                            <td>Mesacol 300 mg</td>
                                            <td>250</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="formCheck">
                                                    <input
                                                        className="formCheckInput"
                                                        type="checkbox"
                                                        name="flexRadioDefault"
                                                    />
                                                </div>
                                            </td>
                                            <td>Mesacol 300 mg</td>
                                            <td>250</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="exchangeValueRow dFlex jcSpaceBetween">
                                    <div className="exchangeValue strong">
                                        Exchange Product value
                                    </div>
                                    <div className="exchangeValue strong">
                                        30000
                                    </div>
                                </div>
                                <table className="table exchange">
                                    <thead>
                                        <tr>
                                            <td width="90%">Medicine</td>
                                            <td width="5%">Qty</td>
                                            <td width="5%">Amount</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Mesacol 300 mg</td>
                                            <td>250</td>
                                            <td>BDT 25000</td>
                                        </tr>
                                        <tr>
                                            <td>Mesacol 300 mg</td>
                                            <td>250</td>
                                            <td>BDT 25000</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="exchangeValueRow dFlex jcSpaceBetween">
                                    <div className="exchangeValue strong">
                                        New Product value
                                    </div>
                                    <div className="exchangeValue strong">
                                        30000
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}
                </SideDrawer>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        alert: state.alertReducer.alert,
        loginData: state.loginDataReducer?.loginData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createExchangeReturnAlertAction: payload =>
            dispatch(createExchangeReturnAlertAction(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeReturn)
