import React, { useEffect, createContext, useState } from 'react'
import { connect } from 'react-redux'
import socketio from 'socket.io-client'
import {
    newSalesAlertAction,
    newSalesDataAction,
    notificationActiveAction,
    updateSalesByCustomerAlertAction,
    updateSalesDataAction
} from '../../modules/actions/action'
import { LOGIN_CONST } from '../constant/page-constant'
import { getCookieCustom } from '../utils/storageUtils'

export const SocketContext = createContext()

// Component to provide the socket context
const SocketProvider = ({
    children,
    dispatchNewSalesDataAction,
    dispatchNewSalesAlertAction,
    dispatchUpdateSalesByCustomerAlertAction,
    dispatchUpdateSalesDataAction,
    dispatchNotificationActiveAction,
    loginData
}) => {
    const [socketInstance, setSocketInstance] = useState(null)
    const [message, setMessage] = useState(null)

    useEffect(() => {
        let sInstance

        if (loginData?.phoneNumber) {
            console.log('Connecting to socket server')
            sInstance = createSocketInstance()
        }

        return () => {
            console.log(
                'Disconnecting from socket server: ',
                sInstance?.readyState
            )
            if (sInstance?.readyState === 1) {
                sInstance.close()
            }
        }
    }, [loginData])

    const createSocketInstance = () => {
        let sInstance = socketInstance
        let accessToken = getCookieCustom(LOGIN_CONST.ACCESS_TOKEN)
        let authHeaderValue = `Bearer ${accessToken}`
        let websocketURL = `wss://www.backend-epharma.dghealthcare.online/ws/pharmacy/?token=${encodeURIComponent(
            authHeaderValue
        )}`

        if (!accessToken) return

        let retryAttempts = 0
        const maxRetries = 5 // Maximum number of retries
        const baseDelay = 1000 // Initial delay (1 second)

        const connectWebSocket = () => {
            sInstance = socketInstance
            if (!sInstance) {
                sInstance = new WebSocket(websocketURL)

                // Connection opened
                sInstance.onopen = () => {
                    console.log('WebSocket connection established')
                    retryAttempts = 0 // Reset retry attempts on successful connection
                    sInstance.send(JSON.stringify({ token: authHeaderValue }))
                }

                // Handle incoming messages
                sInstance.onmessage = event => {
                    if (event.data) {
                        try {
                            let data = JSON.parse(event.data)
                            dispatchRelevantAction(data)
                        } catch (err) {
                            console.error('Parsing error: ', err)
                        }
                    }
                    console.log('Received message from WebSocket:', event.data)
                }

                // Connection closed
                sInstance.onclose = err => {
                    console.warn('WebSocket connection closed:', err)
                    setSocketInstance(null)

                    if (retryAttempts < maxRetries) {
                        retryAttempts += 1
                        const retryDelay =
                            baseDelay * Math.pow(2, retryAttempts) // Exponential backoff

                        setTimeout(() => {
                            let accessToken = getCookieCustom(
                                LOGIN_CONST.ACCESS_TOKEN
                            )
                            if (accessToken) {
                                console.log(
                                    `Reconnecting in ${
                                        retryDelay / 1000
                                    } seconds...`
                                )
                                connectWebSocket() // Attempt reconnection
                            }
                        }, retryDelay)
                    } else {
                        console.error(
                            'Max retry attempts reached. WebSocket will not reconnect.'
                        )
                    }
                }

                // Handle connection error
                sInstance.onerror = error => {
                    console.error('WebSocket error:', error)
                }

                setSocketInstance(sInstance)
            }
        }

        connectWebSocket() // Start WebSocket connection

        return sInstance
    }
    // const createSocketInstance = () => {
    //     let sInstance = socketInstance

    //     let accessToken = getCookieCustom(LOGIN_CONST.ACCESS_TOKEN)

    //     let authHeaderValue = `Bearer ${accessToken}`
    //     let websocketURL = `wss://www.backend-epharma.dghealthcare.online/ws/pharmacy/?token=${encodeURIComponent(
    //         authHeaderValue
    //     )}`

    //     if (!accessToken) return
    //     if (!sInstance) {
    //         sInstance = new WebSocket(websocketURL)

    //         // Connection opened
    //         sInstance.onopen = () => {
    //             console.log('WebSocket connection established')
    //             // Send the authentication token after the connection is established
    //             sInstance.send(JSON.stringify({ token: authHeaderValue }))
    //         }

    //         sInstance.onmessage = event => {
    //             if (event.data) {
    //                 try {
    //                     let data = JSON.parse(event?.data)
    //                     dispatchRelevantAction(data)
    //                 } catch (err) {
    //                     console.log('Parsing error: ', err)
    //                 }
    //             }

    //             console.log('Received message from WebSocket:', event.data)
    //             // Handle incoming messages from the WebSocket server
    //         }

    //         sInstance.onclose = err => {
    //             console.log('WebSocket connection closed: ', err)
    //             setSocketInstance(null)
    //             setTimeout(function () {
    //                 let accessToken = getCookieCustom(LOGIN_CONST.ACCESS_TOKEN)
    //                 if (accessToken) createSocketInstance()
    //             }, 1000)
    //         }

    //         sInstance.onerror = error => {
    //             console.error('WebSocket error:', error)
    //         }

    //         setSocketInstance(sInstance)
    //     }

    //     return sInstance
    // }

    const dispatchRelevantAction = data => {
        if (!data.type) return

        console.log('data type: ', data.type)
        switch (data.type) {
            case 'NEW_SALES_ORDER': {
                let message = data.message

                console.log('dispatching sales new order action')
                dispatchNewSalesDataAction(data.message)

                dispatchNewSalesAlertAction(true)
                dispatchNotificationActiveAction(true)
                setTimeout(() => {
                    dispatchNewSalesAlertAction(false)
                }, 5000)
                break
            }
            case 'UPDATE_SALES_ORDER': {
                let message = data.message

                console.log('dispatching sales update order action')
                dispatchUpdateSalesDataAction(data.message)

                dispatchUpdateSalesByCustomerAlertAction(true)
                dispatchNotificationActiveAction(true)
                setTimeout(() => {
                    dispatchUpdateSalesByCustomerAlertAction(false)
                }, 5000)
                break
            }
            default:
                return
        }
    }

    return (
        <SocketContext.Provider value={message}>
            {children}
        </SocketContext.Provider>
    )
}

const mapStateToProps = state => {
    return {
        alert: state.alertReducer.alert,
        salesData: state?.appDataReducer?.salesData,
        loginData: state.loginDataReducer?.loginData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchNewSalesDataAction: payload =>
            dispatch(newSalesDataAction(payload)),
        dispatchNewSalesAlertAction: payload =>
            dispatch(newSalesAlertAction(payload)),
        dispatchUpdateSalesDataAction: payload =>
            dispatch(updateSalesDataAction(payload)),
        dispatchUpdateSalesByCustomerAlertAction: payload =>
            dispatch(updateSalesByCustomerAlertAction(payload)),
        dispatchNotificationActiveAction: payload =>
            dispatch(notificationActiveAction(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SocketProvider)
