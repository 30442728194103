import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import "./ExchangeReturn.scss";
import { PRODUCT_DETAIL_FIELDS } from '../../common/constant/page-constant';

function ExchangeReturnProduct(props) {
    const navigate = useNavigate();

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <div className='formSection'>
                <div className="formGroup formFloating fgrow1">
                    <input
                        type="text"
                        className="formControl"
                        id="batch"
                        placeholder="Product Barcode"
                        value={props.product?.[PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE]}
                        onChange={(e) => props.handleProductUpdate(props.product?.id, PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE, e.target.value)}
                        required />
                    <label for="batch">Product Barcode</label>
                    {props.isFieldErrorVisible && !props.product?.[PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE] && <div class="invalidFeedback">
                        Please select a valid Product Barcode.
                    </div>}
                </div>
            </div>
            <div className="formGroup formFloating fgrow1 maxWidth">
                <select
                    className="formSelect"
                    id="floatingSelect"
                    value={props.product?.[PRODUCT_DETAIL_FIELDS.PRODUCT_NAME]}
                    onChange={(e) => props.handleProductUpdate(props.product?.id, PRODUCT_DETAIL_FIELDS.PRODUCT_NAME, e.target.value)}
                >
                    <option value="none" selected disabled hidden>Select an Option</option>
                    <option value="1">NetMeds</option>
                    <option value="2">DavaIndia</option>
                </select>
                <label for="floatingSelect">Product Name</label>
                {props.isFieldErrorVisible && !props.product?.[PRODUCT_DETAIL_FIELDS.PRODUCT_NAME] && <div class="invalidFeedback">
                    Please choose Product Name.
                </div>}
                <div className='inputSuggestionsWrap'>
                    <ul className='inputSuggestion dFlex'>
                        <li className='suggestion'>Foreign</li>
                        <li className='suggestion'>Allergy & Immune System</li>
                    </ul>
                </div>
            </div>
            <div className='formInline dFlex flexNowrap'>
                <div className="formGroup formFloating fgrow1">
                    <input
                        type="text"
                        className="formControl"
                        id="quantity"
                        placeholder="Quantity"
                        value={props.product?.[PRODUCT_DETAIL_FIELDS.QUANTITY]}
                        onChange={(e) => props.handleProductUpdate(props.product?.id, PRODUCT_DETAIL_FIELDS.QUANTITY, e.target.value)}
                        required />
                    <label for="quantity">Quantity</label>
                    {props.isFieldErrorVisible && !props.product?.[PRODUCT_DETAIL_FIELDS.QUANTITY] && <div class="invalidFeedback">
                        Please select a valid Quantity.
                    </div>}
                </div>
                <div className="formGroup formFloating ml-4 fgrow1">
                    <input
                        type="text"
                        className="formControl"
                        id="dose"
                        placeholder="Dose"
                        value={props.product?.[PRODUCT_DETAIL_FIELDS.DOSE]}
                        onChange={(e) => props.handleProductUpdate(props.product?.id, PRODUCT_DETAIL_FIELDS.DOSE, e.target.value)}
                        required />
                    <label for="dose">Dose</label>
                    {props.isFieldErrorVisible && !props.product?.[PRODUCT_DETAIL_FIELDS.DOSE] && <div class="invalidFeedback">
                        Please select a valid Dose.
                    </div>}
                </div>
            </div>
            <div className='formInline dFlex dualInlineGroup'>
                <div className="formGroup formFloating fgrow1">
                    <input
                        type="text"
                        className="formControl"
                        id="batch"
                        placeholder="Exchange Quantity"
                        value={props.product?.[PRODUCT_DETAIL_FIELDS.BATCH_NUMBER]}
                        onChange={(e) => props.handleProductUpdate(props.product?.id, PRODUCT_DETAIL_FIELDS.BATCH_NUMBER, e.target.value)}
                        required />
                    <label for="batch">Exchange Quantity</label>
                    {props.isFieldErrorVisible && !props.product?.[PRODUCT_DETAIL_FIELDS.BATCH_NUMBER] && <div class="invalidFeedback">
                        Please select a valid Exchange Quantity.
                    </div>}
                </div>
                <div className="formGroup formFloating fgrow1 formInputText">
                    <div className='preInputGroup backColor'>
                        <div className='preInputText'>BDT</div>
                    </div>
                    <input
                        type="text"
                        className="formControl"
                        id="date"
                        placeholder="Amount"
                        value={props.product?.[PRODUCT_DETAIL_FIELDS.EXPIRY_DATE]}
                        onChange={(e) => props.handleProductUpdate(props.product?.id, PRODUCT_DETAIL_FIELDS.EXPIRY_DATE, e.target.value)}
                        required />
                    <label for="date">Amount</label>
                    {props.isFieldErrorVisible && !props.product?.[PRODUCT_DETAIL_FIELDS.EXPIRY_DATE] && <div class="invalidFeedback">
                        Please select a Amount.
                    </div>}
                </div>
            </div>
        </form>
    );
}

ExchangeReturnProduct.propTypes = {
    isFieldErrorVisible: PropTypes.bool,
    handleProductUpdate: PropTypes.func,
    product: PropTypes.object
}

const mapStateToProps = (state) => {

    return {};
}

const mapDispatchToProps = (dispatch) => {

    return {
        // testAction: (payload) => dispatch(testAction(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeReturnProduct);