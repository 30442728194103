import React from 'react'

const NotificationInActiveIcon = () => (
    <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g id="Notification">
            <path
                id="Vector"
                d="M14.0001 2.32867C16.2711 2.32568 18.4541 3.20698 20.0866 4.78585C21.719 6.36472 22.6726 8.51711 22.7454 10.787L22.7501 11.0787V15.8585L24.3601 19.5405C24.4572 19.7624 24.4975 20.0049 24.4774 20.2463C24.4572 20.4876 24.3772 20.7201 24.2446 20.9228C24.112 21.1255 23.931 21.2919 23.7179 21.407C23.5048 21.5221 23.2664 21.5823 23.0242 21.5822L17.5001 21.5857C17.5013 22.4968 17.1471 23.3725 16.5128 24.0267C15.8785 24.6808 15.0141 25.0618 14.1034 25.0887C13.1926 25.1156 12.3073 24.7863 11.6355 24.1707C10.9637 23.5551 10.5585 22.7018 10.5059 21.7922L10.5001 21.5822H4.98757C4.74585 21.5819 4.50798 21.5216 4.29533 21.4066C4.08268 21.2917 3.90191 21.1258 3.76924 20.9237C3.63658 20.7216 3.55618 20.4898 3.53526 20.249C3.51434 20.0082 3.55356 19.7659 3.6494 19.544L5.25007 15.8597V11.0787C5.25007 6.23117 9.16074 2.32867 14.0001 2.32867ZM15.7501 21.5833L12.2501 21.5857C12.25 22.0352 12.4229 22.4675 12.7329 22.793C13.043 23.1185 13.4664 23.3122 13.9154 23.3339C14.3644 23.3557 14.8045 23.2038 15.1446 22.9098C15.4846 22.6158 15.6985 22.2023 15.7419 21.7548L15.7501 21.5833ZM14.0001 4.07867C10.1267 4.07867 7.00007 7.19833 7.00007 11.0787V16.2237L5.43207 19.8333H22.5786L21.0001 16.2248V11.0938L20.9966 10.8313C20.936 9.01745 20.1718 7.29836 18.8659 6.03799C17.5601 4.77762 15.815 4.07493 14.0001 4.07867Z"
                fill="#212121"
            />
            {/* <circle id="Ellipse 12" cx="21" cy="7" r="7" fill="#EF8658" /> */}
        </g>
    </svg>
)

export default NotificationInActiveIcon
