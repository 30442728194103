import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import './Card.scss'
import PhoneIcon from '../../../assets/PhoneIcon'
import EmailIcon from '../../../assets/EmailIcon'
import DotsIcon from '../../../assets/DotsIcon'
import LocationIcon from '../../../assets/LocationIcon'
import useCheckMobileScreen from '../../../common/hooks/use-check-mobile-screen-hook'
import CloseIcon from '../../../assets/CloseIcon'
import { convertToTitleCase } from '../../../common/utils/appUtils'

const Card = props => {
    const isMobile = useCheckMobileScreen()
    const [isCardActive, setIsCardActive] = useState(false)

    return (
        <div
            className={`card product ${props?.cardCrown && 'spaceTop'}`}
            onClick={props.handleClick}>
            {props.cardCrown && (
                <div className={`cardCrown ${props.primary && 'primary'}`}>
                    {props.cardCrown}
                </div>
            )}
            <div
                className={`cardHeader dFlex flexNowrap aiCenter ${
                    !props.noHeaderBorder && 'border'
                }`}>
                {props.userInitials && (
                    <div className="cardIcon dFlex jcContentCenter aiCenter">
                        <div className="userInitials">{props.userInitials}</div>
                    </div>
                )}
                <div className="cardHeaderDetails">
                    <div className="headerSup">{props.headerSup}</div>
                    <div className="headerMain">{props.headerMain}</div>
                    {props.headerProduct && (
                        <div className={`productName`}>
                            {props.headerProduct.productType && (
                                <span
                                    className={`item name ${
                                        props.strong ?? 'strong'
                                    }`}>
                                    {props.headerProduct.productType}
                                </span>
                            )}
                            {props.headerProduct.productCategory && (
                                <span
                                    className={`item type ${
                                        props.strong ?? 'strong'
                                    }`}>
                                    {props.headerProduct.productCategory}
                                </span>
                            )}
                            {props.headerProduct.quantity && (
                                <span
                                    className={`item space ${
                                        props.strong ?? 'strong'
                                    }`}>
                                    {props.headerProduct.quantity}
                                </span>
                            )}
                        </div>
                    )}
                    {props.exchangeItem && (
                        <div className="exchangeItem">{props.exchangeItem}</div>
                    )}
                </div>
                {props.cardRight && (
                    <div
                        className={`cardHeaderRight alignSelf ${
                            props.nowrap ? 'nowrap' : ''
                        } ${
                            props.cardRight.startsWith('BDT') &&
                            props.cardRight.split('BDT ')[1] !== '0'
                                ? props.cardRight.split('BDT ')[1] > 0
                                    ? 'textGreen'
                                    : 'textRed'
                                : ''
                        } `}>
                        {props.cardRight}
                    </div>
                )}
                {props.users && (
                    <div className="cardHeaderRight">
                        {isCardActive ? (
                            <span onClick={() => setIsCardActive(false)}>
                                <CloseIcon />
                            </span>
                        ) : (
                            <span onClick={() => setIsCardActive(true)}>
                                <DotsIcon />
                            </span>
                        )}
                        {props.cardAction && (
                            <ul
                                className={`cardActionsWrapper ${
                                    isCardActive ? 'show' : 'hide'
                                }`}>
                                {props?.cardAction?.map(action => {
                                    return (
                                        <li
                                            className="actionItem"
                                            onClick={() => {
                                                setIsCardActive(false)
                                                props?.onCardAction(action)
                                            }}>
                                            {convertToTitleCase(action)}
                                        </li>
                                    )
                                })}
                            </ul>
                        )}
                    </div>
                )}
            </div>
            <div
                className={`cardBody ${props.cardFooter && 'border'} ${
                    props.shippingAddress && 'borderBig'
                }`}>
                {props.product && (
                    <div className={`productName`}>
                        <span
                            className={`item name ${props.strong ?? 'strong'}`}>
                            {props.product && props.product.productName}
                        </span>
                        <span
                            className={`item type ${props.strong ?? 'strong'}`}>
                            {props.product && props.product.dose}
                        </span>
                        <span
                            className={`item space ${
                                props.strong ?? 'strong'
                            }`}>
                            {props.product && props.product.quantity}
                        </span>
                    </div>
                )}
                {props.productDesc && (
                    <div
                        className={`productDesc ${
                            props.cardFooter && 'strong'
                        }`}>
                        {props.productDesc ||
                            'Lorem ipsum dolor sit amet consectetur.'}
                    </div>
                )}
                <ul className="userContactDetails">
                    {props.phone && (
                        <li className="userContact dFlex">
                            <PhoneIcon />
                            {props.phone}
                        </li>
                    )}
                    {props.email && (
                        <li className="userContact">
                            <EmailIcon />
                            {props.email}
                        </li>
                    )}
                    {props.location && (
                        <li className="userContact">
                            <LocationIcon />
                            {props.location}
                        </li>
                    )}
                </ul>
                {props.documents && (
                    <div className="documentsName">
                        <span className="item name">Due Date: 04 Dec 2023</span>
                        <span className="item type">
                            Paymeny Mode: DiGi Pay
                        </span>
                    </div>
                )}
                {props.orderBook && (
                    <div className="documentsName">
                        <span className="item name">
                            Order Qty: {props.orderBook.totalQuantity}
                        </span>
                        {props.orderBook.receivableQuantity && (
                            <span className="item type">
                                Received Qty:{' '}
                                {props.orderBook.receivableQuantity}
                            </span>
                        )}
                    </div>
                )}
                {props.sales && (
                    <div className="productDesc">{props.sales}</div>
                )}
                {props.orderStatus && (
                    <div className={`${props?.orderStatus?.color} orderStatus`}>
                        {props?.orderStatus?.text}
                    </div>
                )}
                {props.actionButtons && (
                    <div className="actionButtons mt-5">
                        <button className="btn btnPrimaryOutlined">
                            CANCEL
                        </button>
                        <button className="btn btnPrimary ml-5">CONFIRM</button>
                    </div>
                )}
                {props.billingAddress && (
                    <div className="addressDetails">
                        <div className="addressType">Billing Address</div>
                        <div className="address dFlex flexNowrap">
                            <LocationIcon />
                            {props.billingAddress}
                        </div>
                    </div>
                )}
            </div>
            <div className="cardFooter">
                {props.cardFooter && (
                    <div className="itemBack dFlex jc-space-even">
                        {props.cardFooter.expiryDate && (
                            <div className="item date dFlex flexColumn">
                                <small>Expiry Date</small>
                                {props.cardFooter.expiryDate}
                            </div>
                        )}
                        {!isMobile && (
                            <div className="item loss dFlex flexColumn ">
                                <small>Return Loss</small>BDT{' '}
                                {props.cardFooter.returnLossAmount}
                            </div>
                        )}
                        {!isMobile && (
                            <div className="item loss dFlex flexColumn no-border">
                                <small>Receivable Amt</small>BDT{' '}
                                {props.cardFooter.recievableAmounut}
                            </div>
                        )}
                        {isMobile && (
                            <div className="dFlex jcSpaceBetween aiCenter ">
                                <div className="item loss dFlex flexColumn mt-2">
                                    <small>Return Loss</small>BDT{' '}
                                    {props.cardFooter.returnLossAmount}
                                </div>
                                <div className="item loss dFlex flexColumn">
                                    <small>Receivable Amt</small>BDT{' '}
                                    {props.cardFooter.recievableAmounut}
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {props.shippingAddress && (
                    <div className="addressDetails">
                        <div className="addressType">Shipping Address</div>
                        <div className="address dFlex flexNowrap">
                            <LocationIcon />
                            {props.shippingAddress}
                        </div>
                    </div>
                )}
                {props.address && (
                    <div className="addressDetails">
                        <div className="addressType"> Address</div>
                        <div className="address dFlex flexNowrap">
                            <LocationIcon />
                            {props.address}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {}
}

Card.propTypes = {
    strong: PropTypes.bool,
    cardCrown: PropTypes.bool,
    online: PropTypes.bool,
    product: PropTypes.bool,
    productDesc: PropTypes.bool,
    phone: PropTypes.bool,
    email: PropTypes.bool,
    location: PropTypes.bool,
    userInitials: PropTypes.bool,
    documents: PropTypes.bool,
    headerSup: PropTypes.string,
    headerMain: PropTypes.string,
    cardRight: PropTypes.string,
    actionButtons: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps)(Card)
