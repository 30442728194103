import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import AddIcon from '../../assets/AddIcon'
import CloseIcon from '../../assets/CloseIcon'
import LocationIcon from '../../assets/LocationIcon'
import {
    HEADER_CONFIG,
    HEADER_CONST
} from '../../common/constant/common-constant'
import {
    CARD_ACTION,
    PAGE_CONST,
    PERMISSION_FEATURE,
    PERMISSION_SCREEN,
    PERMISSION_TYPE
} from '../../common/constant/page-constant'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import { redirect } from '../../common/utils/redirectionUtils'
import Alert from '../../components/Alert/Alert'
import CustomMap from '../../components/CustomMap/CustomMap'
import {
    addBranchHelper,
    getBranchHelper,
    updateBranchDetailsHelper
} from '../../helper/branch-management-helper'
import {
    addBranchAlertAction,
    updateBranchAlertAction
} from '../../modules/actions/action'
import Card from '../Common/Card/Card'
import MobileHeader from '../Common/Header/MobileHeader'
import SideDrawer from '../Common/SideDrawer/SideDrawer'

import './BranchManagement.scss'
import { convertToTitleCase } from '../../common/utils/appUtils'

const BranchManagement = props => {
    const navigate = useNavigate()
    const isMobile = useCheckMobileScreen()

    const [showMap, setShowMap] = useState(false)
    const [viewDrawer, setViewDrawer] = useState(false)
    const [open, setOpen] = useState(false)
    const [branchName, setBranchName] = useState(undefined)
    const [countryCode, setCountryCode] = useState(undefined)
    const [phone, setPhone] = useState(undefined)
    const [email, setEmail] = useState(undefined)
    const [locationString, setLocationString] = useState('')
    const [latitude, setLatitude] = useState(undefined)
    const [longitude, setLongitude] = useState(undefined)
    const [addressComponent, setAddressComponent] = useState(undefined)
    const [placeId, setPlaceId] = useState(undefined)
    // const [latitude, setLatitude] = useState(13.77)
    // const [longitude, setLongitude] = useState(72.77) // TODO: update this with longitude
    const [branchData, setBranchData] = useState(undefined)
    const [addBranchAlert, setAddBranchAlert] = useState(false)
    const [updateBranchAlert, setUpdateBranchAlert] = useState(false)
    const [headerOptions, setHeaderOptions] = useState({
        [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
        [HEADER_CONFIG.TITLE]: HEADER_CONST.BRANCH_MANAGEMENT,
        [HEADER_CONFIG.HANDLE_BACK_FUNC]: () => props?.onBackClick()
    })
    const [isFieldErrorVisible, setIsFieldErrorVisible] = useState(false)
    const [isEditFlow, setIsEditFlow] = useState(false)
    const [selectedBranchData, setSelectedBranchData] = useState(undefined)
    const [permissions, setPermissions] = useState({})

    useEffect(() => {
        let addBranchAlert = props.alert?.addBranchAlert
        let updateBranchAlert = props.alert?.updateBranchAlert
        setAddBranchAlert(addBranchAlert)
        setUpdateBranchAlert(updateBranchAlert)
    }, [props.alert?.addBranchAlert, props.alert?.updateBranchAlert])

    useEffect(() => {
        getBranchData()
    }, [])

    const handleHeaderConfigUpdate = (title, handleBackFunc) => {
        setHeaderOptions({
            [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
            [HEADER_CONFIG.TITLE]: title,
            [HEADER_CONFIG.HANDLE_BACK_FUNC]: () => handleBackFunc()
        })
    }

    useEffect(() => {
        if (!open) {
            handleHeaderConfigUpdate(HEADER_CONST.BRANCH_MANAGEMENT, () =>
                props?.onBackClick()
            )
            return
        }

        handleHeaderConfigUpdate(HEADER_CONST.ADD_BRANCH_MANAGEMENT, () =>
            handleDrawerToggle()
        )
    }, [open])

    useEffect(() => {
        if (props.loginData.permissions) {
            let permission = props.loginData.permissions.filter(
                permission => permission.screen === PERMISSION_SCREEN.OTHER
            )[0]

            if (permission) setPermissions(permission)
        }
    }, [props.loginData])

    const getBranchData = () => {
        getBranchHelper()
            .then(res => {
                console.log('br res: ', res)
                setBranchData(res)
            })
            .catch(err => {
                console.log('err: ', err)
            })
    }

    const handleDrawerToggle = () => {
        if (open) resetEditData()
        setViewDrawer(!viewDrawer)
        setOpen(!open)
    }

    const handleCreateAccountValidation = () => {
        return branchName && phone && email && locationString
        // && latitude
        // && longitude
        // && locationString
    }

    const handleSubmit = () => {
        if (isEditFlow) {
            updateBranchDetails()
            return
        }

        if (!handleCreateAccountValidation()) {
            setIsFieldErrorVisible(true)
            return
        }

        let data = {
            name: branchName,
            lat: latitude,
            long: longitude,
            location: locationString,
            email: email,
            mobile_no: phone,
            address_component: addressComponent,
            place_id: placeId
        }

        addBranchHelper(data)
            .then(res => {
                console.log('res: ', res)

                props.addBranchAlertAction(true)
                setTimeout(() => {
                    props.addBranchAlertAction(false)
                }, 5000)
                handleDrawerToggle()
                getBranchData()
            })
            .catch(err => {
                console.log('err: ', err)
            })
    }

    const handleLocationSave = (
        selected,
        selectedString,
        placeId,
        addressComponent
    ) => {
        console.log('selected: ', selected, ' selectedString: ', selectedString)
        setLocationString(selectedString)
        setLatitude(selected?.lat)
        setLongitude(selected?.lng)
        setPlaceId(placeId)
        setAddressComponent(addressComponent)
        setShowMap(false)
    }

    const handleLocationCancel = () => {
        setLatitude(undefined)
        setLongitude(undefined)
        setLocationString(undefined)
        setPlaceId(undefined)
        setAddressComponent(undefined)
        setShowMap(false)
    }

    const handleBranchEditClick = branchData => {
        setIsEditFlow(true)
        // set selected branch data
        setSelectedBranchData(branchData)
        setBranchName(branchData?.name)
        setPhone(branchData?.mobile_no)
        setEmail(branchData?.email)
        setLocationString(branchData?.location)

        handleDrawerToggle()
    }

    const updateBranchDetails = () => {
        if (!branchName && !email) {
            setIsFieldErrorVisible(true)
            return
        }

        updateBranchDetailsHelper(selectedBranchData?.id, branchName, email)
            .then(res => {
                console.log('res: ', res)

                props.updateBranchAlertAction(true)

                setTimeout(() => {
                    props.updateBranchAlertAction(false)
                    // resetEditData()
                }, 5000)

                handleDrawerToggle()
                getBranchData()
            })
            .catch(err => {
                console.log('err: ', err)
            })
    }

    const resetEditData = () => {
        setIsEditFlow(false)
        setSelectedBranchData(undefined)
        setBranchName(undefined)
        setPhone(undefined)
        setEmail(undefined)
        setLocationString(undefined)
    }

    return (
        <>
            {isMobile && <MobileHeader headerOptions={headerOptions} />}
            {addBranchAlert && (
                <Alert
                    success
                    alertType="success"
                    alertMessage={'New Branch added Successfully!'}
                />
            )}
            {updateBranchAlert && (
                <Alert
                    success
                    alertType="success"
                    alertMessage={'Branch updated Successfully!'}
                />
            )}
            <div className="pageBodyRightPanel">
                {isMobile &&
                    permissions?.features?.[
                        PERMISSION_FEATURE.BRANCH_MANAGEMENT
                    ] === PERMISSION_TYPE.MANAGE && (
                        <div className="addProductBtnMobile dFlex aiCenter mwebVisible fixed">
                            <button
                                className="animated zoomIn btn btnPrimary"
                                onClick={handleDrawerToggle}>
                                <AddIcon />
                            </button>
                        </div>
                    )}
                {!isMobile && (
                    <div className="pageBodyRightPanelHeader dFlex jcSpaceBetween aiCenter">
                        <div className="pageBodyRightPanelHeading">
                            Branch Management
                        </div>
                        <div className="addProductBtn dFlex aiCenter">
                            {permissions?.features?.[
                                PERMISSION_FEATURE.BRANCH_MANAGEMENT
                            ] === PERMISSION_TYPE.MANAGE && (
                                <button
                                    className="btn btnPrimary"
                                    onClick={handleDrawerToggle}>
                                    ADD
                                </button>
                            )}
                            <div
                                className="pointer ml-4"
                                onClick={() => props?.onClose()}>
                                <CloseIcon />
                            </div>
                        </div>
                    </div>
                )}
                <div className="productLlistWrap dFlex">
                    {branchData &&
                        branchData.map(branch => {
                            return (
                                <div className="cardWrap">
                                    <Card
                                        users
                                        cardAction={
                                            permissions?.features?.[
                                                PERMISSION_FEATURE
                                                    .BRANCH_MANAGEMENT
                                            ] === PERMISSION_TYPE.MANAGE &&
                                            CARD_ACTION.BRANCH_MANAGEMENT
                                        }
                                        onCardAction={action =>
                                            handleBranchEditClick(
                                                branch,
                                                action
                                            )
                                        }
                                        phone={branch.mobile_no}
                                        email={branch.email}
                                        location={branch.location}
                                        // userInitials="A"
                                        userInitials={convertToTitleCase(
                                            branch?.name?.[0]
                                        )}
                                        // headerSup='+880 865432567'
                                        headerMain={branch.name}
                                    />
                                </div>
                            )
                        })}
                </div>
            </div>
            {open && (
                <SideDrawer
                    handleDrawerToggle={handleDrawerToggle}
                    open={open}
                    drawerButton={showMap ? false : true}
                    drawerHeading={
                        isEditFlow ? 'Edit Branch' : 'Add New Branch'
                    }
                    handleClick={handleSubmit}
                    // mwebSideDrawerFooter
                >
                    {!showMap && (
                        <form
                            onSubmit={e => e.preventDefault()}
                            className="mt-5">
                            {/* <> */}
                            <div className="formGroup formFloating">
                                <input
                                    type="text"
                                    className="formControl"
                                    id="branchName"
                                    placeholder="Branch Name"
                                    value={branchName}
                                    onChange={e => {
                                        setIsFieldErrorVisible(false)
                                        setBranchName(e.target.value)
                                    }}
                                    required
                                />
                                <label for="floatingSelect">Branch Name</label>
                                {isFieldErrorVisible && !branchName && (
                                    <div className="invalidFeedback">
                                        Please choose Branch Name.
                                    </div>
                                )}
                            </div>
                            <div className="formInline dFlex flexNowrap">
                                <div className="formGroup formFloating fgrow1 maxWidth">
                                    <select
                                        disabled={isEditFlow}
                                        className="formSelect"
                                        id="floatingSelect">
                                        {/* <option value="1">+91</option> */}
                                        <option value="2">+88</option>
                                    </select>
                                    <label for="floatingSelect">Country</label>
                                    {false && (
                                        <div className="invalidFeedback">
                                            Please choose country code.
                                        </div>
                                    )}
                                </div>
                                <div className="formGroup formFloating ml-4 fgrow1">
                                    <input
                                        disabled={isEditFlow}
                                        type="text"
                                        className="formControl"
                                        id="phone"
                                        placeholder="Phone"
                                        value={phone}
                                        onChange={e => {
                                            setIsFieldErrorVisible(false)
                                            setPhone(e.target.value)
                                        }}
                                        required
                                    />
                                    <label for="phone">Phone</label>
                                    {isFieldErrorVisible && !phone && (
                                        <div className="invalidFeedback">
                                            Please select a valid Phone.
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="formGroup formFloating">
                                <input
                                    type="text"
                                    className="formControl"
                                    id="email"
                                    placeholder="Email Address"
                                    value={email}
                                    onChange={e => {
                                        setIsFieldErrorVisible(false)
                                        setEmail(e.target.value)
                                    }}
                                    required
                                />
                                <label for="address">Email Address</label>
                                {isFieldErrorVisible && !email && (
                                    <div className="invalidFeedback">
                                        Please enter a valid Email Address.
                                    </div>
                                )}
                            </div>
                            <div className="formGroup formFloating formInputText">
                                <div className="preInputGroup">
                                    <div className="preInputText">
                                        <LocationIcon />
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    disabled={isEditFlow}
                                    className="formControl"
                                    id="location"
                                    placeholder="Location"
                                    value={locationString}
                                    autocomplete="off"
                                    onClick={() => setShowMap(true)}
                                    required
                                />
                                <label for="address">Location</label>
                                {isFieldErrorVisible && !locationString && (
                                    <div className="invalidFeedback">
                                        Please enter a valid Location.
                                    </div>
                                )}
                            </div>

                            {/* </> */}
                        </form>
                    )}
                    {showMap && (
                        <div className="locationContaine">
                            <div className="welcomeHeading">
                                <span>LOCATION</span>
                            </div>

                            {/* <LocatorButton mapObject={mapObject} /> */}
                            {/* <CustomMap setMapObject={setMapObject} /> */}
                            <CustomMap
                                onClose={handleLocationCancel}
                                onSave={handleLocationSave}
                            />
                        </div>
                    )}
                </SideDrawer>
            )}
        </>
    )
}

MobileHeader.propTypes = {
    alert: PropTypes.any,
    addBranchAlertAction: PropTypes.any,
    updateBranchAlertAction: PropTypes.any,
    onBackClick: PropTypes.func
}

const mapStateToProps = state => {
    return {
        alert: state.alertReducer.alert,
        loginData: state.loginDataReducer?.loginData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addBranchAlertAction: payload =>
            dispatch(addBranchAlertAction(payload)),
        updateBranchAlertAction: payload =>
            dispatch(updateBranchAlertAction(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BranchManagement)
