import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import BackIcon from '../../../assets/BackIcon'
import NotificationIcon from '../../../assets/NotificationActiveIcon'
import SearchIcon from '../../../assets/SearchIcon'

import './MobileHeader.scss'
import { HEADER_CONFIG } from '../../../common/constant/common-constant'
import { redirect } from '../../../common/utils/redirectionUtils'
import { useNavigate } from 'react-router-dom'
import { PAGE_CONST } from '../../../common/constant/page-constant'

const MobileHeader = props => {
    const navigate = useNavigate()

    const [viewSearch, setViewSearch] = useState(false)
    const inputRef = useRef()

    const expandSearch = () => {
        inputRef.current.focus(setViewSearch(true))
    }

    const handleClick = () => {
        redirect(navigate, PAGE_CONST.NOTIFICATION)
    }

    return (
        <header className="mobileHeaderContainer fixed">
            <div className="mobileHeader dFlex jcSpaceBetween aiCenter">
                <div
                    className="dFlex backButton"
                    onClick={() =>
                        props?.headerOptions?.[HEADER_CONFIG.HANDLE_BACK_FUNC]()
                    }>
                    {props?.headerOptions?.[HEADER_CONFIG.IS_BACK_VISIBLE] && (
                        <BackIcon />
                    )}
                </div>
                <div className="productHeaderName">
                    {props?.headerOptions?.[HEADER_CONFIG.TITLE]}
                </div>
                <div className="headerRight dFlex aiCenter">
                    {/* <div className={`formGroup mb-0 searchBar ${viewSearch ? 'expand' : ''}`} onClick={expandSearch} onBlur={() => setViewSearch(false)}>
                        <input ref={inputRef} type='text' className='formControl' placeholder='Search' />
                        <div className={`searchIcon`}><SearchIcon /></div>
                    </div> */}
                    <div
                        className="notification dFlex ml-2"
                        onClick={handleClick}>
                        <NotificationIcon />
                    </div>
                </div>
            </div>
        </header>
    )
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {}
}

MobileHeader.propTypes = {
    headerOptions: {
        isBackVisible: PropTypes.bool.isRequired,
        title: PropTypes.string.isRequired,
        onBackClick: PropTypes.func
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader)
