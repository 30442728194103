import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    GoogleMap,
    useLoadScript,
    Marker,
    InfoWindow
} from '@react-google-maps/api'

import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
    getDetails
} from 'use-places-autocomplete'

import './CustomMap.scss'
import { redirect } from '../../common/utils/redirectionUtils'
import { PAGE_CONST } from '../../common/constant/page-constant'
import LocationIcon from '../../assets/LocationIcon'
import { placeComponentToAddress } from '../../common/utils/appUtils'

function CustomMap(props) {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyCXeEP7QcTq7N-Or5V9PjP0FKgppyZbT8U',
        libraries: ['places']
    })

    if (!isLoaded) return <div> Loading... </div>

    return <Map onClose={() => props.onClose()} onSave={props.onSave} />
}

function Map(props) {
    const navigate = useNavigate()

    const center = useMemo(() => ({ lat: 32.04408, lng: 76.72337 }), [])
    // const [center, setCenter] = useState({lat: 32.04408, lng: 76.72337});
    const [selected, setSelected] = useState(center)
    const [addressComponent, setAddressComponent] = useState({})
    const [placeId, setPlaceId] = useState(undefined)
    const [selectedString, setSelectedString] = useState(null)
    const [isFieldErrorVisible, setIsFieldErrorVisible] = useState(false)

    useEffect(() => {
        getUserCurrentLocation()
        // check userlocation save it and set the selected to this location
    }, [])

    const getUserCurrentLocation = async () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async position => {
                    console.log('position: ', position)

                    if (position.coords) {
                        let location = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        }
                        setSelected(location)

                        getAddressStringFromLatLng(location.lat, location.lng)
                    }
                },
                error => {
                    console.log('error: ', error)
                },
                { timeout: 10000 }
            )
        }

        console.log('selected lat lng', selected)
    }

    const getAddressStringFromLatLng = async (lat, lng) => {
        let location = { lat, lng }
        let results = await getGeocode({ location })
        let addressString = results[0]?.formatted_address
        let place_id = results[0]?.place_id
        let addressComponent = placeComponentToAddress(results[0])

        setAddressComponent(addressComponent)
        setSelectedString(addressString)
        setPlaceId(place_id)
    }

    const onMarkerDragEnd = async event => {
        let lat = event.latLng.lat()
        let lng = event.latLng.lng()

        console.log('drag:', lat, lng)

        getAddressStringFromLatLng(lat, lng)
        setSelected(event.latLng)
    }

    const handleValidation = () => {
        return selected && selected.lat && selected.lng && selectedString
    }

    const handleSubmit = () => {
        if (!handleValidation()) {
            setIsFieldErrorVisible(true)
            return
        }

        setIsFieldErrorVisible(true)
        props.onSave(selected, selectedString, placeId, addressComponent)
    }

    return (
        <div className="places-container dFlex d-col">
            <PlacesAutoComplete
                className={'place-autocomplete mb-4'}
                setSelected={setSelected}
                setAddressComponent={setAddressComponent}
                selectedString={selectedString}
                setSelectedString={setSelectedString}
                setPlaceId={setPlaceId}
            />

            <div className="map-container">
                <GoogleMap
                    style="width: 100%; height: 100%"
                    zoom={18}
                    center={selected}
                    id="map-component"
                    mapContainerClassName="map-component">
                    {(selected || true) && (
                        <Marker
                            position={selected}
                            draggable={true}
                            // label={selectedString}
                            onDragEnd={event => onMarkerDragEnd(event)}
                        />
                    )}
                </GoogleMap>
            </div>
            {isFieldErrorVisible && (
                <div className="invalidFeedback">
                    Please Select a Valid Location.
                </div>
            )}
            <div className="actionButton mt-5 dFlex">
                <button
                    className="btn btnPrimaryOutlined dFlex"
                    onClick={() => props.onClose()}>
                    Cancel
                </button>
                <button
                    className="btn btnPrimary dFlex ml-5"
                    onClick={handleSubmit}>
                    Save
                </button>
            </div>
        </div>
    )
}

const PlacesAutoComplete = ({
    setSelected,
    setSelectedString,
    setAddressComponent,
    selectedString,
    setPlaceId,
    className
}) => {
    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions
    } = usePlacesAutocomplete()

    useEffect(() => {
        if (selectedString) setValue(selectedString)
    }, [selectedString])

    // function placeToAddress(place) {
    //     if (!place) return
    //     var addressComponent = {}
    //     place.address_components.forEach(function (component) {
    //         switch (component.types[0]) {
    //             case 'street_number':
    //                 addressComponent.streetNumber =
    //                     component?.long_name || component?.short_name
    //                 break
    //             case 'route':
    //                 addressComponent.streetName =
    //                     component?.long_name || component?.short_name
    //                 break
    //             case 'neighborhood':
    //             case 'locality': // North Hollywood or Los Angeles?
    //                 addressComponent.city =
    //                     component?.long_name || component?.short_name
    //                 break
    //             case 'administrative_area_level_1': //  Note some countries don't have states
    //                 addressComponent.state =
    //                     component?.long_name || component?.short_name
    //                 break
    //             case 'postal_code':
    //                 addressComponent.zip =
    //                     component?.long_name || component?.short_name
    //                 break
    //             case 'country':
    //                 addressComponent.country =
    //                     component?.long_name || component?.short_name
    //                 break
    //         }
    //     })

    //     console.log('addressComponent: ', addressComponent)
    //     setAddressComponent(addressComponent)
    //     return addressComponent
    // }

    const getLatLngFromAddressString = async (addressString, placeId) => {
        const results = await getGeocode({ address: addressString })

        console.log('results from address string: ', results)
        const { lat, lng } = getLatLng(results[0])
        const addressComponent = placeComponentToAddress(results[0])
        setAddressComponent(addressComponent)
        setSelected({ lat, lng })
    }

    const handleSelect = address => {
        if (!address) return

        setValue(address.description, false)
        clearSuggestions()

        console.log('address: ', address)
        setPlaceId(address.place_id)
        setSelectedString(address.description)
        // get geocode from address and setSelectted and address string
        getLatLngFromAddressString(address.description, address.place_id)
    }

    return (
        <div className={className}>
            <div className="formSection">
                <div className="formGroup formFloating fgrow1 mb-0 relative">
                    <input
                        value={value}
                        onChange={e => setValue(e.target.value)}
                        disabled={!ready}
                        className="autocomplete-input formControl"
                        placeholder="Search an address"
                        type="text"
                        id="location"
                        // value={locationString}
                        required
                    />
                    <label for="location">Search an address</label>
                    {/* <div className="invalidFeedback">
                    Please Choose Location.
                </div> */}
                    <div className="positionedIcon">
                        <LocationIcon />
                    </div>
                </div>

                {status === 'OK' && (
                    <div className="results-pane">
                        {data.map(address => {
                            return (
                                <p
                                    key={address.place_id}
                                    className="results-pane-option"
                                    onClick={() => handleSelect(address)}>
                                    {address.description}
                                </p>
                            )
                        })}
                    </div>
                )}
            </div>
        </div>
    )
}

export default CustomMap
