import React from 'react'

const HomeIcon = () => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Home">
            <path id="Vector" d="M12.3083 2.95373C12.7817 2.55475 13.3809 2.33594 14 2.33594C14.6191 2.33594 15.2183 2.55475 15.6917 2.95373L23.5667 9.59439C24.1582 10.0937 24.5 10.8276 24.5 11.6011V22.4627C24.5 23.0042 24.2849 23.5235 23.902 23.9064C23.5191 24.2893 22.9998 24.5044 22.4583 24.5044H18.375C17.8335 24.5044 17.3142 24.2893 16.9313 23.9064C16.5484 23.5235 16.3333 23.0042 16.3333 22.4627V16.6212C16.3333 16.5439 16.3026 16.4697 16.2479 16.415C16.1932 16.3603 16.119 16.3296 16.0417 16.3296H11.9583C11.881 16.3296 11.8068 16.3603 11.7521 16.415C11.6974 16.4697 11.6667 16.5439 11.6667 16.6212V22.4627C11.6667 23.0042 11.4516 23.5235 11.0687 23.9064C10.6858 24.2893 10.1665 24.5044 9.625 24.5044H5.54167C5.27355 24.5044 5.00806 24.4516 4.76035 24.349C4.51265 24.2464 4.28758 24.096 4.09799 23.9064C3.9084 23.7168 3.75802 23.4917 3.65541 23.244C3.55281 22.9963 3.5 22.7308 3.5 22.4627V11.6011C3.5 10.8276 3.84183 10.0937 4.43333 9.59439L12.3083 2.95373ZM14.5635 4.29189C14.4058 4.15911 14.2062 4.08629 14 4.08629C13.7938 4.08629 13.5942 4.15911 13.4365 4.29189L5.5615 10.9314C5.46405 11.0134 5.38568 11.1158 5.33188 11.2312C5.27807 11.3467 5.25013 11.4725 5.25 11.5999V22.4616C5.25 22.6226 5.38067 22.7532 5.54167 22.7532H9.625C9.70235 22.7532 9.77654 22.7225 9.83124 22.6678C9.88594 22.6131 9.91667 22.5389 9.91667 22.4616V16.6201C9.91667 15.4919 10.8313 14.5784 11.9583 14.5784H16.0417C17.1687 14.5784 18.0833 15.4919 18.0833 16.6201V22.4616C18.0833 22.6226 18.214 22.7532 18.375 22.7532H22.4583C22.5357 22.7532 22.6099 22.7225 22.6646 22.6678C22.7193 22.6131 22.75 22.5389 22.75 22.4616V11.5999C22.7499 11.4725 22.7219 11.3467 22.6681 11.2312C22.6143 11.1158 22.536 11.0134 22.4385 10.9314L14.5635 4.29189Z" fill="#6E6E6E" />
        </g>
    </svg>
)

export default HomeIcon
