import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SIDEBAR_CONST } from '../../common/constant/common-constant'
import {
    HOME_CONST,
    HOME_NAVIGATION_ITEMS,
    PAGE_CONST,
    PERMISSION_SCREEN,
    PERMISSION_TYPE
} from '../../common/constant/page-constant'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import { redirect } from '../../common/utils/redirectionUtils'
import { activeHomeDetailNavigation } from '../../modules/actions/action'
import Header from '../Common/Header/Header'
import SideMenu from '../Common/SideMenu/SideMenu'
import DamageLoss from '../DamageLoss/DamageLoss'
import ExchangeReturn from '../ExchangeReturn/ExchangeReturn'
import ExpiredProducts from '../ExpiredProducts/ExpiredProducts'
import ManageCustomer from '../ManageCustomer/ManageCustomer'
import ManageInventory from '../ManageInventory/ManageInventory'
import ManageUsers from '../ManageUsers/ManageUsers'
import ProductReceiving from '../ProductReceiving/ProductReceiving'
import Reports from '../Reports/Reports'
import Sales from '../Sales/Sales'

import './HomeDetails.scss'

const HomeDetails = props => {
    const navigate = useNavigate()
    const isMobile = useCheckMobileScreen()

    const [activeItem, setActiveItem] = useState(undefined)
    const [permissions, setPermissions] = useState({})
    const [loginData, setLoginData] = useState({})

    useEffect(() => {
        setLoginData(props.loginData)

        if (props.loginData.permissions) {
            console.log(
                'props.loginData.permissions: ',
                props.loginData.permissions
            )

            let permission = props.loginData.permissions.reduce(
                (acc, permission) => {
                    acc.features = {
                        ...acc.features,
                        ...permission.features
                    }

                    return acc
                },
                {}
            )

            console.log('home screen permission: ', permission)
            if (permission) setPermissions(permission)
        }
    }, [props.loginData])

    useEffect(() => {
        if (props.config?.activeHomeDetailNavigation) {
            setActiveItem(props.config.activeHomeDetailNavigation)
        }
    }, [props.config?.activeHomeDetailNavigation])

    const handleItemClick = itemName => {
        props.activeHomeDetailNavigation(itemName)
    }

    const handleClose = () => {
        redirect(navigate, PAGE_CONST.HOME)
    }

    return (
        <div className="containerHome">
            {!isMobile && <Header />}
            <SideMenu
                activeItem={SIDEBAR_CONST.HOME}
                component={PAGE_CONST.HOME_DETAILS}
            />
            <div className="pageBody dFlex">
                {!isMobile && (
                    <div className="pageBodyLeftPanel sticky">
                        <div className="chainNavigationWrapper">
                            <ul className="chainNavigation">
                                {HOME_NAVIGATION_ITEMS.map(item => {
                                    if (
                                        permissions?.features?.[
                                            item.feature
                                        ] !== PERMISSION_TYPE.RESTRICTED
                                    ) {
                                        return (
                                            <li
                                                className={`chainItem ${
                                                    activeItem === item.name
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                onClick={() =>
                                                    handleItemClick(item.name)
                                                }>
                                                {item.homeDetailIcon}
                                                <span>{item.name}</span>
                                            </li>
                                        )
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                )}
                {activeItem === HOME_CONST.PRODUCT_RECEIVING && (
                    <ProductReceiving onClose={() => handleClose()} />
                )}
                {activeItem === HOME_CONST.SALES && (
                    <Sales onClose={() => handleClose()} />
                )}
                {activeItem === HOME_CONST.EXCHANGE_RETURN && (
                    <ExchangeReturn onClose={() => handleClose()} />
                )}
                {activeItem === HOME_CONST.INVENTORY && (
                    <ManageInventory onClose={() => handleClose()} />
                )}
                {activeItem === HOME_CONST.CUSTOMERS && (
                    <ManageCustomer onClose={() => handleClose()} />
                )}
                {activeItem === HOME_CONST.REPORTS && (
                    <Reports onClose={() => handleClose()} />
                )}
                {activeItem === HOME_CONST.DAMAGE_LOST && (
                    <DamageLoss onClose={() => handleClose()} />
                )}
                {activeItem === HOME_CONST.EXPIRED_PRODUCTS && (
                    <ExpiredProducts onClose={() => handleClose()} />
                )}
                {activeItem === HOME_CONST.USER_PERMISSION && (
                    <ManageUsers onClose={() => handleClose()} />
                )}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        config: state.configReducer.config,
        loginData: state.loginDataReducer?.loginData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        activeHomeDetailNavigation: payload =>
            dispatch(activeHomeDetailNavigation(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeDetails)
