import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '../../assets/CloseIcon'
import FilterIcon from '../../assets/FilterIcon'
import FilterIconBright from '../../assets/FilterIconBright'
import SearchIcon from '../../assets/SearchIcon'
import SortIcon from '../../assets/SortIcon'
import {
    HEADER_CONFIG,
    HEADER_CONST
} from '../../common/constant/common-constant'
import {
    INVENTORY_STAGES,
    INVENTORY_TABS,
    PAGE_CONST,
    PRODUCT_CATEGORY
} from '../../common/constant/page-constant'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import { formatDate } from '../../common/utils/appUtils'
import { redirect } from '../../common/utils/redirectionUtils'
import {
    getInventoryDetailsHelper,
    getInvoiceDetailsHelper,
    getProductDetailsHelper
} from '../../helper/home-helper'
import Card from '../Common/Card/Card'
import MobileHeader from '../Common/Header/MobileHeader'

import './ManageInventory.scss'
import ViewInventory from './ViewInventory'

const ManageInventory = props => {
    const navigate = useNavigate()
    const isMobile = useCheckMobileScreen()

    const [viewDrawer, setViewDrawer] = useState(false)
    const [open, setOpen] = useState(false)
    const [isViewInventoryActive, setViewInventoryActive] = useState(false)
    const [inventoryData, setInventoryData] = useState(undefined)
    const [productData, setProductData] = useState(undefined)
    const [invoiceData, setInvoiceData] = useState(undefined)
    const [activeTab, setActiveTab] = useState(INVENTORY_TABS.PHARAMCEUTICAL)
    const [inventoryStage, setInventoryStage] = useState(
        INVENTORY_STAGES.PRODUCT_LISTING
    )
    const [activeProduct, setActiveProduct] = useState(undefined)
    const [headerOptions, setHeaderOptions] = useState({
        [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
        [HEADER_CONFIG.TITLE]: HEADER_CONST.MANAGE_INVENTORY,
        [HEADER_CONFIG.HANDLE_BACK_FUNC]: () =>
            redirect(navigate, PAGE_CONST.HOME)
    })
    const inputRef = useRef()
    const [viewSearch, setViewSearch] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [filteredInventoryData, setFilteredInventoryData] =
        useState(inventoryData)

    useEffect(() => {
        getInventoryDetails()
    }, [])

    useEffect(() => {
        console.log('useEfeect:: ', open, inventoryStage)
        if (!open && inventoryStage === INVENTORY_STAGES.PRODUCT_LISTING) {
            handleHeaderConfigUpdate(HEADER_CONST.MANAGE_INVENTORY, () =>
                redirect(navigate, PAGE_CONST.HOME)
            )
            return
        }

        if (open) {
            handleHeaderConfigUpdate(HEADER_CONST.VIEW_INVENTORY, () => {
                handleDrawerToggle()
                setInventoryStage(INVENTORY_STAGES.PRODUCT_DETAILS)
            })
        } else if (inventoryStage === INVENTORY_STAGES.PRODUCT_DETAILS) {
            handleHeaderConfigUpdate(HEADER_CONST.VIEW_INVENTORY, () =>
                setInventoryStage(INVENTORY_STAGES.PRODUCT_LISTING)
            )
        }
    }, [open, inventoryStage])

    // useEffect(() => {
    //     if (inventoryStage === INVENTORY_STAGES.PRODUCT_LISTING) {
    //         handleHeaderConfigUpdate(HEADER_CONST.MANAGE_INVENTORY, () =>
    //             redirect(navigate, PAGE_CONST.HOME)
    //         )
    //         return
    //     }
    // }, [inventoryStage])

    useEffect(() => {
        let inventoryFilteredData = inventoryData?.filter(
            inventory =>
                inventory?.name
                    ?.toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                inventory?.generic_name
                    ?.toLowerCase()
                    .includes(searchTerm.toLowerCase())
        )

        setFilteredInventoryData(inventoryFilteredData)
    }, [searchTerm])

    const expandSearch = () => {
        inputRef.current.focus(setViewSearch(true))
    }

    const handleHeaderConfigUpdate = (title, handleBackFunc) => {
        setHeaderOptions({
            [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
            [HEADER_CONFIG.TITLE]: title,
            [HEADER_CONFIG.HANDLE_BACK_FUNC]: () => handleBackFunc()
        })
    }

    const getInventoryDetails = () => {
        getInventoryDetailsHelper()
            .then(res => {
                console.log('mc res: ', res)
                setInventoryData(res)
                setFilteredInventoryData(res)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const getProductDetails = id => {
        getProductDetailsHelper(id)
            .then(res => {
                console.log('mc res: ', res)
                setProductData(res)
                setInventoryStage(INVENTORY_STAGES.PRODUCT_DETAILS)
            })
            .catch(err => {
                console.log(err)
            })
    }

    // const handleProductIdClick = (id) => {

    //     getProductDetails();
    // }

    const getInvoiceDetails = id => {
        console.log('invoice details: ', id)
        getInvoiceDetailsHelper(id)
            .then(res => {
                console.log('mc res: ', res)
                setInvoiceData(res)
                handleDrawerToggle()
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleDrawerToggle = () => {
        setViewDrawer(!viewDrawer)
        setOpen(!open)
    }

    const handleProductListingClick = product => {
        setActiveProduct(product)
        getProductDetails(product.id)
    }

    return (
        <>
            {isMobile && <MobileHeader headerOptions={headerOptions} />}
            <div className="pageBodyRightPanel">
                {isMobile && (
                    <div className="addProductBtnMobile dFlex flexColumnReverse aiCenter mwebVisible fixed">
                        {inventoryStage ===
                            INVENTORY_STAGES.PRODUCT_LISTING && (
                            <div
                                className={'formGroup mb-0 searchBar expand'}
                                onClick={expandSearch}
                                onBlur={() => setViewSearch(false)}>
                                <input
                                    ref={inputRef}
                                    type="text"
                                    className="formControl"
                                    placeholder="Search By Product Name"
                                    onChange={e =>
                                        setSearchTerm(e?.target?.value)
                                    }
                                />
                                <div className={`searchIcon`}>
                                    <SearchIcon />
                                </div>
                            </div>
                        )}
                        <button className="animated zoomIn btn btnSecondary pointer mb-2">
                            <SortIcon />
                        </button>
                        <button className="animated zoomIn btn btnSecondary pointer mb-2">
                            <FilterIconBright />
                        </button>
                    </div>
                )}
                {!isMobile && (
                    <div className="pageBodyRightPanelHeader dFlex jcSpaceBetween aiCenter">
                        <div className="pageBodyRightPanelHeading">
                            Manage Inventory
                        </div>
                        <div className="addProductBtn dFlex aiCenter">
                            {inventoryStage ===
                                INVENTORY_STAGES.PRODUCT_LISTING && (
                                <div
                                    className={`formGroup mb-0 searchBar expand`}
                                    onClick={expandSearch}
                                    onBlur={() => setViewSearch(false)}>
                                    <input
                                        ref={inputRef}
                                        type="text"
                                        className="formControl"
                                        placeholder="Search By Product Name"
                                        onChange={e =>
                                            setSearchTerm(e?.target?.value)
                                        }
                                    />
                                    <div className={`searchIcon`}>
                                        <SearchIcon />
                                    </div>
                                </div>
                            )}
                            <div className="pointer ml-4">
                                <SortIcon />
                            </div>
                            <div className="pointer ml-4">
                                <FilterIcon />
                            </div>
                            <div
                                className="pointer ml-4"
                                onClick={() =>
                                    setInventoryStage(
                                        INVENTORY_STAGES.PRODUCT_LISTING
                                    )
                                }>
                                <CloseIcon />
                            </div>
                        </div>
                    </div>
                )}
                {inventoryStage === INVENTORY_STAGES.PRODUCT_LISTING && (
                    <ul className="tabs dFlex mt-5">
                        <li
                            className={
                                activeTab === INVENTORY_TABS.PHARAMCEUTICAL
                                    ? 'tabItem active'
                                    : 'tabItem'
                            }
                            onClick={() =>
                                setActiveTab(INVENTORY_TABS.PHARAMCEUTICAL)
                            }>
                            Pharmaceutical
                        </li>
                        <li
                            className={
                                activeTab === INVENTORY_TABS.NON_PHARMACEUTICAL
                                    ? 'tabItem active'
                                    : 'tabItem'
                            }
                            onClick={() =>
                                setActiveTab(INVENTORY_TABS.NON_PHARMACEUTICAL)
                            }>
                            Non Pharmaceutical
                        </li>
                    </ul>
                )}
                {inventoryStage === INVENTORY_STAGES.PRODUCT_DETAILS && (
                    <div className="itemBased">
                        <div className="itemName">{activeProduct.name}</div>
                        <div className="itemDetails">
                            <span className="item name">
                                {activeProduct.origin}
                            </span>
                            <span className="item type">
                                {activeProduct.category}
                            </span>
                            <span className="item space">{`${activeProduct.dose}`}</span>
                        </div>
                    </div>
                )}
                {inventoryStage === INVENTORY_STAGES.PRODUCT_LISTING && (
                    <div className="productLlistWrap dFlex">
                        {filteredInventoryData &&
                            filteredInventoryData.map(inventory => {
                                let html = (
                                    <div className="cardWrap">
                                        <Card
                                            headerProduct={{
                                                productType: inventory.origin,
                                                productCategory:
                                                    inventory.category,
                                                quantity: `Qty: ${inventory.quantity}`
                                            }}
                                            noHeaderBorder
                                            strong={false}
                                            productDesc={`Generic Name: ${inventory.generic_name}`}
                                            headerMain={`${inventory.name} ${inventory.dose}`}
                                            handleClick={() =>
                                                handleProductListingClick(
                                                    inventory
                                                )
                                            }
                                            // handleClick={() => setViewInventoryActive(true)}
                                        />
                                    </div>
                                )
                                if (
                                    inventory.type ===
                                        PRODUCT_CATEGORY.PHARMACEUTICAL &&
                                    activeTab === INVENTORY_TABS.PHARAMCEUTICAL
                                ) {
                                    return html
                                } else if (
                                    inventory.type ===
                                        PRODUCT_CATEGORY.NON_PHARMACEUTICAL &&
                                    activeTab ===
                                        INVENTORY_TABS.NON_PHARMACEUTICAL
                                ) {
                                    return html
                                } else {
                                    return
                                }
                            })}
                    </div>
                )}
                {inventoryStage === INVENTORY_STAGES.PRODUCT_DETAILS && (
                    <div className="productLlistWrap dFlex">
                        {productData &&
                            productData.map(product => {
                                let html = (
                                    <div className="cardWrap">
                                        <Card
                                            headerProduct={{
                                                productType: `Invoice No.: ${product.invoice_number}`,
                                                productCategory: `Amount: BDT ${parseFloat(
                                                    product?.amount
                                                ).toFixed(2)}`,
                                                quantity: `Qty: ${product.total_quantity}`
                                            }}
                                            noHeaderBorder
                                            strong={false}
                                            headerSup={`Expiry Date: ${formatDate(
                                                product.expiry_date
                                            )}`}
                                            headerMain={`${product.pharma_company}`}
                                            handleClick={() =>
                                                getInvoiceDetails(
                                                    product.product_receiving_id
                                                )
                                            }
                                        />
                                    </div>
                                )

                                return html
                            })}
                    </div>
                )}
                <ViewInventory
                    open={open}
                    handleDrawerToggle={() => handleDrawerToggle()}
                    invoiceData={invoiceData}
                />
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageInventory)
