import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

import './Login.scss'
import {
    loginDataAction,
    notificationActiveAction,
    testAction
} from '../../modules/actions/action'
import { redirect } from '../../common/utils/redirectionUtils'
import {
    GET_PERMISSION_INITIAL_DATA,
    PAGE_CONST,
    PERMISSION_TYPE
} from '../../common/constant/page-constant'

import LogoLarge from '../../assets/LogoLarge'
import { loginHelper } from '../../helper/login-helper'
import LoginLeft from './LoginLeft'
import { getPersonalDetailsHelper } from '../../helper/personal-details-helper'
import AddIcon from '../../assets/AddIcon'
import EyeOpen from '../../assets/EyeOpen'
import EyeClose from '../../assets/EyeClose'

function Login(props) {
    const navigate = useNavigate()

    const [areaCode, setAreaCode] = useState(null)
    const [phoneNumber, setPhoneNumber] = useState(null)
    const [password, setPassword] = useState(null)
    const [isFieldErrorVisible, setIsFieldErrorVisible] = useState(false)
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const [err, setErr] = useState('')

    const handleCreateAccount = () => {
        redirect(navigate, PAGE_CONST.CREATE_ACCOUNT)
    }

    const handleResetAccount = () => {
        redirect(navigate, PAGE_CONST.RESET_PASSWORD)
    }

    const checkValidation = () => {
        return phoneNumber && password
    }

    const handleLogin = () => {
        if (!checkValidation()) {
            setIsFieldErrorVisible(true)
            return
        }

        setIsFieldErrorVisible(false)

        let data = { phoneNumber, password }
        loginHelper(data)
            .then(res => {
                props.loginDataAction({ phoneNumber: phoneNumber })

                if (res.is_new_notification) {
                    props.dispatchNotificationActiveAction(true)
                }

                setUserData(res.user)
                if (!res.user?.is_verified) {
                    redirect(navigate, PAGE_CONST.OTP_VERIFICATION)
                    return
                } else if (!res.pharmacy.is_verified) {
                    redirect(navigate, PAGE_CONST.DOCUMENT_UPLOAD)
                    return
                } else if (res.user?.is_verified && res.pharmacy?.is_verified) {
                    redirect(navigate, PAGE_CONST.HOME)
                } else {
                    // some Error msg
                }
            })
            .catch(err => {
                setErr(err)
            })
    }

    const setUserData = user => {
        // set owner manage permissions
        if (user.user_type === 'OWNER') {
            let intialPermission = GET_PERMISSION_INITIAL_DATA()

            let updatedPermission = intialPermission.map(permissionScreen => {
                let features = permissionScreen.permissionList.reduce(
                    (acc, item) => {
                        acc[item.name] = PERMISSION_TYPE.MANAGE
                        return acc
                    },
                    {}
                )
                return {
                    screen: permissionScreen.name,
                    features: features
                }
            })

            user.permissions = updatedPermission
            console.log('updated Permission: ', updatedPermission)
        }

        props.loginDataAction(user)
    }

    return (
        <>
            <main className="loginPage dFlex jcSpaceBetween flexNowrap">
                <LoginLeft />
                <div className="loginRight">
                    <div className="loginRightControl verticalCenter">
                        <div className="login-content">
                            <div className="welcomeHeading">
                                Welcome to <span>DG ePharma</span>
                            </div>
                            {/* <div className="welcomeDesc">
                                Lorem ipsum dolor sit amet consectetur. Arcu
                                mattis donec eu etiam amet.
                            </div> */}
                        </div>
                        <form onSubmit={e => e.preventDefault()}>
                            <div className="formInline dFlex flexNowrap">
                                <div className="formGroup formFloating fgrow1 maxWidth">
                                    <select
                                        className="formSelect"
                                        id="floatingSelect"
                                        onChange={e =>
                                            setAreaCode(e.target.value)
                                        }>
                                        {/* <option value="1">+91</option> */}
                                        <option value="2">+88</option>
                                    </select>
                                    <label for="floatingSelect">Country</label>
                                    {isFieldErrorVisible && false && (
                                        <div class="invalidFeedback">
                                            Please choose country code.
                                        </div>
                                    )}
                                </div>
                                <div className="formGroup formFloating ml-4 fgrow1">
                                    <input
                                        type="text"
                                        className="formControl"
                                        id="phone"
                                        placeholder="Phone Number"
                                        onChange={e => {
                                            setErr('')
                                            setPhoneNumber(e.target.value)
                                        }}
                                        required
                                    />
                                    <label for="phone">Phone Number</label>
                                    {isFieldErrorVisible && !phoneNumber && (
                                        <div class="invalidFeedback">
                                            Please select a valid Phone Number.
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="formGroup formFloating formInputText">
                                <input
                                    type={`${
                                        isPasswordVisible ? 'text' : 'password'
                                    }`}
                                    className="formControl"
                                    id="password"
                                    placeholder="Password"
                                    onChange={e => {
                                        setErr('')
                                        setPassword(e.target.value)
                                    }}
                                    required
                                />
                                <div className="postInputGroup">
                                    <div className="postInputIcon">
                                        <button
                                            onClick={() =>
                                                setIsPasswordVisible(
                                                    !isPasswordVisible
                                                )
                                            }>
                                            {isPasswordVisible ? (
                                                <EyeClose />
                                            ) : (
                                                <EyeOpen />
                                            )}
                                        </button>
                                    </div>
                                </div>
                                <label for="password">Password</label>
                                {/* <PasswordView /> */}
                                {/* <PasswordHide /> */}
                                {isFieldErrorVisible && !password && (
                                    <div class="invalidFeedback">
                                        Please enter a valid Password.
                                    </div>
                                )}
                            </div>
                            <div
                                className="forgotPassword"
                                onClick={handleResetAccount}>
                                Forgot Password
                            </div>
                            {err && <div class="invalidFeedback">{err}</div>}
                            <button
                                className="btn btnPrimary block space"
                                onClick={() => handleLogin()}>
                                Sign In
                            </button>
                        </form>
                        <div className="createAccountWrap">
                            <div className="createAccountText">
                                Don’t have an Account?
                            </div>
                            <div
                                className="createAccount"
                                onClick={handleCreateAccount}>
                                CREATE AN ACCOUNT
                            </div>
                        </div>
                        <div className="loginFooter"></div>
                    </div>
                </div>
            </main>
        </>
    )
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        loginDataAction: payload => dispatch(loginDataAction(payload)),
        dispatchNotificationActiveAction: payload =>
            dispatch(notificationActiveAction(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
